import React from 'react'
import moment from 'moment';
import I18nManager from '../../../../../core/I18nManager/I18nManager';

export default function AssistanteChooseCalendar(props) {
    const { onChange, value, TimeSlots, onSelectChange, disabled } = props
    return (
        <>
            <input {...props} className="form-control" onChange={onChange} type="date" min={moment(new Date()).format('YYYY-MM-DD')} value={value} />
            {TimeSlots?.length > 0 && <select className="form-control mt-20" onChange={onSelectChange} disabled={disabled}>
                {TimeSlots?.map((item, index) => (
                    <option key={index} value={index}>{item?.timeFrom?.[I18nManager.isRTL() ? 'ar' : 'en']}</option>
                ))}
            </select>}
        </>
    )
}
