import React, { useEffect } from 'react'
import './ModalSubmit.css';
import Modal from 'react-modal';
import { modalStyle } from '../../../core/styles/custom-styles';

export default function ModalSubmit(props) {

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    const { text, uri, leftButtonText, rightButtonText, onLeftButtonClick, onRightButtonClick, loading } = props
    return (
        <Modal {...props} style={modalStyle} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
            <div className="d-flex flex-column p-20 align-items-center">
                <img src={uri} alt="" width="100px" height="100px" />
                <span className="modal-text pt-20 pb-10">{text}</span>
            </div>
            <div className="d-flex flex-row">
                <button disabled={loading} className="modal-button-yes" onClick={onLeftButtonClick}>
                    {leftButtonText}
                </button>
                <button disabled={loading} className="modal-button-no" onClick={onRightButtonClick}>
                    {rightButtonText}
                </button>
            </div>
        </Modal>
    )
}
