import React, { useEffect, useState, useContext } from 'react'
import './SideFilter.css';
import I18nManager from '../../../../core/I18nManager/I18nManager';
import Accordion from '../../accordion-collapse/Accordion';
import FilterCheckBox from '../filter-checkbox/FilterCheckBox';
import FilterCheckBoxRating from '../filter-checkbox/FilterCheckBoxRating';
import FilterRadioButton from '../filter-radio-button/FilterRadioButton';
import FilterContext from '../../../../core/store/FilterContext';
import { getLocalStorageItem, setLocalStorageItem, deleteLocalStorageItem } from '../../../../core/common-service/CommonService';
import { postGetEducationLevel, postGetNationality } from '../../../../core/api/Api';

const SideFilter = ({ }) => {
    const { filters, ourSpecialities, setFilterContextValue } = useContext(FilterContext)

    const onSpecialityClick = (index) => () => {
        let result = [...ourSpecialities]
        result.forEach((element, i) => {
            element.active = parseInt(index) === i
        });
        setFilterContextValue('ourSpecialities', result)
    }

    const onFilterClick = (index, type) => () => {
        let result = [...filters?.[type]]
        result.forEach((element, i) => {
            element.active = parseInt(index) === i
        });
        setFilterContextValue('filters', { ...filters, [type]: result })
    }

    const [showMore, setShowMore] = useState(false)
    const onShowMoreClick = () => {
        setShowMore(true)
    }

    const getEducationLevel = (nextData) => {
        postGetEducationLevel().then(res => {
            const response = res.data.data
            response.unshift({ EducationLevelID: null, Name: "Any", NameAr: "كل", active: false })
            const item = JSON.parse(getLocalStorageItem('FilterContext'))
            if (item?.filters?.boardCertificates?.length > 0) {
                const natIndex = item?.filters?.boardCertificates?.findIndex(el => el.active === true)
                if (natIndex !== -1) {
                    response[natIndex].active = true
                } else {
                    response[0].active = true
                }
            }
            if (response) {
                setFilterContextValue('filters', { ...nextData, boardCertificates: response })
            }
        }).catch(e => getEducationLevel())
    }

    const getNationalities = () => {
        postGetNationality().then(res => {
            const response = res.data.data
            response.unshift({ NationalityID: null, Name: "Any", NameAr: "كل", active: false })
            const item = JSON.parse(getLocalStorageItem('FilterContext'))
            if (item?.filters?.nationality?.length > 0) {
                const natIndex = item?.filters?.nationality?.findIndex(el => el.active === true)
                if (natIndex !== -1) {
                    response[natIndex].active = true
                } else {
                    response[0].active = true
                }
            }
            if (response) {
                const nextData = { ...item?.filters, nationality: response }
                getEducationLevel(nextData)
            }
        }).catch(e => getNationalities())
    }

    useEffect(() => {
        try {
            const item = JSON.parse(getLocalStorageItem('FilterContext'))
            if (item) {
                setFilterContextValue('filters', item?.filters)
                setFilterContextValue('ourSpecialities', item?.ourSpecialities)
            } else {
                deleteLocalStorageItem('FilterContext')
            }
        } catch (error) { }
        getNationalities()
    }, [])

    useEffect(() => {
        setLocalStorageItem('FilterContext', JSON.stringify({ filters, ourSpecialities }))
    }, [filters, ourSpecialities])

    return (
        <>
            <div className="doctor-list-side-filter-header">
                <span>{I18nManager.isRTL() ? "المرشحات" : "Filters"}</span>
            </div>
            <div className="doctor-list-side-filter">
                <Accordion initialCollapse title={I18nManager.isRTL() ? "التخصصات" : "Sepcialities"}
                    content={ourSpecialities.map((item, index) => (<FilterCheckBox key={index} item={item} onItemClick={onSpecialityClick(index)} />))}
                />
                <Accordion initialCollapse title={I18nManager.isRTL() ? "الكشفية" : "Visit fees"}
                    content={filters.examinationFee.map((item, index) => (<FilterRadioButton onItemClick={onFilterClick(index, 'examinationFee')} key={index} item={item} />))}
                />
                {/* <Accordion title={I18nManager.isRTL() ? "المواعيد المتوفرة" : "Availability"}
                    content={filters.availability.map((item, index) => (<FilterCheckBox key={index} item={item} onItemClick={onAvailabilityClick} />))}
                /> */}
                {!showMore && <span className="see-more" onClick={onShowMoreClick}>{I18nManager.isRTL() ? "المزيد" : "See More"}</span>}
                {showMore && <>
                    {/* <Accordion title={I18nManager.isRTL() ? "مكان العمل" : "Entity"}
                        content={filters.entity.map((item, index) => (<FilterCheckBox onItemClick={onEntityClick} key={index} item={item} />))}
                    /> */}
                    <Accordion title={I18nManager.isRTL() ? "الخبرة" : "Experience"}
                        content={filters.yearsOfExperience.map((item, index) => (<FilterCheckBox onItemClick={onFilterClick(index, 'yearsOfExperience')} key={index} item={item} />))}
                    />
                    <Accordion title={I18nManager.isRTL() ? "الألقاب الطبية" : "Title"}
                        content={filters.title.map((item, index) => (<FilterCheckBox onItemClick={onFilterClick(index, 'title')} key={index} item={item} />))}
                    />
                    {/* <Accordion title={I18nManager.isRTL() ? "نوع الخدمة المتوفرة للطبيب" : "Available service"}
                        content={filters.serviceType.map((item, index) => (<FilterCheckBox onItemClick={onAvailableServiceClick} key={index} item={item} />))}
                    /> */}

                    <Accordion title={I18nManager.isRTL() ? "جنسية الطبيب" : "Nationality"}
                        content={
                            <div className="nationality-content-container">
                                {filters.nationality.map((item, index) => (<FilterCheckBox onItemClick={onFilterClick(index, 'nationality')} key={index} item={item} />))}
                            </div>
                        }
                    />
                    <Accordion title={I18nManager.isRTL() ? "شهادات البورد" : "Board Certificates"}
                        content={filters.boardCertificates.map((item, index) => (<FilterCheckBox onItemClick={onFilterClick(index, 'boardCertificates')} key={index} item={item} />))}
                    />
                    <Accordion title={I18nManager.isRTL() ? "جنس الطبيب" : "Gender"}
                        content={filters.genders.map((item, index) => (<FilterCheckBox onItemClick={onFilterClick(index, 'genders')} key={index} item={item} />))}
                    />
                    <Accordion title={I18nManager.isRTL() ? "تقييم" : "Rating"}
                        content={filters.ratings.map((item, index) => (<FilterCheckBoxRating onItemClick={onFilterClick(index, 'ratings')} key={index} item={item} />))}
                    />
                </>}
            </div>
        </>
    )
}

export default SideFilter;
