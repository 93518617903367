import React, { Component } from 'react'
import { getHyperPayPaymentStatus, postNotificationForChat, postAcceptAddonRequest, addon_prefix, postPayAmount, postReOpenBooking } from '../../../core/api/Api';
import './ChatPayment.css';
import firebase from '../../../core/firebase/firebase';
import { BankTransferOpenOrderMessage, PaymentAddedOpenOrderMessage, AfterPaymentMessage, HomeVisitAddonAcceptMessage, HomeVisitWelcomeMessage, ConsultationWelcomeMessage, AddBankTransferConfirmationMessage } from '../../../core/firebase/automated-message/AutomatedMessage';
import { reception_db_chat, db_location, db_chat } from '../../../core/firebase/fire/Fire';
import I18nManager from '../../../core/I18nManager/I18nManager';
import { getLocalStorageItem } from '../../../core/common-service/CommonService';

var IS_MOUNTED = true

const DB_ENTITY_ID = addon_prefix === 'admin' ? '8acda4c97292f4710172b165fa3a1021' : '8ac7a4ca71cbc59f0171d9f6f05a1316'
const MADA_ENTITY_ID = addon_prefix === 'admin' ? '8acda4c97292f4710172b166d275102a' : '8ac7a4ca71cbc59f0171d9f801e7131b'

let params = {}
try {
    params = JSON?.parse(getLocalStorageItem('PARAM'))
} catch (error) { }

export default class ChatPaymentVerification extends Component {

    state = {
        loading: false,
        CouponDetails: {},
        data: [],
        checkoutDetails: {},
        paymentDetails: {},
        TotalPrice: 0,
        BookingID: 0,
        ConsumerID: 0,
        ManPowerID: 0,
        IsRemoteBooking: false,
        PaymentMethods: [
            { id: 1, name: 'Mada', code: 'MADA', uri: '/assets/img/mada.png', style: { width: 84, height: 28 }, entityId: MADA_ENTITY_ID, paymentType: 'DB', active: true },
            { id: 5, name: 'Visa', code: 'VISA', uri: '/assets/img/visa.png', style: { width: 84, height: 28 }, entityId: DB_ENTITY_ID, paymentType: 'DB', active: false },
            { id: 6, name: 'Master', code: 'MASTER', uri: '/assets/img/mastercard.png', style: { width: 42, height: 28 }, entityId: DB_ENTITY_ID, paymentType: 'DB', active: false },
            { id: 7, name: 'Cash On Delivery', code: 'COD', uri: '/assets/img/cash-on-delivery.png', style: { width: 70, height: 28 }, active: false },
        ],
        PaymentType: '',
    }

    SendNotification() {
        const { BookingID } = this.state
        postNotificationForChat({ BookingID }).then(res => {
        }).catch(e => { })
    }


    getActivePaymentMethod() {
        const { PaymentMethods } = this.state
        return PaymentMethods.find(el => el.active === true)
    }

    async changeActivePaymentMethod(id) {
        const PaymentMethods = this.state.PaymentMethods.slice()
        PaymentMethods.forEach(element => {
            element.active = element.id === id
        });
        this.setState({ PaymentMethods })
    }

    // Start HyperPay
    onNavigationStateChange() {
        const { BookingID, checkoutDetails, PaymentMethods, CouponDetails, TotalPrice } = this.state
        this.setState({ loading: true })
        getHyperPayPaymentStatus(checkoutDetails.id, this.getActivePaymentMethod()?.entityId).then(res => {
            // '200.300.404'==> amount is 0
            if (res?.data?.result?.code === (addon_prefix === 'admin' ? '000.000.000' : '000.100.112')) {
                var paymentDetails = res.data
                delete paymentDetails.resultDetails
                if (IS_MOUNTED) {
                    this.setState({ paymentDetails })
                }
                const PaymentObj = {
                    BookingID, PaymentMethodID: this.getActivePaymentMethod()?.id || 2,
                    OnlinePaymentPaid: PaymentMethods.find(el => el.active === true) ? true : false,
                    IsDiscountPercent: CouponDetails?.IsPercent || null, CouponCode: CouponDetails?.CouponCode || null,
                    DiscountValue: CouponDetails?.Amount || CouponDetails?.Percentage || null, CouponID: CouponDetails?.CouponID || null, TotalPrice: TotalPrice
                }
                this.checkoutAmount(PaymentObj)
            } else {
                if (IS_MOUNTED) {
                    alert(I18nManager.isRTL() ? 'خطأ اثناء تتفيذ عملية الدفع' : 'Error While Proceeding Payment')
                    this.setState({ loading: false })
                }
            }
        }).catch(e => { })
    }
    // End HyperPay

    getOrderType(data) {
        if (data?.IsOnClinic) {
            return { text: I18nManager.isRTL() ? 'في العيادة' : 'Onclinic', type: 'Onclinic' }
        } else if (data?.IsOnSite) {
            return { text: I18nManager.isRTL() ? 'في موقعك' : 'Onsite', type: 'Onsite' }
        } else if (data?.IsRemoteSession) {
            return { text: I18nManager.isRTL() ? 'اون لاين' : 'Online', type: 'Online' }
        } else if (data?.IsSecondOpinion) {
            return { text: I18nManager.isRTL() ? 'رأي ثاني' : 'Second Opinion', type: 'SecondOpinion' }
        }
    }

    async OpenOrderRequest() {
        const { BookingID, IsRemoteBooking, TotalPrice, paymentDetails, PaymentMethods, WireTransferDetails } = this.state
        const obj = {
            InvoiceID: BookingID,
            Date: firebase.database.ServerValue.TIMESTAMP,
            DueDate: firebase.database.ServerValue.TIMESTAMP,
            Services: [
                {
                    Name: IsRemoteBooking ? { en: 'Medical Consultation', ar: 'إستشارة طبية اتصالية' } : { en: 'Home Visit', ar: 'زيارة منزلية' },
                    Price: TotalPrice?.toFixed(2) || 0,
                    Date: firebase.database.ServerValue.TIMESTAMP
                },
            ],
        }
        if (!PaymentMethods.find(el => el.active === true)) {
            WireTransferDetails.paymentBrand = 'BANKTRANSFER'
            obj.PaymentDetails = WireTransferDetails
        } else {
            if (PaymentMethods.find(el => el.active === true)?.id !== 7) {
                obj.PaymentDetails = paymentDetails
            }
            if (TotalPrice === 0) {
                const PaymentDetails = { paymentBrand: PaymentMethods.find(el => el.active === true).code }
                obj.PaymentDetails = PaymentDetails
            }
            if (PaymentMethods.find(el => el.active === true)?.id === 7) {
                const PaymentDetails = { paymentBrand: 'COD' }
                obj.PaymentDetails = PaymentDetails
            }
        }

        await this.SendPatientFireMessage(null, null, obj, 'Invoice')
        await this.SendChatPatientFireMessage(null, null, obj, 'Invoice')

        if (!PaymentMethods.find(el => el.active === true)) {
            await this.SendPatientFireMessage(null, BankTransferOpenOrderMessage(), null, 'PatientPaymentAdded')
            await this.SendDoctorFireMessage(null, AddBankTransferConfirmationMessage(), null, 'AddBankTransferConfirmation')
        } else {
            await this.SendPatientFireMessage(null, PaymentAddedOpenOrderMessage(), null, 'PatientPaymentAdded')
        }

        await this.SendDoctorFireMessage(null, AfterPaymentMessage(this.getOrderType(params?.FireData)?.type), null, null)

        switch (this.getOrderType(params?.FireData)?.type) {
            case 'Onsite':
                this.SendChatDoctorFireMessage(null, HomeVisitWelcomeMessage(params?.FireData?.ManPowerFirstNameAr, params?.FireData?.ManPowerFirstName), null, 'Welcome')
                db_location(params?.BookingID).update({ status: 0, status_text: null })
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const location = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                    await this.SendPatientFireMessage(null, null, location, 'Location')
                    db_location(params?.BookingID).update({ patient: location })
                }, (error) => { });
                break;
            case 'Online':
                this.SendChatPatientFireMessage(null, ConsultationWelcomeMessage(params?.FireData?.ManPowerFirstNameAr, params?.FireData?.ManPowerFirstName), null, 'PatientWaitingApprove')
                break;
            case 'Onclinic':
                const data = this.state.data.slice()
                data.forEach(async element => {
                    var item = element.data.find(el => el.Type === 'Location')
                    if (item) {
                        const location = { latitude: item?.Data?.latitude, longitude: item?.Data?.longitude }
                        this.SendChatDoctorFireMessage(null, null, location, 'Location')
                        db_location(params?.BookingID).update({ status: 0, status_text: null })
                        this.SendChatDoctorFireMessage(null, HomeVisitWelcomeMessage(params?.FireData?.ManPowerFirstNameAr, params?.FireData?.ManPowerFirstName), null, 'Welcome')
                    }
                });
                break;
            default:
                break;
        }

        const data = this.state.data.slice()
        await data.forEach(async element => {
            var item = element.data.find(el => el.Type === 'WaitingPatientPayment')
            if (item) {
                var key = item.key
                item.date = null
                item.key = null
                item.Type = null
                await reception_db_chat(BookingID).child(key).update(item)
            }
        });
        // this.props.history.push('/assistant-chat/' + BookingID, params)
        this.props.history.push('/#')
        if (IS_MOUNTED) {
            await this.setState({ loading: false })
        }
    }

    async ReopenOrderRequest() {
        const { BookingID } = this.state
        const params = this.props.location.state
        const obj = { BookingID }
        postReOpenBooking(obj).then(async res => {
            params.data.forEach(async element => {
                var item = element.data.find(el => el.Type === 'OrderCompleted')
                if (item) {
                    var key = item.key
                    item.date = null
                    item.key = null
                    item.Type = null
                    await db_chat(BookingID).child(key).update(item)
                }
            });
            await this.SendPatientFireMessage(null, 'Payment has been processed successfully for Reopening the order.', null, 'PatientPaymentAdded')
            if (IS_MOUNTED) {
                this.setState({ loading: false })
                this.timeout = setTimeout(() => {
                    this.props.history.push('/assistant-chat/' + BookingID, params)
                }, 500);
            }
        }).catch(e => this.setState({ loading: false }))
    }

    checkoutAmount(PaymentObj) {
        const { PaymentType, BookingID, data } = this.state
        const params = this.props.location.state
        this.setState({ loading: true })
        if (PaymentType === 'OpenOrder') {
            postPayAmount(PaymentObj).then(res => {
                this.SendNotification()
                this.OpenOrderRequest()
                if (IS_MOUNTED) {
                    this.setState({ showSubmitModal: false })
                }
            }).catch(e => this.setState({ loading: false }))
        } else if (PaymentType === 'AddonRequest') {
            const addonObj = { BookingID, accepted: true, ...params.Addon }
            postAcceptAddonRequest(addonObj).then(async res => {
                const key = params?.AddonSelectedElement?.key
                var item = params?.AddonSelectedElement
                item.key = null
                item.date = null
                item.Type = 'AddonAccepted'
                await db_chat(BookingID).child(key).update(item)
                var InvoiceItem = data.find(el => el.data.find(item => item.Type === 'Invoice'))
                if (InvoiceItem) {
                    InvoiceItem = InvoiceItem.data.find(el => el.Type === 'Invoice')
                    var services = await InvoiceItem.Data.Services
                    await services.push({
                        Name: { en: 'Addon Service: ' + params?.Addon?.GeneralServiceAddonName, ar: 'الخدمة الاضافية: ' + params?.Addon?.GeneralServiceAddonName },
                        Price: params?.Addon?.AddonPrice,
                        Date: firebase.database.ServerValue.TIMESTAMP
                    })
                    InvoiceItem.Data.Services = await services
                    const key = InvoiceItem.key
                    InvoiceItem.key = null
                    InvoiceItem.CreatedDate = firebase.database.ServerValue.TIMESTAMP
                    await db_chat(BookingID).child(key).update(InvoiceItem)
                }
                // await this.SendPatientFireMessage(null, HomeVisitAddonAcceptPaymentMessage(params?.Addon?.GeneralServiceAddonName), null, 'AddonServicePaymentAdded')
                await this.SendPatientFireMessage(null, HomeVisitAddonAcceptMessage(params?.Addon?.GeneralServiceAddonName), null, null)
                postPayAmount(PaymentObj).then(res => {
                    this.SendNotification()
                    this.timeout = setTimeout(() => {
                        this.props.history.push('/assistant-chat/' + BookingID, params)
                    }, 500);
                    if (IS_MOUNTED) {
                        this.setState({ showSubmitModal: false })
                    }
                }).catch(e => this.setState({ loading: false }))
            }).catch(e => this.setState({ loading: false }))
        } else if (PaymentType === 'ReopenOrder') {
            postPayAmount(PaymentObj).then(res => {
                this.SendNotification()
                this.ReopenOrderRequest()
                if (IS_MOUNTED) {
                    this.props.history.push('/assistant-chat/' + BookingID, params)
                    this.setState({ showSubmitModal: false })
                }
            }).catch(e => this.setState({ loading: false }))
        }

        // postPayAmount(PaymentObj).then(res => {
        //     if (PaymentType === 'OpenOrder') {
        //         this.OpenOrderRequest()
        //     } else if (PaymentType === 'AddonRequest') {
        //         this.AcceptAddonRequest()
        //     } else if (PaymentType === 'ReopenOrder') {
        //         this.ReopenOrderRequest()
        //     }
        //     this.setState({ showSubmitModal: false })
        // }).catch(e => this.setState({ loading: false }))
    }

    SendPatientFireMessage(Text, AutoText, Data, Type) {
        const { BookingID, ConsumerID, ManPowerID } = this.state
        return reception_db_chat(BookingID).push({
            SenderID: ConsumerID,
            ConsumerID,
            ManPowerID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        });
    }

    SendChatPatientFireMessage(Text, AutoText, Data, Type) {
        const { ConsumerID, ManPowerID, BookingID } = this.state
        return db_chat(BookingID).push({
            SenderID: ConsumerID,
            ConsumerID,
            ManPowerID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        })
    }

    SendDoctorFireMessage(Text, AutoText, Data, Type) {
        const { ConsumerID, ManPowerID, BookingID } = this.state
        return reception_db_chat(BookingID).push({
            SenderID: ManPowerID,
            ConsumerID,
            ManPowerID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        })
    }

    SendChatDoctorFireMessage(Text, AutoText, Data, Type) {
        const { ConsumerID, ManPowerID, BookingID } = this.state
        return db_chat(BookingID).push({
            SenderID: ManPowerID,
            ConsumerID,
            ManPowerID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        })
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
        IS_MOUNTED = false
    }

    componentDidMount() {
        IS_MOUNTED = true
        const url_string = window.location.href
        var url = new URL(url_string);
        var url_params = JSON.parse(url.searchParams.get("all_params"))
        this.setState({
            data: params?.data, CouponDetails: url_params?.CouponDetails, checkoutDetails: url_params?.checkoutDetails,
            paymentDetails: url_params?.paymentDetails, TotalPrice: url_params?.TotalPrice, BookingID: url_params?.BookingID,
            ConsumerID: url_params?.ConsumerID, ManPowerID: url_params?.ManPowerID,
            IsRemoteBooking: url_params?.IsRemoteBooking, PaymentType: url_params?.PaymentType
        }, () => {
            this.changeActivePaymentMethod(url_params?.ActivePaymentID).then(() => {
                this.timeout = setTimeout(() => {
                    this.onNavigationStateChange()
                }, 300);
            })
        })

    }
    render() {
        return (
            <div></div>
        )
    }
}
