import React, { useEffect, useState, useContext } from 'react'
import './ClinicDetails.css';
import { BASE_URL, postGetDoctorsByClinics, postGetDoctorTimeSlots, postBookingOrder, addon_prefix, setDefaultHeader, postPatientProfile, getCoordsFromIp } from '../../../../core/api/Api'
import I18nManager from '../../../../core/I18nManager/I18nManager';
import ViewContainer from '../../../../core/routes/view-container/ViewContainer';
import FAQ from '../../../../core/routes/faq/FAQ';
import TopFilter from '../../../_common/filter/top-filter/TopFilter';
import TopViewBg from '../../../_common/top-view-bg/TopViewBg';
import SideFilter from '../../../_common/filter/side-filter/SideFilter';
import FilterContext from '../../../../core/store/FilterContext';
import { useParams } from "react-router-dom";
import DoctorItem from '../../doctors-list/doctor-item/DoctorItem';
import { getCookie, removeWhiteSpaceFromString, setCookie } from '../../../../core/common-service/CommonService';
import Auth from '../../../../core/routes/auth/Auth';
import StoreContext from '../../../../core/store/StoreContext';
import { reception_db_data } from '../../../../core/firebase/fire/Fire';
import firebase from '../../../../core/firebase/firebase';
import ModalBookingSuccess from '../../../_common/modal-booking-success/ModalBookingSuccess';

var IS_MOUNTED = true
export default function ClinicDetails(props) {

    const { ourSpecialities, filters } = useContext(FilterContext)
    const { UserProfileBaseInfo } = useContext(StoreContext)

    const [clinicItemTabs, setClinicItemTabs] = useState([
        { title: I18nManager.isRTL() ? "حول" : "About", active: true },
        { title: I18nManager.isRTL() ? "التخصصات" : "Specialities", active: false },
        { title: I18nManager.isRTL() ? "المراجعات" : "Insurances", active: false },
    ])
    let ClinicID = useParams()?.id ?? 0
    const params = props.location.state

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [originalData, setOriginalData] = useState([])
    const [pagination, setPagination] = useState([])
    const [FCM, setFCM] = useState('')
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const getDoctorsByClinic = () => {
        getCoordsFromIp().then(coords => {
            const obj = {
                Latitude: coords?.data?.location?.lat, Longitude: coords?.data?.location?.lng,
                // 0 is to get all doctors and then filter when choosing speciality
                ClinicID, Radius: null, SpecialityID: ourSpecialities.find(el => el.active === true)?.GeneralServiceCategoryID,
                IsRemoteSession: false, IsOnClinic: true
            }
            postGetDoctorsByClinics(obj).then(async res => {
                if (IS_MOUNTED) {
                    var data = res.data.data
                    data.ProfileDate = data?.ProfileDate?.sort((a, b) => a?.Profile?.AvailabilityOrder - b?.Profile?.AvailabilityOrder)
                    setData(data?.ProfileDate)
                    setOriginalData(data?.ProfileDate)
                    applyFilter(data?.ProfileDate)
                }
            }).catch(e => { })
        }).catch(e => { getDoctorsByClinic() })
    }

    const changeActiveClinicTabs = (index) => () => {
        let tmp_clinicItemTabs = [...clinicItemTabs]
        tmp_clinicItemTabs.forEach((element, i) => {
            element.active = parseInt(index) === i
        });
        setClinicItemTabs(tmp_clinicItemTabs)
    }

    const getFeesRange = async (prevResult) => {
        const range = filters.examinationFee.find(el => el.active === true)
        if (Number.isInteger(range?.from)) {
            return prevResult.filter((el) => (el?.OrderOnlineAmount ?? el?.OrderAmount) >= range?.from && (el?.OrderOnlineAmount ?? el?.OrderAmount) <= range?.to)
        } else {
            return prevResult
        }
    }
    const getExperienceRange = async (prevResult) => {
        const range = filters.yearsOfExperience.find(el => el.active === true)
        if (Number.isInteger(range?.from)) {
            return prevResult.filter((el) => el?.Profile?.Experience >= range?.from && el?.Profile?.Experience <= range?.to)
        } else {
            return prevResult
        }
    }
    const getTitleFilter = async (prevResult) => {
        const occupation = filters.title.find(el => el.active === true)
        if (Number.isInteger(occupation?.OccupationID)) {
            return prevResult.filter((el) => el?.Profile?.OccupationID === occupation?.OccupationID)
        } else {
            return prevResult
        }
    }
    const getNationalityFilter = async (prevResult) => {
        const nationality = filters.nationality.find(el => el.active === true)
        if (Number.isInteger(nationality?.NationalityID)) {
            return prevResult.filter((el) => el?.Profile?.Nationality === nationality?.NationalityID)
        } else {
            return prevResult
        }
    }
    // Board Certificates
    const getEducationLevelFilter = async (prevResult) => {
        const educationLevel = filters.boardCertificates.find(el => el.active === true)
        if (Number.isInteger(educationLevel?.EducationLevelID)) {
            return prevResult.filter((el) => el?.Profile?.EducationLevelID === educationLevel?.EducationLevelID)
        } else {
            return prevResult
        }
    }
    const getGenderFilter = async (prevResult) => {
        const gender = filters.genders.find(el => el.active === true)
        if (Number.isInteger(gender?.id)) {
            return prevResult.filter((el) => el?.Profile?.GenderName === gender?.value)
        } else {
            return prevResult
        }
    }
    const getRatingFilter = async (prevResult) => {
        const rating = filters.ratings.find(el => el.active === true)
        if (Number.isInteger(rating?.value)) {
            return prevResult.filter((el) => el?.Profile?.Rating === rating?.value)
        } else {
            return prevResult
        }
    }
    const applyFilter = (data) => {
        if (data.length > 0) {
            getFeesRange(data).then(res => {
                getExperienceRange(res).then(res => {
                    getTitleFilter(res).then(res => {
                        getGenderFilter(res).then(res => {
                            getRatingFilter(res).then(res => {
                                getEducationLevelFilter(res).then(res => {
                                    getNationalityFilter(res).then(res => {
                                        setData(res)
                                    })
                                })
                            })
                        })
                    })
                })
            })
        }
    }

    const PAGINATION_SIZE = 10
    const onPaginationClick = (index) => (e) => {
        e.preventDefault()
        let arr = [...pagination]
        arr.forEach((element, i) => {
            element.active = i === parseInt(index)
        });
        setPagination(arr)
        window.scrollTo(0, 0)
    }
    const getPaginationNumber = () => {
        let pag = data?.length / PAGINATION_SIZE;
        const dec = pag % 1;
        let res = dec === 0 ? pag : parseInt(pag) + 1
        let arr = Array.from({ length: res }, (el, index) => ({ active: index === 0 }))
        setPagination(arr)
    }
    const findActivePaginationIndex = () => {
        return pagination.findIndex(el => el.active === true)
    }
    const paginatedData = () => {
        return data?.slice(findActivePaginationIndex() * PAGINATION_SIZE, (findActivePaginationIndex() + 1) * PAGINATION_SIZE)
    }

    const onIsMoreClick = (doctorIndex) => (dayIndex) => {
        let tmpData = [...data]
        tmpData[doctorIndex].DoctorTimeSlots.dateSlots[dayIndex].isMorePressed = !tmpData[doctorIndex]?.DoctorTimeSlots?.dateSlots[dayIndex].isMorePressed
        setData(tmpData)
    }
    const onTimeSlotClick = (doctorIndex) => (dayIndex, slotIndex) => {
        let tmpData = [...data]
        tmpData[doctorIndex].DoctorTimeSlots.dateSlots.forEach((element, i) => {
            element.timeSlots.forEach((el_timeslot, i_timeslot) => {
                el_timeslot.active = (parseInt(dayIndex) === i && parseInt(i_timeslot) === slotIndex)
            });
        });
        setData(tmpData)
    }
    const getDoctorTimeSlots = (doctorIndex, obj) => {
        postGetDoctorTimeSlots(obj).then(res => {
            const response = res.data.data
            if (response?.dateSlots) {
                let tmpData = [...data]
                tmpData[doctorIndex].DoctorTimeSlots = response
                setData(tmpData)
            }
        }).catch(e => { })
    }
    const onPreviousPress = (doctorIndex) => () => {
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID
        const obj = { DoctorID, page: page > 1 ? (page - 1) : page }
        getDoctorTimeSlots(doctorIndex, obj)
    }
    const onNextPress = (doctorIndex) => () => {
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID
        const obj = { DoctorID, page: page + 1 }
        getDoctorTimeSlots(doctorIndex, obj)
    }

    const onCloseSuccessModal = () => {
        setShowSuccessModal(false)
    }

    const getFCMToken = () => {
        return firebase.messaging().getToken()
    }

    const onBookNowPress = (doctorIndex) => (FullName, MobileNo, Password, Email) => {
        const isSlotSelected = data[doctorIndex]?.DoctorTimeSlots?.dateSlots?.find(el => el.timeSlots.find(el => el.active === true))
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID

        if (isSlotSelected) {
            let CouponDetails = {}
            try {
                CouponDetails = JSON?.parse(getCookie('selectedCouponCode'))
            } catch (error) { }
            const DoctorSlotID = isSlotSelected?.timeSlots?.find(el => el.active === true)?.SlotID
            const obj = {
                DoctorID, ClinicID,
                Patientlatitude: 0, Patientlongitude: 0,
                DoctorSlotID, IsProduction: addon_prefix === 'cp' ? false : true,
                IsRemoteSession: false, IsOnSite: false, IsOnClinic: true,
                IsSecondOpinion: false, IsEmergency: false, TriggerNotificationID: null,
                IsPaid: false, OrderWithRecepionist: false,
                CouponID: CouponDetails?.CouponID || null, CouponCode: CouponDetails?.CouponCode || null,
                CouponPercentage: CouponDetails?.Percentage || null, CouponAmount: CouponDetails?.Amount || null,
                CouponIsPercent: CouponDetails?.IsPercent || null, CouponIsCampaign: CouponDetails?.IsCampaign || null,
                CouponPaymentTypeID: CouponDetails?.PaymentTypeID || null, CouponServiceProviderID: CouponDetails?.ServiceProviderID || null,
                CouponEndDate: CouponDetails?.EndDate || null
            }
            if (Auth.isAuthenticated()) {
                setLoading(true)
                postBookingOrder(obj).then(async res => {
                    var data = res.data.data
                    if (IS_MOUNTED) {
                        if (res?.data?.StatusCode === 100) {
                            data.Questions = res?.data?.questions
                            const tmp_item = { ...data, ...obj, IsSpecialitySelected: false, IsAssistante: false, ConsumerDetails: UserProfileBaseInfo }
                            await reception_db_data(data.BookingID).update(tmp_item).then(() => {
                                setLoading(false)
                                setShowSuccessModal(true)
                                getDoctorTimeSlots(doctorIndex, { DoctorID, page })
                                // this.props.history.push('/assistant-chat/' + data?.BookingID, tmp_item)
                            }).catch(e => { })
                        } else if (res?.data?.StatusCode === 111) {
                            const message = I18nManager.isRTL() ? 'الطبيب غير متوفر' : 'Doctor isn\'t available'
                            alert(message)
                            setLoading(false)
                        } else {
                            const message = I18nManager.isRTL() ? 'نعتذر، لا يمكنك تنفيذ اكثر من ٣ طلبات في نفس الوقت. يمكنك الغاء اي من الطلبات المتعلقة او الجارية.' :
                                'Sorry, you can\'t place more than 3 order at the same time. you can cancel from the pending orders.'
                            alert(message)
                            setLoading(false)
                        }
                    }
                }).catch(e => { setLoading(false) })
            } else {
                var loginObj = {
                    MobileNo: removeWhiteSpaceFromString(MobileNo), Password, Email,
                    FirstName: FullName.split(' ')[0], LastName: FullName.substr(FullName.indexOf(" ") + 1),
                    IsAppointment: true, IsLogin: true,
                    FCM, IsProduction: addon_prefix === 'cp' ? false : true,
                }
                setLoading(true)
                postPatientProfile(loginObj).then(res => {
                    if (IS_MOUNTED) {
                        const data = res.data
                        if (data.Status === 'Success') {
                            Auth.login(() => {
                                setDefaultHeader(data.Message)
                                setCookie('token', data.Message)
                                onBookNowPress(doctorIndex)(FullName, MobileNo, Password, Email)
                            })
                        } else {
                            setLoading(false)
                        }
                    }
                }).catch((e) => setLoading(false))
            }
        }
    }

    useEffect(() => {
        IS_MOUNTED = true
        if (firebase.messaging.isSupported()) {
            getFCMToken().then(res => {
                setFCM(res)
            })
        }
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    useEffect(() => {
        applyFilter(originalData)
    }, [filters])


    useEffect(() => {
        getPaginationNumber()
    }, [data])

    useEffect(() => {
        IS_MOUNTED = true
        getDoctorsByClinic()
        return () => {
            IS_MOUNTED = false
        }
        // Added ClinicID, because if user search from top filter, only URl param is changed
    }, [ourSpecialities, ClinicID])

    return (
        <ViewContainer>
            <ModalBookingSuccess isOpen={showSuccessModal} onCloseClick={onCloseSuccessModal} />
            <TopViewBg title={params?.Profile?.[I18nManager.isRTL() ? "ClinicNameAr" : "ClinicName"]} />

            <TopFilter />

            <div className="container doctor-list-sort-header">
                <div className="row">
                    <div className="col-12">
                        <div className="clinic-item-container" style={{ cursor: 'default' }}>
                            <div className="profile-container">
                                <img loading="lazy" className="img-fluid" src={params?.Profile?.Image ? (BASE_URL + params?.Profile?.Image) : '/assets/img/clinic.png'} alt="" />
                            </div>
                            <div className={I18nManager.isRTL() ? "mr-10" : "ml-10"}>
                                <h1>{params?.Profile?.[I18nManager.isRTL() ? "ClinicNameAr" : "ClinicName"]}</h1>
                                <div className="d-flex align-items-center mb-10 mt-10">
                                    <img wid='16' height='16' loading="lazy" src="/assets/img/choose-speciality.svg" alt="" />
                                    <span className={I18nManager.isRTL() ? "mr-10" : "ml-10"}>{params?.ClinicSpecialities?.length || 0} {I18nManager.isRTL() ? "الإختصاص" : "Speciality"}</span>
                                </div>
                                <div className="d-flex align-items-center">
                                    <img wid='16' height='16' loading="lazy" src="/assets/img/speak-with-psychologist.svg" alt="" />
                                    <span className={I18nManager.isRTL() ? "mr-10" : "ml-10"}>{params?.DoctorCount || 0} {I18nManager.isRTL() ? "أطباء" : "Doctors"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-3">
                        <SideFilter />
                    </div>
                    <div className="col-9">
                        <div className="clinic-details-info-container">
                            <div>
                                <ul className="nav nav-tabs doctor-item-tabs">
                                    {clinicItemTabs.map((item, index) => (
                                        <li key={index} className={"nav-item " + (item.active ? 'doctor-item-tabs-active' : '')} onClick={changeActiveClinicTabs(index)}>
                                            <a><span>{item.title}</span></a>
                                        </li>
                                    ))}
                                </ul>
                                <div className="doctor-item-tabs-content">
                                    {clinicItemTabs[0].active ?
                                        <div className="tab">
                                            <div className="mt-25 mb-15">
                                                <span className="tab-title">{I18nManager.isRTL() ? "عن المستشفى" : "About Hospital"}</span>
                                            </div>
                                            <span className="tab-description">
                                                Attending a trade show can be a very effective method of promoting your company and its products. And one of the most effective ways to optimize your trade show display and increase traffic to your booth is through the use of banner stands. A banner stand for your trade show display draws attention to your booth and helps you deliver your message to prospective clients, current customers and business contacts at what is usually a highly competitive event. Your trade show display should stand out from the crowd, and a banner stand is a great way to make sure it does.
                                                Banners themselves are typically constructed with either fabric or vinyl. There are advantages and disadvantages to each type of material, so you should consider how you will use your banner stand before deciding between fabric and vinyl. Fabric banners are durable and long-lasting. Fabric doesn’t reflect light like vinyl, which can decrease. Banners themselves are typically constructed with either fabric or vinyl. There are advantages and disadvantages to each type of material, so you should consider how you will use your banner stand before deciding between fabric and vinyl. Fabric banners are durable and long-lasting. Fabric doesn’t reflect light like vinyl, which can decrease.
                                            </span>
                                        </div>
                                        : clinicItemTabs[1].active ?
                                            <div className="tab">
                                                <div className="row mt-25">
                                                    {ourSpecialities.map((item, index) => (
                                                        <div key={index} className="col-md-3 col-6 mb-20">
                                                            <span className="specialities-text">{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            : <div className="tab">
                                                <div className="row mt-25 mb-10">
                                                    {ourSpecialities.map((item, index) => (
                                                        <div key={index} className="col-md-3 col-6 mb-20">
                                                            <span className="specialities-text">{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {paginatedData()?.map((item, index) => (
                            <DoctorItem key={index} item={item} loading={loading}
                                onIsMoreClick={onIsMoreClick(index)} onTimeSlotClick={onTimeSlotClick(index)}
                                onPreviousPress={onPreviousPress(index)} onNextPress={onNextPress(index)}
                                onBookNowPress={onBookNowPress(index)}
                            />
                        ))}
                        {pagination?.length > 1 && <nav>
                            <ul className="pagination pagination-sm">
                                {pagination?.map((item, index) => (
                                    <li key={index} className={"page-item " + (item?.active ? "active" : "")} onClick={onPaginationClick(index)}>
                                        <a className="page-link" href="#">{++index}</a>
                                    </li>
                                ))}
                            </ul>
                        </nav>}
                    </div>
                </div>
            </div>

            <FAQ />

        </ViewContainer>
    )
}