import React, { useState, useRef } from "react";
import { useEffect } from "react";
import I18nManager from "../../../core/I18nManager/I18nManager";

import "./Accordion.css";

function Accordion({ title, content, initialCollapse }) {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");
    const [setRotate, setRotateState] = useState("accordion__icon");

    const contentRef = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(setActive === "active" ? "0px" : `${contentRef.current.scrollHeight}px`);
        setRotateState(setActive === "active" ? "accordion__icon" : "accordion__icon rotate");
    }

    useEffect(() => {
        if (initialCollapse) {
            toggleAccordion()
        }
    }, [])

    return (
        <div className="accordion__section">
            <span className={`accordion ${setActive}`} onClick={toggleAccordion}>
                <span className="accordion__title">{title}</span>
                <i className={setRotate + " fa fa-chevron-" + (I18nManager.isRTL() ? "left" : "right")}></i>
            </span>
            <div ref={contentRef} style={{ maxHeight: `${setHeight}` }} className="accordion__content">
                {content}
            </div>
        </div>
    );
}

export default Accordion;
