import React from 'react'
import { constants } from '../../constants/constants'
import I18nManager from '../../I18nManager/I18nManager'
import './AppDownload.css'
import { useHistory } from 'react-router-dom'

export default function AppDownload() {
    let history = useHistory()

    const goToPage = (path) => (e) => {
        e.preventDefault()
        history.push(path)
    }
    return (
        <section id="download" className="pt-100">
            <div className="container">
                <div className="row">
                    <div className="col-md-7 col-12">
                        <div>
                            <div className="yellow-circle"></div>
                            <h2 className="download-title">{constants.downloadApp?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</h2>
                            <h3 className="download-subtitle text-justify">{constants.downloadApp?.[I18nManager.isRTL() ? "subTitleAr" : "subTitleEn"]}</h3>
                        </div>
                        <div className="download-buttons-container">
                            <a onClick={goToPage("/download?t=patient&p=android")} className="mb-20 mt-20" target="_blank" rel="noopener noreferrer">
                                <img loading="lazy" src="/assets/img/google-play-btn.svg" height="50" alt="" />
                            </a>
                            <a onClick={goToPage("/download?t=patient&p=ios")} target="_blank" rel="noopener noreferrer" className="ml-10 mr-10">
                                <img loading="lazy" src="/assets/img/app-store-btn.svg" height="50" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-5 col-12 download-side-image">
                        <img className="img-fluid" loading="lazy" src="/assets/img/app-download.png" alt="" style={{ maxHeight: 400, }} />
                    </div>
                </div>
            </div>
        </section>
    )
}
