import React, { useEffect, useState, useContext } from 'react'
import '../NavBar.css';
import I18nManager from '../../../I18nManager/I18nManager';
import { deleteCookie, getCookie, scrollIntoView } from '../../../common-service/CommonService';
import Auth from '../../auth/Auth';
import { BASE_URL, postGetPatientProfile, postLogout } from '../../../api/Api';
import StoreContext from '../../../store/StoreContext';
import { useHistory } from "react-router-dom";
import ViewDisplay from '../../../../components/_common/view-display/ViewDisplay';

export default function MainNavBar() {
    let history = useHistory();
    const { setStoreContextValue, UserProfileBaseInfo } = useContext(StoreContext)

    const [navLinks, setNavLinks] = useState([
        { title: I18nManager.isRTL() ? "الرئيسية" : "Home", path: '/', active: false },
        { title: I18nManager.isRTL() ? "العروض" : "Offers", href: '/#offers', active: false },
        { title: I18nManager.isRTL() ? "العيادات المميزة" : "Featured clinics", href: '/#featured-clinics', active: false },
        { title: I18nManager.isRTL() ? 'مقالات' : "Blog", active: false },
        { title: I18nManager.isRTL() ? "شبكتنا" : "Network", path: '/clinics-list', active: false },
    ])
    const [NavBarExpanded, setNavBarExpanded] = useState(false)

    const goToPage = (path) => (e) => {
        e.preventDefault()
        history.push(path)
    }

    const onChangeLanguage = (e) => {
        e.preventDefault()
        I18nManager.setCookieDirValue(!I18nManager.isRTL())
    }

    const onNavBarItemClick = (index, val) => {
        let tmp_navLinks = [...navLinks]
        tmp_navLinks.forEach((element, i) => {
            element.active = parseInt(index) === i
        });
        setNavLinks(tmp_navLinks)
        if (navLinks[index]?.path) {
            history.push(navLinks[index]?.path, { IsRemoteSession: true, IsOnSite: false, IsOnClinic: true, IsSecondOpinion: false, IsEmergency: false, IsAssistante: false })
        }
        // Auto Hide NavBar on item click
        if (window.matchMedia('screen and (max-width: 768px)').matches && val) {
            document.getElementById('navButton').click()
        }
    }

    const onMobileIconClick = () => {
        const win = window.open("/whatsapp", "_blank");
        win.focus();
    }

    const onLogout = (e) => {
        e.preventDefault()
        const data = { IsLogout: true, PushNotificationKey: null }
        postLogout(data).then(res => {
            Auth.logout(() => {
                deleteCookie('token')
            })
            window.location.reload()
        }).catch(e => { })
    }

    function getPatientProfile() {
        postGetPatientProfile().then(res => {
            const checkAuth = res?.data?.length
            if (checkAuth) {
                Auth.logout(() => {
                    deleteCookie('token')
                })
                return
            }
            Auth.login(() => {
                const data = res.data.data
                setStoreContextValue('UserProfileBaseInfo', data)
            })
        }).catch(e => getPatientProfile())
    }

    useEffect(() => {
        document.getElementById('navButton').addEventListener('click', function (e) {
            var x = document.getElementById("navbarNav");
            var isDisplayNone = window.getComputedStyle(x).display === "none"
            setNavBarExpanded(isDisplayNone)
            var button = document.getElementById("navButton");
            var navBar = document.getElementById("navBar")
            if (isDisplayNone) {
                button.classList.add("navButton-active");
                navBar.classList.add("navbar-expanded");
            } else {
                button.classList.remove("navButton-active");
                navBar.classList.remove("navbar-expanded");
            }
        })
        if (getCookie('token')) {
            getPatientProfile()
        }
        let path = window.location.href
        path = path.split('/')?.[path.split('/').length - 1]
        if (path && path.length > 0) {
            const navIndex = navLinks.findIndex(el => el.href === ('/' + path))
            if (navIndex !== -1) {
                onNavBarItemClick(navIndex, false)
            }
        }
    }, [])

    return (
        <nav id="navBar" className="navbar navbar-expand-md navbar-light fixed-top">
            <div className="container pl-0 pr-0">

                <button id="navButton" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span>{NavBarExpanded ? (I18nManager.isRTL() ? "إغلاق" : "Close") : (I18nManager.isRTL() ? "قائمة" : "Menu")}</span>
                </button>
                <a onClick={scrollIntoView("home")} className="navbar-brand">
                    <img loading="lazy" src="/assets/img/logo-transparent.svg" width="56" height="56" alt="docnow" />
                </a>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav align-items-center">
                        {navLinks.map((item, index) => (
                            <li key={index} className={"nav-item " + (item.active === true ? "nav-item-active" : "")} onClick={() => onNavBarItemClick(index, true)}>
                                <a className={"nav-link " + (item.active === true ? "nav-link-active" : "")} href={item?.href} rel="noopener noreferrer">{item?.title}</a>
                            </li>
                        ))}
                    </ul>

                    <div className="nav-right-side">
                        <div className="navbar-call-button cursor-pointer">
                            <img loading='lazy' src="/assets/img/mobile-msg.svg" width="24" height="24" onClick={onMobileIconClick}></img>
                            <a href="tel:966550600288"><span className="font-bold">{I18nManager.isRTL() ? '966550600288+' : '+966550600288'}</span></a>
                        </div>
                        <ul className="navbar-nav align-items-center">
                            <li className="nav-item mr-10 ml-10" onClick={onChangeLanguage}>
                                <a className="nav-link font-bold" rel="noopener noreferrer">{I18nManager.isRTL() ? "EN" : "AR"}</a>
                            </li>
                            {Auth.isAuthenticated() ?
                                <div className="nav-dropdown">
                                    <img loading="lazy" className="rounded-circle" src={UserProfileBaseInfo?.Image ? BASE_URL + UserProfileBaseInfo?.Image : "/assets/img/patient-profile-ph.svg"} width="24" height="24" alt="" />
                                    <div className="nav-dropdown-content" style={{ overflow: 'hidden' }}>
                                        <a onClick={goToPage('/profile')} rel="noopener noreferrer"><span className="dropdown-item">{I18nManager.isRTL() ? "حساب" : "Profile"}</span></a>
                                        <a rel="noopener noreferrer"><span className="dropdown-item" onClick={onLogout}>{I18nManager.isRTL() ? "خروج" : "Logout"}</span></a>
                                    </div>
                                </div> :

                                <ViewDisplay
                                    showMinHideMax={
                                        <li className="nav-item">
                                            <a onClick={goToPage('/login')} className="nav-link" rel="noopener noreferrer">{I18nManager.isRTL() ? "تسجيل" : "Login"}</a>
                                        </li>
                                    }
                                    hideMinShowMax={
                                        <div div className="d-flex align-items-center mt-30 mb-20">
                                            <button onClick={goToPage('/login')} className={"login-button " + (I18nManager.isRTL() ? "ml-10" : "mr-10")}>{I18nManager.isRTL() ? "تسجيل" : "Login"}</button>
                                            <button onClick={goToPage('/signup')} className="signup-button" >{I18nManager.isRTL() ? "اشتراك" : "Sign up"}</button>
                                        </div>
                                    }
                                />
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    )
}