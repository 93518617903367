import React, { useEffect, useState } from 'react'
import './InvoiceView.css'
import { BASE_URL, postCreateInvoice } from '../../../../../core/api/Api';
import moment from 'moment';
import I18nManager from '../../../../../core/I18nManager/I18nManager';

var IS_MOUNTED = true

export default function InvoiceView(props) {

    const [downloadLoading, setDownloadLoading] = useState(false)

    function getTotalPrice(Data) {
        var total = 0
        Data.Services.forEach(element => {
            total += parseFloat(element.Price)
        });
        return total
    }

    function onCreateInvoice(Data) {
        setDownloadLoading(true)
        postCreateInvoice({ BookingID: Data.InvoiceID }).then(res => {
            if (IS_MOUNTED) {
                const uri = res.data.data
                window.open(BASE_URL + uri, '_blank');
                setDownloadLoading(false)
            }
        }).catch(e => setDownloadLoading(false))
    }

    useEffect(() => {
        IS_MOUNTED = true
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    const { Data, showPreview } = props
    return (
        <div>
            <div className="mt-40">
                <div className="invoice-header-container">
                    <div className="invoice-header-subcontainer">
                        <p className="text-center">{I18nManager.isRTL() ? 'فاتورة' : 'INVOICE'}#</p>
                        <p className="text-center text-white">{Data?.InvoiceID}</p>
                    </div>
                    <div className="invoice-header-subcontainer">
                        <p className="text-center">{I18nManager.isRTL() ? 'تاريخ' : 'DATE'}</p>
                        <p className="text-center text-white">{moment(Data?.Date).format('DD/MM/YYYY')}</p>
                    </div>
                </div>
            </div>
            {Data?.PaymentDetails &&
                <div className="invoice-mid-container">
                    {Data?.PaymentDetails?.paymentBrand === 'VISA' && <img src="/assets/img/visa.png" width="84" height="28" alt="" />}
                    {Data?.PaymentDetails?.paymentBrand === 'MASTER' && <img src="/assets/img/mastercard.png" width="84" height="28" alt="" />}
                    {Data?.PaymentDetails?.paymentBrand === 'MADA' && <img src="/assets/img/mada.png" width="84" height="28" alt="" />}
                    {Data?.PaymentDetails?.paymentBrand === 'COD' && <img src="/assets/img/cash-on-delivery.png" width="70" height="28" alt="" />}
                    {Data?.PaymentDetails?.paymentBrand === 'BANKTRANSFER' && <img src="/assets/img/wire-transfer.png" width="36" height="36" alt="" />}
                    {Data?.PaymentDetails?.paymentBrand !== 'COD' &&
                        <div className="mt-20">
                            {Data?.PaymentDetails?.paymentBrand !== 'BANKTRANSFER' &&
                                <div>
                                    {Data?.PaymentDetails?.amount &&
                                        <div>
                                            <div className="form-group">
                                                <label htmlFor="card-number">{I18nManager.isRTL() ? 'رقم البطاقة' : 'Card Number'}</label>
                                                <input id="card-number" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'رقم البطاقة' : 'Card Number'} disabled defaultValue={Data?.PaymentDetails?.card?.bin + Data?.PaymentDetails?.card?.last4Digits} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="expiry-date">{I18nManager.isRTL() ? 'تاريخ الانتهاء' : 'Expiry Date'}</label>
                                                <input id="expiry-date" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'تاريخ الانتهاء' : 'Expiry Date'} disabled defaultValue={(Data?.PaymentDetails?.card?.expiryYear)?.slice(-2) + '/' + Data?.PaymentDetails?.card?.expiryMonth} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="card-holder-name">{I18nManager.isRTL() ? 'الاسم على البطاقة' : 'Card Holder Name'}</label>
                                                <input id="card-holder-name" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'الاسم على البطاقة' : 'Card Holder Name'} disabled defaultValue={Data?.PaymentDetails?.card?.holder} />
                                            </div>
                                        </div>}
                                </div>
                            }
                            {Data?.PaymentDetails?.paymentBrand === 'BANKTRANSFER' &&
                                <div>
                                    <div className="form-group">
                                        <label htmlFor="iban-invoice">{I18nManager.isRTL() ? 'الايبان' : 'IBAN'}</label>
                                        <input id="iban-invoice" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'الايبان' : 'IBAN'} disabled defaultValue={Data?.PaymentDetails?.Val_} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="account-number-invoice">{I18nManager.isRTL() ? 'رقم الحساب' : 'Account Number'}</label>
                                        <input id="account-number-invoice" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'رقم الحساب' : 'Account Number'} disabled defaultValue={Data?.PaymentDetails?.AccountNumber} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="bank-name-invoice">{I18nManager.isRTL() ? 'اسم البنك' : 'Bank Name'}</label>
                                        <input id="bank-name-invoice" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'اسم البنك' : 'Bank Name'} disabled defaultValue={Data?.PaymentDetails?.NameOfBank} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="company-name-invoice">{I18nManager.isRTL() ? 'اسم الشركة' : 'Company Name'}</label>
                                        <input id="company-name-invoice" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'اسم الشركة' : 'Company Name'} disabled defaultValue={Data?.PaymentDetails?.HolderName} />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
            <div className="invoice-details-container">
                <div className="invoice-header-subcontainer">
                    <p className="text-center">{I18nManager.isRTL() ? 'خدمات' : 'SERVICES'}#</p>
                    {Data?.Services?.map((item, index) => (
                        <p key={index} className="text-center">{item?.Name['en']}</p>
                    ))}
                </div>
                <div className="invoice-header-subcontainer">
                    <p className="text-center">{I18nManager.isRTL() ? 'السعر' : 'PRICE'}</p>
                    {Data?.Services?.map((item, index) => (
                        <p key={index} className="text-center">{item?.Price} {I18nManager.isRTL() ? 'ريال' : 'SAR'}</p>
                    ))}
                </div>
            </div>
            <div className="p-20">
                <h3 className="text-center">{I18nManager.isRTL() ? 'المبلغ الإجمالي' : 'TOTAL AMOUNT'}</h3>
                <h5 className="total-price-text">{getTotalPrice(Data)?.toFixed(2)} {I18nManager.isRTL() ? 'ريال' : 'SAR'}</h5>
            </div>
            <div className="d-flex flex-row">
                <button disabled={downloadLoading} onClick={() => onCreateInvoice(Data)} className="invoice-modal-left-button">
                    <i className="fa fa-download fa-lg"></i> {I18nManager.isRTL() ? 'تحميل' : 'DOWNLOAD'}
                </button>
                <button disabled={downloadLoading} onClick={() => onCreateInvoice(Data)} className="invoice-modal-right-button">
                    <i className="fa fa-share-alt fa-lg"></i> {I18nManager.isRTL() ? 'شارك' : 'Share'}
                </button>
            </div>
        </div>
    )
}
