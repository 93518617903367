import React, { useState, useRef } from "react";
import "./FAQ.css";

function Accordion(props) {
    const [setActive, setActiveState] = useState("");
    const [setHeight, setHeightState] = useState("0px");

    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === "" ? "active" : "");
        setHeightState(
            setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
        );
    }

    return (
        <div className="accordion__section">
            <span className={`accordion ${setActive}`} onClick={toggleAccordion}>
                <span className="accordion__title">How private and confidential is Cura medical consultations?</span>
            </span>
            <div ref={content} style={{ maxHeight: `${setHeight}` }} className="accordion__content">
                <p>
                    Your privacy and the security of your information is one of our most important principles, and your personal information is private to you, and all information that may indicate your identity is hidden from others. Including strict confidentiality in video calls, all information on Cura is protected and encrypted in compliance with telemedicine standards. We use technology and our company's security policies to preserve your privacy and ensure that your information is protected
                </p>
            </div>
        </div>
    );
}

export default Accordion;
