import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import I18nManager from './core/I18nManager/I18nManager';
import * as serviceWorker from './serviceWorker';
import { setDefaultHeader } from './core/api/Api';
import { StoreProvider } from './core/store/StoreContext';
import { getCookie, setCookie } from './core/common-service/CommonService';
import { FilterProvider } from './core/store/FilterContext';

const url_string = window.location.href
var url = new URL(url_string);
var lang = url.searchParams.get("lang")
if (lang === 'rtl' || lang === 'ltr') {
  setCookie('dir', lang)
}

const token = getCookie('token')
setDefaultHeader(token)
const dir = getCookie('dir')
if (dir === 'rtl' || dir === 'ltr') {
  I18nManager.setDirValue(dir === 'rtl' ? true : false)
  document.getElementsByTagName('html')[0].setAttribute("dir", dir);
} else {
  I18nManager.setDirValue(true)
  document.getElementsByTagName('html')[0].setAttribute("dir", 'rtl');
}


ReactDOM.render(
  <React.StrictMode>
    <StoreProvider>
      <FilterProvider>
        <App />
      </FilterProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
