import React, { Component } from 'react'

const StoreContext = React.createContext();

export class StoreProvider extends Component {
    state = {
        UserProfileBaseInfo: {
            FirstName: null,
            LastName: null,
            Image: null,
            BirthDate: null,
        },
    }

    setStoreContextValue = (key, val) => {
        this.setState({ [key]: val })
    }

    render() {
        const { UserProfileBaseInfo } = this.state
        const { setStoreContextValue } = this
        return (
            <StoreContext.Provider value={{
                UserProfileBaseInfo,
                setStoreContextValue
            }}>
                {this.props.children}
            </StoreContext.Provider>
        )
    }
}

export default StoreContext