import React from 'react'
import I18nManager from '../../../../core/I18nManager/I18nManager';
import './FilterRadioButton.css';

const FilterRadioButton = ({ item, onItemClick }) => {
    return (
        <div className='d-flex align-items-center filter-radio-button mt-10 mb-10' onClick={onItemClick}>
            <div className={(I18nManager.isRTL() ? "ml-5" : "mr-5") + " check-circle " + (item?.active ? 'check-circle-active' : '')}>
                {item?.active && <span />}
            </div>
            <span>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</span>
        </div>
    )
}

export default FilterRadioButton