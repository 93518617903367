import React from 'react'
import I18nManager from '../../../../core/I18nManager/I18nManager';
import StarsRating from '../../stars-rating/StarsRating';
import './FilterCheckBox.css';

const FilterCheckBoxRating = ({ item, onItemClick }) => {
    return (
        <div className='d-flex align-items-center filter-checkbox mt-10 mb-10' onClick={onItemClick}>
            <div className={(I18nManager.isRTL() ? "ml-5" : "mr-5") + " check-square " + (item?.active ? 'check-square-active' : '')}>
                {item?.active && <i className="fa fa-check"></i>}
            </div>
            {item?.value ? <StarsRating value={item?.value} /> :
                <span>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</span>
            }
        </div>
    )
}

export default FilterCheckBoxRating