import React from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from '../auth/Auth';

export const ProtectedRoute = ({
    component: Component,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (Auth.isAuthenticated()) {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect to={{ pathname: "/login?return_url=" + window.location.pathname, state: { from: props.location } }} />
                    );
                }
            }}
        />
    );
};