import React, { useEffect } from 'react'
import Modal from 'react-modal';
import I18nManager from '../../../core/I18nManager/I18nManager';
import { modalStyle } from '../../../core/styles/custom-styles';
import AssistanteChooseCalendar from '../../chat/assistante-chat/assistante-chat-message/assistante-choose-calendar/AssistanteChooseCalendar';
import './ModalDoctorInfo.css';

export default function ModalDoctorInfo(props) {

    function getCouponDiscount(item) {
        if (item?.CouponID) {
            return item?.IsPercent ? (item?.Percentage + ' %') : (item?.Amount + ' ' + (I18nManager.isRTL() ? 'ريال' : 'SAR'))
        }
        return I18nManager.isRTL() ? 'حدد رمز القسيمة' : 'Select Coupon Code'
    }

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])
    const { bio, loading, onBookNowPress, showCouponCodeList, CouponList,
        onChange, value, onSelectChange, disabled, TimeSlots,
    } = props
    return (
        <Modal {...props} style={modalStyle} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
            <div className="p-20 d-flex flex-column container">
                <p className="modal-bio-title">{I18nManager.isRTL() ? 'السيرة الذاتية' : 'Bio'}</p>
                <p className="modal-bio-subTitle">{bio}</p>
                {showCouponCodeList && <div>
                    <p className="modal-bio-title">{I18nManager.isRTL() ? 'رمز الكوبون' : 'Coupon Code'}</p>
                    <select onChange={props.onCouponCodeChange} className="form-control mb-10">
                        {CouponList?.map((item, index) => (
                            <option value={index} key={index}>{getCouponDiscount(item)}</option>
                        ))}
                    </select>
                </div>}

                <AssistanteChooseCalendar {...props} onChange={onChange} value={value}
                    onSelectChange={onSelectChange} disabled={disabled} TimeSlots={TimeSlots} />

                <button disabled={loading} className="modal-button-yes mt-20" onClick={onBookNowPress}>
                    {I18nManager.isRTL() ? 'احجز الآن' : 'BOOK NOW'}
                </button>
            </div>
        </Modal>
    )
}
