import React from 'react'
import './ViewDisplay.css'

const ViewDisplay = ({ showMinHideMax, hideMinShowMax }) => {
    return (
        <>
            <div className="show-display-min-md hide-display-max-md">
                {showMinHideMax}
            </div>
            <div className="hide-display-min-md show-display-max-md">
                {hideMinShowMax}
            </div>
        </>
    )
}
export default ViewDisplay