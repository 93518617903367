import React from 'react'
import { constants } from '../../core/constants/constants';
import I18nManager from '../../core/I18nManager/I18nManager';
import ViewContainer from '../../core/routes/view-container/ViewContainer';

export default function PrivacyPolicy() {
    const data = constants.privacyPolicy.data
    return (
        <ViewContainer>
            <div className="pt-100 container">
                <h1>{I18nManager.isRTL() ? 'سياسة الخصوصية' : 'Privacy policy'}</h1>
                <h5>{constants.privacyPolicy.summary?.[I18nManager.isRTL() ? 'ar' : 'en']}</h5>
                {data.map((item, index) => (
                    <div key={index} className="mt-20 mb-20">
                        <h2 key={index}>{item?.[I18nManager.isRTL() ? 'titleAr' : 'titleEn']}</h2>
                        {item?.[I18nManager.isRTL() ? 'descriptionAr' : 'descriptionEn'].map((element, index) => (
                            <p key={index}>{element}</p>
                        ))}
                    </div>
                ))}
            </div>
        </ViewContainer>
    )
}
