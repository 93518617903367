import React from 'react'
import I18nManager from '../../../core/I18nManager/I18nManager'

export default function FlipCloseButton({ onClick }) {
    return (
        <div style={{ position: 'absolute', zIndex: 100, left: I18nManager.isRTL() ? 10 : undefined, right: I18nManager.isRTL() ? undefined : 10 }}
            className="cursor-pointer" onClick={onClick}>
            <i className="fa fa-times color-grey text-secondary"></i>
        </div>
    )
}
