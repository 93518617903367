import React, { useState, useEffect, useContext } from 'react'
import StoreContext from '../../../core/store/StoreContext'
import { BASE_URL, postGetDoctorsByClinics, postBookingOrder, postGetDoctorAvailabilityForPatient } from '../../../core/api/Api'
import CancelButton from '../../_common/cancel-button/CancelButton'
import './ClinicInfo.css'
import { useTable, useSortBy, usePagination } from 'react-table'
import ModalDoctorInfo from '../modal-doctor-info/ModalDoctorInfo'
import { reception_db_data } from '../../../core/firebase/fire/Fire'
import I18nManager from '../../../core/I18nManager/I18nManager'
import ViewContainer from '../../../core/routes/view-container/ViewContainer'
import { getCookie } from '../../../core/common-service/CommonService'
import moment from 'moment';
import Auth from '../../../core/routes/auth/Auth';

var IS_MOUNTED = true
var _SPECIALIZATION = []
var SELECTED_TIMESLOT_DATA = {}

export default function ClinicInfo(props) {
    const { UserProfileBaseInfo } = useContext(StoreContext)

    const params = props.location.state

    const [data, setData] = useState([])
    const [Questions, setQuestions] = useState([])
    const [Specialization, setSpecialization] = useState([])
    const [doctorInfoShowModal, setDoctorInfoShowModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})

    const [loginShowModal, setLoginShowModal] = useState(false)

    const [timeSlotDisabled, setTimeSlotDisabled] = useState(false)
    const [CalendarSelectedDate, setCalendarSelectedDate] = useState(moment())
    const [TimeSlots, setTimeSlots] = useState([])

    function getDoctorsByClinic() {
        const obj = {
            Latitude: 0, Longitude: 0,
            IsRemoteSession: params?.IsRemoteSession, SpecialityID: _SPECIALIZATION?.find(el => el.active === true)?.GeneralServiceCategoryID ?? params?.Specialization?.find(el => el.active === true)?.ServiceCategoryID,
            Radius: null, ClinicID: params?.item?.Profile?.ClinicID, IsOnClinic: params?.IsOnClinic
        }
        postGetDoctorsByClinics(obj).then(res => {
            var data = res.data.data
            if (IS_MOUNTED) {
                setData(data?.ProfileDate)
                setQuestions(data?.Questions)
            }
        }).catch(e => { })
    }

    function onSpecializationChange(index) {
        const tmp_specialization = params?.item?.ClinicSpecialities
        tmp_specialization.forEach((element, i) => {
            element.active = parseInt(index) === i
        });
        _SPECIALIZATION = tmp_specialization
        setSpecialization(tmp_specialization)
        getDoctorsByClinic()
    }

    function onItemClick(item) {
        let tmp_coupons = []
        if (params?.item?.Coupons?.length > 0) {
            tmp_coupons = [{ CouponID: null, Name: I18nManager.isRTL() ? 'حدد رمز القسيمة' : 'Select Coupon Code', active: true }, ...params?.item?.Coupons]
        }
        let tmp_item = { ...item, Coupons: tmp_coupons }
        setSelectedItem(tmp_item)
        onDateSelected(moment().format('YYYY-MM-DD'))
        setDoctorInfoShowModal(true)
    }

    function onCouponCodeChange(e) {
        const index = parseInt(e.target.value)
        selectedItem.Coupons.forEach((element, i) => {
            element.active = i === index
        });
        setSelectedItem(selectedItem)
        if (index > 0) {
            alert(I18nManager.isRTL() ? 'سيتم إضافة رمز القسيمة في طلبك التالي.' : 'Coupon Code will be added in your next order.')
        }
    }  // Start Schedule
    function onDateSelected(date) {
        setCalendarSelectedDate(date)
        const obj = { ManPowerID: selectedItem?.Profile?.ManPowerID, From: moment(date).locale('en').format('L'), To: moment(date).locale('en').format('L') }
        postGetDoctorAvailabilityForPatient(obj).then(async res => {
            var arr = []
            var data = res.data.data
            if (IS_MOUNTED) {
                const isDataValid = data?.ClinicSlots?.filter(el => el?.clinicSlotItemData?.length > 0)
                if (isDataValid?.length > 0) {
                    data.ClinicSlots.forEach(element => {
                        element.clinicSlotItemData.forEach((slot, index) => {
                            if (slot.From) {
                                var hourMinuteTimeFrom = moment(new Date(2020, 5, 22, slot?.From.split(':')[0], slot?.From.split(':')[1], 0, 0));
                                var hourMinuteTimeTo = moment(new Date(2020, 5, 22, slot?.To.split(':')[0], slot?.To.split(':')[1], 0, 0));
                                slot.timeFrom = {
                                    ar: hourMinuteTimeFrom.format("hh:mm A"),
                                    en: hourMinuteTimeFrom.format("hh:mm A")
                                }
                                slot.timeTo = {
                                    ar: hourMinuteTimeTo.format("hh:mm A"),
                                    en: hourMinuteTimeTo.format("hh:mm A")
                                }
                                slot.active = (index === 0)
                                slot.DateTimeStatusID = element.DateTimeStatusID
                                arr.push(slot)
                            }
                        });
                    });
                    SELECTED_TIMESLOT_DATA = {}
                    setTimeSlots(arr)
                }
            }
        }).catch(e => { })
    }
    function onCalendarSlotPress(index) {
        let timeslots = [...TimeSlots]
        const item = timeslots[parseInt(index)]
        timeslots.forEach((element, i) => {
            element.active = i === parseInt(index)
        });
        setTimeSlots(timeslots)
        SELECTED_TIMESLOT_DATA = {
            ManPowerSlotDate: moment(CalendarSelectedDate).format('L'),
            ManPowerSlotFrom: item?.From,
            ManPowerSlotTo: item?.To
        }
    }
    // End Schedule

    function onSubmitLogin() {
        setLoginShowModal(false)
        onBookNowPress()
    }

    function onBookNowPress() {
        if (!SELECTED_TIMESLOT_DATA.ManPowerSlotDate) {
            alert(I18nManager.isRTL() ? 'الرجاء تحديد فترة زمنية.' : 'Please select a timeslot.')
        } else if (!Auth.isAuthenticated()) {
            setLoginShowModal(true)
        } else {
            if (!loading) {
                let SELECTED_SERVICE = params
                delete SELECTED_SERVICE?.Specialization
                delete SELECTED_SERVICE?.Questions
                delete SELECTED_SERVICE?.item

                let CouponDetails = {}
                try {
                    CouponDetails = selectedItem?.Coupons?.find(el => el.active === true) ?? JSON?.parse(getCookie('selectedCouponCode'))
                } catch (error) { }

                const obj = {
                    DoctorID: selectedItem?.Profile?.ManPowerID, ClinicID: params?.ClinicID || null, Price: selectedItem?.OrderAmount ?? selectedItem?.OrderOnlineAmount,
                    Patientlatitude: 0, Patientlongitude: 0,
                    ...SELECTED_SERVICE, TriggerNotificationID: null,
                    IsPaid: false, OrderWithRecepionist: false,
                    CouponID: CouponDetails?.CouponID, CouponCode: CouponDetails?.CouponCode,
                    CouponPercentage: CouponDetails?.Percentage, CouponAmount: CouponDetails?.Amount,
                    CouponIsPercent: CouponDetails?.IsPercent, CouponIsCampaign: CouponDetails?.IsCampaign,
                    CouponPaymentTypeID: CouponDetails?.PaymentTypeID, CouponServiceProviderID: CouponDetails?.ServiceProviderID,
                    CouponEndDate: CouponDetails?.EndDate,
                    ...SELECTED_TIMESLOT_DATA,
                }

                const IsSpecialitySelected = true
                const spec = Specialization?.find(el => el.active === true)?.Name
                const specAr = Specialization?.find(el => el.active === true)?.NameAr
                setLoading(true)
                postBookingOrder(obj).then(async res => {
                    var data = res.data.data
                    if (IS_MOUNTED) {
                        if (res?.data?.StatusCode === 100) {
                            data.Questions = res?.data?.questions
                            const tmp_item = {
                                ...data, ...obj, Specialization: spec, SpecializationAr: specAr, ClinicName: params?.ClinicName, ClinicNameAr: params?.ClinicNameAr,
                                IsSpecialitySelected, ConsumerDetails: UserProfileBaseInfo
                            }
                            await reception_db_data(data?.BookingID).update(tmp_item).then(() => {
                                setLoading(false)
                                alert(I18nManager.isRTL() ? 'تم إنشاء الطلب. سيتم الاتصال بك قريبا.' : 'Order has been created. You will be contacted shortly.')
                                props.history.push('/#')
                                // props.history.push('/assistant-chat/' + data?.BookingID, tmp_item)
                            })
                        } else if (res?.data?.StatusCode === 111) {
                            const message = I18nManager.isRTL() ? 'الطبيب غير متوفر' : 'Doctor isn\'t available'
                            alert(message)
                            setLoading(false)
                        } else {
                            const message = I18nManager.isRTL() ? 'نعتذر، لا يمكنك تنفيذ اكثر من ٣ طلبات في نفس الوقت. يمكنك الغاء اي من الطلبات المتعلقة او الجارية.' :
                                'Sorry, you can\'t place more than 3 order at the same time. you can cancel from the pending orders.'
                            alert(message)
                            setLoading(false)
                        }
                    }
                }).catch(e => { setLoading(false) })
            }
        }
    }

    useEffect(async () => {
        IS_MOUNTED = true
        await onSpecializationChange(0)
        await getDoctorsByClinic()
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    const columns = React.useMemo(
        () => [
            {
                Header: I18nManager.isRTL() ? "الصورة" : 'Image',
                accessor: item => <img className="item-profile-img" src={item?.Profile?.Image ? (BASE_URL + item?.Profile?.Image) : '/assets/img/patient-profile-ph.svg'} alt="" />
            },
            {
                Header: I18nManager.isRTL() ? "الاسم" : 'Name',
                accessor: item => I18nManager.isRTL() ? ('دكتور ' + item?.Profile?.FirstNameAr + ' ' + item?.Profile?.LastNameAr) : ('Doctor ' + item?.Profile?.FirstName + ' ' + item?.Profile?.LastName)
            },
            {
                Header: I18nManager.isRTL() ? 'الخبرة' : 'Experience',
                accessor: item => (item?.Profile?.Experience || 0) + " " + (I18nManager.isRTL() ? 'سنوات' : 'Years')
            },
            {
                Header: I18nManager.isRTL() ? 'الجنسية' : 'Nationality',
                accessor: item => item?.Profile?.NationalityImage && <img className="item-nationality-img" src={BASE_URL + item?.Profile?.NationalityImage} alt="" />
            },
            {
                Header: I18nManager.isRTL() ? 'السعر' : 'Price',
                accessor: item => ((params?.IsRemoteSession ? item?.OrderOnlineAmount : item?.OrderAmount) || 0) + " " + (I18nManager.isRTL() ? 'ريال' : 'SAR'),
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 },
    }, useSortBy, usePagination)


    return (
        <ViewContainer>
            <div className="container patient-plus-bg d-flex align-items-center justify-content-center">
                <ModalDoctorInfo isOpen={doctorInfoShowModal} showCouponCodeList={true} onRequestClose={() => setDoctorInfoShowModal(false)} loading={loading}
                    bio={selectedItem?.Profile?.Bio} CouponList={selectedItem?.Coupons} onCouponCodeChange={onCouponCodeChange} onBookNowPress={() => onBookNowPress()}
                    value={moment(CalendarSelectedDate).format('YYYY-MM-DD')} onChange={e => onDateSelected(e.target.value)} onSelectChange={e => onCalendarSlotPress(e.target.value)}
                    disabled={timeSlotDisabled} TimeSlots={TimeSlots}
                />


                <div className="align-self-center main-container">
                    <CancelButton />
                    <h3 className="header pt-20 pb-20">{I18nManager.isRTL() ? "معلومات العيادة" : "Clinic Info"}</h3>
                    <select onChange={e => onSpecializationChange(e.target.value)} className="form-control mb-10">
                        {Specialization?.map((item, index) => (
                            <option value={index} key={index}>{item?.[I18nManager.isRTL() ? "NameAr" : "Name"]}</option>
                        ))}
                    </select>
                    <table {...getTableProps()} className="table dt-responsive nowrap">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span></th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td onClick={() => onItemClick(row.original)}  {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="pagination d-flex align-items-center justify-content-between" style={{ padding: '0.5rem' }}>
                        <div className="d-flex flex-row">
                            <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>{' '}
                            <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>{' '}
                            <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>{' '}
                            <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>{' '}
                        </div>
                        <span>{I18nManager.isRTL() ? "الصفحة" : "Page"}{' '}<strong>{pageIndex + 1} {I18nManager.isRTL() ? "من" : "of"} {pageOptions.length}</strong>{' '}</span>                        <select className="form-control" style={{ maxWidth: 80 }} value={pageSize} onChange={e => { setPageSize(Number(e.target.value)) }}>
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>{pageSize}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </ViewContainer>
    )
}
