import React, { useState, useEffect, useContext } from 'react'
import StoreContext from '../../core/store/StoreContext';
import { BASE_URL, postGetPatientProfile, postSavePatientProfileData } from '../../core/api/Api';
import './Profile.css';
import moment from 'moment';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { colors } from '../../core/colors/Colors';
import CancelButton from '../_common/cancel-button/CancelButton';
import I18nManager from '../../core/I18nManager/I18nManager';
import ViewContainer from '../../core/routes/view-container/ViewContainer';

var IS_MOUNTED = true

export default function Profile(props) {
    const { setStoreContextValue } = useContext(StoreContext)

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)

    const [genders] = useState([
        { id: 1, title: I18nManager.isRTL() ? 'ذكر' : 'Male', active: true },
        { id: 2, title: I18nManager.isRTL() ? 'أنثى' : 'Female', active: false }
    ])

    function getPatientProfile() {
        postGetPatientProfile().then(res => {
            if (IS_MOUNTED) {
                var data = res.data.data
                setData(data)
                if (data.BirthDate) {
                    setData({ ...data, BirthDate: moment(new Date(data.BirthDate)).format('YYYY-MM-DD') })
                    setStoreContextValue('UserProfileBaseInfo', data)
                }
                setStoreContextValue('UserProfileBaseInfo', data)
            }
        }).catch(e => { })
    }

    function onEditProfile() {
        if (data?.FirstName && data?.LastName) {
            setLoading(true)
            postSavePatientProfileData(data).then(res => {
                if (IS_MOUNTED) {
                    setStoreContextValue('UserProfileBaseInfo', data)
                    setLoading(false)
                    window.history.back()
                }
            }).catch(e => setLoading(false))
        }
    }

    function onImageCick() {
        // document.getElementById("imgupload").click();
    }

    function onProfileUpload(file) {
        const formdata = new FormData()
        formdata.append('image', { file });
        postSavePatientProfileData(formdata).then(res => {
            getPatientProfile()
        }).catch(e => { })
    }

    useEffect(() => {
        IS_MOUNTED = true
        getPatientProfile()
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    return (
        <ViewContainer>
            <div className="container patient-plus-bg d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center main-container">
                    <div className="percentage-container d-flex flex-column align-items-center">
                        <CancelButton />
                        <h5 className="percentage-text">{data?.Percentage}%</h5>
                        <span className="percentage-subtext">{I18nManager.isRTL() ? 'منتهى' : 'Completed'}</span>
                    </div>
                    <div onClick={() => onImageCick()} className="circle-progress">
                        <CircularProgressbarWithChildren value={data?.Percentage} strokeWidth={5} styles={{ path: { stroke: colors.successGreen } }}>
                            <div className="camera-upload-icon-container">
                                <input type="file" multiple={false} onChange={e => onProfileUpload(e.target.files[0])} accept="image/jpeg, image/png, image/jpg" id="imgupload" className="d-none" />
                                <img src="/assets/img/camera-colored.png" width="25px" height="25px" alt="" />
                            </div>
                            <img src={data?.Image ? BASE_URL + data?.Image : "/assets/img/patient-profile-ph.svg"} className="profile-img" alt="" />
                        </CircularProgressbarWithChildren>
                    </div>
                    <form className="row" onSubmit={() => onEditProfile()}>
                        <div className="form-group col-6">
                            <label htmlFor="first-name-profile">{I18nManager.isRTL() ? 'الاسم الاول' : 'First Name'}</label>
                            <input id="first-name-profile" type="text" value={data?.FirstName} onChange={(e) => setData({ ...data, FirstName: e.target.value })} className="form-control custom-form-control" placeholder={I18nManager.isRTL() ? 'الاسم الاول' : 'First Name'} />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="last-name-profile">{I18nManager.isRTL() ? 'الاسم الأخير' : 'Last Name'}</label>
                            <input id="last-name-profile" type="text" value={data?.LastName} onChange={(e) => setData({ ...data, LastName: e.target.value })} className="form-control custom-form-control" placeholder={I18nManager.isRTL() ? 'الاسم الأخير' : 'Last Name'} />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="mobile-number-profile">{I18nManager.isRTL() ? "رقم الجوال" : 'Mobile Number'}</label>
                            <input id="mobile-number-profile" type="text" disabled value={data?.MobileNo} className="form-control custom-form-control" placeholder={I18nManager.isRTL() ? "رقم الجوال" : 'Mobile Number'} />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="email-address-profile">{I18nManager.isRTL() ? 'الإيميل' : 'Email Address'}</label>
                            <input id="email-address-profile" type="email" value={data?.Email} onChange={(e) => setData({ ...data, Email: e.target.value })} className="form-control custom-form-control" placeholder={I18nManager.isRTL() ? 'الإيميل' : 'Email Address'} />
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="gender-profile">{I18nManager.isRTL() ? 'الجنس' : 'Gender'}</label>
                            <select id="gender-profile" onChange={e => setData({ ...data, GenderID: e.target.value })} className="form-control custom-form-control">
                                {genders?.map((item, index) => (
                                    <option selected={data?.GenderID === item.id} value={item?.id} key={index}>{item?.title}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="insurance-category-profile">{I18nManager.isRTL() ? 'اختر فئة التأمين' : 'Select Insurance Category'}</label>
                            <select id="insurance-category-profile" onChange={e => setData({ ...data, InsuranceCategoryID: e.target.value })} className="form-control custom-form-control">
                                {data?.InsuranceCategories?.map((item, index) => (
                                    <option selected={data?.InsuranceCategoryID === item?.InsuranceCategoryID} value={item?.InsuranceCategoryID} key={index}>{item?.Name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group col-6">
                            <label htmlFor="insurance-company-profile">{I18nManager.isRTL() ? 'اختر شركة التأمين' : 'Select Insurance Company'}</label>
                            <select id="insurance-company-profile" onChange={e => setData({ ...data, InsuranceCompanyID: e.target.value })} className="form-control custom-form-control">
                                {data?.InsuranceCompanies?.map((item, index) => (
                                    <option selected={data?.InsuranceCompanyID === item?.InsuranceCompanyID} value={item?.InsuranceCompanyID} key={index}>{item?.[I18nManager.isRTL() ? 'Name_Ar' : 'Name']}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group col-6">
                            <label htmlFor="date-birth-profile">{I18nManager.isRTL() ? 'تاريخ الميلاد' : 'Date of Birth'}</label>
                            <input id="date-birth-profile" type="date" value={data?.BirthDate} onChange={(e) => setData({ ...data, BirthDate: e.target.value })} className="form-control custom-form-control" placeholder={I18nManager.isRTL() ? 'تاريخ الميلاد' : 'Date of Birth'} />
                        </div>
                        <div className="container d-flex justify-content-between">
                            <button disabled={loading} onClick={() => props.history.push('/home')} className="table-custom-btn">{I18nManager.isRTL() ? 'إلغاء' : 'Cancel'}</button>
                            <button disabled={loading} className="table-custom-btn" onClick={() => onEditProfile()}>{I18nManager.isRTL() ? 'حفظ' : 'Save'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </ViewContainer>
    )
}
