import React from 'react'
import './AboutUs.css';
import { constants } from '../../core/constants/constants'
import I18nManager from '../../core/I18nManager/I18nManager'
import AppDownload from '../../core/routes/app-download/AppDownload'
import ViewContainer from '../../core/routes/view-container/ViewContainer'

const AboutUs = () => {
    const aboutus = constants.aboutUs
    return (
        <ViewContainer>
            <div className="container about-us-container pt-150 mb-40">
                <div className="row align-items-center">
                    <div className="col-md-6 col-12">
                        <h1>{aboutus[0]?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</h1>
                        {aboutus[0]?.[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"].map((item, index) => (
                            <p key={index}>{item}</p>
                        ))}
                    </div>
                    <div className="col-md-6 col-12">
                        <img loading="lazy" className="img-fluid" src={aboutus[0].uri} alt="" />
                    </div>
                </div>
                <div className="row align-items-center about-us-bg">
                    <div className="col-md-4 col-12">
                        <img loading="lazy" className="img-fluid" src={aboutus[1].uri} alt="" />
                    </div>
                    <div className="col-md-8 col-12">
                        <div className="mr-10 ml-10">
                            <h1>{aboutus[1]?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</h1>
                            {aboutus[1]?.[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"].map((item, index) => (
                                <p key={index}>{item}</p>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row align-items-center about-us-bg">
                    <div className="col-md-8 col-12">
                        <div className="mr-10 ml-10">
                            <h1>{aboutus[2]?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</h1>
                            {aboutus[2]?.[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"].map((item, index) => (
                                <p key={index}>{item}</p>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <img loading="lazy" className="img-fluid" src={aboutus[2].uri} alt="" />
                    </div>
                </div>
            </div>
            <AppDownload />
        </ViewContainer>
    )
}

export default AboutUs