import { colors } from "../colors/Colors";

export const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        borderWidth: 0,
        borderTopWidth: 4,
        borderColor: colors.mainYellow,
        bottom: 'auto',
        minWidth: 300,
        padding: 0,
        transform: 'translate(-50%, -50%)'
    }
};

export const invoiceModalStyle = {
    content: {
        ...modalStyle,
        maxHeight: '75vh',
    }
}