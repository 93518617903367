import React, { useState } from 'react';
import './ReadMoreText.css';
import ShowMoreText from 'react-show-more-text';
import I18nManager from '../../../core/I18nManager/I18nManager';

const ReadMoreText = ({ content, className }) => {
    const [showMore, setShowMore] = useState(false)
    const onClick = () => {
        setShowMore(prevState => !prevState)
    }
    return (
        <ShowMoreText
            lines={2}
            more={I18nManager.isRTL() ? "أظهر المزيد" : "Show more"}
            less={I18nManager.isRTL() ? "تظهر أقل" : "Show less"}
            className={className}
            anchorClass='read-more-anchor'
            onClick={onClick}
            expanded={showMore}
            children={content}
        />
    );
}

export default ReadMoreText;