import React, { Component } from 'react'
import { postCancelBookingRequest, postBookingOrder, postGetPatientOrderByID, postPatientUploadFileAndGetUrl, postSaveChatHistory } from '../../../core/api/Api';
import firebase from '../../../core/firebase/firebase';
import { checkFireDataByType, isOrderOpen, toBase64 } from '../../../core/common-service/CommonService';
import { db_chat, db_chat_timer, db_settings } from '../../../core/firebase/fire/Fire';
import './OnlineChat.css';
import { ConsultationOrderCanceledMessage } from '../../../core/firebase/automated-message/AutomatedMessage';
import CancelButton from '../../_common/cancel-button/CancelButton';
import ModalInvoice from '../chat-payment/modal-invoice/ModalInvoice';
import moment from 'moment';
import OnlineChatMessage from './online-chat-message/OnlineChatMessage';
import ModalWaitingConfirmation from '../modal-waiting-confirmation/ModalWaitingConfirmation';
import I18nManager from '../../../core/I18nManager/I18nManager';
import ViewContainer from '../../../core/routes/view-container/ViewContainer';
import ModalSubmit from '../../_common/modal-submit/ModalSubmit';

var IS_MOUNTED = true
const ORDER_CONFIRMATION_MSG = { ar: 'تم تأكيد الطلب من قبل المريض. (منجز)', en: 'Order has been confirmed by the patient. (Completed)' }
const ORDER_NO_CONFIRMATION_MSG = { ar: 'تم تأكيد الطلب من قبل المريض. (غير مكتمل)', en: 'Order has been confirmed by the patient. (Not Completed)' }

export default class OnlineChat extends Component {

    state = {
        data: [],
        TotalPrice: 0,
        SpecializationName: '',
        params: {},
        OrderDetails: {},

        ActiveDateIndex: null,

        showPurchaseModal: false,
        purchase_loading: false,

        showPaymentModal: false, payment_loading: false,

        showCancelOrderModal: false, modalLoading: false,
        cancelOrderMessage: I18nManager.isRTL() ? 'هل أنت متأكد أنك تريد إلغاء الطلب؟' : 'Are your sure you want to cancel order?',

        showConfirmOrderModal: false,

        showImagePreviewModal: false, imagePreviewURI: '',

        Questions: [], showPatientDetailsView: false, patientDetails_loading: false,

        showInvoiceModal: false, invoiceData: {},

        bottomViewLoading: false,

        ChatSettings: {},

        TextMessage: '',

        // added chattimer value 1 to prevent clearinterval in chatTimerCountdown() when appstate is active
        ChatTimer: 1, ChatTimerText: '00:00', IsPatientOnline: false, IsDoctorOnline: false,
        showChatTimerModal: false, IsExtraTimeAdded: false,

        ConsumerID: 0, ManPowerID: 0, BookingID: 0, ConsumerImage: '', ManPowerImage: ''
    }

    changeState(key, value) {
        this.setState({ [key]: value })
    }

    // Start Bottom View
    onChangeText(TextMessage) {
        const { params } = this.state
        clearTimeout(this.timeout)
        if (TextMessage.indexOf("\n") === -1 && TextMessage !== ' ') {
            this.setState({ TextMessage })
            db_settings(params?.BookingID).child('typing').update({ patient_typing: true })
            this.timeout = setTimeout(() => {
                db_settings(params?.BookingID).child('typing').update({ patient_typing: false })
            }, 1000);
        }
    }

    onSubmitSendText() {
        const { TextMessage } = this.state
        if (TextMessage.length > 0) {
            this.SendPatientFireMessage(TextMessage.trim(), null, null, null)
            this.setState({ TextMessage: '' })
        }
    }

    getPatientOrderByID(BookingID, cb) {
        postGetPatientOrderByID(BookingID).then(res => {
            var data = res.data.data[0]
            if (IS_MOUNTED) {
                const params = this.props.location.state
                this.setState({ params: { ...params, ...data } }, () => {
                    cb()
                })
                this.setState({ TotalPrice: data?.TotalPrice, SpecializationName: I18nManager.isRTL() ? data?.GeneralServiceCategoryAr : data?.GeneralServiceCategory })
                this.setState({ OrderDetails: data })
            }
            cb()
            // Completed or Canceled
            if (data?.GeneralStatusID !== 2 && data.GeneralStatusID !== 5) {
                this.updatePatientOnlineStatus(true)

                db_chat_timer(BookingID).on('value', async (snap) => {
                    var FchatTimer = snap?.val()?.FollowUpTimer
                    if (FchatTimer) {
                        this.setState({ IsPatientOnline: FchatTimer?.IsPatientOnline || false })
                        this.setState({ IsDoctorOnline: FchatTimer?.IsDoctorOnline || false })
                        this.setState({ IsExtraTimeAdded: FchatTimer?.IsExtraTimeAdded || false })
                    }
                })
            } else {
                // Store.OrderStatus = ''
            }
        }).catch(e => { this.getPatientOrderByID(BookingID, cb) })
    }

    onImageFileClick(id) {
        document.getElementById(id).click();
    }

    async onImageFileUpload(file, type) {
        const { BookingID, ConsumerID } = this.state
        var base64Data = (await toBase64(file)).split(',')[1]
        const obj = {
            FileName: new Date().getTime() + '_' + file.name, Data: base64Data, FileTypeID: 3,
            BookingID, ConsumerID
        }
        this.setState({ bottomViewLoading: true })
        postPatientUploadFileAndGetUrl(obj).then(res => {
            if (type === "image") {
                this.SendPatientFireMessage(null, null, { uri: res.data.Message }, 'Image')
            } else {
                if (file.name.endsWith('.pdf')) {
                    this.SendPatientFireMessage(null, null, { uri: res.data.Message }, 'File')
                } else {
                    this.SendPatientFireMessage(null, null, { uri: res.data.Message }, 'Image')
                }
            }
            if (IS_MOUNTED) {
                this.setState({ bottomViewLoading: false })
            }
        }).catch(e => { this.setState({ bottomViewLoading: false }) })
    }

    updatePatientOnlineStatus(IsPatientOnline) {
        const { params } = this.state
        db_chat_timer(params?.BookingID).child('FollowUpTimer').update({ IsPatientOnline })
    }

    onYesCancelOrderModal() {
        const { BookingID } = this.state
        if (!this.state.modalLoading) {
            const obj = { BookingID, CancelRequestStatus: true }
            this.setState({ modalLoading: true })
            postCancelBookingRequest(obj).then(async res => {
                const data = this.state.data.slice()
                const cases = ['PatientWaitingApprove', 'WaitingPatientPayment']
                await cases.forEach(async case_el => {
                    await data.forEach(async element => {
                        var item = element.data.find(el => el.Type === case_el)
                        if (item) {
                            var key = item.key
                            item.date = null
                            item.key = null
                            item.Type = null
                            await db_chat(BookingID).child(key).update(item)
                        }
                    });
                });
                await db_chat_timer(BookingID).remove()
                await this.SendPatientFireMessage(null, ConsultationOrderCanceledMessage(), null, 'OrderCanceled')
                if (IS_MOUNTED) {
                    await this.setState({ modalLoading: false, showCancelOrderModal: false })
                    window.history.back()
                }
            }).catch(e => this.setState({ modalLoading: false }))
        }
    }

    async onStartFollowUp(element) {
        const { params } = this.state
        element.Type = 'FollowUpStarted'
        await db_chat(params?.BookingID).child(element.key).update(element)
    }

    onConfirmOrder(val) {
        const { BookingID } = this.state
        this.setState({ modalLoading: true })
        const obj = { BookingID, IsPatientConfirmed: val }
        postBookingOrder(obj).then(res => {
            this.SendPatientFireMessage(null, val ? ORDER_CONFIRMATION_MSG : ORDER_NO_CONFIRMATION_MSG, null, 'PatientConfirmation')
            this.RemoveFireMessageType(['WaitingPatientConfirmation']).then(() => {
                this.setState({ modalLoading: false, showConfirmOrderModal: false })
            })
        }).catch(e => { this.setState({ modalLoading: false, showConfirmOrderModal: false }) })
    }

    saveChatHistory(BookingID, data) {
        const obj = { BookingID, Text: JSON.stringify(data) }
        postSaveChatHistory(obj).then(res => {
        }).catch(e => { })
    }

    async setupData(data) {
        const today = moment().format('L')
        const yesterday = moment().subtract(1, 'days').format('L')
        data.forEach(element => {
            if (moment(element.CreatedDate).format('L') === today) {
                element.date = I18nManager.isRTL() ? 'اليوم' : 'TODAY'
            } else if (moment(element.CreatedDate).format('L') === yesterday) {
                element.date = I18nManager.isRTL() ? 'البارحة' : 'YESTERDAY'
            } else {
                element.date = moment(element.CreatedDate).locale(I18nManager.isRTL() ? 'ar' : 'en').format('L')
            }
        });
        const distinct = data.filter((item, i, arr) => {
            return arr.indexOf(arr.find(t => t.date === item.date)) === i;
        });
        const distinctDates = []
        distinct.forEach(element => {
            distinctDates.push(element.date)
        });
        var new_arr = []
        distinctDates.forEach(el_date => {
            const res = data.filter(el => el.date === el_date)
            new_arr.push({ date: el_date, data: res })
        });
        return await new_arr
    }

    SendPatientFireMessage(Text, AutoText, Data, Type) {
        const { ConsumerID, ManPowerID, BookingID } = this.state
        db_chat(BookingID).push({
            SenderID: ConsumerID,
            ConsumerID,
            ManPowerID,
            BookingID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        });
    }

    async RemoveFireMessageType(Types) {
        const { BookingID } = this.state
        const data = this.state.data.slice()
        Types.forEach(async case_el => {
            await data.forEach(async element => {
                var items = element.data.filter(el => el.Type === case_el)
                items.forEach(async item => {
                    if (item) {
                        var key = item.key
                        item.date = null
                        item.key = null
                        item.Type = null
                        await db_chat(BookingID).child(key).update(item)
                    }
                });
            });
        });
    }

    componentWillUnmount() {
        IS_MOUNTED = false
        const { params } = this.state
        this.updatePatientOnlineStatus(false)
        clearTimeout(this.timeout)
        db_settings(params?.BookingID).child('typing').update({ patient_typing: false })
        db_chat(params?.BookingID).off()
        db_chat_timer(params?.BookingID).off()
        db_settings(params?.BookingID).off()
    }

    componentDidMount() {
        IS_MOUNTED = true
        const { id } = this.props.match.params
        this.getPatientOrderByID(id, () => {
            const { params } = this.state
            this.setState({ Questions: params?.Questions })
            this.setState({ TotalPrice: params?.TotalPrice || 0 })
            this.setState({
                SpecializationName: I18nManager.isRTL() ? (params?.GeneralServiceCategoryAr || params?.SpecializationAr)
                    : (params?.GeneralServiceCategory || params?.Specialization), ConsumerID: params?.ConsumerID, ManPowerID: params?.ManPowerID, BookingID: params?.BookingID,
                ConsumerImage: params?.ConsumerImage, ManPowerImage: params?.ManPowerImage
            })
            db_chat(params?.BookingID).on('value', async (snap) => {
                var chat = await snap?.val()
                if (chat) {
                    var objectKeys = await Object.keys(chat);
                    var data = []
                    objectKeys.forEach(async (element) => {
                        await data.push({ key: element, ...chat[element] })
                    });
                    data = await data.sort((a, b) => a.CreatedDate - b.CreatedDate)
                    await this.saveChatHistory(params?.BookingID, data)
                    this.setupData(data).then((async (res) => {
                        if (IS_MOUNTED) {
                            await this.setState({ data: res })
                        }
                        // if (!checkFireDataByType(res, 'OrderCanceled')) {
                        //     if (!checkFireDataByType(res, 'DetailsCompleted')) {
                        //         if (IS_MOUNTED) {
                        //             // this.setState({ showPatientDetailsView: true })
                        //         }
                        //     }
                        //     // Top Right Header
                        //     if (checkFireDataByType(res, 'PatientWaitingApprove')) {
                        //         Store.OrderStatus = I18nManager.isRTL() ? 'جديد' : 'New'
                        //     } else if (checkFireDataByType(res, 'DoctorApprove')) {
                        //         if (checkFireDataByType(res, 'FollowUpCompleted')) {
                        //             // To Clear timer after follow up from doctor
                        //             if (this.state.OrderDetails.GeneralStatusID === 1 || this.state.OrderDetails.GeneralStatusID === 3) {
                        //                 window.history.back()
                        //                 this.props.navigation.navigate('Bookings')
                        //             }
                        //             // To Clear timer after follow up from doctor
                        //             if (checkFireDataByType(res, 'OrderCompleted')) {
                        //                 Store.OrderStatus = ''
                        //             } else {
                        //                 Store.OrderStatus = I18nManager.isRTL() ? 'متابعة' : 'Follow Up'
                        //             }
                        //         } else {
                        //             Store.OrderStatus = I18nManager.isRTL() ? 'تقدم' : 'In-Progress'
                        //         }
                        //     }
                        // } else {
                        //     Store.OrderStatus = ''
                        // }
                    }))
                }
            })

            db_settings(params?.BookingID).on('value', async (snap) => {
                var Fsettings = await snap?.val()
                if (Fsettings) {
                    if (IS_MOUNTED) {
                        this.setState({ ChatSettings: Fsettings })
                    }
                }
            })
        })
    }

    render() {
        const { ConsumerID, ManPowerID, BookingID, ConsumerImage, ManPowerImage, data, OrderDetails, ActiveDateIndex,
            showImagePreviewModal, imagePreviewURI,
            TotalPrice, SpecializationName, TextMessage,
            currentTimeText, currentTime, ChatSettings,
            showCancelOrderModal, modalLoading, cancelOrderMessage,
            showConfirmOrderModal,
            showPaymentModal, payment_loading,
            Questions, showPatientDetailsView, patientDetails_loading,
            showInvoiceModal, invoiceData, bottomViewLoading,
            ChatTimer, ChatTimerText, showChatTimerModal, IsExtraTimeAdded
        } = this.state
        return (
            <ViewContainer>
                <div className="container patient-plus-bg d-flex align-items-center justify-content-center">
                    <div className="align-self-center main-container">
                        <CancelButton />

                        <ModalSubmit isOpen={showConfirmOrderModal} text={I18nManager.isRTL() ? 'هل تم تأكيد الطلب؟' : 'Is the order confirmed?'} uri="/assets/img/question.png"
                            leftButtonText={I18nManager.isRTL() ? 'نعم' : 'YES'} rightButtonText={I18nManager.isRTL() ? 'كلا' : 'NO'} loading={modalLoading} onRequestClose={() => this.changeState('showConfirmOrderModal', false)}
                            onLeftButtonClick={() => this.onConfirmOrder(true)} onRightButtonClick={() => this.onConfirmOrder(false)} />

                        <ModalInvoice isOpen={showInvoiceModal} Data={invoiceData} onRequestClose={() => this.changeState('showInvoiceModal', false)} />

                        {isOrderOpen(data, OrderDetails) && checkFireDataByType(data, 'PatientWaitingApprove') &&
                            <ModalWaitingConfirmation text={I18nManager.isRTL() ? 'انتظار موافقة الطبيب' : 'Waiting Doctor Approval' + '...'}
                                isOpen={true} loading={modalLoading} onCancelOrderPress={() => this.onYesCancelOrderModal()} />
                        }

                        <section className="msger">
                            {data?.map((item, index) => (
                                <div key={index}>
                                    <div className="chat-group-date-container">
                                        <span className="chat-group-date-text">{item?.date}</span>
                                    </div>
                                    {item?.data?.map((element, el_index) => (
                                        <OnlineChatMessage key={el_index} data={data} element={element}
                                            ConsumerImage={ConsumerImage} ManPowerImage={ManPowerImage} OrderDetails={OrderDetails}
                                            onStartFollowUp={(element) => this.onStartFollowUp(element)}
                                            onConfirmOrder={() => this.changeState('showConfirmOrderModal', true)}
                                        />
                                    ))}
                                </div>
                            ))}

                            {isOrderOpen(data, OrderDetails) && !checkFireDataByType(data, 'FollowUpCompleted') && !checkFireDataByType(data, 'PatientWaitingApprove') &&
                                <div className="msger-inputarea">
                                    <div className="nav-item dropdown dropup align-self-center" style={{ flex: 0 }}>
                                        <i className="fa fa-ellipsis-v" data-toggle="dropdown"></i>
                                        <div className="dropdown-menu">
                                            <input type="file" multiple={false} accept="application/pdf"
                                                id="fileupload" className="d-none"
                                                onChange={e => this.onImageFileUpload(e.target.files[0], "file")} />
                                            <span className="dropdown-item" onClick={() => this.onImageFileClick('fileupload')}>{I18nManager.isRTL() ? "الملف" : 'File'}</span>
                                            <input type="file" multiple={false} accept="image/jpeg, image/png, image/jpg"
                                                id="imageupload" className="d-none"
                                                onChange={e => this.onImageFileUpload(e.target.files[0], "image")} />
                                            <span className="dropdown-item" onClick={() => this.onImageFileClick('imageupload')}>{I18nManager.isRTL() ? "الصورة" : 'Image'}</span>
                                        </div>
                                    </div>
                                    <textarea type="text" onChange={(e) => this.onChangeText(e.target.value)} value={TextMessage} className="msger-input" placeholder={I18nManager.isRTL() ? 'اكتب رسالة' : 'Type a message'} />
                                    <button onClick={() => this.onSubmitSendText()} className="msger-send-btn">{I18nManager.isRTL() ? "إرسال" : "Send"}</button>
                                </div>
                            }
                        </section>
                    </div>
                </div>
            </ViewContainer>
        )
    }
}
