import React, { Fragment } from 'react'
import Footer from '../footer/Footer'
import MainNavBar from '../navbar/main-navbar/MainNavBar'

export default function ViewContainer(props) {
    return (
        <Fragment>
            <MainNavBar />
            {props.children}
            <Footer />
        </Fragment>
    )
}
