import React, { useEffect } from 'react'
import { goToWhatsapp } from '../../../common-service/CommonService'

export default function Whatsapp() {
    useEffect(() => {
        window.location = goToWhatsapp();
    })
    return (
        <></>
    )
}
