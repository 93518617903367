import React from 'react'
import I18nManager from '../../../../../core/I18nManager/I18nManager'
import StarsRating from '../../../../_common/stars-rating/StarsRating'

const PatientReviewItem = () => {
    return (
        <div className="doctor-item-tabs-content">
            <div className="review-tab">
                <div className="d-flex align-items-center mt-10 mb-10">
                    <img loading="lazy" src='/assets/img/patient-profile-ph.svg' alt="" />
                    <div className={I18nManager.isRTL() ? "mr-10" : "ml-10"}>
                        <div>
                            <span className="review-user">Jeremy Gomez</span>
                        </div>
                        <StarsRating value={5} />
                    </div>
                </div>
                <h4 className="review-date">Reviewed on Aug 29th 2016</h4>
                <h6 className="review-description">Doctor  Abby even gave me a follow up call 3 days after I was home to discuss test results. she is truly an outstanding dentist! I feel very comfortable .</h6>
            </div>
        </div>
    )
}

export default PatientReviewItem