import React from 'react'
import I18nManager from '../../I18nManager/I18nManager';
import AccordionFAQ from './AccordionFAQ';
import './FAQ.css';

const FAQ = () => {
    return (
        <div className="container mt-60 mb-60">
            <div className="row">
                <div className="col-md-4 col-12">
                    <img loading='lazy' src="/assets/img/faq.svg" className='img-fluid' alt='' />
                </div>
                <div className="col-md-8 col-12 faq-container">
                    <h1>{I18nManager.isRTL() ? "أسئلة مكررة" : "Frequently asked questions"}</h1>
                    {Array(4).fill(0).map((item, index) => (
                        <AccordionFAQ key={index} item={item} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default FAQ