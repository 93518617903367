import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/messaging';
import 'firebase/analytics';

var config = {
    apiKey: "AIzaSyAFTgabg9cESc3dEVHsOL0GDo0LXVn8yVk",
    authDomain: "docnowpatientdoctor.firebaseapp.com",
    databaseURL: "https://docnowpatientdoctor.firebaseio.com",
    projectId: "docnowpatientdoctor",
    storageBucket: "docnowpatientdoctor.appspot.com",
    messagingSenderId: "383806699605",
    appId: "1:383806699605:web:62f9e58dbced67680e7cdc",
    measurementId: "G-4W1M4PJXEK"
};
firebase.initializeApp(config)
firebase.analytics();

export default firebase;