import React, { useEffect } from 'react'
import './ModalAddon.css'
import Modal from 'react-modal';
import I18nManager from '../../../../core/I18nManager/I18nManager';
import { modalStyle } from '../../../../core/styles/custom-styles';

export default function ModalAddon(props) {

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    const { item, accept_loading, reject_loading } = props

    return (
        <Modal {...props} style={modalStyle} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
            <div className="d-flex flex-column p-20 align-items-center">
                <span className="modal-text pt-20 pb-10">{I18nManager.isRTL() ? 'تفاصيل الخدمة الاضافية' : 'Addon Service Request Details'}</span>
            </div>
            <div className="p-20 pr-20">
                <p>{I18nManager.isRTL() ? 'إسم الخدمة الاضافية' : 'Addon Service Name'}: {item.GeneralServiceAddonName}</p>
                <p>{I18nManager.isRTL() ? 'تعليق' : 'Comment'}: {item.Comment}</p>
                <p>{I18nManager.isRTL() ? 'السعر' : 'Price'} {item.AddonPrice} {I18nManager.isRTL() ? 'ريال سعودي' : 'SAR'}</p>
            </div>
            <div className="d-flex flex-row">
                <button disabled={accept_loading} className="modal-button-yes" onClick={props.onAcceptPress}>
                    {I18nManager.isRTL() ? 'قبول' : 'ACCEPT'}
                </button>
                <button disabled={reject_loading} className="modal-button-no" onClick={props.onRejectPress}>
                    {I18nManager.isRTL() ? 'رفض' : 'REJECT'}
                </button>
            </div>
        </Modal>
    )
}
