import moment from 'moment';

// Home Visit
export function HomeVisitWelcomeMessage(ManPowerFirstNameAr, ManPowerFirstName) {
    return {
        ar: 'مرحبا , شكرا لك لاختيارك د. ' + ManPowerFirstNameAr + '.',
        en: 'Hello, thank you for choosing Doctor  ' + ManPowerFirstName + '.'
    }

}
export function HomeVisitNegotiateMessage(time) {
    return {
        ar: 'مرحبا, أرغب بحجز موعد اليوم الساعة ' + time.ar + '.',
        en: 'Hello, I would like to make a booking today at ' + time.en + '.'
    }
}
export function HomeVisitChangeNegotiateMessage(time) {
    return {
        ar: 'عذرا. أنا مشغول في الوقت المحدد , الوقت الجديد سوف يكون في  ' + time.ar + '.',
        en: 'Sorry, I\'m busy at the chosen time slot, the new time slot is at ' + time.en + '.'
    }
}
export function HomeVisitAddonRequestRejectMessage(name) {
    return {
        ar: 'عذرا , تم رفض الخدمة الاضافیة  ' + name + '.',
        en: 'Your add-on request ' + name + ' has been rejected.'
    }
}
export function HomeVisitOrderCanceledMessage() {
    return {
        ar: 'تم إلغاء الموعد , شكرا لك.',
        en: 'Order has been canceled. Thank you.'
    }
}
export function HomeVisitAddonAcceptMessage(name) {
    return {
        ar: 'تم قبول الخدمة الاضافیة ' + name + '.',
        en: 'Your add-on request ' + name + ' has been approved.'
    }
}
export function HomeVisitAddonAcceptPaymentMessage() {
    return {
        ar: 'تمت عملیة الدفع للخدمات الاضافیة بنجاح.',
        en: 'Payment has been processed successfully for Addon Service.'
    }
}

// Consultation
export function ConsultationWelcomeMessage(ManPowerFirstNameAr, ManPowerFirstName) {
    return {
        ar: 'مرحبا د.' + ManPowerFirstNameAr + ' هل أنت متاح لخدمة الزيارة عن بعد؟',
        en: 'Hello, Doctor  ' + ManPowerFirstName + '. Are you available for a remote visit?'
    }

}
export function ConsultationOrderCanceledMessage() {
    return {
        ar: 'تم إلغاء الموعد , شكرا لك.',
        en: 'Order has been canceled. Thank you.'
    }
}

// Both
export function BankTransferOpenOrderMessage() {
    return {
        ar: 'الرجاء سداد قيمة الفاتورة المرفقة من خلال تحويل بنكي على الحساب المرفق معلوماته بالفاتورة. ثم إرسال وصل التحويل داخل هذه المحادثة.',
        en: 'Please transfer the invoice amount through bank transfer on the account details mentioned in attached invoice. And later to send the transfer receipt in this chat.'
    }
}
export function AddBankTransferConfirmationMessage() {
    return {
        ar: 'الرجاء إرسال صورة التحقق للتحويل المصرفي.',
        en: 'Please send a verification image for bank transfer.'
    }
}
export function WaitingBankTransferConfirmationMessage() {
    return {
        ar: 'في انتظار تأكيد التحويل المصرفي.',
        en: 'Waiting for Bank Transfer confirmation.'
    }
}
export function PaymentAddedOpenOrderMessage() {
    return {
        ar: 'تمت عملية الدفع بنجاح.',
        en: 'The payment was successful.'
    }
}
export function AcceptMessage() {
    return {
        ar: 'تم قبول الطلب.',
        en: 'Order Accepted.'
    }
}
export function OrderInvoice(BookingID, TotalPrice, PaymentMethods) {
    const ar = 'تفاصيل الفاتورة:\n\n' + 'Booking ID:\n' + BookingID + '\n\n'
        + 'السعر الكلي:\n' + TotalPrice + " " + 'ريال سعودي' + '\n\n'
        + 'طريقة الدفع:\n' + PaymentMethods.find(el => el.PaymentMethodID === 2)?.NameAr + '\n\n'
        + 'معلومات الدفع:\n' + PaymentMethods.find(el => el.PaymentMethodID === 2)?.Val_ + '\n\n'
        + 'تاريخ الفاتورة:\n' + moment().format('LLL');
    const en = 'Invoice Details:\n\n' + 'Booking ID:\n' + BookingID + '\n\n'
        + 'Total Price:\n' + TotalPrice + ' SAR' + '\n\n'
        + 'Payment Method:\n' + PaymentMethods.find(el => el.PaymentMethodID === 2)?.Name + '\n\n'
        + 'Payment Info:\n' + PaymentMethods.find(el => el.PaymentMethodID === 2)?.Val_ + '\n\n'
        + 'Invoice Date:\n' + moment().format('LLL');
    return {
        ar: ar,
        en: en
    }
}
export function PersonalDetailsMessage(data) {
    var ar = ''
    var en = ''

    var questAr = ''
    var questEn = ''

    data.QuestionGroupData.forEach((element, index) => {
        questAr += (index + 1) + "- " + element?.QuestionAr + ": " + element.QuestionAnswers.find(el => el.IsTrue === true)?.AnswerAr +
            (index < data?.QuestionGroupData?.length - 1 ? "\n" : '');
        questEn += (index + 1) + "- " + element?.Question + ": " + element.QuestionAnswers.find(el => el.IsTrue === true)?.Answer +
            (index < data?.QuestionGroupData?.length - 1 ? "\n" : '');
    });
    var patientAr = 'المريض: ' + (data?.FamilyMemberID === 0 ? 'أنا' : data?.PatientName)
    var patientEn = 'Patient: ' + (data?.FamilyMemberID === 0 ? 'ME' : data?.PatientName)

    // var medicationAr = 'الدواء الحالي:\n' + (data?.CurrentMedication || 'لا يوجد معلومات')
    // var medicationEn = 'Current Medication:\n' + (data?.CurrentMedication || 'No Informations')

    // var patientDescriptionAr = 'وصف المريض:\n' + (data?.PatientDescription || 'لا يوجد معلومات')
    // var patientDescriptionEn = 'Patient Description:\n' + (data?.PatientDescription || 'No Informations')

    // ar = "تفاصيل المريض:\n\n" + patientAr + '\n\n' + medicationAr + '\n\n' + patientDescriptionAr + '\n\n' + questAr
    // en = "Patient Details:\n\n" + patientEn + '\n\n' + medicationEn + '\n\n' + patientDescriptionEn + '\n\n' + questEn

    ar = "تفاصيل المريض:\n\n" + patientAr + '\n\n' + questAr
    en = "Patient Details:\n\n" + patientEn + '\n\n' + questEn

    return {
        ar: ar,
        en: en
    }
}
export function OrderReferenceMessage(data) {
    var ar = 'مرجع الطلب #' + data?.BookingID
    var en = 'Order Reference #' + data?.BookingID

    return {
        ar: ar,
        en: en
    }
}
export function AfterPaymentMessage(data) {
    switch (data) {
        case 'Onsite':
            return {
                ar: 'سيتصل بك الطبيب المعين لتأكيد الطلب.',
                en: 'Assigned doctor will contact you to confirm the order.'
            }
        case 'Online':
            return {
                ar: 'بعد تأكيد الطبيب، سيبدأ الطلب.',
                en: 'After doctor confirmation, the order will start.'
            }
        case 'Onclinic':
            return {
                ar: 'تحتاج إلى الحصول على تأكيد من موظف استقبال العيادة لبدء الطلب.',
                en: 'You need to get confirmation from clinic recepionist to start the order.'
            }
        default:
            break;
    }
}