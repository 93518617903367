import React from 'react'
import './PageNotFound.css'
import ViewContainer from '../view-container/ViewContainer'
import I18nManager from '../../I18nManager/I18nManager'

const PageNotFound = (props) => {
    const goToPage = () => {
        props.history.push('/')
    }
    return (
        <ViewContainer>
            <div className="page-not-found">
                <img loading='lazy' className="img-fluid" src='/assets/img/page-not-found.svg' />
                <h1>{I18nManager.isRTL() ? "الصفحة المطلوبة غير موجودة على السرفير" : "The request URL was not found on this server"}</h1>
                <button onClick={goToPage}>
                    <span>{I18nManager.isRTL() ? "الصفحة الرئسية" : "Go Home"}</span>
                </button>
            </div>
        </ViewContainer>
    )
}

export default PageNotFound