import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import GoogleMapReact from 'google-map-react';
import { modalStyle } from '../../../../core/styles/custom-styles';

var IS_MOUNTED = true

export default function ModalTracking(props) {

    const [currentLocation, setCurrentLocation] = useState({ latitude: 0, longitude: 0 })

    useEffect(() => {
        IS_MOUNTED = true
        Modal.setAppElement('body')
        navigator.geolocation.getCurrentPosition(position => {
            setCurrentLocation(position.coords)
        })
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    const { FireLocation } = props
    return (
        <Modal {...props} style={modalStyle} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
            <GoogleMapReact
                style={{ width: 300, height: 300 }}
                onClick={e => setCurrentLocation({ ...currentLocation, latitude: e.lat, longitude: e.lng })}
                bootstrapURLKeys={{ key: 'AIzaSyBQ8SHQ3-80PspE8HsPbZn53opJ8Qb8ecs' }}
                center={{ lat: FireLocation?.doctor?.latitude, lng: FireLocation?.doctor?.longitude }}
                zoom={15}>
                <div lat={FireLocation?.doctor?.latitude}
                    lng={FireLocation?.doctor?.longitude}>
                    <i className="fa fa-map-pin fa-2x"></i>
                </div>
                <div lat={FireLocation?.patient?.latitude}
                    lng={FireLocation?.patient?.longitude}>
                    <i className="fa fa-map-pin fa-2x"></i>
                </div>
            </GoogleMapReact>
        </Modal>
    )
}
