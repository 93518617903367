import React, { useState, useEffect } from 'react'
import { postGetBusinessInformations } from '../../core/api/Api'
import I18nManager from '../../core/I18nManager/I18nManager'
import ViewContainer from '../../core/routes/view-container/ViewContainer'
import CancelButton from '../_common/cancel-button/CancelButton'
import './Faq.css'

var IS_MOUNTED = true

export default function Faq() {

    const [data, setData] = useState([])

    function getBusinessInformations() {
        postGetBusinessInformations(4).then(res => {
            if (IS_MOUNTED) {
                var data = res.data.data
                setData(data)
            }
        }).catch(e => { })
    }

    useEffect(() => {
        IS_MOUNTED = true
        getBusinessInformations()
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    return (
        <ViewContainer>
            <div className="container patient-plus-bg d-flex align-items-center justify-content-center">
                <div className="container m-20 align-self-center main-container">
                    <CancelButton />
                    <h3 className="header pt-20 pb-20">{I18nManager.isRTL() ? 'الأسئلة الشائعة' : "FAQ's"}</h3>
                    <div id="accordion">
                        {data.map((item, index) => (
                            <div className="card btn btn-link" key={index} data-toggle="collapse" data-target={"#collapseOne" + index} aria-expanded="true" aria-controls="collapseOne">
                                <div className="card-header" id="headingOne">
                                    <h5 className="mb-0 d-flex flex-row align-items-center justify-content-between">
                                        <span className="btn">
                                            {item?.[I18nManager.isRTL() ? "TitleAr" : "TitleEn"]}
                                        </span>
                                        <i className="fa fa-sort-down fa-lg mr-20"></i>
                                    </h5>
                                </div>
                                <div id={"collapseOne" + index} className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div className="card-body">
                                        {item?.[I18nManager.isRTL() ? "DescriptionAr" : "DescriptionEn"]}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ViewContainer>
    )
}
