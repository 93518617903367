import React, { useContext } from 'react'
import I18nManager from '../../core/I18nManager/I18nManager'
import AppDownload from '../../core/routes/app-download/AppDownload'
import ViewContainer from '../../core/routes/view-container/ViewContainer'
import TopFilter from '../_common/filter/top-filter/TopFilter'
import TopViewBg from '../_common/top-view-bg/TopViewBg'
import FilterContext from '../../core/store/FilterContext'

const AllSpecialities = (props) => {
    const { ourSpecialities } = useContext(FilterContext)

    const goToPage = (path) => (e) => {
        e.preventDefault()
        props.history.push(path)
    }

    return (
        <ViewContainer>
            <TopViewBg title={I18nManager.isRTL() ? "تخصصات الطب" : "Medicine Specialities"} />
            <TopFilter />
            <div className="all-offers-bg mb-40">
                <div className="container">
                    <div className="all-offers-header-title">
                        <h1>{I18nManager.isRTL() ? "العيادات المميزة" : "Our Specialities"}</h1>
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-12 d-flex align-items-center">
                                <span>{I18nManager.isRTL() ? "اطلع على التخصصات الطبية المختلفة، وابق على اطلاع دائم بكل ما هو جديد في عالم الطب، أو احجز موعدًا في العيادة التي تختارها." : ""}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {ourSpecialities.map((item, index) => (
                            <div key={index} className="col-md-4 col-6">
                                <a onClick={goToPage("/speciality-details/" + item?.GeneralServiceCategoryID)} style={{ textDecoration: 'none' }}>
                                    <div className="our-specialites-container">
                                        <img loading="lazy" src={item.uri} alt="" />
                                        <h4>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</h4>
                                        <h5>{item?.[I18nManager.isRTL() ? "subTitleAr" : "subTitleEn"]}</h5>
                                        <button>
                                            <span>{I18nManager.isRTL() ? "اقرأ أكثر" : "Read More"}</span>
                                        </button>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <AppDownload />
        </ViewContainer>
    )
}

export default AllSpecialities
