import React, { useState } from 'react'
import { BASE_URL } from '../../../core/api/Api'
import I18nManager from '../../../core/I18nManager/I18nManager'
import FlipCard from '../../_common/flip-card/FlipCard'
import StarsRating from '../../_common/stars-rating/StarsRating'
import TimeslotAppointments from '../../_common/timeslot-appointments/TimeslotAppointments'

const RelatedDoctorItem = ({ item, loading, onIsMoreClick, onTimeSlotClick, onPreviousPress, onNextPress, onBookNowPress }) => {

    const [isAppointmentBookingFlipped, setIsAppointmentBookingFlipped] = useState(false)

    const flipCardAppointmentBooking = (e) => {
        e.preventDefault()
        setIsAppointmentBookingFlipped(prevState => !prevState)
    }

    const _onIsMoreClick = (index) => () => {
        onIsMoreClick(index)
    }

    const _onTimeSlotClick = (index, el_index) => () => {
        onTimeSlotClick(index, el_index)
    }

    const _onBookNowPress = (FullName, MobileNo, Password, Email) => () => {
        onBookNowPress(FullName, MobileNo, Password, Email)
    }

    return (
        <div className="col-md-3 col-6">
            <FlipCard isFlipped={isAppointmentBookingFlipped}
                frontChildren={
                    <div className="related-doctors">
                        <div className="doctor-profile">
                            <img loading="lazy" className="rounded-circle" src={item?.Profile?.Image ? (BASE_URL + item?.Profile?.Image) : '/assets/img/doctor-profile-ph.svg'} alt="" />
                        </div>
                        <h5 className="doctor-name">{I18nManager.isRTL() ? ((item?.Profile?.GenderName === "Male" ? 'دكتور' : 'دكتورة') + ' ' + item?.Profile?.FirstNameAr + ' ' + item?.Profile?.LastNameAr) : ('Doctor ' + item?.Profile?.FirstName + ' ' + item?.Profile?.LastName)}</h5>
                        <h5 className="doctor-specialities">{(I18nManager.isRTL() ? "التخصصات" : "Specialities") + " - " + (item?.Profile?.[I18nManager.isRTL() ? 'OccupationName_Ar' : 'OccupationName'])}</h5>
                        <div className="d-flex align-items-center">
                            <StarsRating value={item?.Profile?.Rating || 5} />
                            <span className="doctor-rating">{item?.Profile?.Rating || 5} {I18nManager.isRTL() ? "من" : "out of"} 5</span>
                        </div>
                        <div className="d-flex align-items-center doctor-info-container mt-10">
                            <img loading="lazy" width='15' height='15' src="/assets/img/experience.svg" alt="" />
                            <span className="doctor-info">{I18nManager.isRTL() ? "الخبرة" : "Experience"} : {item?.Profile?.Experience || 0} {I18nManager.isRTL() ? 'سنوات' : 'Years'}</span>
                        </div>
                        <div className="d-flex align-items-center doctor-info-container">
                            <img loading="lazy" width='15' height='15' src="/assets/img/mobile.svg" alt="" />
                            <a href="tel:966550600288">
                                <span className="doctor-info">{I18nManager.isRTL() ? "رقم الجوال" : "Mobile"}: +966 550 600 288</span>
                            </a>
                        </div>
                        {item?.GoogleDistanceAndDuration?.DistanceText && <div className="d-flex align-items-center doctor-info-container">
                            <img loading="lazy" width='15' height='15' src="/assets/img/time.svg" alt="" />
                            <span className="doctor-info">{I18nManager.isRTL() ? "المسافة" : "Distance"}: {item?.GoogleDistanceAndDuration?.DistanceText}</span>
                        </div>}
                        <div className="d-flex align-items-center doctor-info-container">
                            <img loading="lazy" width='15' height='15' src="/assets/img/fees.svg" alt="" />
                            <span className="doctor-info">{I18nManager.isRTL() ? "كشفية" : "Fees"} : {((item?.OrderOnlineAmount ?? item?.OrderAmount) || 0) + " " + (I18nManager.isRTL() ? 'ريال' : 'SAR')}</span>
                        </div>
                        <div className="d-flex align-items-center doctor-info-container">
                            <img loading="lazy" width='15' height='15' src="/assets/img/nationality.svg" alt="" />
                            <span className="doctor-info">{I18nManager.isRTL() ? "جنسية الطبيب" : "Nationality"} : {item?.Profile?.[I18nManager.isRTL() ? 'NationalityNameAr' : 'NationalityName']}</span>
                        </div>
                        <button className="d-flex m-auto" onClick={flipCardAppointmentBooking}>
                            <span>{I18nManager.isRTL() ? "احجز الآن" : "Book Now"}</span>
                        </button>
                    </div>
                }
                backChildrem={
                    <TimeslotAppointments onFlipCloseButtonClick={flipCardAppointmentBooking}
                        data={item?.DoctorTimeSlots?.dateSlots || []}
                        loading={loading} onIsMoreClick={_onIsMoreClick}
                        onPreviousPress={onPreviousPress} onNextPress={onNextPress}
                        onTimeSlotClick={_onTimeSlotClick} onBookNowPress={_onBookNowPress}
                    />
                }
            />

        </div>
    )
}

export default RelatedDoctorItem