import React, { useEffect, useState, useContext } from 'react'
import './TopFilter.css'
import ReactSelect from '../../react-select/ReactSelect'
import { getCoordsFromIp, postGetDoctorLocationWithProfile, postGetClinics } from '../../../../core/api/Api';
import { useHistory } from "react-router-dom";
import FilterContext from '../../../../core/store/FilterContext'
import I18nManager from '../../../../core/I18nManager/I18nManager';
import ViewDisplay from '../../view-display/ViewDisplay';

var IS_MOUNTED = true
const TopFilter = () => {
    const { setFilterContextValue, ourSpecialities } = useContext(FilterContext)
    let history = useHistory();

    const [mobileFilters, setMobileFilters] = useState([
        { titleEn: "Select Doctor", titleAr: "اختر الطبيب", uri: "/assets/img/type-doctor-name.svg", active: true },
        { titleEn: "Choose Specialty", titleAr: "اختر التخصص", uri: "/assets/img/choose-speciality.svg", active: false },
        { titleEn: "Select Clinic", titleAr: "اختر العيادة", uri: "/assets/img/choose-city.svg", active: false },
    ])
    const [clinics, setClinics] = useState([])
    const [doctors, setDoctors] = useState([])

    const styles = {
        containerStyle: {
            width: 140, borderWidth: 0, backgroundColor: 'transparent'
        },
        mobileContainerStyle: {
            margin: 'auto', width: '100%', backgroundColor: 'transparent',
        },
        controlStyle: {
            backgroundColor: 'transparent'
        },
        placeholderStyle: {
            fontSize: '14px !important'
        },
    }

    const getDoctorLocationWithProfile = () => {
        getCoordsFromIp().then(coords => {
            let obj = {
                Latitude: coords?.data?.location?.lat, Longitude: coords?.data?.location?.lng,
                // 0 is to get all doctors and then filter when choosing speciality
                Radius: null, SpecialityID: ourSpecialities.find(el => el.active === true)?.GeneralServiceCategoryID,
                IsRemoteSession: false, IsOnClinic: false
            }
            postGetDoctorLocationWithProfile(obj).then(res => {
                if (IS_MOUNTED) {
                    var data = res.data.data
                    data.ProfileDate = data?.ProfileDate?.sort((a, b) => a?.Profile?.AvailabilityOrder - b?.Profile?.AvailabilityOrder)
                    setDoctors(data?.ProfileDate)
                }
            }).catch(e => { })
        }).catch(e => getDoctorLocationWithProfile())
    }

    const getClinics = () => {
        const obj = { Latitude: 0, Longitude: 0, IsRemoteSession: true, Radius: null }
        postGetClinics(obj).then(res => {
            var data = res.data.data
            setClinics(data?.ProfileData)
        }).catch(e => { })
    }

    const onSpecialityChange = (item, trigger) => {
        const triggerAction = trigger?.action
        let result = [...ourSpecialities]
        result.forEach((element, i) => {
            element.active = triggerAction === "clear" ? false : (parseInt(item?.GeneralServiceCategoryID) === element?.GeneralServiceCategoryID)
        });
        setFilterContextValue('ourSpecialities', result)
    }

    const onClinicChange = (item, trigger) => {
        const triggerAction = trigger?.action
        const selectedID = item?.Profile?.ClinicID
        let tmpClinics = [...clinics]
        tmpClinics.forEach(element => {
            element.active = triggerAction === "clear" ? false : (element?.Profile?.ClinicID === parseInt(selectedID))
        });
        setClinics(tmpClinics)
    }

    const onDoctorChange = (item, trigger) => {
        const triggerAction = trigger?.action
        const selectedID = item?.Profile?.ManPowerID
        let tmpDoctors = [...doctors]
        tmpDoctors.forEach(element => {
            element.active = triggerAction === "clear" ? false : (element?.Profile?.ManPowerID === parseInt(selectedID))
        });
        setDoctors(tmpDoctors)
    }

    const onMobileFilterClick = (index) => (e) => {
        e.preventDefault()
        let tmpMobileFilters = [...mobileFilters]
        tmpMobileFilters.forEach((element, i) => {
            element.active = parseInt(index) === i
        });
        setMobileFilters(tmpMobileFilters)
    }

    const onSeachFilterClick = (e) => {
        e.preventDefault();
        const clinicSelectedItem = clinics.find(el => el.active === true)
        const doctorSelectedItem = doctors.find(el => el.active === true)
        if (doctorSelectedItem) {
            history.push('/doctor-info/' + doctorSelectedItem?.Profile?.ManPowerID)
        } else if (clinicSelectedItem) {
            history.push('/clinic-details/' + clinicSelectedItem?.Profile?.ClinicID)
        } else {
            history.push('/doctors-list/clinic')
        }
    }

    useEffect(() => {
        IS_MOUNTED = true
        if (clinics.length === 0) {
            getClinics()
        }
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    useEffect(() => {
        getDoctorLocationWithProfile()
    }, [ourSpecialities])

    return (
        <ViewDisplay
            showMinHideMax={
                <div className="container filter-container">
                    <div className="filter-sub-container">
                        <div className="d-flex align-items-center justify-content-center">
                            <img loading="lazy" src="/assets/img/type-doctor-name.svg" alt="" />
                            <ReactSelect isSearchable options={doctors}
                                placeholder={I18nManager.isRTL() ? "اختر الطبيب" : "Select Doctor"}
                                getOptionLabel={item => item?.Profile?.[I18nManager.isRTL() ? "FirstNameAr" : "FirstName"] + " " +
                                    item?.Profile?.[I18nManager.isRTL() ? "LastNameAr" : "LastName"]}
                                getOptionValue={item => item?.Profile?.ManPowerID}
                                containerStyle={styles.containerStyle} controlStyle={styles.controlStyle}
                                placeholderStyle={styles.placeholderStyle} singleValueStyle={styles.placeholderStyle}
                                onChange={onDoctorChange} />
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <img loading="lazy" src="/assets/img/choose-speciality.svg" alt="" />
                            <ReactSelect isSearchable options={ourSpecialities}
                                placeholder={ourSpecialities.find(el => el.active === true)?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}
                                getOptionLabel={item => item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]} getOptionValue={item => item?.GeneralServiceCategoryID}
                                containerStyle={styles.containerStyle} controlStyle={styles.controlStyle}
                                placeholderStyle={styles.placeholderStyle} singleValueStyle={styles.placeholderStyle}
                                onChange={onSpecialityChange} />
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <img loading="lazy" src="/assets/img/choose-city.svg" alt="" />
                            <ReactSelect isSearchable options={clinics}
                                placeholder={clinics.find(el => el.active === true)?.Profile?.[I18nManager.isRTL() ? "ClinicNameAr" : "ClinicName"]}
                                getOptionLabel={item => item?.Profile?.[I18nManager.isRTL() ? "ClinicNameAr" : "ClinicName"]} getOptionValue={item => item?.Profile?.ClinicID}
                                containerStyle={styles.containerStyle} controlStyle={styles.controlStyle}
                                placeholderStyle={styles.placeholderStyle} singleValueStyle={styles.placeholderStyle}
                                onChange={onClinicChange} />
                        </div>
                        {/* <div className="d-flex align-items-center justify-content-center">
                    <img loading="lazy" src="/assets/img/choose-area.svg" alt="" />
                    <ReactSelect isSearchable options={[]}
                        placeholder={I18nManager.isRTL() ? "اختر المنطقة" : "Choose Area"}
                        optionLabel='title' optionValue='title'
                        containerStyle={styles.containerStyle} controlStyle={styles.controlStyle}
                        placeholderStyle={styles.placeholderStyle} singleValueStyle={styles.placeholderStyle} />
                </div> */}
                    </div>
                    <button onClick={onSeachFilterClick}>
                        <img loading="lazy" src="/assets/img/search.svg" alt="" />
                    </button>
                </div>
            }
            hideMinShowMax={
                <>
                    <div className="mobile-top-filter-container">
                        {mobileFilters?.map((item, index) => (
                            <div key={index} className="col-4" style={{ backgroundColor: item?.active ? '#ffd433' : 'transparent' }}
                                onClick={onMobileFilterClick(index)}>
                                <div className="mobile-top-filter-sub-container">
                                    <img loading="lazy" src={item.uri} alt="" />
                                    <span>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="mr-15 ml-15 mt-15">
                        {mobileFilters?.[0]?.active &&
                            <ReactSelect isSearchable options={doctors}
                                placeholder={I18nManager.isRTL() ? "اختر الطبيب" : "Select Doctor"}
                                getOptionLabel={item => item?.Profile?.[I18nManager.isRTL() ? "FirstNameAr" : "FirstName"] + " " +
                                    item?.Profile?.[I18nManager.isRTL() ? "LastNameAr" : "LastName"]}
                                getOptionValue={item => item?.Profile?.ManPowerID}
                                containerStyle={styles.mobileContainerStyle} controlStyle={styles.controlStyle}
                                placeholderStyle={styles.placeholderStyle} singleValueStyle={styles.placeholderStyle}
                                onChange={onDoctorChange} />
                        }
                        {mobileFilters?.[1]?.active &&
                            <ReactSelect isSearchable options={ourSpecialities}
                                placeholder={ourSpecialities.find(el => el.active === true)?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}
                                getOptionLabel={item => item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]} getOptionValue={item => item?.GeneralServiceCategoryID}
                                containerStyle={styles.mobileContainerStyle} controlStyle={styles.controlStyle}
                                placeholderStyle={styles.mobileContainerStyle} singleValueStyle={styles.placeholderStyle}
                                onChange={onSpecialityChange} />
                        }
                        {mobileFilters?.[2]?.active &&
                            <ReactSelect isSearchable options={clinics}
                                placeholder={clinics.find(el => el.active === true)?.Profile?.[I18nManager.isRTL() ? "ClinicNameAr" : "ClinicName"]}
                                getOptionLabel={item => item?.Profile?.[I18nManager.isRTL() ? "ClinicNameAr" : "ClinicName"]} getOptionValue={item => item?.Profile?.ClinicID}
                                containerStyle={styles.mobileContainerStyle} controlStyle={styles.controlStyle}
                                placeholderStyle={styles.placeholderStyle} singleValueStyle={styles.placeholderStyle}
                                onChange={onClinicChange} />
                        }
                    </div>
                </>
            }
        />

    )
}

export default TopFilter
