import React from 'react'
import moment from 'moment';
import { BASE_URL, getMapStaticImage } from '../../../../core/api/Api';
import InvoiceView from '../../chat-payment/modal-invoice/invoice-view/InvoiceView';
import './AssistanteChatMessage.css';
import I18nManager from '../../../../core/I18nManager/I18nManager';
import { colors } from '../../../../core/colors/Colors';
import { stopAllAudio } from '../../../../core/common-service/CommonService';
import AssistanteChooseCalendar from './assistante-choose-calendar/AssistanteChooseCalendar';

export default function AssistanteChatMessage(props) {

    function getMessageTime(element) {
        return moment(new Date(element.CreatedDate)).locale(I18nManager.isRTL() ? 'ar' : 'en').format("hh:mm A")
    }

    const { element, ConsumerImage,
        onDateSelected, onCalendarSlotPress, CalendarSelectedDate, TimeSlots, timeSlotDisabled } = props
    return (
        <div className={element?.SenderID === element?.ManPowerID ? "msg right-msg" : "msg left-msg"}>
            <img className="msg-img" src={element?.SenderID === element?.ManPowerID ? "/assets/img/assistante-headset.png" : (ConsumerImage ? BASE_URL + ConsumerImage : "/assets/img/patient-profile-ph.svg")} alt="" />
            <div className="msg-bubble">
                <div className={element?.SenderID === element?.ManPowerID ? "msg-text-left" : "msg-text-right"}
                    style={{ backgroundColor: element.Type === 'VoiceNote' ? 'transparent' : (element?.SenderID === element?.ManPowerID ? 'white' : colors.lightBlue) }}>
                    {(element?.Text || element?.AutoText) && <div className="p-5">
                        {element?.Text && element?.Text}
                        {element?.AutoText && (I18nManager.isRTL() ? element?.AutoText.ar : element?.AutoText.en)}
                    </div>}
                    {element?.Type === 'SelectPeople' && <button onClick={props.onChoosePeople} className="msg-text-yes-button ">{I18nManager.isRTL() ? 'تحديد' : 'Select'}</button>}
                    {element?.Type === 'AddBankTransferConfirmation' &&
                        <>
                            <input type="file" multiple={false} accept="image/jpeg, image/png, image/jpg"
                                id="banktransferimageupload" className="d-none" onChange={props.onBankTransferImageUpload} />
                            <button onClick={props.onOpenImagePicker} className="msg-text-yes-button ">{I18nManager.isRTL() ? 'اختر صورة' : 'Select Image'}</button>
                        </>
                    }
                    {element?.Type === 'SendAmbulance' &&
                        <div className="d-flex flex-row align-items-center">
                            <button onClick={props.onAmbulanceResponse.bind(this, true)} className="msg-text-yes-button" style={{ borderBottomRightRadius: 0 }}>{I18nManager.isRTL() ? 'نعم' : 'Yes'}</button>
                            <button onClick={props.onAmbulanceResponse.bind(this, false)} className="msg-text-no-button" style={{ borderBottomLeftRadius: 0 }}>{I18nManager.isRTL() ? 'كلا' : 'No'}</button>
                        </div>
                    }
                    {element?.Type === 'SelectLocation' && <button onClick={props.onChooseLocation} className="msg-text-yes-button ">{I18nManager.isRTL() ? 'تحديد' : 'Select'}</button>}
                    {element?.Type === 'Location' &&
                        <a href={"https://www.google.com/maps/@" + element.Data?.latitude + "," + element.Data?.longitude + ",12z"} target="_blank" rel="noopener noreferrer">
                            <img src={getMapStaticImage(element.Data?.latitude, element.Data?.longitude, 100, 100)} className="location-img" alt="" />
                        </a>
                    }
                    {element?.Type === 'Image' &&
                        <a href={BASE_URL + element?.Data?.uri} target="_blank" rel="noopener noreferrer">
                            <img src={BASE_URL + element?.Data?.uri} className="location-img" alt="" />
                        </a>
                    }
                    {element?.Type === 'File' &&
                        <a href={BASE_URL + element?.Data?.uri} target="_blank" rel="noopener noreferrer">
                            <img src="/assets/img/pdf-colored.png" className="location-img" alt="" />
                        </a>
                    }
                    {element?.Type === 'SelectAppointment' &&
                        <AssistanteChooseCalendar {...props} onChange={onDateSelected} value={moment(CalendarSelectedDate).format('YYYY-MM-DD')}
                            onSelectChange={onCalendarSlotPress} disabled={timeSlotDisabled} TimeSlots={TimeSlots}
                        />
                    }
                    {element.Type === 'SelectSpeciality' &&
                        <div className="d-flex flex-row align-items-center">
                            <button className="msg-text-no-button" onClick={props.onChooseSpeciality.bind(this, false)}>{I18nManager.isRTL() ? 'انا لا اعرف' : 'Skip'}</button>
                            <button className="msg-text-yes-button" onClick={props.onChooseSpeciality.bind(this, true)}>{I18nManager.isRTL() ? 'تحديد' : 'Select'}</button>
                        </div>
                    }
                    {element.Type === 'PatientNegotiate' &&
                        <button className="msg-text-yes-button">{I18nManager.isRTL() ? 'انتظار...' : 'Waiting...'}</button>
                    }
                    {element.Type === 'Invoice' &&
                        <div>
                            <div style={{ maxHeight: 150, overflow: 'hidden' }}>
                                <InvoiceView Data={element.Data} showPreview={true} />
                            </div>
                            <button onClick={props.onShowInvoiceModal.bind(this, element.Data)} className="msg-text-yes-button">{I18nManager.isRTL() ? 'عرض الفاتورة' : 'View Invoice'}</button>
                        </div>
                    }
                    {element?.Type === 'WaitingPatientPayment' && <button onClick={props.onPaymentPress} className="msg-text-yes-button">{I18nManager.isRTL() ? 'إصدار الفاتورة' : 'Add Payment'}</button>}
                    {element.Type === 'VoiceNote' &&
                        <audio controls onPlay={() => stopAllAudio()}>
                            <source src={BASE_URL + element?.Data?.uri} />
                        </audio>
                    }
                </div>
                <div className={element?.SenderID === element?.ManPowerID ? "msg-info-time-left" : "msg-info-time-right"}>{getMessageTime(element)}</div>
            </div>
        </div >
    )
}
