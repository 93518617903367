import React from 'react'
import { constants } from '../../core/constants/constants';
import I18nManager from '../../core/I18nManager/I18nManager';
import ViewContainer from '../../core/routes/view-container/ViewContainer';

export default function TermsConditions() {
    const data = constants.termsConditions.data
    return (
        <ViewContainer>
            <div className="pt-100 container">
                <h1>{I18nManager.isRTL() ? 'الشروط والأحكام' : 'Terms and conditions'}</h1>
                {data.map((item, index) => (
                    <div key={index} className="mt-20 mb-20">
                        {item?.titleEn && <h2 key={index}>{item?.[I18nManager.isRTL() ? 'titleAr' : 'titleEn']}</h2>}
                        {item?.descriptionEn && item?.[I18nManager.isRTL() ? 'descriptionAr' : 'descriptionEn'].map((element, index) => (
                            <p key={index}>{element}</p>
                        ))}
                        {item?.list && <>
                            {item?.list?.titleAr && <h3 key={index}>{item?.list?.[I18nManager.isRTL() ? 'titleAr' : 'titleEn']}</h3>}
                            {item?.list?.subTitleEn && item?.list?.[I18nManager.isRTL() ? 'subTitleAr' : 'subTitleEn'].map((element, index) => (
                                <h5 key={index}>{element}</h5>
                            ))}
                            {item?.list?.infoEn && item?.list?.[I18nManager.isRTL() ? 'infoAr' : 'infoEn'].map((element, index) => (
                                <li key={index}>{element}</li>
                            ))}
                        </>}
                    </div>
                ))}
                <span className="font-bold">{constants.termsConditions.lastUpdate[I18nManager.isRTL() ? 'ar' : 'en']}</span>
            </div>
        </ViewContainer>
    )
}
