import React, { Component } from 'react'
import './AssistanteChat.css'
import { postGetPatientOrderByID } from '../../../core/api/Api'
import { db_chat, reception_db_chat, reception_db_data } from '../../../core/firebase/fire/Fire'
import moment from 'moment';
import I18nManager from '../../../core/I18nManager/I18nManager';
import firebase from '../../../core/firebase/firebase';

var IS_MOUNTED = true

const EMERGENCY_MSG = { ar: 'مرحبا، هل تريد مني أن أرسل لك سيارة إسعاف؟', en: 'Hello, Would you like me to send you an Ambulance?' }
const WELCOME_SPECIALITY_MSG = { ar: 'أهلا بك في دُك ناو للخدمات الطبية. سلامتك ولا أراك الله مكروهاً.\n\nما هو التخصص الي تود ان نخدمك فيه ؟', en: 'Welcome to DocNow for medical services. Hope you safe and healthy.\n\nWhich medical speciality you would like us to help with ?' }
const WELCOME_CASE_MSG = { ar: 'أهلا بك في دُك ناو للخدمات الطبية. سلامتك ولا أراك الله مكروهاً.\n\nما هي حالتك ؟', en: 'Welcome to DocNow for medical services. Hope you safe and healthy.\n\nWhat is your case ?' }


export default class AutoPaymentRedirect extends Component {

    state = {
        data: [],
        FireData: [],
        params: {},
        BookingID: 0,
        ManPowerID: 0,
        ConsumerID: 0,
    }

    onPaymentPress() {
        const { data, FireData, params } = this.state
        const item = { ...params, IsRemoteBooking: FireData?.IsRemoteSession, FireData, PaymentType: 'OpenOrder', data }
        this.props.history.push('/chat-payment', item)
    }

    getPatientOrderByID(BookingID, cb) {
        postGetPatientOrderByID(BookingID).then(res => {
            var data = res.data.data[0]
            if (IS_MOUNTED) {
                if (data?.BookingID) {
                    this.setState({ BookingID: data?.BookingID, ManPowerID: data?.ManPowerID, ConsumerID: data?.ConsumerID })
                    const params = this.props.location.state
                    this.setState({ params: { ...params, ...data } }, () => {
                        cb()
                    })
                } else {
                    alert(I18nManager.isRTL() ? 'الطلب غير موجود!' : 'Order Not Found!')
                }
            }
        }).catch(e => this.getPatientOrderByID(BookingID, cb))
    }


    async SendAssistanteFireMessage(Text, AutoText, Data, Type) {
        const { BookingID, ConsumerID, ManPowerID } = this.state
        reception_db_chat(BookingID).push({
            SenderID: ManPowerID,
            ConsumerID,
            ManPowerID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        });
    }

    async setupData(data) {
        const today = moment().format('L')
        const yesterday = moment().subtract(1, 'days').format('L')
        data.forEach(element => {
            if (moment(element.CreatedDate).format('L') === today) {
                element.date = I18nManager.isRTL() ? 'اليوم' : 'TODAY'
            } else if (moment(element.CreatedDate).format('L') === yesterday) {
                element.date = I18nManager.isRTL() ? 'البارحة' : 'YESTERDAY'
            } else {
                element.date = moment(element.CreatedDate).locale(I18nManager.isRTL() ? 'ar' : 'en').format('L')
            }
        });
        const distinct = data.filter((item, i, arr) => {
            return arr.indexOf(arr.find(t => t.date === item.date)) === i;
        });
        const distinctDates = []
        distinct.forEach(element => {
            distinctDates.push(element.date)
        });
        var new_arr = []
        distinctDates.forEach(el_date => {
            const res = data.filter(el => el.date === el_date)
            new_arr.push({ date: el_date, data: res })
        });
        return await new_arr
    }

    componentWillUnmount() {
        const { params } = this.state
        IS_MOUNTED = false
        db_chat(params?.BookingID).off()
        reception_db_chat(params?.BookingID).off()
        reception_db_data(params?.BookingID).off()
    }

    componentDidMount() {
        IS_MOUNTED = true
        const { id } = this.props.match.params
        this.getPatientOrderByID(id, () => {
            const { params } = this.state
            reception_db_chat(params?.BookingID).on('value', async (snap) => {
                var chat = await snap?.val()
                if (chat) {
                    var objectKeys = await Object.keys(chat);
                    var data = []
                    objectKeys.forEach(async (element) => {
                        await data.push({ key: element, ...chat[element] })
                    });
                    data = await data.sort((a, b) => a.CreatedDate - b.CreatedDate)
                    this.setupData(data).then((async (res) => {
                        if (IS_MOUNTED) {
                            await this.setState({ data: res })
                        }
                    }))
                    reception_db_data(params?.BookingID).once('value', async (snap) => {
                        var data = snap?.val()
                        if (data) {
                            this.setState({ FireData: data }, () => {
                                this.onPaymentPress()
                            })
                        }
                    })
                } else {
                    if (params?.IsSpecialitySelected) {
                        this.SendAssistanteFireMessage(null, WELCOME_CASE_MSG, null, 'AddPatientCase')
                    } else {
                        this.SendAssistanteFireMessage(null, WELCOME_SPECIALITY_MSG, null, 'SelectSpeciality')
                    }
                    return
                    this.SendAssistanteFireMessage(null, EMERGENCY_MSG, null, 'SendAmbulance')
                }
            })
        })
    }

    render() {
        return (
            <></>
        )
    }
}
