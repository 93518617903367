import React, { Component } from 'react'
import { checkFireDataByType, isOrderOpen, toBase64 } from '../../../core/common-service/CommonService';
import { db, db_chat, db_data, db_location, db_settings } from '../../../core/firebase/fire/Fire';
import CancelButton from '../../_common/cancel-button/CancelButton';
import ModalInvoice from '../chat-payment/modal-invoice/ModalInvoice';
import './OnsiteChat.css';
import moment from 'moment';
import { postAcceptAddonRequest, postBookingOrder, postGetDoctorAvailabilityForPatient, postGetPatientOrderByID, postPatientUploadFileAndGetUrl, postSaveChatHistory } from '../../../core/api/Api';
import firebase from '../../../core/firebase/firebase';
import { HomeVisitAddonRequestRejectMessage } from '../../../core/firebase/automated-message/AutomatedMessage';
import OnsiteChatMessage from './onsite-chat-message/OnsiteChatMessage';
import ModalTracking from './modal-tracking/ModalTracking';
import ModalAddon from './modal-addon/ModalAddon';
import I18nManager from '../../../core/I18nManager/I18nManager';
import ViewContainer from '../../../core/routes/view-container/ViewContainer';
import ModalSubmit from '../../_common/modal-submit/ModalSubmit';

var IS_MOUNTED = true

const ORDER_CONFIRMATION_MSG = { ar: 'تم تأكيد الطلب من قبل المريض. (منجز)', en: 'Order has been confirmed by the patient. (Completed)' }
const ORDER_NO_CONFIRMATION_MSG = { ar: 'تم تأكيد الطلب من قبل المريض. (غير مكتمل)', en: 'Order has been confirmed by the patient. (Not Completed)' }

export default class OnsiteChat extends Component {

    state = {
        data: [],
        TimeSlots: [],
        showTimeSlot: false,
        ActiveTimeSlotIndex: 0,
        Addon: {}, AddonSelectedElement: {}, reject_loading: false, showAddonModal: false,
        FireLocation: {},
        TotalPrice: 0,
        SpecializationName: '',
        Questions: [], showPatientDetailsView: false, patientDetails_loading: false,
        params: {},
        doctorNegotiate_loading: false,

        showMainLoaderView: false,

        OrderDetails: {},

        ActiveDateIndex: null,

        showPaymentModal: false, payment_loading: false,

        showTrackingModal: false,

        showReopenModal: false,

        showImagePreviewModal: false, imagePreviewURI: '',

        showCancelOrderModal: false, modalLoading: false,
        cancelOrderMessage: I18nManager.isRTL() ? 'هل أنت متأكد أنك تريد إلغاء الطلب؟' : 'Are your sure you want to cancel order?',

        showConfirmOrderModal: false,

        showInvoiceModal: false, invoiceData: {},

        bottomViewLoading: false,

        ChatSettings: {},

        TextMessage: '', SendMicButtonStatus: 'initial',

        ConsumerID: 0, ManPowerID: 0, BookingID: 0, ConsumerImage: '', ManPowerImage: ''
    }

    changeState(key, value) {
        this.setState({ [key]: value })
    }

    // Start Bottom View
    onChangeText(TextMessage) {
        const { params } = this.state
        clearTimeout(this.timeout)
        if (TextMessage.indexOf("\n") === -1 && TextMessage !== ' ') {
            this.setState({ TextMessage })
            db_settings(params?.BookingID).child('typing').update({ patient_typing: true })
            this.timeout = setTimeout(() => {
                db_settings(params?.BookingID).child('typing').update({ patient_typing: false })
            }, 1000);
        }
    }

    onSubmitSendText() {
        const { TextMessage } = this.state
        if (TextMessage.length > 0) {
            this.SendPatientFireMessage(TextMessage.trim(), null, null, null)
            this.setState({ TextMessage: '' })
        }
    }

    getPatientOrderByID(BookingID, cb) {
        postGetPatientOrderByID(BookingID).then(res => {
            var data = res.data.data[0]
            if (IS_MOUNTED) {
                const params = this.props.location.state
                this.setState({ params: { ...params, ...data } }, () => {
                    this.setState({ OrderDetails: data })
                    this.setState({ TotalPrice: data?.TotalPrice, SpecializationName: I18nManager.isRTL() ? data?.GeneralServiceCategoryAr : data?.GeneralServiceCategory, })
                    if (data?.GeneralStatusID === 2 || data.GeneralStatusID === 5) {
                        // Store.OrderStatus = ''
                    }
                    cb()
                })
            }
        }).catch(e => this.getPatientOrderByID(BookingID, cb))
    }

    saveChatHistory(BookingID, data) {
        const obj = { BookingID, Text: JSON.stringify(data) }
        postSaveChatHistory(obj).then(res => {
        }).catch(e => { })
    }

    async setupData(data) {
        const today = moment().format('L')
        const yesterday = moment().subtract(1, 'days').format('L')
        data.forEach(element => {
            if (moment(element.CreatedDate).format('L') === today) {
                element.date = I18nManager.isRTL() ? 'اليوم' : 'TODAY'
            } else if (moment(element.CreatedDate).format('L') === yesterday) {
                element.date = I18nManager.isRTL() ? 'البارحة' : 'YESTERDAY'
            } else {
                element.date = moment(element.CreatedDate).locale(I18nManager.isRTL() ? 'ar' : 'en').format('L')
            }
        });
        const distinct = data.filter((item, i, arr) => {
            return arr.indexOf(arr.find(t => t.date === item.date)) === i;
        });
        const distinctDates = []
        distinct.forEach(element => {
            distinctDates.push(element.date)
        });
        var new_arr = []
        distinctDates.forEach(el_date => {
            const res = data.filter(el => el.date === el_date)
            new_arr.push({ date: el_date, data: res })
        });
        return await new_arr
    }

    async onStartFollowUp(element) {
        const { params } = this.state
        element.Type = 'FollowUpStarted'
        await db_chat(params?.BookingID).child(element.key).update(element)
    }

    // Start Addon
    onShowAddonRequestPress(element) {
        this.setState({ showAddonModal: true, Addon: element.Data, AddonSelectedElement: element })
    }

    onRejectAddonService() {
        const { Addon, ConsumerID, ManPowerID, BookingID, AddonSelectedElement } = this.state
        const obj = {
            BookingID,
            accepted: false,
            ...Addon,
        }
        if (!this.state.reject_loading) {
            this.setState({ reject_loading: true })
            postAcceptAddonRequest(obj).then(async res => {
                const key = AddonSelectedElement?.key
                var item = AddonSelectedElement
                item.key = null
                item.date = null
                item.Type = 'AddonRejected'
                await db_chat(BookingID).child(key).update(item)
                await this.SendPatientFireMessage(null, HomeVisitAddonRequestRejectMessage(Addon.GeneralServiceAddonName), null, null)
                // this.getPatientOrderByID(ConsumerID, ManPowerID, BookingID)
                if (IS_MOUNTED) {
                    this.setState({ reject_loading: false, showAddonModal: false })
                }
            }).catch(e => this.setState({ reject_loading: false }))
        }
    }
    onAcceptAddonService() {
        const { BookingID, ConsumerID, ManPowerID, Addon, AddonSelectedElement, data } = this.state
        const obj = {
            PaymentType: 'AddonRequest', ConsumerID, ManPowerID, BookingID, TotalPrice: Addon.AddonPrice,
            Addon, data, AddonSelectedElement
        }
        this.setState({ showAddonModal: false }, () => {
            this.props.history.push('/chat-payment', obj)
        })
    }
    // End Addon

    onConfirmOrder(val) {
        const { BookingID } = this.state
        this.setState({ modalLoading: true })
        const obj = { BookingID, IsPatientConfirmed: val }
        postBookingOrder(obj).then(res => {
            this.SendPatientFireMessage(null, val ? ORDER_CONFIRMATION_MSG : ORDER_NO_CONFIRMATION_MSG, null, 'PatientConfirmation')
            this.RemoveFireMessageType(['WaitingPatientConfirmation']).then(() => {
                this.setState({ modalLoading: false, showConfirmOrderModal: false })
            })
        }).catch(e => { this.setState({ modalLoading: false, showConfirmOrderModal: false }) })
    }

    onImageFileClick(id) {
        document.getElementById(id).click();
    }

    async onImageFileUpload(file, type) {
        const { BookingID, ConsumerID } = this.state
        var base64Data = (await toBase64(file)).split(',')[1]
        const obj = {
            FileName: new Date().getTime() + '_' + file.name, Data: base64Data, FileTypeID: 3,
            BookingID, ConsumerID
        }
        this.setState({ bottomViewLoading: true })
        postPatientUploadFileAndGetUrl(obj).then(res => {
            if (type === "image") {
                this.SendPatientFireMessage(null, null, { uri: res.data.Message }, 'Image')
            } else {
                if (file.name.endsWith('.pdf')) {
                    this.SendPatientFireMessage(null, null, { uri: res.data.Message }, 'File')
                } else {
                    this.SendPatientFireMessage(null, null, { uri: res.data.Message }, 'Image')
                }
            }
            if (IS_MOUNTED) {
                this.setState({ bottomViewLoading: false })
            }
        }).catch(e => { this.setState({ bottomViewLoading: false }) })
    }


    SendPatientFireMessage(Text, AutoText, Data, Type) {
        const { ConsumerID, ManPowerID, BookingID } = this.state
        db_chat(BookingID).push({
            SenderID: ConsumerID,
            ConsumerID,
            ManPowerID,
            BookingID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        });
    }

    SendDoctorFireMessage(Text, AutoText, Data, Type) {
        const { ConsumerID, ManPowerID, BookingID } = this.state
        db_chat(BookingID).push({
            SenderID: ManPowerID,
            ConsumerID,
            ManPowerID,
            BookingID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        });
    }

    async RemoveFireMessageType(Types) {
        const { BookingID } = this.state
        const data = this.state.data.slice()
        Types.forEach(async case_el => {
            await data.forEach(async element => {
                var items = element.data.filter(el => el.Type === case_el)
                items.forEach(async item => {
                    if (item) {
                        var key = item.key
                        item.date = null
                        item.key = null
                        item.Type = null
                        await db_chat(BookingID).child(key).update(item)
                    }
                });
            });
        });
    }

    componentWillUnmount() {
        IS_MOUNTED = false
        const { params } = this.state
        clearTimeout(this.timeout)
        db_settings(params?.BookingID).child('typing').update({ patient_typing: false })
        db(params?.BookingID).off()
        db_chat(params?.BookingID).off()
        db_data(params?.BookingID).off()
        db_location(params?.BookingID).off()
        db_settings(params?.BookingID).off()
    }

    componentDidMount() {
        IS_MOUNTED = true
        const { id } = this.props.match.params
        this.getPatientOrderByID(id, () => {
            const { params } = this.state
            this.setState({ Questions: params?.Questions })
            this.setState({ TotalPrice: params?.TotalPrice || 0 })
            this.setState({
                SpecializationName: I18nManager.isRTL() ? (params?.GeneralServiceCategoryAr || params?.SpecializationAr)
                    : (params?.GeneralServiceCategory || params?.Specialization),
                ConsumerID: params?.ConsumerID, ManPowerID: params?.ManPowerID, BookingID: params?.BookingID,
                ConsumerImage: params?.ConsumerImage, ManPowerImage: params?.ManPowerImage
            })

            navigator.geolocation.getCurrentPosition((position) => {
                db_location(params?.BookingID).update({
                    patient: { latitude: position.coords.latitude, longitude: position.coords.longitude }
                })
            }, (error) => { });

            db(params?.BookingID).on('value', async (snap) => {
                var Flocation = await snap?.val()?.LOCATION
                if (Flocation) {
                    if (IS_MOUNTED) {
                        this.setState({ FireLocation: Flocation })
                    }
                }
                var Fchat = await snap?.val()?.CHAT
                if (Fchat) {
                    var objectKeys = await Object.keys(Fchat);
                    var data = []
                    objectKeys.forEach(async (element) => {
                        await data.push({ key: element, ...Fchat[element] })
                    });
                    data = await data.sort((a, b) => a.CreatedDate - b.CreatedDate)
                    await this.saveChatHistory(params?.BookingID, data)
                    this.setupData(data).then((async (res) => {
                        await this.setState({ data: res })
                        var Fdata = await snap?.val()?.DATA
                        if (Fdata?.manPowerSlots) {
                            if (IS_MOUNTED) {
                                this.setState({ TimeSlots: Fdata.manPowerSlots })
                            }
                        } else if (!checkFireDataByType(res, 'WaitingPatientPayment')) {
                            const obj = { ManPowerID: params?.ManPowerID, From: moment().format('L'), To: moment().format('L') }
                            postGetDoctorAvailabilityForPatient(obj).then(res => {
                                var data = res.data.data.days[0]
                                if (IS_MOUNTED) {
                                    data = data.dayTimeStatus.filter(el => el.slots.length > 0)
                                    data.forEach(day => {
                                        day.slots.forEach(slot => {
                                            var hourMinuteTime = moment(new Date(2020, 5, 22, slot?.From.split(':')[0], slot?.From.split(':')[1], 0, 0));
                                            var hours = moment(new Date(2020, 5, 22, slot?.From.split(':')[0], slot?.From.split(':')[1], 0, 0)).get('h')
                                            slot.From = {
                                                ar: (hourMinuteTime.format("hh:mm") + ' ') + (hours >= 12 ? 'م' : 'ص'),
                                                en: hourMinuteTime.format("hh:mm A")
                                            }
                                        })
                                    })
                                    db_data(params?.BookingID).update({
                                        manPowerSlots: data
                                    })
                                }
                            }).catch(e => { })
                        }
                        var Fsettings = await snap?.val()?.SETTINGS
                        if (Fsettings) {
                            if (IS_MOUNTED) {
                                this.setState({ ChatSettings: Fsettings })
                            }
                        }
                        // if (!checkFireDataByType(res, 'OrderCanceled')) {
                        //     if (!checkFireDataByType(res, 'DetailsCompleted')) {
                        //         if (IS_MOUNTED) {
                        //             // this.setState({ showPatientDetailsView: true })
                        //         }
                        //     }

                        //     // go back when doctor submit follow up
                        //     if (checkFireDataByType(res, 'FollowUpCompleted') &&
                        //         (this.state.OrderDetails.GeneralStatusID === 1 || this.state.OrderDetails.GeneralStatusID === 3)) {
                        //         window.history.back()
                        //         this.props.navigation.navigate('Bookings')
                        //     }

                        //     // Top Right Header
                        //     if (checkFireDataByType(res, 'Welcome') || checkFireDataByType(res, 'PatientNegotiate') || checkFireDataByType(res, 'DoctorNegotiate')) {
                        //         Store.OrderStatus = I18nManager.isRTL() ? 'جديد' : 'New'
                        //     } else if (!checkFireDataByType(res, 'FollowUpCompleted')) {
                        //         Store.OrderStatus = I18nManager.isRTL() ? 'تقدم' : 'In-Progress'
                        //     } else if (!checkFireDataByType(res, 'OrderCompleted')) {
                        //         Store.OrderStatus = I18nManager.isRTL() ? 'متابعة' : 'Follow Up'
                        //     } else {
                        //         Store.OrderStatus = ''
                        //     }
                        // } else {
                        //     Store.OrderStatus = ''
                        // }
                    }))
                }
            })
        })
    }

    render() {
        const { ConsumerID, ManPowerID, BookingID, ConsumerImage, ManPowerImage, data, OrderDetails, ActiveDateIndex,
            TimeSlots, ActiveTimeSlotIndex, showTimeSlot, Addon,
            TextMessage,
            FireLocation, showReopenModal, doctorNegotiate_loading, ChatSettings,
            showImagePreviewModal, imagePreviewURI,
            Questions, showPatientDetailsView, patientDetails_loading,
            showPaymentModal, payment_loading, showTrackingModal,
            showCancelOrderModal, modalLoading, cancelOrderMessage,
            showConfirmOrderModal,
            showAddonModal, accept_loading, reject_loading,
            showInvoiceModal, invoiceData, bottomViewLoading,
            TotalPrice, SpecializationName,
            currentTimeText, currentTime,
        } = this.state
        return (
            <ViewContainer>
                <div className="container patient-plus-bg d-flex align-items-center justify-content-center">
                    <div className="align-self-center main-container">
                        <CancelButton />

                        <ModalSubmit isOpen={showConfirmOrderModal} text={I18nManager.isRTL() ? 'هل تم تأكيد الطلب؟' : 'Is the order confirmed?'} uri="/assets/img/question.png"
                            leftButtonText={I18nManager.isRTL() ? 'نعم' : 'YES'} rightButtonText={I18nManager.isRTL() ? 'كلا' : 'NO'} loading={modalLoading} onRequestClose={() => this.changeState('showConfirmOrderModal', false)}
                            onLeftButtonClick={() => this.onConfirmOrder(true)} onRightButtonClick={() => this.onConfirmOrder(false)} />

                        <ModalInvoice isOpen={showInvoiceModal} Data={invoiceData} onRequestClose={() => this.changeState('showInvoiceModal', false)} />

                        <ModalTracking isOpen={showTrackingModal} FireLocation={FireLocation} onRequestClose={() => this.changeState('showTrackingModal', false)} />

                        <ModalAddon isOpen={showAddonModal} onRequestClose={() => this.changeState('showAddonModal', false)}
                            onCancelPress={() => this.changeState('showAddonModal', false)}
                            accept_loading={accept_loading} reject_loading={reject_loading} item={Addon}
                            onAcceptPress={() => this.onAcceptAddonService()} onRejectPress={() => this.onRejectAddonService()} />

                        <section className="msger">
                            {data?.map((item, index) => (
                                <div key={index}>
                                    <div className="chat-group-date-container">
                                        <span className="chat-group-date-text">{item?.date}</span>
                                    </div>
                                    {item?.data?.map((element, el_index) => (
                                        <OnsiteChatMessage key={el_index} data={data} element={element} FireLocation={FireLocation}
                                            ConsumerImage={ConsumerImage} ManPowerImage={ManPowerImage} OrderDetails={OrderDetails}
                                            onStartFollowUp={(element) => this.onStartFollowUp(element)}
                                            onShowInvoiceModal={(invoiceData) => { this.changeState('showInvoiceModal', true); this.changeState('invoiceData', invoiceData) }}
                                            onShowTackingModal={() => this.changeState('showTrackingModal', true)}
                                            onShowAddonRequestPress={(element) => this.onShowAddonRequestPress(element)}
                                            onConfirmOrder={() => this.changeState('showConfirmOrderModal', true)}
                                        />
                                    ))}
                                </div>
                            ))}

                            {isOrderOpen(data, OrderDetails) && !checkFireDataByType(data, 'FollowUpCompleted') && !checkFireDataByType(data, 'PatientWaitingApprove') &&
                                <div className="msger-inputarea">
                                    <div className="nav-item dropdown dropup align-self-center" style={{ flex: 0 }}>
                                        <i className="fa fa-ellipsis-v" data-toggle="dropdown"></i>
                                        <div className="dropdown-menu">
                                            <input type="file" multiple={false} accept="application/pdf"
                                                id="fileupload" className="d-none"
                                                onChange={e => this.onImageFileUpload(e.target.files[0], "file")} />
                                            <span className="dropdown-item" onClick={() => this.onImageFileClick('fileupload')}>{I18nManager.isRTL() ? "الملف" : 'File'}</span>
                                            <input type="file" multiple={false} accept="image/jpeg, image/png, image/jpg"
                                                id="imageupload" className="d-none"
                                                onChange={e => this.onImageFileUpload(e.target.files[0], "image")} />
                                            <span className="dropdown-item" onClick={() => this.onImageFileClick('imageupload')}>{I18nManager.isRTL() ? "الصورة" : 'Image'}</span>
                                        </div>
                                    </div>
                                    <textarea type="text" onChange={(e) => this.onChangeText(e.target.value)} value={TextMessage} className="msger-input" placeholder={I18nManager.isRTL() ? 'اكتب رسالة' : 'Type a message'} />
                                    <button onClick={() => this.onSubmitSendText()} className="msger-send-btn">{I18nManager.isRTL() ? "إرسال" : "Send"}</button>
                                </div>
                            }
                        </section>
                    </div>
                </div>
            </ViewContainer>
        )
    }
}
