import React from 'react'
import I18nManager from '../../../../core/I18nManager/I18nManager';
import './FilterCheckBox.css';

const FilterCheckBox = ({ item, onItemClick, title }) => {
    return (
        <div className='d-flex align-items-center filter-checkbox mt-10 mb-10' onClick={onItemClick}>
            <div className={(I18nManager.isRTL() ? "ml-5" : "mr-5") + " check-square " + (item?.active ? 'check-square-active' : '')}>
                {item?.active && <i className="fa fa-check"></i>}
            </div>
            <span>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"] ?? item?.[I18nManager.isRTL() ? "NameAr" : "Name"]}</span>
        </div>
    )
}

export default FilterCheckBox