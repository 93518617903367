import React, { useEffect, useState, } from 'react'
import { postGetPatientOrders } from '../../core/api/Api'
import moment from 'moment';
import './MyBookings.css';
import CancelButton from '../_common/cancel-button/CancelButton'
import { useTable, useSortBy, usePagination } from 'react-table'
import I18nManager from '../../core/I18nManager/I18nManager';
import ViewContainer from '../../core/routes/view-container/ViewContainer';

var IS_MOUNTED = true

export default function MyBookings(props) {

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    const [from_date, setFrom_date] = useState(moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'))
    const [to_date, setTo_date] = useState(moment(new Date()).format('YYYY-MM-DD'))

    const [navs, setNavs] = useState([
        { name: I18nManager.isRTL() ? 'معلق' : 'Pending', status: 3, active: true },
        { name: I18nManager.isRTL() ? 'جارية' : 'Ongoing', status: 1, active: false },
        { name: I18nManager.isRTL() ? 'منتهى' : 'Completed', status: 2, active: false },
    ])

    function onNavClick(index) {
        if (!loading) {
            setData([])
            const data = [...navs]
            data.forEach((element, i) => {
                element.active = parseInt(index) === i
            });
            setNavs(data)
            const obj = { status: navs[index].status, From: moment(from_date).format('L'), To: moment(to_date).format('L') }
            getPatientOrders(obj)
        }
    }

    function getPatientOrders(obj) {
        setLoading(true)
        postGetPatientOrders(obj).then(res => {
            if (IS_MOUNTED) {
                setLoading(false)
                setData(res.data.data)
            }
        }).catch(e => { setLoading(false) })
    }

    function onChangeDate(e, type) {
        var obj = {}
        if (type === 'from') {
            obj = { status: navs.find(el => el.active === true).status, From: moment(e.target.value).format('L'), To: moment(to_date).format('L') }
            setFrom_date(e.target.value)
        } else {
            obj = { status: navs.find(el => el.active === true).status, From: moment(from_date).format('L'), To: moment(e.target.value).format('L') }
            setTo_date(e.target.value)
        }
        getPatientOrders(obj)
    }

    function onItemClick(item) {
        if (item.order.GeneralStatusID === 6 || item.order.GeneralStatusID === 7) {
            props.history.push('/assistant-chat/' + item?.order?.BookingID, item.order)
        } else {
            props.history.push(item.order.IsRemoteBooking ? ("/online-chat/" + item?.order?.BookingID) : ("/onsite-chat/" + item?.order?.BookingID), item.order)
        }
    }

    useEffect(() => {
        IS_MOUNTED = true
        const obj = { status: navs.find(el => el.active === true).status, From: moment(from_date).format('L'), To: moment(to_date).format('L') }
        getPatientOrders(obj)
        return () => {
            IS_MOUNTED = false
        }
    }, [from_date, to_date, navs])

    const columns = React.useMemo(
        () => [
            {
                Header: 'Booking ID#',
                accessor: 'order.BookingID',
            },
            {
                Header: I18nManager.isRTL() ? 'الحالة' : 'Status',
                accessor: I18nManager.isRTL() ? 'order.GeneralStatusNameAr' : 'order.GeneralStatusName',
            },
            {
                Header: I18nManager.isRTL() ? "اسم الطبيب" : 'Doctor',
                accessor: I18nManager.isRTL() ? 'order.ManPowerFirstNameAr' : 'order.ManPowerFirstName',
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data,
        initialState: { pageIndex: 0 },
    }, useSortBy, usePagination)

    return (
        <ViewContainer>
            <div className="container patient-plus-bg d-flex align-items-center justify-content-center">
                <div className="align-self-center main-container">
                    <CancelButton />
                    <h3 className="header pt-20 pb-20">{I18nManager.isRTL() ? 'حجوزاتي' : 'My Bookings'}</h3>
                    <ul className="nav nav-tabs mb-20 align-items-center justify-content-center nav-ul">
                        {navs.map((item, index) => (
                            <li key={index} onClick={() => onNavClick(index)} className="nav-item">
                                <span className={item.active === true ? "nav-link active" : "nav-link"}>{item.name}</span>
                            </li>
                        ))}
                    </ul>
                    <div className="date-from-to-picker-container">
                        <input className="form-control" onChange={e => onChangeDate(e, 'from')} type="date" value={moment(from_date).format('YYYY-MM-DD')} />
                        <input className="form-control" onChange={e => onChangeDate(e, 'to')} type="date" value={moment(to_date).format('YYYY-MM-DD')} />
                    </div>
                    <table {...getTableProps()} className="table dt-responsive nowrap">
                        <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span>{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span></th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <td onClick={() => onItemClick(row.original)}  {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="pagination d-flex align-items-center justify-content-between" style={{ padding: '0.5rem' }}>
                        <div className="d-flex flex-row">
                            <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                {'<<'}
                            </button>{' '}
                            <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                {'<'}
                            </button>{' '}
                            <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                                {'>'}
                            </button>{' '}
                            <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                {'>>'}
                            </button>{' '}
                        </div>
                        <span>{I18nManager.isRTL() ? "الصفحة" : "Page"}{' '}<strong>{pageIndex + 1} {I18nManager.isRTL() ? "من" : "of"} {pageOptions.length}</strong>{' '}</span>
                        <select className="form-control" style={{ maxWidth: 80 }} value={pageSize} onChange={e => { setPageSize(Number(e.target.value)) }}>
                            {[10, 20, 30, 40, 50].map(pageSize => (
                                <option key={pageSize} value={pageSize}>{pageSize}</option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </ViewContainer>
    )
}