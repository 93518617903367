import I18nManager from "../I18nManager/I18nManager";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function checkFireDataByType(data, Type) {
    return data?.find(el => el?.data?.find(item => item.Type === Type))
}

export function isOrderOpen(data, OrderDetails) {
    if ((OrderDetails?.GeneralStatusID !== 2 && OrderDetails?.GeneralStatusID !== 5 &&
        !checkFireDataByType(data, 'OrderCanceled') && !checkFireDataByType(data, 'OrderCompleted'))) {
        return true
    } else {
        return false
    }
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export function stopAllAudio() {
    document.addEventListener('play', function (e) {
        var allAudios = document.getElementsByTagName('audio');
        for (var i = 0; i < allAudios.length; i++) {
            if (allAudios[i] != e.target) {
                allAudios[i].pause();
            }
        }
    }, true);
    return
    var sounds = document.getElementsByTagName('audio');
    for (var i = 0; i < sounds.length; i++) sounds[i].pause();
}

export function getPublicURL() {
    return process.env.PUBLIC_URL
    return window.location.origin
}

export function getUrlByType(type) {
    let dir = getCookie('dir')
    if (dir !== 'ltr' || dir !== 'rtl') {
        dir = 'rtl'
    }
    switch (type) {
        case 'patient':
            return 'https://patient.docnow247.com/?lang=' + dir
        case 'doctor':
            return 'https://doctor.docnow247.com/?lang=' + dir
        case 'admin':
            return 'https://admin.docnow247.com/'
        default:
            break;
    }
}

export function getStoreUrl(type, platform) {
    const PATIENT_URL = platform === 'ios' ? 'https://apps.apple.com/app/doc-now/id1508474750' : 'https://play.google.com/store/apps/details?id=com.docnow.patient'
    const DOCTOR_URL = platform === 'ios' ? 'https://apps.apple.com/app/doc-now-pro/id1508481234' : 'https://play.google.com/store/apps/details?id=com.docnow.doctor'
    switch (type) {
        case 'patient':
            return PATIENT_URL
        case 'doctor':
            return DOCTOR_URL
        default:
            break;
    }
}

export function setCookie(cname, cvalue) {
    const exdays = 30;
    var secure = '';
    if (window.location.protocol === 'https') {
        secure = "secure;";
    }
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + secure + expires + "; path=/";
}

export function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

export function deleteCookie(cname) {
    document.cookie = cname + "= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/"
}

export function deleteAllCookies() {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
}

export function setLocalStorageItem(cname, cvalue) {
    localStorage.setItem(cname, cvalue)
}

export function getLocalStorageItem(cname) {
    return localStorage.getItem(cname)
}

export function deleteLocalStorageItem(cname) {
    localStorage.removeItem(cname)
}

export function goToWhatsapp() {
    return 'https://api.whatsapp.com/send/?phone=966550600288&text&app_absent=0'
}

export function flipCardAction(id) {
    const element = document.getElementById(id)
    const styleApplied = element.classList.contains("flip-card-hover")
    element.classList[styleApplied ? 'remove' : 'add']("flip-card-hover")
}

export function getDoctorSpecialitiesString(data) {
    var arr = ''
    if (data?.length > 0) {
        data.forEach((element, index) => {
            arr += element?.[I18nManager.isRTL() ? 'SpecializationNameAr' : 'SpecializationName'] +
                (index < data?.length - 1 ? ', ' : '')
        });
    }
    return arr
}

export function getPhoneNumberDetails(phone) {
    const phoneNumber = parsePhoneNumberFromString(phone)
    return {
        isValid: phoneNumber?.isValid(),
        country: phoneNumber?.country,
        countryCode: phoneNumber?.countryCallingCode,
        format: phoneNumber?.formatInternational()
    }
}

export function removeWhiteSpaceFromString(text) {
    return text?.replace(/\s/g, '')
}

export const scrollIntoView = (id) => (e) => {
    e.preventDefault()
    window.location.href = getPublicURL() + '/#' + id
}