import React, { useEffect, useState } from 'react'
import { BASE_URL, postGetCampaignCoupons } from '../../core/api/Api'
import I18nManager from '../../core/I18nManager/I18nManager'
import AppDownload from '../../core/routes/app-download/AppDownload'
import ViewContainer from '../../core/routes/view-container/ViewContainer'
import TopFilter from '../_common/filter/top-filter/TopFilter'
import TopViewBg from '../_common/top-view-bg/TopViewBg'
import './AllOffers.css'
import OfferItem from './offer-item/OfferItem'

var IS_MOUNTED = true

const AllOffers = () => {

    const [offers, setOffers] = useState([])

    const getOffers = () => {
        postGetCampaignCoupons(1).then(res => {
            if (IS_MOUNTED) {
                const data = res.data.data
                setOffers(data)
            }
        }).catch(e => getOffers())
    }

    useEffect(() => {
        IS_MOUNTED = true
        getOffers()
        return () => {
            IS_MOUNTED = false
        }
    }, [])


    return (
        <ViewContainer>
            <TopViewBg title={I18nManager.isRTL() ? "افضل العروض في السعودية" : "Best Offers in Saudi"} />
            <TopFilter />
            <div className="all-offers-bg mb-40">
                <div className="container">
                    <div className="all-offers-header-title">
                        <h1>{I18nManager.isRTL() ? "أحدث العروض" : "Best offers"}</h1>
                        <div className="row justify-content-center">
                            <div className="col-md-6 col-12 d-flex align-items-center">
                                <span>
                                    {I18nManager.isRTL() ? "اكتشف عروضنا المميزة في عالم الجمال والصحة والعناية بالأسنان والخيارات الصحية الأخرى." : "Discover our special offers in the world of beauty, health, dental care and other healthy options."}
                                    {I18nManager.isRTL() ? "نستمر بإضافة المزيد من العروض، استمر بمتابعتنا للإطلاع على عروضنا الدائمة." : "We keep adding more offers, keep following us for our permanent offers."}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {offers?.map((item, index) => (
                            <div key={index} className="col-md-4 col-6 d-flex flex-column mb-10">
                                <OfferItem item={item} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <AppDownload />
        </ViewContainer>
    )
}
export default AllOffers
