import firebase from '../firebase'
import { addon_prefix } from '../../api/Api';

const MAIN_PATH = addon_prefix === 'admin' ? 'BOOKINGS_CHAT' : 'BOOKINGS_CHAT_TEST'
const RECEPTION_MAIN_PATH = addon_prefix === 'admin' ? 'RECEPTIONS_CHAT' : 'RECEPTIONS_CHAT_TEST'

export function reception_db(BookingID) {
    return firebase.database().ref(RECEPTION_MAIN_PATH + '/BOOKING_' + BookingID)
}
export function reception_db_chat(BookingID) {
    return firebase.database().ref(RECEPTION_MAIN_PATH + '/BOOKING_' + BookingID + '/CHAT')
}
export function reception_db_data(BookingID) {
    return firebase.database().ref(RECEPTION_MAIN_PATH + '/BOOKING_' + BookingID + '/DATA')
}


export function db(BookingID) {
    return firebase.database().ref(MAIN_PATH + '/BOOKING_' + BookingID)
}

export function db_chat(BookingID) {
    return firebase.database().ref(MAIN_PATH + '/BOOKING_' + BookingID + '/CHAT')
}

export function db_location(BookingID) {
    return firebase.database().ref(MAIN_PATH + '/BOOKING_' + BookingID + '/LOCATION')
}

export function db_data(BookingID) {
    return firebase.database().ref(MAIN_PATH + '/BOOKING_' + BookingID + '/DATA')
}

export function db_chat_timer(BookingID) {
    return firebase.database().ref(MAIN_PATH + '/BOOKING_' + BookingID + '/CHAT_TIMER')
}

export function db_settings(BookingID) {
    return firebase.database().ref(MAIN_PATH + '/BOOKING_' + BookingID + '/SETTINGS')
}

