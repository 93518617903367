import React, { useState } from 'react'
import './DoctorItemTabs.css'
import I18nManager from '../../../../core/I18nManager/I18nManager'
import StarsRating from '../../../_common/stars-rating/StarsRating'
import PatientReviewItem from '../doctor-item/patient-review-item/PatientReviewItem'

const DoctorItemTabs = ({ doctorItem }) => {
    const [doctorItemTabs, setDoctorItemTabs] = useState([
        { title: I18nManager.isRTL() ? "معلومات الطبيب" : "Position & titles", active: true },
        // { title: I18nManager.isRTL() ? "الخبرات" : "Experience", active: false },
        { title: I18nManager.isRTL() ? "التقييمات" : "Ratings", active: false },
    ])

    const changeActiveDoctorTabs = (index) => () => {
        let tmp_doctorItemTabs = [...doctorItemTabs]
        tmp_doctorItemTabs.forEach((element, i) => {
            element.active = parseInt(index) === i
        });
        setDoctorItemTabs(tmp_doctorItemTabs)
    }

    return (
        <div>
            <ul className="nav nav-tabs doctor-item-tabs">
                {doctorItemTabs.map((item, index) => (
                    <li key={index} className={"nav-item " + (item.active ? 'doctor-item-tabs-active' : '')} onClick={changeActiveDoctorTabs(index)}>
                        <a><span>{item.title}</span></a>
                    </li>
                ))}
            </ul>
            <div className="doctor-item-tabs-content">
                {doctorItemTabs[0].active ?
                    <div className="tab">
                        <span>{doctorItem?.Profile?.Bio}</span>
                    </div>
                    :
                    // doctorItemTabs[1].active ? <div className="tab">2</div> :
                    <PatientReviewItem />
                }
            </div>
        </div>
    )
}

export default DoctorItemTabs
