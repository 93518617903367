import React from 'react'
import { getUrlByType, scrollIntoView } from '../../common-service/CommonService'
import { constants } from '../../constants/constants'
import I18nManager from '../../I18nManager/I18nManager'
import './Footer.css'
import { useHistory } from 'react-router-dom'

export default function Footer() {
    let history = useHistory()

    const goToPage = (path, item = {}) => (e) => {
        e.preventDefault()
        history.push(path, item)
    }

    return (
        <div className="yellow-bg pt-40">
            <div className="container">
                <img loading="lazy" src="/assets/img/logo-transparent.svg" width="56" height="56" alt="docnow" />
                <div className="row pt-10 pb-10">
                    <div className="col-md-5 col-12 text-justify">
                        <span className="footer-description">{constants.footer?.[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"]}</span>
                    </div>
                    <div className="col-md-2 col-6 footer-section">
                        <div className="d-flex flex-column">
                            <a onClick={scrollIntoView("home")} rel="noopener noreferrer" className="footer-text">{I18nManager.isRTL() ? "الرئيسية" : "Home"}</a>
                            <a onClick={goToPage("/about-us")} target="_blank" rel="noopener noreferrer" className="footer-text">{I18nManager.isRTL() ? "من نحن" : "About us"}</a>
                            <a onClick={goToPage("/all-offers")} className="footer-text">{I18nManager.isRTL() ? "العروض" : "Offers"}</a>
                            <a onClick={goToPage("#")} className="footer-text">{I18nManager.isRTL() ? "إتصل بنا" : "Contact us"}</a>
                        </div>
                    </div>
                    <div className="col-md-2 col-6 footer-section">
                        <div className="d-flex flex-column">
                            <a onClick={goToPage("#")} rel="noopener noreferrer" className="footer-text">{I18nManager.isRTL() ? "مقالات" : "Blog"}</a>
                            <a onClick={scrollIntoView("featured-clinics")} target="_blank" rel="noopener noreferrer" className="footer-text">{I18nManager.isRTL() ? "العيادات المميزة" : "Featured Clinics"}</a>
                            <a onClick={goToPage("/clinics-list", { IsRemoteSession: true, IsOnSite: false, IsOnClinic: true, IsSecondOpinion: false, IsEmergency: false, IsAssistante: false })} className="footer-text">{I18nManager.isRTL() ? "شبكتنا" : "Network"}</a>
                            <a href={getUrlByType('doctor')} className="footer-text">{I18nManager.isRTL() ? "تسجيل الدخول للطبيب" : "Login for Doctor"}</a>
                        </div>
                    </div>
                    <div className="col-md-3 col-12 footer-section">
                        <div className="d-flex flex-column">
                            <a className="footer-text">{I18nManager.isRTL() ? "إتصل بنا" : "Contact us"}</a>
                            <div className="d-flex align-items-center">
                                <img className="mb-15" loading="lazy" src="/assets/img/phone.svg" width="18" height="18" alt="" />
                                <a href="tel:+966550600288" className="footer-text ml-5 mr-5">{I18nManager.isRTL() ? '966550600288+' : '+966550600288'}</a>
                            </div>
                            <div className="d-flex align-items-center">
                                <img className="mb-15" loading="lazy" src="/assets/img/email.svg" width="18" height="18" alt="" />
                                <a href="mailto:info@docnow247.com" className="footer-text ml-5 mr-5">info@docnow247.com</a>
                            </div>
                            <div className="d-flex align-items-center">
                                <img className="mb-15" loading="lazy" src="/assets/img/location.svg" width="18" height="18" alt="" />
                                <a className="footer-text ml-5 mr-5">{I18nManager.isRTL() ? "المملكة العربية السعودية - الرياض" : "Saudi Arabia - Riyadh"}</a>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-2 col-12 d-flex justify-content-center">
                        <iframe title="Maroof" src="https://maroof.sa/Business/GetStamp?bid=172705" style={{ float: 'left', height: 250 }} frameBorder="0" seamless='seamless' scrollable="no"></iframe>
                    </div> */}
                </div>
                <span className="footer-follow-text">{I18nManager.isRTL() ? "تابعنا !" : "Follow Us !"}</span>
                <div className="d-flex flex-wrap pt-5 pb-15">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/docnow247">
                        <img loading="lazy" src="/assets/img/facebook.svg" alt="" />
                    </a>
                    <a className="ml-10 mr-10" target="_blank" rel="noopener noreferrer" href="https://twitter.com/DocNow247">
                        <img loading="lazy" src="/assets/img/twitter.svg" alt="" />
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/docnow247/">
                        <img loading="lazy" src="/assets/img/linkedin.svg" alt="" />
                    </a>
                    <a className={I18nManager.isRTL() ? "mr-10" : "ml-10"} target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/docnow247/">
                        <img loading="lazy" src="/assets/img/instagram.svg" alt="" />
                    </a>
                </div>
            </div>
            <div className="blue-bg">
                <div className="container below-footer-container">
                    <div style={{ flex: 1, display: 'flex', justifyContent: I18nManager.isRTL() ? 'flex-end' : 'flex-start' }}>
                        <a onClick={goToPage("/privacy-policy")} className="below-footer-text">{I18nManager.isRTL() ? "سياسة الخصوصية" : "Privacy policy"}</a>
                        <a onClick={goToPage("/terms-conditions")} className={"below-footer-text " + (I18nManager.isRTL() ? "mr-15" : "ml-15")}>{I18nManager.isRTL() ? "شروط الإستخدام" : "Terms and conditions"}</a>
                    </div>
                    <div style={{ flex: 1, display: 'flex', justifyContent: I18nManager.isRTL() ? 'flex-end' : 'flex-start' }}>
                        <span className="below-footer-text">{I18nManager.isRTL() ? "2021 @دك ناو247 جميع الحقوق محفوظة." : "2021 @DocNow247 All Rights Reserved."}</span>
                    </div>
                </div>
            </div>
        </div >
    )
}