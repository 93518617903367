import React from 'react'
import Select from 'react-select'
import I18nManager from '../../../core/I18nManager/I18nManager';
import './ReactSelect.css';

const ReactSelect = ({
    placeholder, options, getOptionValue, getOptionLabel, isSearchable,
    containerStyle, controlStyle, singleValueStyle, placeholderStyle,
    onChange,
}) => {
    return (
        <Select className="react-select" classNamePrefix="react-select"
            isSearchable={isSearchable} isClearable={true} isRrl={I18nManager.isRTL()}
            styles={{
                container: (styles) => {
                    return { ...styles, ...containerStyle }
                },
                control: (styles) => {
                    return { ...styles, ...controlStyle }
                },
                option: (styles, { data }) => {
                    return {
                        ...styles,
                        backgroundColor: data.active === true ? '#0f1c49' : '#ffffff',
                        color: data.active === true ? '#ffffff' : '#0f1c49'
                    };
                },
                singleValue: (styles) => {
                    return { ...styles, ...singleValueStyle };
                },
                placeholder: (styles) => {
                    return { ...styles, ...placeholderStyle };
                }
            }}
            placeholder={placeholder ?? (I18nManager.isRTL() ? "إختر..." : "Select...")} noOptionsMessage={() => I18nManager.isRTL() ? "غير موجود..." : "Not Found..."}
            options={options ?? []} getOptionValue={getOptionValue} getOptionLabel={getOptionLabel}
            onChange={onChange}
        />
    )
}

export default ReactSelect
