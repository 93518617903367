export const colors = {
    mainYellow: '#F5C720',
    darkMainYellow: '#e6ba1c',
    lightYellow: '#FCEFBF',
    mediumYellow: '#ffd300',
    grey: '#606060',
    offWhite: '#F5F6FA',
    lightGrey: '#E0E0E0',
    superLightGrey: 'rgb(245,245,245)',
    blue: '#1786fb',
    lightBlue: '#B2F3FF',
    mediumDarkBlue: '#3a5783',
    green: '#1e9039',
    successGreen: 'rgb(0,199,71)',
    lightGreen: '#8ebc31',
    red: '#f44336',
    lightRed: '#fc905d',

}