import React, { useState, useEffect } from 'react'
import { BASE_URL, getMapStaticImage, postCreatePrescription } from '../../../../core/api/Api';
import moment from 'moment';
import InvoiceView from '../../chat-payment/modal-invoice/invoice-view/InvoiceView';
import { isOrderOpen, stopAllAudio } from '../../../../core/common-service/CommonService';
import I18nManager from '../../../../core/I18nManager/I18nManager';
import { colors } from '../../../../core/colors/Colors';

var IS_MOUNTED = true

export default function OnlineChatMessage(props) {

    const [downloadLoading, setdownloadLoading] = useState(false)

    function getMessageTime(element) {
        return moment(new Date(element.CreatedDate)).locale(I18nManager.isRTL() ? 'ar' : 'en').format("hh:mm A")
    }

    function onCreatePrescription(element) {
        setdownloadLoading(true)
        postCreatePrescription({ BookingID: element.BookingID }).then(res => {
            if (IS_MOUNTED) {
                const uri = res.data.data
                window.open(BASE_URL + uri, '_blank');
                setdownloadLoading(false)
            }
        }).catch(e => { this.setState({ downloadLoading: false }) })
    }

    useEffect(() => {
        IS_MOUNTED = true
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    const { data, element, ConsumerImage, ManPowerImage, OrderDetails } = props
    return (
        <div className={element?.SenderID === element?.ManPowerID ? "msg right-msg" : "msg left-msg"}>
            <img className="msg-img" src={element?.SenderID === element?.ManPowerID ? (ManPowerImage ? BASE_URL + ManPowerImage : "/assets/img/patient-profile-ph.svg") : (ConsumerImage ? BASE_URL + ConsumerImage : "/assets/img/patient-profile-ph.svg")} al="" />
            <div className="msg-bubble">
                <div className={element?.SenderID === element?.ManPowerID ? "msg-text-left" : "msg-text-right"}
                    style={{ backgroundColor: element.Type === 'VoiceNote' ? 'transparent' : (element?.SenderID === element?.ManPowerID ? 'white' : colors.lightBlue) }}>
                    {(element?.Text || element?.AutoText) && <div className="p-5">
                        {element?.Text && element?.Text}
                        {element?.AutoText && (I18nManager.isRTL() ? element?.AutoText.ar : element?.AutoText.en)}
                    </div>}
                    {element?.Type === 'Location' &&
                        <a href={"https://www.google.com/maps/@" + element.Data?.latitude + "," + element.Data?.longitude + ",12z"} target="_blank" rel="noopener noreferrer">
                            <img src={getMapStaticImage(element.Data?.latitude, element.Data?.longitude, 100, 100)} className="location-img" alt="" />
                        </a>
                    }
                    {element?.Type === 'Image' &&
                        <a href={BASE_URL + element?.Data?.uri} target="_blank" rel="noopener noreferrer">
                            <img src={BASE_URL + element?.Data?.uri} className="location-img" alt="" />
                        </a>
                    }
                    {element?.Type === 'File' &&
                        <a href={BASE_URL + element?.Data?.uri} target="_blank" rel="noopener noreferrer">
                            <img src="/assets/img/pdf-colored.png" className="location-img" alt="" />
                        </a>
                    }
                    {element.Type === 'PrescriptionAdded' && <button disabled={downloadLoading} onClick={() => onCreatePrescription(element)} className="msg-text-yes-button">{I18nManager.isRTL() ? 'عرض وصفة طبية' : 'View Prescription'}</button>}
                    {isOrderOpen(data, OrderDetails) && element.Type === 'FollowUpCompleted' &&
                        <button onClick={props.onStartFollowUp.bind(this, element)} className="msg-text-yes-button">{I18nManager.isRTL() ? 'بدء مرحلة المراجعة' : 'Start Follow Up'}</button>
                    }
                    {element.Type === 'WaitingPatientConfirmation' &&
                        <button onClick={props.onConfirmOrder} className="msg-text-yes-button">{I18nManager.isRTL() ? 'تأكيد الطلب' : 'Confirm Order'}</button>
                    }
                    {element.Type === 'Invoice' &&
                        <div>
                            <div style={{ maxHeight: 150, overflow: 'hidden' }}>
                                <InvoiceView Data={element.Data} showPreview={true} />
                            </div>
                            <button onClick={props.onShowInvoiceModal.bind(this, element.Data)} className="msg-text-yes-button">{I18nManager.isRTL() ? 'عرض الفاتورة' : 'View Invoice'}</button>
                        </div>
                    }
                    {element?.Type === 'WaitingPatientPayment' && <button onClick={props.onPaymentPress} className="msg-text-yes-button">{I18nManager.isRTL() ? 'إصدار الفاتورة' : 'Add Payment'}</button>}
                    {element.Type === 'VoiceNote' &&
                        <audio controls onPlay={() => stopAllAudio()}>
                            <source src={BASE_URL + element?.Data?.uri} />
                        </audio>
                    }
                </div>
                <div className={element?.SenderID === element?.ManPowerID ? "msg-info-time-left" : "msg-info-time-right"}>{getMessageTime(element)}</div>
            </div>
        </div >
    )
}
