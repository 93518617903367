import React, { useEffect } from 'react'
import { getStoreUrl } from '../../../../core/common-service/CommonService';

export default function StoreDownload() {
    useEffect(() => {
        const url_string = window.location.href
        var url = new URL(url_string);
        var type = url.searchParams.get("t")
        var platform = url.searchParams.get("p")
        window.location = getStoreUrl(type, platform)
    })
    return (
        <></>
    )
}
