import React from 'react'
import I18nManager from '../../../core/I18nManager/I18nManager'
import './TopViewBg.css'

const TopViewBg = ({ title }) => {
    return (
        <div className="top-view-bg">
            <h1>{title}</h1>
        </div>
    )
}

export default TopViewBg