import React, { useState, useContext, useEffect, Fragment } from 'react'
import './DoctorInfo.css'
import { addon_prefix, postBookingOrder, postGetDoctorByID, postGetDoctorTimeSlots, postPatientProfile, setDefaultHeader } from '../../../../core/api/Api'
import ViewContainer from '../../../../core/routes/view-container/ViewContainer'
import { useParams } from 'react-router-dom';
import DoctorItem from '../doctor-item/DoctorItem';
import I18nManager from '../../../../core/I18nManager/I18nManager';
import TopViewBg from '../../../_common/top-view-bg/TopViewBg';
import TopFilter from '../../../_common/filter/top-filter/TopFilter';
import firebase from '../../../../core/firebase/firebase';
import { getCookie, removeWhiteSpaceFromString, setCookie } from '../../../../core/common-service/CommonService';
import Auth from '../../../../core/routes/auth/Auth';
import { reception_db_data } from '../../../../core/firebase/fire/Fire';
import StoreContext from '../../../../core/store/StoreContext';
import ModalBookingSuccess from '../../../_common/modal-booking-success/ModalBookingSuccess';
import StarsRating from '../../../_common/stars-rating/StarsRating';
import PatientReviewItem from '../doctor-item/patient-review-item/PatientReviewItem';
import FAQ from '../../../../core/routes/faq/FAQ';

var IS_MOUNTED = true
const DoctorInfo = () => {
    let { id } = useParams()

    const { UserProfileBaseInfo } = useContext(StoreContext)

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [FCM, setFCM] = useState('')
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const getDoctorByID = () => {
        postGetDoctorByID(id).then(res => {
            const data = res.data.data
            if (IS_MOUNTED && data) {
                setData(data)
            }
        }).catch(e => { getDoctorByID() })
    }

    const onIsMoreClick = (doctorIndex) => (dayIndex) => {
        let tmpData = [...data]
        tmpData[doctorIndex].DoctorTimeSlots.dateSlots[dayIndex].isMorePressed = !tmpData[doctorIndex]?.DoctorTimeSlots?.dateSlots[dayIndex].isMorePressed
        setData(tmpData)
    }
    const onTimeSlotClick = (doctorIndex) => (dayIndex, slotIndex) => {
        let tmpData = [...data]
        tmpData[doctorIndex].DoctorTimeSlots.dateSlots.forEach((element, i) => {
            element.timeSlots.forEach((el_timeslot, i_timeslot) => {
                el_timeslot.active = (parseInt(dayIndex) === i && parseInt(i_timeslot) === slotIndex)
            });
        });
        setData(tmpData)
    }
    const getDoctorTimeSlots = (doctorIndex, obj) => {
        postGetDoctorTimeSlots(obj).then(res => {
            const response = res.data.data
            if (response?.dateSlots) {
                let tmpData = [...data]
                tmpData[doctorIndex].DoctorTimeSlots = response
                setData(tmpData)
            }
        }).catch(e => { })
    }
    const onPreviousPress = (doctorIndex) => () => {
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID
        const obj = { DoctorID, page: page > 1 ? (page - 1) : page }
        getDoctorTimeSlots(doctorIndex, obj)
    }
    const onNextPress = (doctorIndex) => () => {
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID
        const obj = { DoctorID, page: page + 1 }
        getDoctorTimeSlots(doctorIndex, obj)
    }

    const onCloseSuccessModal = () => {
        setShowSuccessModal(false)
    }

    const getFCMToken = () => {
        return firebase.messaging().getToken()
    }

    const onBookNowPress = (doctorIndex) => (FullName, MobileNo, Password, Email) => {
        const isSlotSelected = data[doctorIndex]?.DoctorTimeSlots?.dateSlots?.find(el => el.timeSlots.find(el => el.active === true))
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID

        if (isSlotSelected) {
            let CouponDetails = {}
            try {
                CouponDetails = JSON?.parse(getCookie('selectedCouponCode'))
            } catch (error) { }
            const DoctorSlotID = isSlotSelected?.timeSlots?.find(el => el.active === true)?.SlotID
            const obj = {
                DoctorID, ClinicID: null,
                Patientlatitude: 0, Patientlongitude: 0,
                DoctorSlotID, IsProduction: addon_prefix === 'cp' ? false : true,
                IsRemoteSession: false, IsOnSite: false, IsOnClinic: true,
                IsSecondOpinion: false, IsEmergency: false, TriggerNotificationID: null,
                IsPaid: false, OrderWithRecepionist: false,
                CouponID: CouponDetails?.CouponID || null, CouponCode: CouponDetails?.CouponCode || null,
                CouponPercentage: CouponDetails?.Percentage || null, CouponAmount: CouponDetails?.Amount || null,
                CouponIsPercent: CouponDetails?.IsPercent || null, CouponIsCampaign: CouponDetails?.IsCampaign || null,
                CouponPaymentTypeID: CouponDetails?.PaymentTypeID || null, CouponServiceProviderID: CouponDetails?.ServiceProviderID || null,
                CouponEndDate: CouponDetails?.EndDate || null
            }
            if (Auth.isAuthenticated()) {
                setLoading(true)
                postBookingOrder(obj).then(async res => {
                    var data = res.data.data
                    if (IS_MOUNTED) {
                        if (res?.data?.StatusCode === 100) {
                            data.Questions = res?.data?.questions
                            const tmp_item = { ...data, ...obj, IsSpecialitySelected: false, IsAssistante: false, ConsumerDetails: UserProfileBaseInfo }
                            await reception_db_data(data.BookingID).update(tmp_item).then(() => {
                                setLoading(false)
                                setShowSuccessModal(true)
                                getDoctorTimeSlots(doctorIndex, { DoctorID, page })
                                // this.props.history.push('/assistant-chat/' + data?.BookingID, tmp_item)
                            }).catch(e => { })
                        } else if (res?.data?.StatusCode === 111) {
                            const message = I18nManager.isRTL() ? 'الطبيب غير متوفر' : 'Doctor isn\'t available'
                            alert(message)
                            setLoading(false)
                        } else {
                            const message = I18nManager.isRTL() ? 'نعتذر، لا يمكنك تنفيذ اكثر من ٣ طلبات في نفس الوقت. يمكنك الغاء اي من الطلبات المتعلقة او الجارية.' :
                                'Sorry, you can\'t place more than 3 order at the same time. you can cancel from the pending orders.'
                            alert(message)
                            setLoading(false)
                        }
                    }
                }).catch(e => { setLoading(false) })
            } else {
                var loginObj = {
                    MobileNo: removeWhiteSpaceFromString(MobileNo), Password, Email,
                    FirstName: FullName.split(' ')[0], LastName: FullName.substr(FullName.indexOf(" ") + 1),
                    IsAppointment: true, IsLogin: true,
                    FCM, IsProduction: addon_prefix === 'cp' ? false : true,
                }
                setLoading(true)
                postPatientProfile(loginObj).then(res => {
                    if (IS_MOUNTED) {
                        const data = res.data
                        if (data.Status === 'Success') {
                            Auth.login(() => {
                                setDefaultHeader(data.Message)
                                setCookie('token', data.Message)
                                onBookNowPress(doctorIndex)(FullName, MobileNo, Password, Email)
                            })
                        } else {
                            setLoading(false)
                        }
                    }
                }).catch((e) => setLoading(false))
            }
        }
    }

    useEffect(() => {
        IS_MOUNTED = true
        getDoctorByID()
        if (firebase.messaging.isSupported()) {
            getFCMToken().then(res => {
                setFCM(res)
            })
        }
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    return (
        <ViewContainer>
            <ModalBookingSuccess isOpen={showSuccessModal} onCloseClick={onCloseSuccessModal} />
            <TopViewBg title={I18nManager.isRTL() ? "أفضل الأطباء في السعودية" : "Best Doctors in Saudi"} />
            <TopFilter />
            <div className="pl-20 pr-20 pt-20 mb-20">
                {data?.map((item, index) => (
                    <Fragment key={index}>
                        <DoctorItem item={item} loading={loading} isDoctorInfo={true}
                            onIsMoreClick={onIsMoreClick(index)} onTimeSlotClick={onTimeSlotClick(index)}
                            onPreviousPress={onPreviousPress(index)} onNextPress={onNextPress(index)}
                            onBookNowPress={onBookNowPress(index)} />
                        <div className="doctor-info-about-the-doctor mb-15">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-20">
                                    <img loading='lazy' width="24" height="24" src="/assets/img/speak-with-psychologist.svg" alt='' />
                                    <span>{I18nManager.isRTL() ? "عن الطبيب" : "About the Doctor"}</span>
                                </div>
                                <p>{item?.Profile?.Bio}</p>
                            </div>
                        </div>
                        <div className="doctor-info-patient-reviews">
                            <div className="d-flex aling-items-center">
                                <i className="fa fa-star-o fa-2x"></i>
                                <span className="doctor-info-header">{I18nManager.isRTL() ? "آراء المرضى" : "Patients’ Reviews"} (32)</span>
                            </div>
                            <div className="mt-20 mb-30">
                                <StarsRating value={5} />
                                <div className="d-flex align-items-center">
                                    <span className="span-1">{I18nManager.isRTL() ? "تقييم عام" : "Overall Rating"}</span>
                                    &nbsp;&nbsp;
                                    <span className="span-2">From 123 Visitors</span>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-center doctor-info-below-overall-rating">
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <StarsRating value={5} />
                                    <span className="span-1">Assistant Rating</span>
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-center ml-30 mr-30">
                                    <StarsRating value={5} />
                                    <span className="span-1">Assistant Rating</span>
                                </div>
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                    <span className="span-1">4.9 out of 5</span>
                                    <span className="span-1">Doctor Rating</span>
                                </div>
                            </div>
                            <div>
                                <div className="mt-40">
                                    <PatientReviewItem />
                                </div>
                                <div className="mt-40">
                                    <PatientReviewItem />
                                </div>
                                <div className="mt-40">
                                    <PatientReviewItem />
                                </div>
                                <div className="mt-40">
                                    <PatientReviewItem />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-20">
                                <button className="doctor-info-all-reviews-button">
                                    <span>{I18nManager.isRTL() ? "اقرأ جميع المراجعات" : "Read all Reviews"}</span>
                                </button>
                            </div>
                        </div>
                        <div className="doctor-info-about-the-doctor mt-20">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-20">
                                    <i className="fa fa-picture-o fa-2x"></i>
                                    <span>{I18nManager.isRTL() ? "صور العيادة" : "Clinic Photos"}</span>
                                </div>
                                <div className="d-flex flex-wrap align-items-center">
                                    <img loading='lazy' width="150" height="100" src="/assets/img/clinic.jpg" className={"mb-10 img-fluid " + (I18nManager.isRTL() ? "ml-10" : "mr-10")} alt='' />
                                    <img loading='lazy' width="150" height="100" src="/assets/img/clinic.jpg" className={"mb-10 img-fluid " + (I18nManager.isRTL() ? "ml-10" : "mr-10")} alt='' />
                                    <img loading='lazy' width="150" height="100" src="/assets/img/clinic.jpg" className={"mb-10 img-fluid " + (I18nManager.isRTL() ? "ml-10" : "mr-10")} alt='' />
                                    <img loading='lazy' width="150" height="100" src="/assets/img/clinic.jpg" className={"mb-10 img-fluid " + (I18nManager.isRTL() ? "ml-10" : "mr-10")} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="doctor-info-about-the-doctor mt-20">
                            <div className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-20">
                                    <img loading="lazy" src="/assets/img/choose-speciality.svg" alt='' />
                                    <span>{I18nManager.isRTL() ? "خدمات الطبيب" : "Doctor's services"}</span>
                                </div>
                                <div className="d-flex flex-wrap align-items-center">
                                    <button className={"doctor-service-button " + (I18nManager.isRTL() ? "ml-10" : "mr-10")}>
                                        <span>Doctor's services</span>
                                    </button>
                                    <button className={"doctor-service-button " + (I18nManager.isRTL() ? "ml-10" : "mr-10")}>
                                        <span>Doctor's services</span>
                                    </button>
                                    <button className={"doctor-service-button " + (I18nManager.isRTL() ? "ml-10" : "mr-10")}>
                                        <span>Doctor's services</span>
                                    </button>
                                    <button className={"doctor-service-button " + (I18nManager.isRTL() ? "ml-10" : "mr-10")}>
                                        <span>Doctor's services</span>
                                    </button>
                                    <button className={"doctor-service-button " + (I18nManager.isRTL() ? "ml-10" : "mr-10")}>
                                        <span>Doctor's services</span>
                                    </button>
                                    <button className={"doctor-service-button " + (I18nManager.isRTL() ? "ml-10" : "mr-10")}>
                                        <span>Doctor's services</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                ))}
            </div>
            <FAQ />
        </ViewContainer>
    )
}

export default DoctorInfo