import React, { useEffect, useState, useContext } from 'react';
import './Home.css';
import I18nManager from '../../core/I18nManager/I18nManager';
import ViewContainer from '../../core/routes/view-container/ViewContainer';
import { addon_prefix, postBookingOrder, postGetCampaignCoupons, postGetHomeSerives, postPatientProfile, setDefaultHeader } from '../../core/api/Api';
import AppDownload from '../../core/routes/app-download/AppDownload';
import { constants } from '../../core/constants/constants';
import FlipCard from '../_common/flip-card/FlipCard';
import { getCookie, getPublicURL, goToWhatsapp, removeWhiteSpaceFromString, setCookie } from '../../core/common-service/CommonService';
import TimeslotAppointments from '../_common/timeslot-appointments/TimeslotAppointments';
import Auth from '../../core/routes/auth/Auth';
import { reception_db_data } from '../../core/firebase/fire/Fire';
import StoreContext from '../../core/store/StoreContext';
import firebase from '../../core/firebase/firebase';
import ModalBookingSuccess from '../_common/modal-booking-success/ModalBookingSuccess';
import TopFilter from '../_common/filter/top-filter/TopFilter';
import OfferItem from '../all-offers/offer-item/OfferItem';
import FilterContext from '../../core/store/FilterContext';
import ViewDisplay from '../_common/view-display/ViewDisplay';

var IS_MOUNTED = true
var TIMESLOT_PAGE = 1

export default function Home(props) {

    const { UserProfileBaseInfo } = useContext(StoreContext)
    const { ourSpecialities } = useContext(FilterContext)

    const [offers, setOffers] = useState([])
    const [homeServices, setHomeServices] = useState([])
    const [isPsychiatristFlipped, setIsPsychiatristFlipped] = useState(false)
    const [isHomeServiceFlipped, setIsHomeServiceFlipped] = useState(false)
    const [loading, setLoading] = useState(false)
    const [FCM, setFCM] = useState('')

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [ourServices, setOurServices] = useState(constants.ourServices.data)
    const [activeOurServiceImage, setActiveOurServiceImage] = useState(constants.ourServices.data[0].img)

    const goToPage = (path) => (e) => {
        e.preventDefault()
        props.history.push(path)
    }

    function getOffers(id) {
        postGetCampaignCoupons(id).then(res => {
            if (IS_MOUNTED) {
                const data = res.data.data
                setOffers(data)
            }
        }).catch(e => getOffers(id))
    }

    const getActiveServiceIndex = () => {
        const activeServiceIndex = homeServices.findIndex(el => el?.active === true)
        return activeServiceIndex
    }

    function getHomeServices(page) {
        const obj = { page }
        postGetHomeSerives(obj).then(res => {
            if (IS_MOUNTED) {
                const data = res.data.data
                if (getActiveServiceIndex() !== -1) {
                    data[getActiveServiceIndex()].active = true
                }
                setHomeServices(data)
            }
        }).catch(() => getHomeServices(page))
    }

    const onOurServicesClick = (item) => (e) => {
        e.preventDefault()
        if (item?.id) {
            window.location.href = getPublicURL() + '/#' + item?.id
        } else {
            props.history.push('/doctors-list/' + item?.type, { ...item.option, IsEmergency: false, IsAssistante: false })
        }
    }

    const setHomeServiceToActive = (index) => {
        let tmp_homeServices = [...homeServices]
        tmp_homeServices.forEach((element, i) => {
            element.active = parseInt(index) === i
        });
        setHomeServices(tmp_homeServices)
    }

    const flipCardHomeService = (index) => (e) => {
        if (!isHomeServiceFlipped) {
            setHomeServiceToActive(index)
        }
        e.preventDefault()
        setIsHomeServiceFlipped(prevState => !prevState)
    }

    const onTimeSlotClick = (index, el_index) => () => {
        if (getActiveServiceIndex() !== -1) {
            let tmp_homeServices = [...homeServices]
            tmp_homeServices[getActiveServiceIndex()].homeServiceSlots.forEach((element, i) => {
                element.timeSlots.forEach((el_timeslot, i_timeslot) => {
                    el_timeslot.active = (parseInt(index) === i && parseInt(i_timeslot) === el_index)
                });
            });
            setHomeServices(tmp_homeServices)
        }
    }

    const onIsMoreClick = (index) => () => {
        let tmp_homeServices = [...homeServices]
        tmp_homeServices[getActiveServiceIndex()].homeServiceSlots[index].isMorePressed = !tmp_homeServices[getActiveServiceIndex()].homeServiceSlots[index]?.isMorePressed
        setHomeServices(tmp_homeServices)
    }

    const flipCardPsychiatrist = (e) => {
        if (!isPsychiatristFlipped) {
            let tmp_homeServices = [...homeServices]
            tmp_homeServices.forEach((element, i) => {
                element.active = 4 === i
            });
            setHomeServices(tmp_homeServices)
        }
        e.preventDefault();
        setIsPsychiatristFlipped(prevState => !prevState)
    }

    const onPreviousPress = () => {
        if (TIMESLOT_PAGE > 1) {
            TIMESLOT_PAGE -= 1
            getHomeServices(TIMESLOT_PAGE)
        } else {
            getHomeServices(1)
        }
    }
    const onNextPress = () => {
        TIMESLOT_PAGE += 1
        getHomeServices(TIMESLOT_PAGE)
    }

    const onOurServicesMouseOver = (index) => () => {
        setActiveOurServiceImage(ourServices[index].img)
        var tmpOurServ = [...ourServices]
        tmpOurServ.forEach((element, i) => {
            element.active = parseInt(index) === i
        });
        setOurServices(tmpOurServ)
    }

    const onOurServicesMouseLeave = () => {
        var tmpOurServ = [...ourServices]
        tmpOurServ.forEach(element => {
            element.active = false
        });
        setOurServices(tmpOurServ)
    }

    const onBookNowPress = (FullName, MobileNo, Password, Email) => () => {
        if (getActiveServiceIndex() !== -1) {
            const isSlotSelected = homeServices[getActiveServiceIndex()]?.homeServiceSlots?.find(el => el.timeSlots.find(el => el.active === true))
            if (isSlotSelected) {
                let CouponDetails = {}
                try {
                    CouponDetails = JSON?.parse(getCookie('selectedCouponCode'))
                } catch (error) { }
                const GeneralServiceCategorySlotID = isSlotSelected?.timeSlots?.find(el => el.active === true)?.SlotID
                const activeHomeServiceItem = homeServices[getActiveServiceIndex()]
                const obj = {
                    DoctorID: null, ClinicID: null,
                    Patientlatitude: 0, Patientlongitude: 0,
                    GeneralServiceCategorySlotID, IsProduction: addon_prefix === 'cp' ? false : true,
                    IsRemoteSession: activeHomeServiceItem?.IsOnline, IsOnSite: activeHomeServiceItem?.IsOnsite,
                    IsOnClinic: false, IsSecondOpinion: false, IsEmergency: false, TriggerNotificationID: null,
                    IsPaid: false, OrderWithRecepionist: false,
                    CouponID: CouponDetails?.CouponID || null, CouponCode: CouponDetails?.CouponCode || null,
                    CouponPercentage: CouponDetails?.Percentage || null, CouponAmount: CouponDetails?.Amount || null,
                    CouponIsPercent: CouponDetails?.IsPercent || null, CouponIsCampaign: CouponDetails?.IsCampaign || null,
                    CouponPaymentTypeID: CouponDetails?.PaymentTypeID || null, CouponServiceProviderID: CouponDetails?.ServiceProviderID || null,
                    CouponEndDate: CouponDetails?.EndDate || null
                }
                if (Auth.isAuthenticated()) {
                    setLoading(true)
                    postBookingOrder(obj).then(async res => {
                        var data = res.data.data
                        if (IS_MOUNTED) {
                            if (res?.data?.StatusCode === 100) {
                                data.Questions = res?.data?.questions
                                const tmp_item = { ...data, ...obj, IsSpecialitySelected: false, IsAssistante: false, ConsumerDetails: UserProfileBaseInfo }
                                await reception_db_data(data.BookingID).update(tmp_item).then(() => {
                                    setLoading(false)
                                    setShowSuccessModal(true)
                                    getHomeServices(TIMESLOT_PAGE)
                                    // this.props.history.push('/assistant-chat/' + data?.BookingID, tmp_item)
                                }).catch(e => { })
                            } else if (res?.data?.StatusCode === 111) {
                                const message = I18nManager.isRTL() ? 'الطبيب غير متوفر' : 'Doctor isn\'t available'
                                alert(message)
                                setLoading(false)
                            } else {
                                const message = I18nManager.isRTL() ? 'نعتذر، لا يمكنك تنفيذ اكثر من ٣ طلبات في نفس الوقت. يمكنك الغاء اي من الطلبات المتعلقة او الجارية.' :
                                    'Sorry, you can\'t place more than 3 order at the same time. you can cancel from the pending orders.'
                                alert(message)
                                setLoading(false)
                            }
                        }
                    }).catch(e => { setLoading(false) })
                } else {
                    var loginObj = {
                        MobileNo: removeWhiteSpaceFromString(MobileNo), Password, Email,
                        FirstName: FullName.split(' ')[0], LastName: FullName.substr(FullName.indexOf(" ") + 1),
                        FCM, IsAppointment: true, IsLogin: true, IsProduction: addon_prefix === 'cp' ? false : true,
                    }
                    setLoading(true)
                    postPatientProfile(loginObj).then(res => {
                        if (IS_MOUNTED) {
                            const data = res.data
                            if (data.Status === 'Success') {
                                Auth.login(() => {
                                    setDefaultHeader(data.Message)
                                    setCookie('token', data.Message)
                                    onBookNowPress(FullName, MobileNo, Password, Email)()
                                })
                            } else {
                                setLoading(false)
                            }
                        }
                    }).catch((e) => setLoading(false))
                }
            }
        }
    }

    const onCloseSuccessModal = () => {
        setShowSuccessModal(false)
    }

    function getFCMToken() {
        return firebase.messaging().getToken()
    }

    useEffect(() => {
        IS_MOUNTED = true
        getOffers(1)
        getHomeServices(TIMESLOT_PAGE)
        if (firebase.messaging.isSupported()) {
            getFCMToken().then(res => {
                setFCM(res)
            })
        }
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    return (
        <ViewContainer>

            <ModalBookingSuccess isOpen={showSuccessModal} onCloseClick={onCloseSuccessModal} />

            <div id="home" className="home-top-bg-img">
                <div className="container">
                    {constants.topSection?.[I18nManager.isRTL() ? 'titleAr' : 'titleEn'].map((item, index) => (
                        <h2 key={index} onClick={() => index === 2 ? window.open(goToWhatsapp(), '_newtab') : undefined}
                            className="home-top-text">{item}</h2>
                    ))}
                </div>
            </div>

            <TopFilter />

            <div className="container below-filter-container">
                <div className="row">
                    <div className="col-4 d-flex flex-column align-items-center mb-10 mt-10">
                        <div className="below-filter-container-subview">
                            <div className="d-flex align-items-center justify-content-center below-filter-container-view">
                                <img loading="lazy" src="/assets/img/4.8-stars-rating.svg" alt="" />
                            </div>
                        </div>
                        <div className="below-filter-text-container">
                            <h6 className="below-filter-text mt-10 mb-5">{I18nManager.isRTL() ? "حجز مجاني عبر" : "All free"}</h6>
                            <h6 className="below-filter-text">{I18nManager.isRTL() ? "الموقع والتطبيق" : "booking"}</h6>
                        </div>
                    </div>
                    <div className="col-4 d-flex flex-column align-items-center mb-10 mt-10">
                        <div className="below-filter-container-subview">
                            <div className="d-flex align-items-center justify-content-center below-filter-container-view">
                                <img loading="lazy" src="/assets/img/location.svg" alt="" />
                            </div>
                        </div>
                        <div className="below-filter-text-container">
                            <h6 className="below-filter-text mt-10 mb-5">{I18nManager.isRTL() ? "حجوزات منزلية وفي العيادات" : "Available everywhere"}</h6>
                        </div>
                    </div>
                    {/* <div className="col-md-3 col-6 d-flex flex-column align-items-center mb-10 mt-10">
                        <div className="below-filter-container-subview">
                            <div className="d-flex align-items-center justify-content-center below-filter-container-view">
                                <img loading="lazy" src="/assets/img/largest-pool.svg" alt="" />
                            </div>
                        </div>
                        <div className="below-filter-text-container">
                            <span className="below-filter-text">{I18nManager.isRTL() ? "مجموعة كبيرة من الأطباء والممرضين والتقنيين" : "Large pool of professional healthcare providers."}</span>
                        </div>
                    </div> */}
                    <div className="col-4 d-flex flex-column align-items-center mb-10 mt-10">
                        <div className="below-filter-container-subview">
                            <div className="d-flex align-items-center justify-content-center below-filter-container-view">
                                <img loading="lazy" src="/assets/img/24-hour-care.svg" alt="" />
                            </div>
                        </div>
                        <div className="below-filter-text-container">
                            <h6 className="below-filter-text mt-10 mb-5">{I18nManager.isRTL() ? "24 ساعة" : "24 Hours"}</h6>
                            <h6 className="below-filter-text">{I18nManager.isRTL() ? "خدمات" : "services"}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <section id="our-services" className="pb-40">
                <div className="mb-50">
                    <div className="container">
                        <div className="col-md-8 col-12">
                            <div className="yellow-circle"></div>
                            <h4 className="offers-title">{I18nManager.isRTL() ? 'خدماتنا' : 'Our services'}</h4>
                            <h6 className="offers-subtitle">{constants.ourServices.summary?.[I18nManager.isRTL() ? "ar" : "en"]}</h6>
                        </div>
                        <div className="container mb-20 mt-20">
                            <div className="row">
                                <div className="col-md-4 col-12 d-flex align-items-center">
                                    <img loading="lazy" className="img-fluid" src={activeOurServiceImage} alt="" />
                                </div>
                                <div className="col-md-8 col-12">
                                    <div className="row">
                                        {ourServices.map((item, index) => (
                                            <div key={index} className="col-md-6 col-12 our-services-main-container">
                                                <div key={index} className="our-services-container" onClick={onOurServicesClick(item)}
                                                    onMouseOver={onOurServicesMouseOver(index)} onMouseLeave={onOurServicesMouseLeave}>
                                                    <div className="below-filter-container-subview" style={{ borderColor: item?.active ? '#ffffff' : item.borderColor }}>
                                                        <div className="d-flex align-items-center justify-content-center below-filter-container-view" style={{ backgroundColor: item?.active ? '#ffffff' : item.bgColor }}>
                                                            <img loading="lazy" width="32" height="32" src={item.uri} alt="" />
                                                        </div>
                                                    </div>
                                                    <h6>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</h6>
                                                    <h4>{item?.[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"]}</h4>
                                                    <button><img loading="lazy" src="/assets/img/down-arrow.svg" alt="" /></button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="offers" className="pt-40 pb-40 light-grey-bg-color">
                <div className="mt-50 mb-50">
                    <div className="container">
                        <div className="col-md-8 col-12">
                            <div className="yellow-circle"></div>
                            <h4 className="offers-title">{I18nManager.isRTL() ? 'أحدث العروض' : 'Latest Offers'}</h4>
                            <h6 className="offers-subtitle">{constants.offers.summary?.[I18nManager.isRTL() ? "ar" : "en"]}</h6>
                        </div>
                    </div>
                    <div className="container mb-20 mt-20">
                        <div className="row">
                            {offers?.slice(0, 3)?.map((item, index) => (
                                <div key={index} className="col-md-4 col-12 d-flex flex-column mb-10">
                                    <OfferItem item={item} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <a onClick={goToPage("/all-offers")} rel="noopener noreferrer">
                            <span className="offers-all-offers-button">{I18nManager.isRTL() ? "كل العروض" : "More offers"}</span>
                        </a>
                    </div>
                </div>
            </section>

            <section id="home-book-service" className="pt-40 pb-40">
                <div className="mt-50 mb-50">
                    <div className="container">
                        <div className="col-md-8 col-12">
                            <div className="yellow-circle"></div>
                            <h4 className="offers-title">{I18nManager.isRTL() ? 'الخدمات المنزلية' : 'Home services'}</h4>
                            <h6 className="offers-subtitle">{constants.homeServices.summary?.[I18nManager.isRTL() ? "ar" : "en"]}</h6>
                        </div>
                        <div className="container mb-20 mt-20">
                            <div className="row">
                                <div className="col-md-6 col-12 p-0 mb-20">
                                    <div className="d-flex">
                                        <div className="col-6 p-0">
                                            {constants.homeServices.data.slice(0, 2).map((item, index) => (
                                                <div key={index} className={"home-services " + (index === 0 ? "mb-60" : "")}>
                                                    <img loading="lazy" src={item.img} className="img-fluid" alt="" />
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-6 p-0">
                                            {constants.homeServices.data.slice(-2).map((item, index) => (
                                                <div key={index} className={"home-services " + (index === 0 ? "mt-120" : "mt-60")}>
                                                    <img loading="lazy" src={item.img} className="img-fluid" alt="" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <FlipCard isFlipped={isHomeServiceFlipped}
                                        frontChildren={
                                            <>
                                                {constants.homeServices.data.map((item, index) => (
                                                    <div key={index} className="col-12 d-flex flex-column mb-20 home-services-item" onClick={flipCardHomeService(index)}>
                                                        <img loading="lazy" src={item.uri} alt="" />
                                                        <div className="d-flex align-items-center justify-content-between mt-10">
                                                            <h4>{item?.[I18nManager.isRTL() ? 'titleAr' : 'titleEn']}</h4>
                                                            <h5>{item?.[I18nManager.isRTL() ? 'extraInfoAr' : 'extraInfoEn']}</h5>
                                                        </div>
                                                        <h6>{item?.[I18nManager.isRTL() ? 'descriptionAr' : 'descriptionEn']}</h6>
                                                        <button>{I18nManager.isRTL() ? "احجز موعدًا" : "Book an appointment"}</button>
                                                    </div>
                                                ))}
                                            </>
                                        }
                                        backChildrem={
                                            <TimeslotAppointments
                                                onFlipCloseButtonClick={flipCardHomeService(0)}
                                                data={homeServices?.find(el => el.active === true)?.homeServiceSlots || []}
                                                loading={loading} onIsMoreClick={onIsMoreClick}
                                                onPreviousPress={onPreviousPress} onNextPress={onNextPress}
                                                onTimeSlotClick={onTimeSlotClick} onBookNowPress={onBookNowPress}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="book-psychiatrist-service" className="pt-50 pb-50 light-grey-bg-color">
                <div className="container">
                    <div className="col-md-8 col-12">
                        <div className="yellow-circle"></div>
                        <h4 className="offers-title">{constants.psychiatristService?.[I18nManager.isRTL() ? 'titleAr' : 'titleEn']}</h4>
                        <h6 className="offers-subtitle">{constants.psychiatristService.summary?.[I18nManager.isRTL() ? 'ar' : 'en']}</h6>
                    </div>
                </div>
                <div className="container mb-20 mt-20">
                    <div className="row">
                        <div className="col-md-4 col-12 align-self-center">
                            <div className="psychiatrist-service-container">
                                <img loading="lazy" className="img-fluid" src="/assets/img/speak-with-psychologist.png" alt="" />
                            </div>
                        </div>
                        <div className="col-md-8 col-12">
                            <div className="psychiatrist-service-book-container">
                                <FlipCard isFlipped={isPsychiatristFlipped}
                                    frontChildren={
                                        <>
                                            <h4>{constants.psychiatristService.body?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</h4>
                                            <div className="d-flex align-items-center justify-content-between mt-10">
                                                <h6>{constants.psychiatristService.body?.[I18nManager.isRTL() ? "subTitleAr" : "subTitleEn"]}</h6>
                                                <h5>{constants.psychiatristService.body?.[I18nManager.isRTL() ? "extraInfoAr" : "extraInfoEn"]}</h5>
                                            </div>
                                            {constants.psychiatristService.body?.[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"].map((item, index) => (
                                                <p key={index}>{item}</p>
                                            ))}
                                            <div className="d-flex mt-20 mb-20">
                                                <img loading="lazy" src="/assets/img/video.svg" alt="" />
                                                <img loading="lazy" className="ml-20 mr-20" src="/assets/img/call.svg" alt="" />
                                                <img loading="lazy" src="/assets/img/chat.svg" alt="" />
                                            </div>
                                            <button onClick={flipCardPsychiatrist} className="psychiatrist-service-book-button">{I18nManager.isRTL() ? "احجز موعدًا" : "Book an appointment"}</button>
                                        </>
                                    }
                                    backChildrem={
                                        <TimeslotAppointments style={{ boxShadow: 'none' }} data={homeServices?.[4]?.homeServiceSlots || []}
                                            onFlipCloseButtonClick={flipCardPsychiatrist}
                                            loading={loading} onIsMoreClick={onIsMoreClick}
                                            onPreviousPress={onPreviousPress} onNextPress={onNextPress}
                                            onTimeSlotClick={onTimeSlotClick} onBookNowPress={onBookNowPress}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="featured-clinics">
                <div className="pt-50 pb-50">
                    <div className="container">
                        <div className="col-md-8 col-12">
                            <div className="yellow-circle"></div>
                            <h4 className="offers-title">{I18nManager.isRTL() ? "العيادات المميزة" : 'Featured Clinics'}</h4>
                            <h6 className="offers-subtitle">{I18nManager.isRTL() ? 'اطلع على التخصصات الطبية المختلفة، وابق على اطلاع دائم بكل ما هو جديد في عالم الطب، أو احجز موعدًا في العيادة التي تختارها.' :
                                `Check out different medical specialties, and stay updated on what's new in medicine, or book an appointment at the clinic of your choice.`}</h6>
                        </div>
                    </div>
                    <div className="container mb-20 mt-20">
                        <ViewDisplay
                            showMinHideMax={
                                <div className="row">
                                    {ourSpecialities.slice(0, 8).map((item, index) => (
                                        <div key={index} className="col-md-3 col-6">
                                            <a onClick={goToPage("/speciality-details/" + item?.GeneralServiceCategoryID)} style={{ textDecoration: 'none' }}>
                                                <div className="our-specialites-container">
                                                    <img loading="lazy" src={item.uri} alt="" />
                                                    <h4>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</h4>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>}
                            hideMinShowMax={
                                <div className="row">
                                    {ourSpecialities.slice(0, 6).map((item, index) => (
                                        <div key={index} className="col-md-3 col-6">
                                            <a onClick={goToPage("/speciality-details/" + item?.GeneralServiceCategoryID)} style={{ textDecoration: 'none' }}>
                                                <div className="our-specialites-container">
                                                    <img loading="lazy" src={item.uri} alt="" />
                                                    <h4>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</h4>
                                                </div>
                                            </a>
                                        </div>
                                    ))}
                                </div>}
                        />

                    </div>
                    <div className="d-flex justify-content-center">
                        <a onClick={goToPage("/all-specialities")} rel="noopener noreferrer">
                            <span className="offers-all-offers-button">{I18nManager.isRTL() ? "المزيد من التخصصات" : "More Clinics"}</span>
                        </a>
                    </div>
                </div>
            </section>

            <AppDownload />
        </ViewContainer >
    )
}