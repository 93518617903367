import React, { Component } from 'react'
import { postBookingOrder, postNotificationForChat, postGetPatientOrderByID, postCancelBookingRequest, postGetPatientSavedLocation, postGetPeopleForPatient, postSaveChatHistory, postGetDoctorAvailabilityForPatient, postPatientUploadFileAndGetUrl, postGetServiceCategories } from '../../../core/api/Api'
import firebase from '../../../core/firebase/firebase';
import { db_chat, reception_db_chat, reception_db_data } from '../../../core/firebase/fire/Fire'
import CancelButton from '../../_common/cancel-button/CancelButton'
import AssistanteChatMessage from './assistante-chat-message/AssistanteChatMessage'
import moment from 'moment';
import './AssistanteChat.css'
import AssistanteModal from './assistante-modal/AssistanteModal';
import { checkFireDataByType, toBase64 } from '../../../core/common-service/CommonService';
import ModalInvoice from '../chat-payment/modal-invoice/ModalInvoice';
import I18nManager from '../../../core/I18nManager/I18nManager';
import ViewContainer from '../../../core/routes/view-container/ViewContainer';
import { HomeVisitOrderCanceledMessage, WaitingBankTransferConfirmationMessage } from '../../../core/firebase/automated-message/AutomatedMessage';
import ModalSubmit from '../../_common/modal-submit/ModalSubmit';

var IS_MOUNTED = true

const EMERGENCY_MSG = { ar: 'مرحبا، هل تريد مني أن أرسل لك سيارة إسعاف؟', en: 'Hello, Would you like me to send you an Ambulance?' }
const WELCOME_SPECIALITY_MSG = { ar: 'أهلا بك في دُك ناو للخدمات الطبية. سلامتك ولا أراك الله مكروهاً.\n\nما هو التخصص الي تود ان نخدمك فيه ؟', en: 'Welcome to DocNow for medical services. Hope you safe and healthy.\n\nWhich medical speciality you would like us to help with ?' }
const WELCOME_CASE_MSG = { ar: 'أهلا بك في دُك ناو للخدمات الطبية. سلامتك ولا أراك الله مكروهاً.\n\nما هي حالتك ؟', en: 'Welcome to DocNow for medical services. Hope you safe and healthy.\n\nWhat is your case ?' }
const PATIENT_CASE_MSG = { ar: 'ما هي حالتك؟', en: 'What is your case?' }
const SPECIALITY_MSG = { ar: 'ما التخصص الذي تبحث عنه؟', en: 'What Speciality are you looking for?' }
const INTERESTED_DISTANCE_MSG = { ar: 'هل أنت مهتم بالمسافة؟', en: 'Are you interested in distance?' }
const SELECTED_TIMESLOT = (date, timeAr, timeEn) => {
    return {
        ar: 'الفترة الزمنية المحددة هي: ' + date + ' ' + timeAr,
        en: 'Selected timeslot is: ' + date + ' ' + timeEn
    }
}
const CHOOSE_APPOINTMENT_MSG = { ar: 'اختر التاريخ والوقت لموعدك', en: 'Choose date and time for your appointment' }
const SELECT_LOCATION_MSG = { ar: 'اختر موقعك', en: 'Choose your location' }
const SELECT_PEOPLE_MSG = { ar: 'اختر موقعك', en: 'Who is the Patient?' }
const PAYMENT_MSG = { ar: 'يرجى متابعة الدفع', en: 'Please proceed with payment' }
const ADD_MORE_DETAILS_MSG = { ar: 'إن كنت تريد إرسال رسالة نصية، ملاحظة صوتية أو صورة، يمكنك ارسلها الان', en: 'If you want to send a text message, voice note or image you can send them now' }
const DONT_KNOW_MSG = { ar: 'انا لا اعرف', en: 'I dont\'t know' }
const YES_MSG = { ar: 'نعم', en: 'Yes' }
const NO_MSG = { ar: 'كلا', en: 'No' }


export default class AssistanteChat extends Component {

    state = {
        data: [],
        Specializations: [],
        BookingID: 0,
        ManPowerID: 0,
        ConsumerID: 0,
        ConsumerImage: '',
        FireData: [],
        TextMessage: '',
        SelectedLocation: { Latitude: 0, Longitude: 0 },
        params: {},
        showCancelOrderModal: false, cancelOrder_loading: false,

        SpecialityID: 0,

        TimeSlots: [], CalendarSelectedDate: moment(), IsClinicSchedule: false, timeSlotDisabled: false,

        showInvoiceModal: false, invoiceData: {},

        modalKey: '',
        Peoples: [],
        Locations: [],
    }

    saveChatHistory(BookingID, data) {
        const obj = { BookingID, Text: JSON.stringify(data), IsReceptionist: true }
        postSaveChatHistory(obj).then(res => {
        }).catch(e => { })
    }

    changeState(key, value) {
        this.setState({ [key]: value })
    }

    SendNotification() {
        const { BookingID } = this.state
        postNotificationForChat({ BookingID }).then(res => {
        }).catch(e => { })
    }

    async setupData(data) {
        const today = moment().format('L')
        const yesterday = moment().subtract(1, 'days').format('L')
        data.forEach(element => {
            if (moment(element.CreatedDate).format('L') === today) {
                element.date = I18nManager.isRTL() ? 'اليوم' : 'TODAY'
            } else if (moment(element.CreatedDate).format('L') === yesterday) {
                element.date = I18nManager.isRTL() ? 'البارحة' : 'YESTERDAY'
            } else {
                element.date = moment(element.CreatedDate).locale(I18nManager.isRTL() ? 'ar' : 'en').format('L')
            }
        });
        const distinct = data.filter((item, i, arr) => {
            return arr.indexOf(arr.find(t => t.date === item.date)) === i;
        });
        const distinctDates = []
        distinct.forEach(element => {
            distinctDates.push(element.date)
        });
        var new_arr = []
        distinctDates.forEach(el_date => {
            const res = data.filter(el => el.date === el_date)
            new_arr.push({ date: el_date, data: res })
        });
        return await new_arr
    }

    async SendPatientFireMessage(Text, AutoText, Data, Type) {
        const { BookingID, ConsumerID, ManPowerID } = this.state
        this.SendNotification()
        reception_db_chat(BookingID).push({
            SenderID: ConsumerID,
            ConsumerID,
            ManPowerID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        });
    }

    async SendAssistanteFireMessage(Text, AutoText, Data, Type) {
        const { BookingID, ConsumerID, ManPowerID } = this.state
        this.SendNotification()
        reception_db_chat(BookingID).push({
            SenderID: ManPowerID,
            ConsumerID,
            ManPowerID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        });
    }

    async RemoveFireMessageType(Types) {
        const { BookingID } = this.state
        const data = this.state.data.slice()
        Types.forEach(async case_el => {
            await data.forEach(async element => {
                var items = element.data.filter(el => el.Type === case_el)
                items.forEach(async item => {
                    if (item) {
                        var key = item.key
                        item.date = null
                        item.key = null
                        item.Type = null
                        await reception_db_chat(BookingID).child(key).update(item)
                    }
                });
            });
        });
    }

    onChangeText(TextMessage) {
        clearTimeout(this.timeout)
        if (TextMessage.indexOf("\n") === -1 && TextMessage !== ' ') {
            this.setState({ TextMessage })
        }
    }

    onSubmitSendText() {
        const { TextMessage } = this.state
        if (TextMessage.length > 0) {
            this.SendPatientFireMessage(TextMessage.trim(), null, null, null)
            this.setState({ TextMessage: '' }, () => {
                this.timeout = setTimeout(() => {
                    if (checkFireDataByType(this.state.data, 'AddPatientCase')) {
                        this.SendAssistanteFireMessage(null, SELECT_PEOPLE_MSG, null, 'SelectPeople').then(() => {
                            this.RemoveFireMessageType(['AddPatientCase'])
                        })
                    }
                }, 100);
            })
        }
    }

    // Start Ambulance
    onAmbulanceResponse(value) {
        if (value) {
            this.SendPatientFireMessage(null, YES_MSG, null, null).then(() => {
                this.SendAssistanteFireMessage(null, PATIENT_CASE_MSG, null, 'AddPatientCase').then(() => {
                    this.RemoveFireMessageType(['SendAmbulance'])
                })
            })
        } else {
            window.history.back()
        }
    }
    // End Ambulance

    // Start People
    onChoosePeople() {
        const { Peoples } = this.state
        this.setState({ modalKey: 'people' }, () => {
            if (Peoples.length === 0) {
                postGetPeopleForPatient(null).then(res => {
                    if (IS_MOUNTED) {
                        var data = res.data.data
                        data.unshift({ FamilyMemberID: 0, Name: I18nManager.isRTL() ? 'أنا' : 'Me', LastName: '', active: true })
                        data.push({ FamilyMemberID: -1, Name: I18nManager.isRTL() ? 'أضف' : 'Add New' })
                        this.setState({ Peoples: data }, () => {
                            this.onPeopleItemPress(0)
                        })
                    }
                }).catch(e => { })
            }
        })
    }

    onPeopleItemPress(index) {
        const Peoples = this.state.Peoples.slice()
        if (Peoples[index]?.FamilyMemberID === 0 || Peoples[index]?.FamilyMemberID !== -1) {
            Peoples.forEach(element => {
                element.active = false
            });
            Peoples[index].active = true
            this.setState({ Peoples, FamilyMemberID: Peoples[index]?.FamilyMemberID })
        } else if (Peoples[index]?.FamilyMemberID === -1) {
            // this.setState({ modalKey: 'add-people' })
            this.props.history.push('/add-people/')
        }
    }

    onPeopleResponse(value) {
        const { FireData, Peoples, BookingID } = this.state
        const item = Peoples.find(el => el.active === true)
        if (item) {
            this.setState({ modalKey: '' }, () => {
                if (value) {
                    const peopleIndex = Peoples.findIndex(el => el.active === true)
                    const item = Peoples.find(el => el.active === true)
                    const text = item?.Name + ' ' + item?.LastName
                    this.SendPatientFireMessage(peopleIndex === 0 ? null : text, peopleIndex === 0 ? { ar: 'أنا', en: 'Me' } : null, null, null, null).then(() => {
                        postBookingOrder({ BookingID, FamilyMemberID: item?.FamilyMemberID }).then(res => {
                            this.RemoveFireMessageType(['SelectPeople']).then(() => {
                                if (FireData?.IsOnSite) {
                                    this.SendAssistanteFireMessage(null, SELECT_LOCATION_MSG, null, 'SelectLocation')
                                }
                            })
                        }).catch(e => { })
                    })
                }
            })
        }
    }
    // End People

    // Start Location
    onChooseLocation() {
        const { Locations } = this.state
        this.setState({ modalKey: 'location' }, () => {
            if (Locations.length === 0) {
                postGetPatientSavedLocation(null).then(res => {
                    var data = res.data.data
                    if (IS_MOUNTED) {
                        data.unshift({ ConsumerSavedLocationID: 0, Name: I18nManager.isRTL() ? 'الموقع الحالي' : 'Current Location', active: true })
                        data.push({ ConsumerSavedLocationID: -1, Name: I18nManager.isRTL() ? 'أضف' : 'Add New' })
                        navigator.geolocation.getCurrentPosition((position) => {
                            const obj = { Latitude: position.coords.latitude, Longitude: position.coords.longitude }
                            this.setState({ SelectedLocation: obj })
                        }, (error) => { });
                        this.setState({ Locations: data }, () => {
                            this.onLocationItemPress(0)
                        })
                    }
                }).catch(e => { })
            }
        })
    }
    onLocationItemPress(index) {
        const Locations = this.state.Locations.slice()
        if (Locations[index]?.ConsumerSavedLocationID === 0 || Locations[index]?.ConsumerSavedLocationID !== -1) {
            Locations.forEach(element => {
                element.active = false
            });
            Locations[index].active = true
            this.setState({ Locations }, () => {
                if (Locations[index]?.ConsumerSavedLocationID === 0) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const obj = { Latitude: position.coords.latitude, Longitude: position.coords.longitude }
                        this.setState({ SelectedLocation: obj })
                    }, (error) => { });
                } else {
                    const obj = { Latitude: Locations[index]?.Latitude, Longitude: Locations[index]?.Longitude }
                    this.setState({ SelectedLocation: obj })
                }
            })
        } else if (Locations[index]?.ConsumerSavedLocationID === -1) {
            // this.setState({ modalKey: 'map' })
            this.props.history.push('/add-location/')
        }
    }
    onLocationResponse(value) {
        const { SelectedLocation, BookingID } = this.state
        this.setState({ modalKey: '' }, () => {
            if (value) {
                const item = { latitude: SelectedLocation.Latitude, longitude: SelectedLocation.Longitude }
                postBookingOrder({
                    BookingID, Patientlatitude: SelectedLocation.Latitude,
                    Patientlongitude: SelectedLocation.Longitude
                }).then(res => {
                    let FireData = this.state.FireData
                    FireData.Patientlatitude = SelectedLocation.Latitude
                    FireData.Patientlongitude = SelectedLocation.Longitude
                    this.setState({ FireData }, () => {
                        reception_db_data(BookingID).update(FireData).then(() => {
                            this.SendPatientFireMessage(null, null, item, 'Location').then(() => {
                                this.RemoveFireMessageType(['SelectLocation'])
                            })
                        })
                    })
                    // this.SendAssistanteFireMessage(null, CHOOSE_APPOINTMENT_MSG, null, 'SelectAppointment')
                }).catch(e => { })
            }
        })
    }
    // End Location

    // Start Speciality
    onChooseSpeciality(value) {
        const { params } = this.state
        if (value) {
            const obj = {
                IsRemoteSession: params?.IsRemoteSession, IsOnClinic: params?.IsOnClinic, IsOnSite: params?.IsOnSite,
                IsAssistante: params?.IsAssistante, IsEmergency: params?.IsEmergency, IsSecondOpinion: params?.IsSecondOpinion,
            }
            this.setState({ modalKey: 'speciality', Specializations: [] }, () => {
                this.setState({ showBottomSheet: true })
                postGetServiceCategories(obj).then(res => {
                    this.setState({ Specializations: res.data.data }, () => {
                        this.onSpecialityItemPress(0)
                    })
                }).catch(e => { this.onChooseSpeciality(value) })
            })
        } else {
            this.SendPatientFireMessage(null, DONT_KNOW_MSG, null, null).then(() => {
                if (params?.IsEmergency) {
                    this.SendAssistanteFireMessage(null, EMERGENCY_MSG, null, 'SendAmbulance').then(() => {
                        this.RemoveFireMessageType(['SelectSpeciality'])
                    })
                } else {
                    this.SendAssistanteFireMessage(null, PATIENT_CASE_MSG, null, 'AddPatientCase').then(() => {
                        this.RemoveFireMessageType(['SelectSpeciality'])
                    })
                }
            })
            return
            this.SendPatientFireMessage(null, DONT_KNOW_MSG, null, null).then(() => {
                this.SendAssistanteFireMessage(null, params?.IsRemoteSession ? CHOOSE_APPOINTMENT_MSG : INTERESTED_DISTANCE_MSG, null, 'SelectAppointment').then(() => {
                    this.RemoveFireMessageType(['SelectSpeciality'])
                })
            })
        }
    }
    onSpecialityItemPress(index) {
        const Specializations = this.state.Specializations.slice()
        Specializations.forEach(element => {
            element.active = false
        });
        Specializations[index].active = true
        this.setState({ Specializations, SpecialityID: Specializations[index]?.ServiceCategoryID })
    }
    onSpecialityResponse(value) {
        this.setState({ modalKey: '' }, () => {
            if (value) {
                const { Specializations, params } = this.state
                const item = Specializations.find(el => el.active === true)
                const obj = { ar: item?.ServiceCategoryNameAr, en: item?.ServiceCategoryName }
                // let FireData = this.state.FireData
                // FireData.SpecializationAr = item?.ServiceCategoryNameAr
                // FireData.Specialization = item?.ServiceCategoryName
                // this.setState({ FireData }, () => {
                //     reception_db_data(BookingID).update(FireData)
                // })
                this.SendPatientFireMessage(null, obj, null, null).then(() => {
                    if (params?.IsEmergency) {
                        this.SendAssistanteFireMessage(null, EMERGENCY_MSG, null, 'SendAmbulance').then(() => {
                            this.RemoveFireMessageType(['SelectSpeciality'])
                        })
                    } else {
                        this.SendAssistanteFireMessage(null, PATIENT_CASE_MSG, null, 'AddPatientCase').then(() => {
                            this.RemoveFireMessageType(['SelectSpeciality'])
                        })
                    }
                    return
                    if (params.IsRemoteSession) {
                        this.SendAssistanteFireMessage(null, CHOOSE_APPOINTMENT_MSG, null, 'SelectAppointment').then(() => {
                            this.RemoveFireMessageType(['SelectSpeciality'])
                        })
                    } else {
                        this.SendAssistanteFireMessage(null, INTERESTED_DISTANCE_MSG, null, 'InterestedInDistance').then(() => {
                            this.RemoveFireMessageType(['SelectSpeciality'])
                        })
                    }
                })
            }
        })
    }
    // End Speciality

    // Start Schedule
    onDateSelected(date) {
        const { FireData } = this.state
        this.setState({ CalendarSelectedDate: date })
        const obj = { ManPowerID: FireData?.ManPowerID, From: moment(date).locale('en').format('L'), To: moment(date).locale('en').format('L') }
        postGetDoctorAvailabilityForPatient(obj).then(async res => {
            var arr = []
            var data = res.data.data
            if (IS_MOUNTED) {
                this.setState({ IsClinicSchedule: data?.ClinicSchedule ?? false })
                if (data?.ClinicSchedule) {
                    data = data?.ClinicSlots?.filter(el => el?.clinicSlotItemData?.length > 0)
                    data.forEach(element => {
                        element.clinicSlotItemData.forEach(slot => {
                            if (slot.From) {
                                var hourMinuteTimeFrom = moment(new Date(2020, 5, 22, slot?.From.split(':')[0], slot?.From.split(':')[1], 0, 0));
                                var hourMinuteTimeTo = moment(new Date(2020, 5, 22, slot?.To.split(':')[0], slot?.To.split(':')[1], 0, 0));
                                slot.timeFrom = {
                                    ar: hourMinuteTimeFrom.format("hh:mm A"),
                                    en: hourMinuteTimeFrom.format("hh:mm A")
                                }
                                slot.timeTo = {
                                    ar: hourMinuteTimeTo.format("hh:mm A"),
                                    en: hourMinuteTimeTo.format("hh:mm A")
                                }
                                slot.DateTimeStatusID = element.DateTimeStatusID
                                arr.push(slot)
                            }
                        });
                    });
                    this.setState({ TimeSlots: arr })
                } else {
                    var data = res?.data?.data?.days[0]
                    data = data?.dayTimeStatus?.filter(el => el?.slots?.length > 0)
                    data.forEach(async day => {
                        day.slots.forEach(slot => {
                            var hourMinuteTime = moment(new Date(2020, 5, 22, slot?.From.split(':')[0], slot?.From.split(':')[1], 0, 0));
                            // if (slot?.IsSelected) {
                            slot.timeFrom = {
                                ar: hourMinuteTime.format("hh:mm A"),
                                en: hourMinuteTime.format("hh:mm A")
                            }
                            arr.push(slot)
                            // }
                        })
                    })
                    this.setState({ TimeSlots: arr })
                }
            }
        }).catch(e => { })
    }
    onCalendarSlotPress(item) {
        this.setState({ timeSlotDisabled: true })
        const { BookingID, CalendarSelectedDate, IsClinicSchedule } = this.state
        const date = moment(CalendarSelectedDate).format('L')
        var timeAr = item.timeFrom.ar
        var timeEn = item.timeFrom.en

        let FireData = this.state.FireData
        FireData.IsClinicSchedule = IsClinicSchedule
        if (IsClinicSchedule) {
            FireData.ManPowerSlotDate = moment(CalendarSelectedDate).format('L')
            FireData.ManPowerSlotFrom = item?.From
            FireData.ManPowerSlotTo = item?.To
        } else {
            FireData.ManPowerSlotID = item.ManPowerSlotID
        }
        this.setState({ FireData }, () => {
            reception_db_data(BookingID).update(FireData).then(() => {
                this.SendPatientFireMessage(null, SELECTED_TIMESLOT(date, timeAr, timeEn), item, 'PatientNegotiate').then(() => {
                    this.setState({ timeSlotDisabled: false })
                })
            }).catch(e => this.setState({ timeSlotDisabled: false }))
        })
    }
    // End Schedule

    // Start Payment
    onPaymentPress() {
        const { data, FireData, params } = this.state
        const item = { ...params, IsRemoteBooking: FireData?.IsRemoteSession, FireData, PaymentType: 'OpenOrder', data }
        this.props.history.push('/chat-payment', item)
    }
    // End Payment
    onOpenImagePicker = () => {
        this.onImageFileClick('banktransferimageupload')
    }

    onBankTransferImageUpload = async (e) => {
        const file = e?.target?.files[0]
        const { BookingID, ConsumerID } = this.state
        var base64Data = (await toBase64(file)).split(',')[1]
        const obj = {
            FileName: new Date().getTime() + '_' + file.name, Data: base64Data, FileTypeID: 3,
            BookingID, ConsumerID
        }
        this.setState({ bottomViewLoading: true })
        postPatientUploadFileAndGetUrl(obj).then(res => {
            this.SendPatientFireMessage(null, null, { uri: res.data.Message }, 'Image').then(() => {
                this.SendPatientFireMessage(null, WaitingBankTransferConfirmationMessage(), null, 'WaitingBankTransferConfirmation')
            })
            this.RemoveFireMessageType(['AddBankTransferConfirmation'])
            if (IS_MOUNTED) {
                this.setState({ bottomViewLoading: false })
            }
        }).catch(e => { this.setState({ bottomViewLoading: false }) })
    }

    onYesCancelOrderModal() {
        const { ConsumerID, ManPowerID, BookingID } = this.state
        if (!this.state.cancelOrder_loading) {
            const obj = { BookingID, CancelRequestStatus: true }
            this.setState({ cancelOrder_loading: true })
            postCancelBookingRequest(obj).then(async res => {
                const data = this.state.data.slice()
                const cases = ['PatientNegotiate', 'DoctorNegotiate', 'WaitingPatientPayment']
                cases.forEach(async case_el => {
                    await data.forEach(async element => {
                        var item = element.data.find(el => el.Type === case_el)
                        if (item) {
                            var key = item.key
                            item.date = null
                            item.key = null
                            item.Type = null
                            await reception_db_chat(BookingID).child(key).update(item)
                        }
                    });
                });
                await this.SendPatientFireMessage(null, HomeVisitOrderCanceledMessage(), null, 'OrderCanceled')
                await db_chat(BookingID).push({
                    SenderID: ConsumerID, ConsumerID, ManPowerID,
                    Text: null, AutoText: HomeVisitOrderCanceledMessage(),
                    Type: 'OrderCanceled', Data: null,
                    CreatedDate: firebase.database.ServerValue.TIMESTAMP,
                });
                if (IS_MOUNTED) {
                    await this.setState({ cancelOrder_loading: false, showCancelOrderModal: false })
                }
            }).catch(e => this.setState({ cancelOrder_loading: false }))
        }
    }

    onImageFileClick(id) {
        document.getElementById(id).click();
    }

    async onImageFileUpload(file, type) {
        const { BookingID, ConsumerID } = this.state
        var base64Data = (await toBase64(file)).split(',')[1]
        const obj = {
            FileName: new Date().getTime() + '_' + file.name, Data: base64Data, FileTypeID: 3,
            BookingID, ConsumerID
        }
        this.setState({ bottomViewLoading: true })
        postPatientUploadFileAndGetUrl(obj).then(res => {
            if (type === "image") {
                this.SendPatientFireMessage(null, null, { uri: res.data.Message }, 'Image')
            } else {
                if (file.name.endsWith('.pdf')) {
                    this.SendPatientFireMessage(null, null, { uri: res.data.Message }, 'File')
                } else {
                    this.SendPatientFireMessage(null, null, { uri: res.data.Message }, 'Image')
                }
            }
            if (IS_MOUNTED) {
                this.setState({ bottomViewLoading: false })
            }
        }).catch(e => { this.setState({ bottomViewLoading: false }) })
    }

    getPatientOrderByID(BookingID, cb) {
        postGetPatientOrderByID(BookingID).then(res => {
            var data = res.data.data[0]
            if (IS_MOUNTED) {
                const params = this.props.location.state
                this.setState({ params: { ...params, ...data } }, () => {
                    cb()
                })
            }
        }).catch(e => { this.getPatientOrderByID(BookingID, cb) })
    }

    componentWillUnmount() {
        const { params } = this.state
        IS_MOUNTED = false
        clearTimeout(this.timeout)
        db_chat(params?.BookingID).off()
        reception_db_chat(params?.BookingID).off()
        reception_db_data(params?.BookingID).off()
    }

    componentDidMount() {
        IS_MOUNTED = true
        const { id } = this.props.match.params
        this.getPatientOrderByID(id, () => {
            const { params } = this.state
            this.setState({
                Specializations: params?.Specialization, BookingID: params?.BookingID, ManPowerID: params?.ManPowerID,
                ConsumerID: params?.ConsumerID, ConsumerImage: params?.ConsumerImage,
            }, () => {
                reception_db_chat(params?.BookingID).on('value', async (snap) => {
                    var chat = await snap?.val()
                    if (chat) {
                        var objectKeys = await Object.keys(chat);
                        var data = []
                        objectKeys.forEach(async (element) => {
                            await data.push({ key: element, ...chat[element] })
                        });
                        data = await data.sort((a, b) => a.CreatedDate - b.CreatedDate)
                        await this.saveChatHistory(params?.BookingID, data)
                        this.setupData(data).then((async (res) => {
                            if (IS_MOUNTED) {
                                await this.setState({ data: res })
                            }
                        }))
                    } else {
                        if (params?.IsSpecialitySelected) {
                            this.SendAssistanteFireMessage(null, WELCOME_CASE_MSG, null, 'AddPatientCase')
                        } else {
                            this.SendAssistanteFireMessage(null, WELCOME_SPECIALITY_MSG, null, 'SelectSpeciality')
                        }
                        return
                        this.SendAssistanteFireMessage(null, EMERGENCY_MSG, null, 'SendAmbulance')
                    }
                })

                reception_db_data(params?.BookingID).on('value', async (snap) => {
                    var data = snap?.val()
                    if (data) {
                        this.setState({ FireData: data })
                    }
                })
            })
        })
    }

    render() {
        const { data, ConsumerImage, Specializations, TextMessage, modalKey, Peoples, Locations,
            CalendarSelectedDate, TimeSlots, timeSlotDisabled, showInvoiceModal, invoiceData,
            showCancelOrderModal, cancelOrder_loading, } = this.state
        return (
            <ViewContainer>
                <div className="container patient-plus-bg d-flex align-items-center justify-content-center">
                    <div className="align-self-center main-container">
                        <CancelButton />

                        <ModalSubmit isOpen={showCancelOrderModal} text={I18nManager.isRTL() ? 'هل أنت متأكد من إلغاء الطلب؟' : 'Are you sure about canceling order?'} uri="/assets/img/completed.png"
                            leftButtonText={I18nManager.isRTL() ? 'نعم' : 'YES'} rightButtonText={I18nManager.isRTL() ? 'كلا' : 'NO'} loading={cancelOrder_loading} onRequestClose={() => this.changeState('showCancelOrderModal', false)}
                            onLeftButtonClick={() => this.onYesCancelOrderModal()} onRightButtonClick={() => this.changeState('showCancelOrderModal', false)} />

                        <AssistanteModal isOpen={modalKey ? true : false} onRequestClose={() => this.changeState('modalKey', '')} children={
                            <>
                                {modalKey === 'people' && <div>
                                    <h3 className="modal-title">Peoples</h3>
                                    <select className="form-control mb-10 mb-10" onChange={e => this.onPeopleItemPress(e.target.value)}>
                                        {Peoples.map((item, index) => (
                                            <option key={index} value={index}>{item?.Name} {item?.LastName}</option>
                                        ))}
                                    </select>
                                    <div className="d-flex flex-row align-items-center">
                                        <button className="yesButton" onClick={() => this.onPeopleResponse(true)}>{I18nManager.isRTL() ? 'نعم' : 'Yes'}</button>
                                        <button className="noButton" onClick={() => this.onPeopleResponse(false)}>{I18nManager.isRTL() ? 'كلا' : 'No'}</button>
                                    </div>
                                </div>}
                                {modalKey === 'location' && <div>
                                    <h3 className="modal-title">Locations</h3>
                                    <select className="form-control mb-10 mb-10" onChange={e => this.onLocationItemPress(e.target.value)}>
                                        {Locations.map((item, index) => (
                                            <option key={index} value={index}>{item?.Name}</option>
                                        ))}
                                    </select>
                                    <div className="d-flex flex-row align-items-center">
                                        <button className="yesButton" onClick={() => this.onLocationResponse(true)}>{I18nManager.isRTL() ? 'نعم' : 'Yes'}</button>
                                        <button className="noButton" onClick={() => this.onLocationResponse(false)}>{I18nManager.isRTL() ? 'كلا' : 'No'}</button>
                                    </div>
                                </div>}
                                {modalKey === 'speciality' && <div>
                                    <h3 className="modal-title">Sepcializations</h3>
                                    <select className="form-control mb-10 mb-10" onChange={e => this.onSpecialityItemPress(e.target.value)}>
                                        {Specializations.map((item, index) => (
                                            <option key={index} value={index}>{item?.[I18nManager.isRTL() ? 'ServiceCategoryNameAr' : 'ServiceCategoryName']}</option>
                                        ))}
                                    </select>
                                    <div className="d-flex flex-row align-items-center">
                                        <button className="yesButton" onClick={() => this.onSpecialityResponse(true)}>{I18nManager.isRTL() ? 'نعم' : 'Yes'}</button>
                                        <button className="noButton" onClick={() => this.onSpecialityResponse(false)}>{I18nManager.isRTL() ? 'كلا' : 'No'}</button>
                                    </div>
                                </div>}
                            </>
                        } />
                        <ModalInvoice isOpen={showInvoiceModal} Data={invoiceData} onRequestClose={() => this.changeState('showInvoiceModal', false)} />

                        <section className="msger">
                            {data?.map((item, index) => (
                                <div key={index}>
                                    <div className="chat-group-date-container">
                                        <span className="chat-group-date-text">{item?.date}</span>
                                    </div>
                                    {item?.data?.map((element, el_index) => (
                                        <AssistanteChatMessage key={el_index} data={data} element={element} ConsumerImage={ConsumerImage}
                                            onChoosePeople={() => this.onChoosePeople()} onAmbulanceResponse={value => this.onAmbulanceResponse(value)}
                                            onChooseLocation={() => this.onChooseLocation()}
                                            onChooseSpeciality={(value) => this.onChooseSpeciality(value)}
                                            CalendarSelectedDate={CalendarSelectedDate} TimeSlots={TimeSlots} timeSlotDisabled={timeSlotDisabled}
                                            onDateSelected={e => this.onDateSelected(e.target.value)} onCalendarSlotPress={e => this.onCalendarSlotPress(e.target.value)}
                                            onPaymentPress={() => this.onPaymentPress()}
                                            onShowInvoiceModal={(invoiceData) => { this.changeState('showInvoiceModal', true); this.changeState('invoiceData', invoiceData) }}
                                            onOpenImagePicker={this.onOpenImagePicker} onBankTransferImageUpload={this.onBankTransferImageUpload}
                                        />
                                    ))}
                                </div>
                            ))}
                            {!checkFireDataByType(data, 'OrderCanceled') &&
                                <div className="msger-inputarea">
                                    <div className="nav-item dropdown dropup align-self-center" style={{ flex: 0 }}>
                                        <i className="fa fa-ellipsis-v" data-toggle="dropdown"></i>
                                        <div className="dropdown-menu">
                                            <input type="file" multiple={false} accept="application/pdf"
                                                id="fileupload" className="d-none"
                                                onChange={e => this.onImageFileUpload(e.target.files[0], "file")} />
                                            <span className="dropdown-item" onClick={() => this.onImageFileClick('fileupload')}>{I18nManager.isRTL() ? "الملف" : 'File'}</span>
                                            <input type="file" multiple={false} accept="image/jpeg, image/png, image/jpg"
                                                id="imageupload" className="d-none"
                                                onChange={e => this.onImageFileUpload(e.target.files[0], "image")} />
                                            <span className="dropdown-item" onClick={() => this.onImageFileClick('imageupload')}>{I18nManager.isRTL() ? "الصورة" : 'Image'}</span>
                                            {!checkFireDataByType(data, 'Invoice') &&
                                                <span className="dropdown-item" onClick={() => this.changeState('showCancelOrderModal', true)}>{I18nManager.isRTL() ? 'إلغاء' : 'Cancel'}</span>
                                            }
                                        </div>
                                    </div>
                                    <textarea type="text" onChange={(e) => this.onChangeText(e.target.value)} value={TextMessage} className="msger-input" placeholder={I18nManager.isRTL() ? 'اكتب رسالة' : 'Type a message'} />
                                    <button onClick={() => this.onSubmitSendText()} className="msger-send-btn">{I18nManager.isRTL() ? "إرسال" : "Send"}</button>
                                </div>}
                        </section>
                    </div>
                </div>
            </ViewContainer>
        )
    }
}
