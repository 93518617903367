import React, { useState, useEffect, useContext } from 'react'
import './SpecialityDetails.css';
import I18nManager from '../../../core/I18nManager/I18nManager';
import ViewContainer from '../../../core/routes/view-container/ViewContainer';
import FAQ from '../../../core/routes/faq/FAQ';
import TopFilter from '../../_common/filter/top-filter/TopFilter';
import TopViewBg from '../../_common/top-view-bg/TopViewBg';
import SideFilter from '../../_common/filter/side-filter/SideFilter';
import { useParams } from "react-router-dom";
import FilterContext from '../../../core/store/FilterContext';
import { addon_prefix, getCoordsFromIp, postBookingOrder, postGetDoctorLocationWithProfile, postGetDoctorTimeSlots, postPatientProfile, setDefaultHeader } from '../../../core/api/Api';
import RelatedDoctorItem from '../related-doctor-item/RelatedDoctorItem';
import { removeWhiteSpaceFromString, getCookie, setCookie } from '../../../core/common-service/CommonService';
import Auth from '../../../core/routes/auth/Auth';
import { reception_db_data } from '../../../core/firebase/fire/Fire';
import firebase from '../../../core/firebase/firebase';
import ModalBookingSuccess from '../../_common/modal-booking-success/ModalBookingSuccess';
import StoreContext from '../../../core/store/StoreContext';

var IS_MOUNTED = true
const SpecialityDetails = (props) => {

    const { UserProfileBaseInfo } = useContext(StoreContext)

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [FCM, setFCM] = useState('')
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const { ourSpecialities, setFilterContextValue } = useContext(FilterContext)

    let id = useParams()?.id ?? 0
    let item = {}
    const index = ourSpecialities.findIndex(el => el?.GeneralServiceCategoryID === parseInt(id))
    if (index !== -1) {
        item = ourSpecialities[index]
    }

    const goToPage = (path) => {
        props.history.push(path)
    }

    const onMoreDoctorsClick = () => {
        let result = [...ourSpecialities]
        result.forEach((element, i) => {
            element.active = parseInt(id) === element?.GeneralServiceCategoryID
        });
        setFilterContextValue('ourSpecialities', result)
        goToPage("/doctors-list/clinic")
    }

    const getDoctorLocationWithProfile = () => {
        getCoordsFromIp().then(coords => {
            const obj = {
                Latitude: coords?.data?.location?.lat, Longitude: coords?.data?.location?.lng,
                // 0 is to get all doctors and then filter when choosing speciality
                Radius: null, SpecialityID: id,
                IsRemoteSession: false, IsOnClinic: true
            }
            postGetDoctorLocationWithProfile(obj).then(res => {
                if (IS_MOUNTED) {
                    var data = res.data.data
                    data.ProfileDate = data?.ProfileDate?.sort((a, b) => a?.Profile?.AvailabilityOrder - b?.Profile?.AvailabilityOrder)
                    setData(data?.ProfileDate)
                }
            }).catch(e => { })
        }).catch(e => getDoctorLocationWithProfile())
    }

    const onIsMoreClick = (doctorIndex) => (dayIndex) => {
        let tmpData = [...data]
        tmpData[doctorIndex].DoctorTimeSlots.dateSlots[dayIndex].isMorePressed = !tmpData[doctorIndex]?.DoctorTimeSlots?.dateSlots[dayIndex].isMorePressed
        setData(tmpData)
    }
    const onTimeSlotClick = (doctorIndex) => (dayIndex, slotIndex) => {
        let tmpData = [...data]
        tmpData[doctorIndex].DoctorTimeSlots.dateSlots.forEach((element, i) => {
            element.timeSlots.forEach((el_timeslot, i_timeslot) => {
                el_timeslot.active = (parseInt(dayIndex) === i && parseInt(i_timeslot) === slotIndex)
            });
        });
        setData(tmpData)
    }
    const getDoctorTimeSlots = (doctorIndex, obj) => {
        postGetDoctorTimeSlots(obj).then(res => {
            const response = res.data.data
            if (response?.dateSlots) {
                let tmpData = [...data]
                tmpData[doctorIndex].DoctorTimeSlots = response
                setData(tmpData)
            }
        }).catch(e => { })
    }
    const onPreviousPress = (doctorIndex) => () => {
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID
        const obj = { DoctorID, page: page > 1 ? (page - 1) : page }
        getDoctorTimeSlots(doctorIndex, obj)
    }
    const onNextPress = (doctorIndex) => () => {
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID
        const obj = { DoctorID, page: page + 1 }
        getDoctorTimeSlots(doctorIndex, obj)
    }

    const onCloseSuccessModal = () => {
        setShowSuccessModal(false)
    }

    const getFCMToken = () => {
        return firebase.messaging().getToken()
    }

    const onBookNowPress = (doctorIndex) => (FullName, MobileNo, Password, Email) => {
        const isSlotSelected = data[doctorIndex]?.DoctorTimeSlots?.dateSlots?.find(el => el.timeSlots.find(el => el.active === true))
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID

        if (isSlotSelected) {
            let CouponDetails = {}
            try {
                CouponDetails = JSON?.parse(getCookie('selectedCouponCode'))
            } catch (error) { }
            const DoctorSlotID = isSlotSelected?.timeSlots?.find(el => el.active === true)?.SlotID
            const obj = {
                DoctorID, ClinicID: null,
                Patientlatitude: 0, Patientlongitude: 0,
                DoctorSlotID, IsProduction: addon_prefix === 'cp' ? false : true,
                IsRemoteSession: false, IsOnSite: false, IsOnClinic: true,
                IsSecondOpinion: false, IsEmergency: false, TriggerNotificationID: null,
                IsPaid: false, OrderWithRecepionist: false,
                CouponID: CouponDetails?.CouponID || null, CouponCode: CouponDetails?.CouponCode || null,
                CouponPercentage: CouponDetails?.Percentage || null, CouponAmount: CouponDetails?.Amount || null,
                CouponIsPercent: CouponDetails?.IsPercent || null, CouponIsCampaign: CouponDetails?.IsCampaign || null,
                CouponPaymentTypeID: CouponDetails?.PaymentTypeID || null, CouponServiceProviderID: CouponDetails?.ServiceProviderID || null,
                CouponEndDate: CouponDetails?.EndDate || null
            }
            if (Auth.isAuthenticated()) {
                setLoading(true)
                postBookingOrder(obj).then(async res => {
                    var data = res.data.data
                    if (IS_MOUNTED) {
                        if (res?.data?.StatusCode === 100) {
                            data.Questions = res?.data?.questions
                            const tmp_item = { ...data, ...obj, IsSpecialitySelected: false, IsAssistante: false, ConsumerDetails: UserProfileBaseInfo }
                            await reception_db_data(data.BookingID).update(tmp_item).then(() => {
                                setLoading(false)
                                setShowSuccessModal(true)
                                getDoctorTimeSlots(doctorIndex, { DoctorID, page })
                                // this.props.history.push('/assistant-chat/' + data?.BookingID, tmp_item)
                            }).catch(e => { })
                        } else if (res?.data?.StatusCode === 111) {
                            const message = I18nManager.isRTL() ? 'الطبيب غير متوفر' : 'Doctor isn\'t available'
                            alert(message)
                            setLoading(false)
                        } else {
                            const message = I18nManager.isRTL() ? 'نعتذر، لا يمكنك تنفيذ اكثر من ٣ طلبات في نفس الوقت. يمكنك الغاء اي من الطلبات المتعلقة او الجارية.' :
                                'Sorry, you can\'t place more than 3 order at the same time. you can cancel from the pending orders.'
                            alert(message)
                            setLoading(false)
                        }
                    }
                }).catch(e => { setLoading(false) })
            } else {
                var loginObj = {
                    MobileNo: removeWhiteSpaceFromString(MobileNo), Password, Email,
                    FirstName: FullName.split(' ')[0], LastName: FullName.substr(FullName.indexOf(" ") + 1),
                    IsAppointment: true, IsLogin: true,
                    FCM, IsProduction: addon_prefix === 'cp' ? false : true,
                }
                setLoading(true)
                postPatientProfile(loginObj).then(res => {
                    if (IS_MOUNTED) {
                        const data = res.data
                        if (data.Status === 'Success') {
                            Auth.login(() => {
                                setDefaultHeader(data.Message)
                                setCookie('token', data.Message)
                                onBookNowPress(doctorIndex)(FullName, MobileNo, Password, Email)
                            })
                        } else {
                            setLoading(false)
                        }
                    }
                }).catch((e) => setLoading(false))
            }
        }
    }

    useEffect(() => {
        IS_MOUNTED = true
        getDoctorLocationWithProfile()
        if (firebase.messaging.isSupported()) {
            getFCMToken().then(res => {
                setFCM(res)
            })
        }
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    return (
        <ViewContainer>
            <ModalBookingSuccess isOpen={showSuccessModal} onCloseClick={onCloseSuccessModal} />
            <TopViewBg title={item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]} />
            <TopFilter />
            <div className="all-offers-bg mt-20">
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <SideFilter />
                        </div>
                        <div className="col-9 speciality-details">
                            <img loading="lazy" src="https://www.southtampaimmediatecare.com/wp-content/uploads/2017/02/doctor-appointment.jpg" className="img-fluid" alt="" />
                            <h1 className="speciality-details-header">{item?.[I18nManager.isRTL() ? "titleAt" : "titleEn"]}</h1>
                            {item?.[I18nManager.isRTL() ? "descriptionAr" : "descriptionEn"]?.map((item, index) => (
                                <p key={index} className="speciality-details-content">{item}</p>
                            ))}
                            {item?.list &&
                                <>
                                    <span className="speciality-details-content">{item?.list?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</span>
                                    <ul>
                                        {item?.list?.[I18nManager.isRTL() ? "infoAr" : "infoEn"]?.map((item, index) => (
                                            <li key={index} className="speciality-details-content">{item}</li>
                                        ))}
                                    </ul>
                                </>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="light-grey-bg-color pt-50 pb-50">
                <div className="container d-flex align-items-center flex-column mb-20">
                    <div className="col-12">
                        <div className="d-flex justify-content-center">
                            <div>
                                <div className={"yellow-circle " + (I18nManager.isRTL() ? "ml-15" : "mr-15")}
                                    style={{ left: 'auto', right: 'auto' }}></div>
                                <h4 className="offers-title pl-15 pr-15">{I18nManager.isRTL() ? 'أفضل الاطباء' : 'Related Doctors'}</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9 col-12">
                        <h6 className="offers-subtitle text-center">{I18nManager.isRTL() ? "احجز موعدا مع أفضل طبيب جلدية في المملكة العربية السعودية، اختر من قائمة افضل العيادات الجلدية، واحجز موعدك الآن بنقرة واحدة!"
                            : "Book an appointment now with one of the finest dermatologists in Saudi Arabia, get your appointment now!"}</h6>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {data?.slice(0, 4)?.map((item, index) => (
                            <RelatedDoctorItem key={index} item={item} loading={loading}
                                onIsMoreClick={onIsMoreClick(index)} onTimeSlotClick={onTimeSlotClick(index)}
                                onPreviousPress={onPreviousPress(index)} onNextPress={onNextPress(index)}
                                onBookNowPress={onBookNowPress(index)} />
                        ))}
                    </div>
                    {data?.length > 4 && <div className="d-flex justify-content-center mt-30">
                        <a onClick={onMoreDoctorsClick} rel="noopener noreferrer">
                            <span className="offers-all-offers-button">{I18nManager.isRTL() ? "كل الأطباء" : "More doctors"}</span>
                        </a>
                    </div>}
                </div>
            </div>

            <FAQ />
        </ViewContainer>
    )
}

export default SpecialityDetails