import React, { useEffect, useState, useContext } from 'react'
import { postGetDoctorLocationWithProfile, postGetDoctorTimeSlots, postBookingOrder, addon_prefix, setDefaultHeader, postPatientProfile, getCoordsFromIp } from '../../../core/api/Api'
import './DoctorsList.css';
import I18nManager from '../../../core/I18nManager/I18nManager';
import ViewContainer from '../../../core/routes/view-container/ViewContainer';
import FAQ from '../../../core/routes/faq/FAQ';
import ReactSelect from '../../_common/react-select/ReactSelect';
import TopFilter from '../../_common/filter/top-filter/TopFilter';
import TopViewBg from '../../_common/top-view-bg/TopViewBg';
import SideFilter from '../../_common/filter/side-filter/SideFilter';
import FilterContext from '../../../core/store/FilterContext';
import DoctorItem from './doctor-item/DoctorItem';
import { getCookie, removeWhiteSpaceFromString, setCookie } from '../../../core/common-service/CommonService';
import Auth from '../../../core/routes/auth/Auth';
import StoreContext from '../../../core/store/StoreContext';
import { reception_db_data } from '../../../core/firebase/fire/Fire';
import firebase from '../../../core/firebase/firebase';
import ModalBookingSuccess from '../../_common/modal-booking-success/ModalBookingSuccess';
import { useParams } from "react-router-dom";

var IS_MOUNTED = true
export default function DoctorsList() {
    const { ourSpecialities, filters } = useContext(FilterContext)
    const { UserProfileBaseInfo } = useContext(StoreContext)

    let { type } = useParams()
    const isDoctorOnline = () => {
        return type === 'online'
    }

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [originalData, setOriginalData] = useState([])
    const [pagination, setPagination] = useState([])
    const [FCM, setFCM] = useState('')
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const getDoctorLocationWithProfile = () => {
        getCoordsFromIp().then(coords => {
            let obj = {
                Latitude: coords?.data?.location?.lat, Longitude: coords?.data?.location?.lng,
                // 0 is to get all doctors and then filter when choosing speciality
                Radius: null, SpecialityID: ourSpecialities.find(el => el.active === true)?.GeneralServiceCategoryID,
                IsRemoteSession: isDoctorOnline(), IsOnClinic: !isDoctorOnline()
            }
            postGetDoctorLocationWithProfile(obj).then(res => {
                if (IS_MOUNTED) {
                    var data = res.data.data
                    data.ProfileDate = data?.ProfileDate?.sort((a, b) => a?.Profile?.AvailabilityOrder - b?.Profile?.AvailabilityOrder)
                    setData(data?.ProfileDate)
                    setOriginalData(data?.ProfileDate)
                    applyFilter(data?.ProfileDate)
                }
            }).catch(e => { })
        }).catch(e => getDoctorLocationWithProfile())
    }

    const getFeesRange = async (prevResult) => {
        const range = filters.examinationFee.find(el => el.active === true)
        if (Number.isInteger(range?.from)) {
            return prevResult.filter((el) => (el?.OrderOnlineAmount ?? el?.OrderAmount) >= range?.from && (el?.OrderOnlineAmount ?? el?.OrderAmount) <= range?.to)
        } else {
            return prevResult
        }
    }
    const getExperienceRange = async (prevResult) => {
        const range = filters.yearsOfExperience.find(el => el.active === true)
        if (Number.isInteger(range?.from)) {
            return prevResult.filter((el) => el?.Profile?.Experience >= range?.from && el?.Profile?.Experience <= range?.to)
        } else {
            return prevResult
        }
    }
    const getTitleFilter = async (prevResult) => {
        const occupation = filters.title.find(el => el.active === true)
        if (Number.isInteger(occupation?.OccupationID)) {
            return prevResult.filter((el) => el?.Profile?.OccupationID === occupation?.OccupationID)
        } else {
            return prevResult
        }
    }
    const getNationalityFilter = async (prevResult) => {
        const nationality = filters.nationality.find(el => el.active === true)
        if (Number.isInteger(nationality?.NationalityID)) {
            return prevResult.filter((el) => el?.Profile?.Nationality === nationality?.NationalityID)
        } else {
            return prevResult
        }
    }
    // Board Certificates
    const getEducationLevelFilter = async (prevResult) => {
        const educationLevel = filters.boardCertificates.find(el => el.active === true)
        if (Number.isInteger(educationLevel?.EducationLevelID)) {
            return prevResult.filter((el) => el?.Profile?.EducationLevelID === educationLevel?.EducationLevelID)
        } else {
            return prevResult
        }
    }
    const getGenderFilter = async (prevResult) => {
        const gender = filters.genders.find(el => el.active === true)
        if (Number.isInteger(gender?.id)) {
            return prevResult.filter((el) => el?.Profile?.GenderName === gender?.value)
        } else {
            return prevResult
        }
    }
    const getRatingFilter = async (prevResult) => {
        const rating = filters.ratings.find(el => el.active === true)
        if (Number.isInteger(rating?.value)) {
            return prevResult.filter((el) => el?.Profile?.Rating === rating?.value)
        } else {
            return prevResult
        }
    }
    const applyFilter = (data) => {
        if (data.length > 0) {
            getFeesRange(data).then(res => {
                getExperienceRange(res).then(res => {
                    getTitleFilter(res).then(res => {
                        getGenderFilter(res).then(res => {
                            getRatingFilter(res).then(res => {
                                getEducationLevelFilter(res).then(res => {
                                    getNationalityFilter(res).then(res => {
                                        setData(res)
                                    })
                                })
                            })
                        })
                    })
                })
            })
        }
    }

    const PAGINATION_SIZE = 10
    const onPaginationClick = (index) => (e) => {
        e.preventDefault()
        let arr = [...pagination]
        arr.forEach((element, i) => {
            element.active = i === parseInt(index)
        });
        setPagination(arr)
        window.scrollTo(0, 0)
    }
    const getPaginationNumber = () => {
        let pag = data?.length / PAGINATION_SIZE;
        const dec = pag % 1;
        let res = dec === 0 ? pag : parseInt(pag) + 1
        let arr = Array.from({ length: res }, (el, index) => ({ active: index === 0 }))
        setPagination(arr)
    }
    const findActivePaginationIndex = () => {
        return pagination.findIndex(el => el.active === true)
    }
    const paginatedData = () => {
        return data?.slice(findActivePaginationIndex() * PAGINATION_SIZE, (findActivePaginationIndex() + 1) * PAGINATION_SIZE)
    }

    const onIsMoreClick = (doctorIndex) => (dayIndex) => {
        let tmpData = [...data]
        tmpData[doctorIndex].DoctorTimeSlots.dateSlots[dayIndex].isMorePressed = !tmpData[doctorIndex]?.DoctorTimeSlots?.dateSlots[dayIndex].isMorePressed
        setData(tmpData)
    }
    const onTimeSlotClick = (doctorIndex) => (dayIndex, slotIndex) => {
        let tmpData = [...data]
        tmpData[doctorIndex].DoctorTimeSlots.dateSlots.forEach((element, i) => {
            element.timeSlots.forEach((el_timeslot, i_timeslot) => {
                el_timeslot.active = (parseInt(dayIndex) === i && parseInt(i_timeslot) === slotIndex)
            });
        });
        setData(tmpData)
    }
    const getDoctorTimeSlots = (doctorIndex, obj) => {
        postGetDoctorTimeSlots(obj).then(res => {
            const response = res.data.data
            if (response?.dateSlots) {
                let tmpData = [...data]
                tmpData[doctorIndex].DoctorTimeSlots = response
                setData(tmpData)
            }
        }).catch(e => { })
    }
    const onPreviousPress = (doctorIndex) => () => {
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID
        const obj = { DoctorID, page: page > 1 ? (page - 1) : page }
        getDoctorTimeSlots(doctorIndex, obj)
    }
    const onNextPress = (doctorIndex) => () => {
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID
        const obj = { DoctorID, page: page + 1 }
        getDoctorTimeSlots(doctorIndex, obj)
    }

    const onCloseSuccessModal = () => {
        setShowSuccessModal(false)
    }

    const getFCMToken = () => {
        return firebase.messaging().getToken()
    }

    const onBookNowPress = (doctorIndex) => (FullName, MobileNo, Password, Email) => {
        const isSlotSelected = data[doctorIndex]?.DoctorTimeSlots?.dateSlots?.find(el => el.timeSlots.find(el => el.active === true))
        const page = data[doctorIndex].DoctorTimeSlots.page
        const DoctorID = data[doctorIndex].DoctorTimeSlots.ManPowerID

        if (isSlotSelected) {
            let CouponDetails = {}
            try {
                CouponDetails = JSON?.parse(getCookie('selectedCouponCode'))
            } catch (error) { }
            const DoctorSlotID = isSlotSelected?.timeSlots?.find(el => el.active === true)?.SlotID
            const obj = {
                DoctorID, ClinicID: null,
                Patientlatitude: 0, Patientlongitude: 0,
                DoctorSlotID, IsProduction: addon_prefix === 'cp' ? false : true,
                IsRemoteSession: isDoctorOnline(), IsOnSite: false, IsOnClinic: !isDoctorOnline(),
                IsSecondOpinion: false, IsEmergency: false, TriggerNotificationID: null,
                IsPaid: false, OrderWithRecepionist: false,
                CouponID: CouponDetails?.CouponID || null, CouponCode: CouponDetails?.CouponCode || null,
                CouponPercentage: CouponDetails?.Percentage || null, CouponAmount: CouponDetails?.Amount || null,
                CouponIsPercent: CouponDetails?.IsPercent || null, CouponIsCampaign: CouponDetails?.IsCampaign || null,
                CouponPaymentTypeID: CouponDetails?.PaymentTypeID || null, CouponServiceProviderID: CouponDetails?.ServiceProviderID || null,
                CouponEndDate: CouponDetails?.EndDate || null
            }
            if (Auth.isAuthenticated()) {
                setLoading(true)
                postBookingOrder(obj).then(async res => {
                    var data = res.data.data
                    if (IS_MOUNTED) {
                        if (res?.data?.StatusCode === 100) {
                            data.Questions = res?.data?.questions
                            const tmp_item = { ...data, ...obj, IsSpecialitySelected: false, IsAssistante: false, ConsumerDetails: UserProfileBaseInfo }
                            await reception_db_data(data.BookingID).update(tmp_item).then(() => {
                                setLoading(false)
                                setShowSuccessModal(true)
                                getDoctorTimeSlots(doctorIndex, { DoctorID, page })
                                // this.props.history.push('/assistant-chat/' + data?.BookingID, tmp_item)
                            }).catch(e => { })
                        } else if (res?.data?.StatusCode === 111) {
                            const message = I18nManager.isRTL() ? 'الطبيب غير متوفر' : 'Doctor isn\'t available'
                            alert(message)
                            setLoading(false)
                        } else {
                            const message = I18nManager.isRTL() ? 'نعتذر، لا يمكنك تنفيذ اكثر من ٣ طلبات في نفس الوقت. يمكنك الغاء اي من الطلبات المتعلقة او الجارية.' :
                                'Sorry, you can\'t place more than 3 order at the same time. you can cancel from the pending orders.'
                            alert(message)
                            setLoading(false)
                        }
                    }
                }).catch(e => { setLoading(false) })
            } else {
                var loginObj = {
                    MobileNo: removeWhiteSpaceFromString(MobileNo), Password, Email,
                    FirstName: FullName.split(' ')[0], LastName: FullName.substr(FullName.indexOf(" ") + 1),
                    IsAppointment: true, IsLogin: true,
                    FCM, IsProduction: addon_prefix === 'cp' ? false : true,
                }
                setLoading(true)
                postPatientProfile(loginObj).then(res => {
                    if (IS_MOUNTED) {
                        const data = res.data
                        if (data.Status === 'Success') {
                            Auth.login(() => {
                                setDefaultHeader(data.Message)
                                setCookie('token', data.Message)
                                onBookNowPress(doctorIndex)(FullName, MobileNo, Password, Email)
                            })
                        } else {
                            setLoading(false)
                        }
                    }
                }).catch((e) => setLoading(false))
            }
        }
    }

    useEffect(() => {
        IS_MOUNTED = true
        if (firebase.messaging.isSupported()) {
            getFCMToken().then(res => {
                setFCM(res)
            })
        }
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    useEffect(() => {
        applyFilter(originalData)
    }, [filters])

    useEffect(() => {
        getPaginationNumber()
    }, [data])

    useEffect(() => {
        getDoctorLocationWithProfile()
    }, [ourSpecialities])

    return (
        <ViewContainer>
            <ModalBookingSuccess isOpen={showSuccessModal} onCloseClick={onCloseSuccessModal} />
            <TopViewBg title={I18nManager.isRTL() ? "أفضل الأطباء في السعودية" : "Best Doctors in Saudi"} />
            <TopFilter />
            <div className="container doctor-list-top-pagination-view">
                <span>{I18nManager.isRTL() ? "الصفحة الرئيسية" : "Home Page"}</span>
                <i className={"ml-10 mr-10 fa fa-" + (I18nManager.isRTL() ? "chevron-left" : "chevron-right")}></i>
                <span>{I18nManager.isRTL() ? "كل الأطباء في السعودية" : "All Doctors in Saudi"}</span>
            </div>

            <div className="container doctor-list-sort-header">
                <div className="row">
                    <div className="col-md-3 col-0"></div>
                    <div className="col-md-9 col-12 d-flex align-items-center justify-content-between">
                        <h6>{I18nManager.isRTL() ? ((data?.length || 0) + " عرض كل النتائج") : ("Showing all " + (data?.length || 0) + " result")}</h6>
                        <div className="d-flex align-items-center">
                            <h4>{I18nManager.isRTL() ? "ترتيب حسب" : "Sort by"}</h4>
                            <ReactSelect isSearchable={false} options={filters.sortBy}
                                placeholder={filters.sortBy.find(el => el.active === true)?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}
                                getOptionLabel={item => item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}
                                getOptionValue={item => item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-3">
                        <SideFilter />
                    </div>
                    <div className="col-9">
                        {paginatedData()?.map((item, index) => (
                            <DoctorItem key={index} item={item} loading={loading}
                                onIsMoreClick={onIsMoreClick(index)} onTimeSlotClick={onTimeSlotClick(index)}
                                onPreviousPress={onPreviousPress(index)} onNextPress={onNextPress(index)}
                                onBookNowPress={onBookNowPress(index)}
                            />
                        ))}
                        {pagination?.length > 1 && <nav>
                            <ul className="pagination pagination-sm">
                                {pagination?.map((item, index) => (
                                    <li key={index} className={"page-item " + (item?.active ? "active" : "")} onClick={onPaginationClick(index)}>
                                        <a className="page-link" href="#">{++index}</a>
                                    </li>
                                ))}
                            </ul>
                        </nav>}
                    </div>
                </div>
            </div>

            <FAQ />

        </ViewContainer>
    )
}