import React from 'react';
import I18nManager from '../../../core/I18nManager/I18nManager';

const StarsRating = ({ value }) => {
    return (
        <div className="d-flex align-items-center">
            {Array(5).fill(0).map((item, index) => (
                <i key={index} className={"fa fa-star " + (I18nManager.isRTL() ? (index === 4 ? "" : "ml-5") : (index === 4 ? "" : "mr-5"))} style={{ color: value > index ? '#ffd433' : '#f0f1f3' }}></i>
            ))}
        </div>
    )
}

export default StarsRating