import React, { useEffect } from 'react'
import Modal from 'react-modal';
import { modalStyle } from '../../../../core/styles/custom-styles';

export default function AssistanteModal(props) {

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    return (
        <Modal {...props} style={modalStyle} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
            <div className="p-20">
                {props.children}
            </div>
        </Modal>
    )
}
