import React from 'react'
import { BASE_URL } from '../../../../core/api/Api'
import I18nManager from '../../../../core/I18nManager/I18nManager'
import StarsRating from '../../../_common/stars-rating/StarsRating'
import DoctorTimeslotAppointments from '../../../_common/timeslot-appointments/DoctorTimeslotAppointments'
import DoctorItemTabs from '../doctor-item-tabs/DoctorItemTabs'
import { useHistory } from 'react-router-dom';
import PatientReviewItem from './patient-review-item/PatientReviewItem'

const DoctorItem = ({ item, isDoctorInfo,
    loading, onIsMoreClick, onTimeSlotClick, onPreviousPress, onNextPress, onBookNowPress
}) => {
    let history = useHistory()

    const goToPage = (e) => {
        e.preventDefault()
        history.push('/doctor-info/' + item?.Profile?.ManPowerID)
    }

    const _onIsMoreClick = (index) => () => {
        onIsMoreClick(index)
    }

    const _onTimeSlotClick = (index, el_index) => () => {
        onTimeSlotClick(index, el_index)
    }

    const _onBookNowPress = (FullName, MobileNo, Password, Email) => {
        onBookNowPress(FullName, MobileNo, Password, Email)
    }

    return (
        <div className="doctor-list-item-container">
            <div className="row mb-20">
                <div className="col-md-6 col-12">
                    <div className="d-flex flex-wrap mb-30">
                        <div className="doctor-list-doctor-profile-container">
                            <img loading="lazy" className="rounded-circle" src={item?.Profile?.Image ? (BASE_URL + item?.Profile?.Image) : '/assets/img/doctor-profile-ph.svg'} alt="" />
                        </div>
                        <div className={I18nManager.isRTL() ? "mr-10" : "ml-10"}>
                            <a href="#" onClick={goToPage}>
                                <h5 className="doctor-name">{I18nManager.isRTL() ? ((item?.Profile?.GenderName === "Male" ? 'دكتور' : 'دكتورة') + ' ' + item?.Profile?.FirstNameAr + ' ' + item?.Profile?.LastNameAr) : ('Doctor ' + item?.Profile?.FirstName + ' ' + item?.Profile?.LastName)}</h5>
                            </a>
                            {item?.Profile?.[I18nManager.isRTL() ? 'OccupationName_Ar' : 'OccupationName'] &&
                                <div className="d-flex align-items-center">
                                    <h5 className={"doctor-specialities " + (I18nManager.isRTL() ? "ml-20" : "mr-20")}>{(I18nManager.isRTL() ? "التخصصات" : "Specialities") + " - " + (item?.Profile?.[I18nManager.isRTL() ? 'OccupationName_Ar' : 'OccupationName'])}</h5>
                                    {isDoctorInfo && <div>
                                        <i className="fa fa-eye"></i>
                                        <span className={"doctor-info-doctor-item-visitor-text " + (I18nManager.isRTL() ? "mr-5" : "ml-5")}>355 {I18nManager.isRTL() ? "" : "View(s)"}</span>
                                    </div>}
                                </div>
                            }
                            <div className="d-flex align-items-center">
                                <StarsRating value={item?.Profile?.Rating || 5} />
                                <span className="doctor-rating">{item?.Profile?.Rating || 5} {I18nManager.isRTL() ? "من" : "out of"} 5</span>
                            </div>
                            {!item?.Profile?.Rating && <small>{I18nManager.isRTL() ? "إنضم حديثاً" : "Recently joined"}</small>}
                            <div className="d-flex align-items-center doctor-info-container mt-5">
                                <img loading="lazy" width='15' height='15' src="/assets/img/experience.svg" alt="" />
                                <span className="doctor-info">{I18nManager.isRTL() ? "الخبرة" : "Experience"} : {item?.Profile?.Experience || 0} {I18nManager.isRTL() ? 'سنوات' : 'Years'}</span>
                            </div>
                            <div className="d-flex align-items-center doctor-info-container">
                                <img loading="lazy" width='15' height='15' src="/assets/img/mobile.svg" alt="" />
                                <a href="tel:966550600288">
                                    <span className="doctor-info">{I18nManager.isRTL() ? "رقم الجوال" : "Mobile"}: +966 550 600 288</span>
                                </a>
                            </div>
                            {item?.GoogleDistanceAndDuration?.DistanceText && <div className="d-flex align-items-center doctor-info-container">
                                <img loading="lazy" width='15' height='15' src="/assets/img/time.svg" alt="" />
                                <span className="doctor-info">{I18nManager.isRTL() ? "المسافة" : "Distance"}: {item?.GoogleDistanceAndDuration?.DistanceText}</span>
                            </div>}
                            <div className="d-flex align-items-center doctor-info-container">
                                <img loading="lazy" width='15' height='15' src="/assets/img/fees.svg" alt="" />
                                <span className="doctor-info">{I18nManager.isRTL() ? "كشفية" : "Fees"} : {((item?.OrderOnlineAmount ?? item?.OrderAmount) || 0) + " " + (I18nManager.isRTL() ? 'ريال' : 'SAR')}</span>
                            </div>
                            {item?.Profile?.[I18nManager.isRTL() ? 'NationalityNameAr' : 'NationalityName'] && <div className="d-flex align-items-center doctor-info-container">
                                <img loading="lazy" width='15' height='15' src="/assets/img/nationality.svg" alt="" />
                                <span className="doctor-info">{I18nManager.isRTL() ? "جنسية الطبيب" : "Nationality"} : {item?.Profile?.[I18nManager.isRTL() ? 'NationalityNameAr' : 'NationalityName']}</span>
                            </div>}
                        </div>
                    </div>
                    {isDoctorInfo ?
                        <>
                            <div className="doctor-info-overall-rating">
                                <StarsRating value={5} />
                                <span className="span-1">Overall Rating From 109 Visitors</span>
                                <span className="span-2">View all Reviews</span>
                            </div>
                            <PatientReviewItem />
                        </>
                        : <>
                            <DoctorItemTabs doctorItem={item} />
                            {item?.Clinic?.[0]?.ClinicID &&
                                <div className="d-flex align-items-center doctor-assigned-clinic">
                                    <img loading="lazy" src={item?.Clinic?.[0]?.Image ? (BASE_URL + item?.Clinic?.[0]?.Image) : '/assets/img/clinic.png'} alt="" />
                                    <span>{item?.Clinic?.[0]?.[I18nManager.isRTL() ? "ClinicNameAr" : "ClinicName"]}</span>
                                </div>
                            }
                        </>
                    }
                </div>
                <div className="col-md-6 col-12 pl-0 pr-0">
                    <DoctorTimeslotAppointments style={{ boxShadow: 'none' }}
                        data={item?.DoctorTimeSlots?.dateSlots || []}
                        loading={loading} onIsMoreClick={_onIsMoreClick}
                        onPreviousPress={onPreviousPress} onNextPress={onNextPress}
                        onTimeSlotClick={_onTimeSlotClick} onBookNowPress={_onBookNowPress}
                    />
                </div>
            </div>
        </div>
    )
}
export default DoctorItem