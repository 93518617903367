import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import I18nManager from '../../../core/I18nManager/I18nManager';
import { modalStyle } from '../../../core/styles/custom-styles';
import LoadingSpinner from '../../_common/loading-spinner/LoadingSpinner';

export default function ModalWaitingConfirmation(props) {

    const [showCancelButton, setShowCancelButton] = useState(true)

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    const { loading, text } = props

    return (
        <Modal {...props} style={modalStyle} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
            <div className="d-flex flex-column align-items-center">
                <LoadingSpinner />
                <h3>{I18nManager.isRTL() ? 'أرجو الإنتظار' : 'Please Wait'}</h3>
                <p>{text}</p>
                {showCancelButton &&
                    <button style={{ width: '100%' }} onClick={() => setShowCancelButton(false)} disabled={loading} className="modal-button-yes">{I18nManager.isRTL() ? 'الغاء الطلب' : 'Cancel Order'}</button>
                }
                {!showCancelButton &&
                    <div className="d-flex flex-row" style={{ width: '100%' }}>
                        <button disabled={loading} onClick={props.onCancelOrderPress} className="modal-button-no">{I18nManager.isRTL() ? 'الغاء الطلب' : 'Cancel Order'}</button>
                        <button disabled={loading} onClick={() => setShowCancelButton(true)} className="modal-button-yes">{I18nManager.isRTL() ? 'تابع' : 'Continue'}</button>
                    </div>
                }
            </div>
        </Modal >
    )
}
