import React from 'react'
import './CancelButton.css'
import { useHistory } from "react-router-dom";


export default function CancelButton(props) {
    let history = useHistory();
    return (
        <div className="d-flex cancel-container" onClick={() => history.goBack()}>
            <i className="fa fa-close"></i>
        </div>
    )
}
