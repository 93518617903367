import React, { Component } from 'react'
import { getHyperPayPaymentStatus, postGetTotalAmount, postNotificationForChat, postGetPatientOrderByID, postActivateCoupons, postInitialHyperPayRequest, postAcceptAddonRequest, postGetPaymentMethods, addon_prefix, postPayAmount, postReOpenBooking, BASE_URL } from '../../../core/api/Api';
import { colors } from '../../../core/colors/Colors';
import CancelButton from '../../_common/cancel-button/CancelButton';
import ModalSubmit from '../../_common/modal-submit/ModalSubmit';
import './ChatPayment.css';
import ModalCouponList from './modal-coupon-list/ModalCouponList';
import firebase from '../../../core/firebase/firebase';
import { BankTransferOpenOrderMessage, PaymentAddedOpenOrderMessage, HomeVisitAddonAcceptMessage } from '../../../core/firebase/automated-message/AutomatedMessage';
import { reception_db_chat, db_chat } from '../../../core/firebase/fire/Fire';
import I18nManager from '../../../core/I18nManager/I18nManager';
import ViewContainer from '../../../core/routes/view-container/ViewContainer';
import { deleteCookie, setLocalStorageItem } from '../../../core/common-service/CommonService';

var IS_MOUNTED = true
const DB_ENTITY_ID = addon_prefix === 'admin' ? '8acda4c97292f4710172b165fa3a1021' : '8ac7a4ca71cbc59f0171d9f6f05a1316'
const MADA_ENTITY_ID = addon_prefix === 'admin' ? '8acda4c97292f4710172b166d275102a' : '8ac7a4ca71cbc59f0171d9f801e7131b'

export default class ChatPayment extends Component {

    state = {
        loading: false,
        PatientCards: [],
        WalletMoney: 0,

        CouponCode: '',
        IsCouponValid: -1,
        showCouponModal: false,

        CouponDetails: {},
        CampaignCoupons: [],
        OriginalPrice: 0,

        showModalAlert: false, alertMessage: '', alertType: '',

        data: [],

        checkoutDetails: {},
        paymentDetails: {},

        showSubmitModal: false,

        TotalPrice: 0,
        ConsumerID: 0,
        ManPowerID: 0,
        BookingID: 0,

        IsRemoteBooking: false,

        PaymentMethods: [
            { id: 1, name: 'Mada', code: 'MADA', uri: '/assets/img/mada.png', style: { width: 84, height: 28 }, entityId: MADA_ENTITY_ID, paymentType: 'DB', active: true },
            { id: 5, name: 'Visa', code: 'VISA', uri: '/assets/img/visa.png', style: { width: 84, height: 28 }, entityId: DB_ENTITY_ID, paymentType: 'DB', active: false },
            { id: 6, name: 'Master', code: 'MASTER', uri: '/assets/img/mastercard.png', style: { width: 42, height: 28 }, entityId: DB_ENTITY_ID, paymentType: 'DB', active: false },
            { id: 7, name: 'Cash On Delivery', code: 'COD', uri: '/assets/img/cash-on-delivery.png', style: { width: 70, height: 28 }, active: false },
        ],
        WireTransferDetails: {},
        PaymentType: '',
    }

    changeState(key, text) {
        this.setState({ [key]: text })
    }

    getActivePaymentMethod() {
        const { PaymentMethods } = this.state
        return PaymentMethods.find(el => el.active === true)
    }

    getPatientOrderByID(BookingID) {
        this.setState({ loading: true })
        postGetPatientOrderByID(BookingID).then(res => {
            var data = res.data.data[0]
            if (IS_MOUNTED) {
                this.setState({ loading: false })
                if (data?.CouponCode) {
                    this.onSubmitCouponCode({ CouponCode: data?.CouponCode })
                }
            }
        }).catch(e => this.getPatientOrderByID(BookingID))
    }

    SendNotification() {
        const { BookingID } = this.state
        postNotificationForChat({ BookingID }).then(res => {
        }).catch(e => { })
    }

    getTotalAmount(BookingID) {
        postGetTotalAmount({ BookingID }).then(res => {
            if (IS_MOUNTED) {
                this.setState({ loading: false })
                this.setState({ OriginalPrice: res.data.data || 0 })
                this.setState({ TotalPrice: res.data.data || 0 }, () => {
                    this.changeActivePaymentMethod(0)
                })
            }
        }).catch(e => this.getTotalAmount())
    }

    getPaymentMethods() {
        const { BookingID } = this.state
        postGetPaymentMethods({ BookingID }).then(res => {
            if (IS_MOUNTED) {
                const data = res.data.data
                const index = data?.findIndex(el => el.PaymentMethodID === 2)
                this.setState({ WireTransferDetails: data[index] })
            }
            // For now because there is no other payment method
        }).catch(e => this.getPaymentMethods())
    }

    changeActivePaymentMethod(index) {
        const { TotalPrice, BookingID, PaymentType } = this.state
        const PaymentMethods = this.state.PaymentMethods.slice()
        if (PaymentMethods.length > 0) {
            this.setState({ loading: true })
            PaymentMethods.forEach((element, i) => {
                element.active = parseInt(index) === i
            });
            this.setState({ PaymentMethods })
            if (PaymentMethods[index]?.id === 7) {
                this.setState({ loading: false })
            } else {
                var url_param = {
                    entityId: this.getActivePaymentMethod()?.entityId, amount: TotalPrice?.toFixed(2), currency: 'SAR', paymentType: this.getActivePaymentMethod().paymentType,
                    testMode: addon_prefix === 'admin' ? undefined : 'EXTERNAL',
                    merchantTransactionId: BookingID + '_' + PaymentType + '_' + new Date().getTime(),
                    'customer.email': addon_prefix === 'admin' ? 'info@docnow247.com' : 'yanal.aladham@docnow247.com',
                }
                this.getInitialHyerpayRequest(url_param)
            }
        }
    }
    changeActivePaymentToWireTransfer() {
        const PaymentMethods = this.state.PaymentMethods.slice()
        PaymentMethods.forEach(element => {
            element.active = false
        });
        this.setState({ PaymentMethods })
    }

    // Start HyperPay
    loadHyperPayScript(checkoutId) {
        try {
            const script = document.createElement("script");
            script.src = "https://" + (addon_prefix === 'admin' ? '' : 'test.') + "oppwa.com/v1/paymentWidgets.js?checkoutId=" + checkoutId + "";
            script.async = true;
            document.body.appendChild(script);

            var wpwlOptions = {
                style: "plain",
                locale: I18nManager.isRTL() ? "ar" : "en",
            }
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.innerHTML = "var wpwlOptions=" + JSON.stringify(wpwlOptions)
            document.body.appendChild(s);
        } catch (error) { }
    }
    getInitialHyerpayRequest(data) {
        clearTimeout(this.timeout)
        this.setState({ checkoutDetails: {} }, () => {
            const { PaymentMethods, BookingID, PaymentType, CouponDetails, TotalPrice } = this.state
            const activePaymentID = PaymentMethods.find(el => el.active === true)?.id
            postInitialHyperPayRequest(data).then(res => {
                if (IS_MOUNTED) {
                    this.setState({ loading: false })
                    this.setState({ checkoutDetails: res.data }, () => {
                        this.loadHyperPayScript(res.data.id)
                    })
                }
            }).catch(e => this.getInitialHyerpayRequest(data))
        })
    }
    onNavigationStateChange() {
        const { BookingID, checkoutDetails, PaymentMethods, CouponDetails, TotalPrice } = this.state
        this.setState({ loading: true })
        getHyperPayPaymentStatus(checkoutDetails.id, this.getActivePaymentMethod()?.entityId).then(res => {
            // '200.300.404'==> amount is 0
            if (res?.data?.result?.code === (addon_prefix === 'admin' ? '000.000.000' : '000.100.112')) {
                var paymentDetails = res.data
                delete paymentDetails.resultDetails
                if (IS_MOUNTED) {
                    this.setState({ paymentDetails })
                }
                const PaymentObj = {
                    BookingID, PaymentMethodID: this.getActivePaymentMethod()?.id || 2,
                    OnlinePaymentPaid: PaymentMethods.find(el => el.active === true) ? true : false,
                    IsDiscountPercent: CouponDetails?.IsPercent || null, CouponCode: CouponDetails?.CouponCode || null,
                    DiscountValue: CouponDetails?.Amount || CouponDetails?.Percentage || null, CouponID: CouponDetails?.CouponID || null, TotalPrice: TotalPrice
                }
                this.checkoutAmount(PaymentObj)
            } else {
                if (IS_MOUNTED) {
                    // alert(I18nManager.isRTL() ? 'خطأ اثناء تتفيذ عملية الدفع' : 'Error While Proceeding Payment')
                    this.setState({ loading: false })
                }
            }
        }).catch(e => { })
    }
    // End HyperPay

    onSubmitCouponCode(item) {
        this.setState({ showCouponModal: false, loading: true, CouponCode: item?.CouponCode })
        const { OriginalPrice, BookingID } = this.state
        const params = this.props.location.state
        postActivateCoupons({ BookingID, coupon: item?.CouponCode }).then(res => {
            if (IS_MOUNTED) {
                var data = res?.data?.data
                this.setState({ CouponDetails: data })
                this.setState({ loading: false })
                if (data) {
                    this.setState({ IsCouponValid: 1 })
                    if (data?.IsPercent) {
                        this.setState({ TotalPrice: OriginalPrice - ((OriginalPrice * data.Percentage) / 100) })
                    } else if (data?.Amount) {
                        this.setState({ TotalPrice: OriginalPrice - data.Amount })
                    }
                    this.timeout = setTimeout(() => {
                        if (this.getActivePaymentMethod()?.id) {
                            this.changeActivePaymentMethod(this.state.PaymentMethods.findIndex(el => el.active === true))
                        }
                    }, 100);
                } else {
                    this.setState({ IsCouponValid: 0 })
                    this.setState({ TotalPrice: params?.TotalPrice })
                }
                this.setState({ loading: false })
            }
        }).catch(e => { this.setState({ TotalPrice: params?.TotalPrice, loading: false }) })
    }

    onRemoveCouponCode = () => {
        this.setState({ CouponCode: '', IsCouponValid: -1, TotalPrice: this.state.OriginalPrice, CouponDetails: {} }, () => {
            this.changeActivePaymentMethod(0)
        })
    }

    async OpenOrderRequest() {
        const { ConsumerID, ManPowerID, BookingID, IsRemoteBooking, TotalPrice, paymentDetails, PaymentMethods, WireTransferDetails } = this.state
        const obj = {
            InvoiceID: BookingID,
            Date: firebase.database.ServerValue.TIMESTAMP,
            DueDate: firebase.database.ServerValue.TIMESTAMP,
            Services: [
                {
                    Name: IsRemoteBooking ? { en: 'Medical Consultation', ar: 'إستشارة طبية اتصالية' } : { en: 'Home Visit', ar: 'زيارة منزلية' },
                    Price: TotalPrice?.toFixed(2) || 0,
                    Date: firebase.database.ServerValue.TIMESTAMP
                },
            ],
        }
        if (!PaymentMethods.find(el => el.active === true)) {
            WireTransferDetails.paymentBrand = 'BANKTRANSFER'
            obj.PaymentDetails = WireTransferDetails
        } else {
            if (PaymentMethods.find(el => el.active === true)?.id !== 7) {
                obj.PaymentDetails = paymentDetails
            }
            if (TotalPrice === 0) {
                const PaymentDetails = { paymentBrand: PaymentMethods.find(el => el.active === true).code }
                obj.PaymentDetails = PaymentDetails
            }
            if (PaymentMethods.find(el => el.active === true)?.id === 7) {
                const PaymentDetails = { paymentBrand: 'COD' }
                obj.PaymentDetails = PaymentDetails
            }
        }

        // If Home Visit
        if (!IsRemoteBooking) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const location = { latitude: position.coords.latitude, longitude: position.coords.longitude }
                await this.SendPatientFireMessage(null, null, location, 'Location')
            }, (error) => { });
        }
        await this.SendPatientFireMessage(null, null, obj, 'Invoice')
        await this.SendPatientFireMessage(null, BankTransferOpenOrderMessage(), null, 'PatientPaymentAdded')
        const data = this.state.data.slice()
        const cases = ['WaitingPatientPayment']
        cases.forEach(async case_el => {
            await data.forEach(async element => {
                var item = element.data.find(el => el.Type === case_el)
                if (item) {
                    var key = item.key
                    item.date = null
                    item.key = null
                    item.Type = null
                    await reception_db_chat(BookingID).child(key).update(item)
                }
            });
        });
        // window.history.back()
        this.props.history.push('/#')
        if (IS_MOUNTED) {
            await this.setState({ loading: false })
        }
    }

    async ReopenOrderRequest() {
        const { ConsumerID, ManPowerID, BookingID } = this.state
        const params = this.props.location.state
        const obj = { BookingID }
        postReOpenBooking(obj).then(async res => {
            params.data.forEach(async element => {
                var item = element.data.find(el => el.Type === 'OrderCompleted')
                if (item) {
                    var key = item.key
                    item.date = null
                    item.key = null
                    item.Type = null
                    await db_chat(BookingID).child(key).update(item)
                }
            });
            await this.SendPatientFireMessage(null, 'Payment has been processed successfully for Reopening the order.', null, 'PatientPaymentAdded')
            if (IS_MOUNTED) {
                this.setState({ loading: false })
                this.timeout = setTimeout(() => {
                    window.history.back()
                }, 500);
            }
        }).catch(e => this.setState({ loading: false }))
    }

    checkoutAmount(PaymentObj) {
        const { PaymentType, BookingID, ConsumerID, ManPowerID, data } = this.state
        const params = this.props.location.state
        this.setState({ loading: true })
        if (PaymentType === 'OpenOrder') {
            postPayAmount(PaymentObj).then(res => {
                this.OpenOrderRequest()
                if (IS_MOUNTED) {
                    this.setState({ showSubmitModal: false })
                }
            }).catch(e => this.setState({ loading: false }))
        } else if (PaymentType === 'AddonRequest') {
            const addonObj = { BookingID, accepted: true, ...params.Addon }
            postAcceptAddonRequest(addonObj).then(async res => {
                const key = params?.AddonSelectedElement?.key
                var item = params?.AddonSelectedElement
                item.key = null
                item.date = null
                item.Type = 'AddonAccepted'
                await db_chat(BookingID).child(key).update(item)
                var InvoiceItem = data.find(el => el.data.find(item => item.Type === 'Invoice'))
                if (InvoiceItem) {
                    InvoiceItem = InvoiceItem.data.find(el => el.Type === 'Invoice')
                    var services = await InvoiceItem.Data.Services
                    await services.push({
                        Name: { en: 'Addon Service: ' + params?.Addon?.GeneralServiceAddonName, ar: 'الخدمة الاضافية: ' + params?.Addon?.GeneralServiceAddonName },
                        Price: params?.Addon?.AddonPrice,
                        Date: firebase.database.ServerValue.TIMESTAMP
                    })
                    InvoiceItem.Data.Services = await services
                    const key = InvoiceItem.key
                    InvoiceItem.key = null
                    InvoiceItem.CreatedDate = firebase.database.ServerValue.TIMESTAMP
                    await db_chat(BookingID).child(key).update(InvoiceItem)
                }
                // await this.SendPatientFireMessage(null, HomeVisitAddonAcceptPaymentMessage(params?.Addon?.GeneralServiceAddonName), null, 'AddonServicePaymentAdded')
                await this.SendPatientFireMessage(null, HomeVisitAddonAcceptMessage(params?.Addon?.GeneralServiceAddonName), null, null)
                postPayAmount(PaymentObj).then(res => {
                    this.timeout = setTimeout(() => {
                        window.history.back()
                    }, 500);
                    if (IS_MOUNTED) {
                        this.setState({ showSubmitModal: false })
                    }
                }).catch(e => this.setState({ loading: false }))
            }).catch(e => this.setState({ loading: false }))
        } else if (PaymentType === 'ReopenOrder') {
            postPayAmount(PaymentObj).then(res => {
                this.ReopenOrderRequest()
                if (IS_MOUNTED) {
                    this.setState({ showSubmitModal: false })
                }
            }).catch(e => this.setState({ loading: false }))
        }

        // postPayAmount(PaymentObj).then(res => {
        //     if (PaymentType === 'OpenOrder') {
        //         this.OpenOrderRequest()
        //     } else if (PaymentType === 'AddonRequest') {
        //         this.AcceptAddonRequest()
        //     } else if (PaymentType === 'ReopenOrder') {
        //         this.ReopenOrderRequest()
        //     }
        //     this.setState({ showSubmitModal: false })
        // }).catch(e => this.setState({ loading: false }))
    }

    onSubmit() {
        const { BookingID, CouponDetails, PaymentMethods, TotalPrice } = this.state
        const PaymentObj = {
            BookingID, PaymentMethodID: this.getActivePaymentMethod()?.id || 2,
            OnlinePaymentPaid: false,
            IsDiscountPercent: CouponDetails?.IsPercent || null, CouponCode: CouponDetails?.CouponCode || null,
            DiscountValue: CouponDetails?.Amount || CouponDetails?.Percentage || null, CouponID: CouponDetails?.CouponID || null, TotalPrice: TotalPrice
        }
        this.checkoutAmount(PaymentObj)
    }

    SendPatientFireMessage(Text, AutoText, Data, Type) {
        const { BookingID, ConsumerID, ManPowerID } = this.state
        this.SendNotification()
        return reception_db_chat(BookingID).push({
            SenderID: ConsumerID,
            ConsumerID,
            ManPowerID,
            Text,
            AutoText,
            Type,
            Data,
            CreatedDate: firebase.database.ServerValue.TIMESTAMP,
        });
    }

    componentWillUnmount() {
        clearTimeout(this.timeout)
        clearInterval(this.interval)
        IS_MOUNTED = false
    }

    componentDidMount() {
        deleteCookie('PARAM')
        IS_MOUNTED = true
        const params = this.props.location.state
        this.interval = setInterval(() => {
            setLocalStorageItem('PARAM', JSON.stringify(params))
        }, 1000);
        this.setState({ loading: true })

        const PaymentMethods = this.state.PaymentMethods.slice()
        if (params?.PaymentNotRequired) {
            PaymentMethods.splice(0, 3)
            const applePayIndex = PaymentMethods.findIndex(el => el.id === 4)
            PaymentMethods.splice(applePayIndex, 1)
        }
        if (params?.IsRemoteBooking === true) {
            const index = PaymentMethods.findIndex(el => el.id === 7)
            PaymentMethods.splice(index, 1)
        }
        this.setState({ PaymentMethods })

        this.getPatientOrderByID(params?.BookingID)

        this.setState({
            PaymentType: params?.PaymentType,
            BookingID: params?.BookingID, ConsumerID: params?.ConsumerID, ManPowerID: params?.ManPowerID,
            data: params?.data, IsRemoteBooking: params?.IsRemoteBooking,
        })

        if (params?.PaymentType === "AddonRequest") {
            this.setState({ OriginalPrice: params?.TotalPrice })
            this.setState({ TotalPrice: params?.TotalPrice }, () => {
                this.changeActivePaymentMethod(0)
            })
        } else {
            this.getTotalAmount(params?.BookingID)
        }

        this.getPaymentMethods()
    }

    render() {
        const { BookingID, ConsumerID, ManPowerID, PaymentType, data, IsRemoteBooking, paymentDetails,
            loading, CouponCode, showSubmitModal, IsCouponValid, CouponDetails,
            TotalPrice, OriginalPrice, checkoutDetails, PaymentMethods, showCouponModal,
            showModalAlert, alertType, alertMessage, WireTransferDetails } = this.state
        return (
            <ViewContainer>
                <div className="container patient-plus-bg d-flex align-items-center justify-content-center">
                    <ModalCouponList isOpen={showCouponModal} onRequestClose={() => this.changeState('showCouponModal', false)}
                        onApplyPress={item => this.onSubmitCouponCode(item)} loading={loading}
                    />
                    <ModalSubmit isOpen={showSubmitModal} text={I18nManager.isRTL() ? 'تأكيد الدفع' : 'Confirm Payment'} uri="/assets/img/wallet-colored.png"
                        leftButtonText={I18nManager.isRTL() ? 'تأكيد' : 'Confirm'} rightButtonText={I18nManager.isRTL() ? 'إلغاء' : 'Cancel'} loading={loading} onRequestClose={() => this.changeState('showSubmitModal', false)}
                        onLeftButtonClick={() => this.onSubmit()} onRightButtonClick={() => this.changeState('showSubmitModal', false)}
                    />
                    <div className="align-self-center main-container">
                        <div className="p-20">
                            <CancelButton />
                            <h3 className="header">{I18nManager.isRTL() ? "طرق الدفع" : "Payment Methods"}</h3>
                        </div>
                        <div className="d-flex flex-row align-items-center justify-content-between payment-methods-container">
                            {PaymentMethods.map((item, index) => (
                                <div onClick={() => this.changeActivePaymentMethod(index)} key={index} className={"pl-5 pr-5 d-flex align-items-center " + (item.active ? "active-payment-method-item" : "inactive-payment-method-item")}>
                                    <img src={item.uri} width={item.style.width} height={item.style.height} alt="" />
                                </div>
                            ))}
                            <div onClick={() => this.changeActivePaymentToWireTransfer()} className={"pl-5 pr-5 d-flex align-items-center " + (!PaymentMethods.find(el => el.active === true) ? "active-payment-method-item" : "inactive-payment-method-item")}>
                                <img src="/assets/img/wire-transfer.png" width="36" height="36" alt="" />
                            </div>
                        </div>
                        <div className="p-20">
                            <div className="row d-flex flex-row align-items-center pl-20 pr-20">
                                <div>
                                    <div className="d-flex flex-row">
                                        <span className="text-secondary">{I18nManager.isRTL() ? 'المبلغ الاجمالي' : 'Subtotal'} :</span>
                                        <span className="ml-10 mr-10 text-secondary">{(OriginalPrice || 0)?.toFixed(2)}</span>
                                        <span className="text-secondary">{I18nManager.isRTL() ? 'ريال سعودي' : 'SAR'}</span>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <span className="text-secondary">{I18nManager.isRTL() ? 'خصم' : 'Discount'} :</span>
                                        <span className="ml-10 mr-10 text-secondary">{CouponDetails?.IsPercent ? ((CouponDetails?.Percentage || 0)?.toFixed(2) + '%') : (CouponDetails?.Amount || 0)?.toFixed(2)}</span>
                                        <span className="text-secondary">{I18nManager.isRTL() ? 'ريال سعودي' : 'SAR'}</span>
                                        <span className="ml-10 mr-10 text-success">{(CouponDetails?.IsPercent ? (OriginalPrice * (CouponDetails?.Percentage / 100))?.toFixed(2) : CouponDetails?.Amount) || 0?.toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className="d-flex flex-row align-items-center">
                                    <div className="coupon-code-text-container" onClick={this.onRemoveCouponCode}>
                                        <span className="coupon-code-text">{CouponCode || "Enter Promo Code"}</span>
                                    </div>
                                    <div className={"ml-5 mr-5 " + (IsCouponValid === 1 ? "valid-coupon-status-container" : (IsCouponValid === 0 ? "invalid-coupon-status-container" : "coupon-status-container"))}>
                                        <i className={"fa " + ((IsCouponValid === 1 || IsCouponValid === -1) ? "fa-check" : "fa-times") + " fa-lg text-white"}></i>
                                    </div>
                                    <img onClick={() => this.changeState('showCouponModal', true)} className="ml-10 mr-5" src="/assets/img/promo-code-gift.png" width="32" height="32" alt="" />
                                    <span style={{ color: colors.mainYellow }}>{I18nManager.isRTL() ? 'كود هدية' : 'Gift Code'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="total-price-container d-flex flex-row">
                            <span className="text-white">{I18nManager.isRTL() ? 'القيمة الاجمالية' : 'Total Charge'} :</span>
                            <span className="ml-10 mr-10 text-white">{(TotalPrice || 0)?.toFixed(2)}</span>
                            <span className="text-white">{I18nManager.isRTL() ? 'ريال سعودي' : 'SAR'}</span>
                        </div>
                        {(checkoutDetails?.buildNumber && TotalPrice > 0 && PaymentMethods.find(el => el.active === true) && PaymentMethods.find(el => el.active === true).id !== 7) &&
                            <div className="hyperpay-container">
                                <form id="form" className="paymentWidgets" data-brands={"" + PaymentMethods.find(el => el.active === true).code + ""}
                                    // action="javascript:void(0);"
                                    // action={"https://" + (addon_prefix === 'admin' ? '' : 'test.') + "oppwa.com?resourcePath=/v1/checkouts/" + checkoutDetails?.id + "/payment"}
                                    action={"/chat-payment-verification?all_params=" +
                                        encodeURIComponent(JSON.stringify({
                                            BookingID,
                                            ConsumerID,
                                            ManPowerID,
                                            checkoutDetails,
                                            CouponDetails,
                                            TotalPrice,
                                            PaymentType,
                                            IsRemoteBooking,
                                            paymentDetails,
                                            ActivePaymentID: this.getActivePaymentMethod()?.id
                                            // data,
                                            // params: this.props.location.state,
                                        }))}>
                                </form>
                            </div>
                        }
                        {!PaymentMethods.find(el => el.active === true) &&
                            <div className="p-20">
                                <img className="mb-10" src={BASE_URL + WireTransferDetails?.Image} width="164" height="48" alt="" />
                                <div className="form-group">
                                    <label htmlFor="iban">{I18nManager.isRTL() ? 'الايبان' : 'IBAN'}</label>
                                    <input id="iban" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'الايبان' : 'IBAN Number'} disabled defaultValue={WireTransferDetails?.Val_} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="account-number">{I18nManager.isRTL() ? 'رقم الحساب' : 'Account Number'}</label>
                                    <input id="account-number" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'رقم الحساب' : 'Account Number'} disabled defaultValue={WireTransferDetails?.AccountNumber} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="bank-name">{I18nManager.isRTL() ? 'اسم البنك' : 'Bank Name'}</label>
                                    <input id="bank-name" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'اسم البنك' : 'Bank Name'} disabled defaultValue={WireTransferDetails?.NameOfBank} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="company-name">{I18nManager.isRTL() ? 'اسم الشركة' : 'Company Name'}</label>
                                    <input id="company-name" type="text" className="form-control" placeholder={I18nManager.isRTL() ? 'اسم الشركة' : 'Company Name'} disabled defaultValue={WireTransferDetails?.HolderName} />
                                </div>
                            </div>
                        }
                        {PaymentMethods.find(el => el.active === true)?.id === 7 &&
                            <div className="p-20">
                                <p>{I18nManager.isRTL() ? 'اختيار خيار الدفع عن الاستلام و الاستمرار بتنفيذ العملية سيتم اصدار فاتورة الطلب، و سيقوم الطبيب بتحصيل المبلغ عند الانتهاء من الزيارة.\n\nالرجاء الاستمرار بتنفيذ عملية الدفع لبدء طلب الزيارة مع الطبيب الذي قمت باختياره.' :
                                    'Proceeding COD payment will officially start the order and Doctor will collect the bill amount upon the visit completion.\n\nPlease proceed the payment to initiate your order with the selected Doctor.'}</p>
                            </div>
                        }
                        {(!PaymentMethods.find(el => el?.active === true) || PaymentMethods.find(el => el.active === true)?.id === 7) && TotalPrice > 0 &&
                            <button className="coupon-apply-button" onClick={() => this.changeState('showSubmitModal', true)}>{!PaymentMethods.find(el => el.active === true) ? (I18nManager.isRTL() ? 'إصدار الفاتورة' : 'Get Invoice') : (I18nManager.isRTL() ? 'تأكيد الدفع' : 'Comfirm Payment')}</button>
                        }
                    </div>
                </div>
            </ViewContainer>
        )
    }
}
