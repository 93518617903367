import React, { useEffect, useContext } from 'react';
import { Route, Switch, BrowserRouter, useLocation } from 'react-router-dom';
import './App.css'
import './DataTable.css'
import Home from './components/home/Home';
import { ProtectedRoute } from './core/routes/protected-routes/ProtectedRoutes';
import Login from './components/login/Login';
import MyBookings from './components/my-bookings/MyBookings';
import NewAppointment from './components/new-appointment/NewAppointment';
import Auth from './core/routes/auth/Auth';
import MyPeople from './components/my-people/MyPeople';
import MyPayments from './components/my-payments/MyPayments';
import MyLocations from './components/my-location/MyLocations';
import Faq from './components/faq/Faq';
import AddPeople from './components/my-people/add-people/AddPeople';
import EditPeople from './components/my-people/edit-people/EditPeople';
import AddLocation from './components/my-location/add-location/AddLocation';
import EditLocation from './components/my-location/edit-location/EditLocation';
import MedicalReports from './components/medical-reports/MedicalReports';
import AddMedicalReport from './components/medical-reports/add-medical-report/AddMedicalReport';
import Profile from './components/profile/Profile';
import DoctorsList from './components/new-appointment/doctors-list/DoctorsList';
import ClinicsList from './components/new-appointment/clinics-list/ClinicsList';
import ClinicInfo from './components/new-appointment/clinic-info/ClinicInfo';
import EditMedicalReport from './components/medical-reports/edit-medical-report/EditMedicalReport';
import AssistanteChat from './components/chat/assistante-chat/AssistanteChat';
import ChatPayment from './components/chat/chat-payment/ChatPayment';
import OnlineChat from './components/chat/online-chat/OnlineChat';
import OnsiteChat from './components/chat/onsite-chat/OnsiteChat';
import ChatPaymentVerification from './components/chat/chat-payment/ChatPaymentVerification';
import Notifications from './components/notifications/Notifications';
import CouponCode from './components/coupon-code/CouponCode';
import 'moment/locale/ar';
import 'moment/locale/en-ca';
import Whatsapp from './core/routes/navbar/whatsapp/Whatsapp';
import AutoPaymentRedirect from './components/chat/assistante-chat/AutoPaymentRedirect';
import PrivacyPolicy from './components/privacy-policy/PrivacyPolicy';
import TermsConditions from './components/terms-conditions/TermsConditions';
import StoreDownload from './core/routes/navbar/store-download/StoreDownload';
import ClinicDetails from './components/new-appointment/clinics-list/clinic-details/ClinicDetails';
import AboutUs from './components/about-us/AboutUs';
import AllOffers from './components/all-offers/AllOffers';
import AllSpecialities from './components/all-specialities/AllSpecialities';
import SignUp from './components/login/SignUp';
import SpecialityDetails from './components/all-specialities/speciality-details/SpecialityDetails';
import PageNotFound from './core/routes/page-not-found/PageNotFound';
import DoctorInfo from './components/new-appointment/doctors-list/doctor-info/DoctorInfo';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/whatsapp" component={Whatsapp} />
        <Route exact path="/download" component={StoreDownload} />
        {/* <Route exact path="/" component={Auth.isAuthenticated() ? Home : Login} /> */}
        {/* <Route exact path={Auth.isAuthenticated() ? "/home" : "/login?return_url=" + window.location.pathname} component={Auth.isAuthenticated() ? Home : Login} /> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/signup" component={SignUp} />
        <ProtectedRoute exact path="/profile" component={Profile} />

        {/* <ProtectedRoute exact path="/home" component={Home} /> */}

        {/* <Route exact path="/new-appointment" component={NewAppointment} /> */}
        <Route exact path="/doctors-list/:type" component={DoctorsList} />
        <Route exact path="/doctor-info/:id" component={DoctorInfo} />
        <Route exact path="/clinics-list" component={ClinicsList} />
        <Route exact path="/clinic-details/:id" component={ClinicDetails} />
        <Route exact path="/clinic-info" component={ClinicInfo} />

        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/all-offers" component={AllOffers} />
        <Route exact path="/all-specialities" component={AllSpecialities} />
        <Route exact path="/speciality-details/:id" component={SpecialityDetails} />

        {/* <ProtectedRoute exact path="/assistant-chat/:id" component={AssistanteChat} /> */}
        <Route exact path="/payment-redirect/:id" component={AutoPaymentRedirect} />
        {/* <ProtectedRoute exact path="/online-chat/:id" component={OnlineChat} /> */}
        {/* <ProtectedRoute exact path="/onsite-chat/:id" component={OnsiteChat} /> */}
        <Route exact path="/chat-payment" component={ChatPayment} />
        <Route exact path="/chat-payment-verification" component={ChatPaymentVerification} />

        <ProtectedRoute exact path="/my-bookings" component={MyBookings} />

        {/* <ProtectedRoute exact path="/my-people" component={MyPeople} /> */}
        {/* <ProtectedRoute exact path="/add-people" component={AddPeople} /> */}
        {/* <ProtectedRoute exact path="/edit-people" component={EditPeople} /> */}

        {/* <ProtectedRoute exact path="/my-payments" component={MyPayments} /> */}

        {/* <ProtectedRoute exact path="/medical-reports" component={MedicalReports} /> */}
        {/* <ProtectedRoute exact path="/add-medical-report" component={AddMedicalReport} /> */}
        {/* <ProtectedRoute exact path="/edit-medical-report" component={EditMedicalReport} /> */}

        {/* <ProtectedRoute exact path="/coupon-code" component={CouponCode} /> */}

        {/* <ProtectedRoute exact path="/my-locations" component={MyLocations} /> */}
        {/* <ProtectedRoute exact path="/add-location" component={AddLocation} /> */}
        {/* <ProtectedRoute exact path="/edit-location" component={EditLocation} /> */}

        <ProtectedRoute exact path="/faq" component={Faq} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-conditions" component={TermsConditions} />
        {/* <ProtectedRoute exact path="/notifications" component={Notifications} /> */}

        <Route path="*" component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
