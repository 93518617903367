import React, { useState, useEffect } from 'react';
import './Login.css';
import { addon_prefix, postPatientProfile } from '../../core/api/Api';
import I18nManager from '../../core/I18nManager/I18nManager';
import ViewContainer from '../../core/routes/view-container/ViewContainer';
import { getPhoneNumberDetails, removeWhiteSpaceFromString } from '../../core/common-service/CommonService';
import { constants } from '../../core/constants/constants';
import Auth from '../../core/routes/auth/Auth';
import firebase from '../../core/firebase/firebase';

var IS_MOUNTED = true

export default function SignUp(props) {
    const [MobileNo, setMobileNo] = useState('')
    const [FirstName, setFirstName] = useState('')
    const [LastName, setLastName] = useState('')
    const [Password, setPassword] = useState('')
    const [ConfirmPassword, setConfirmPassword] = useState('')
    const [countryCode, setCountryCode] = useState(null)
    const [isMobileValid, setIsMobileValid] = useState(false)
    const [loading, setLoading] = useState(false)
    const [FCM, setFCM] = useState('')
    const [showError, setShowError] = useState('')

    const goToPage = (path) => (e) => {
        e.preventDefault()
        props.history.push(path)
    }

    const onInputChange = (e) => {
        if (showError) setShowError(false)
        e.preventDefault();
        const { name, value } = e.target;
        switch (name) {
            case 'FirstName':
                setFirstName(value)
                break;
            case 'LastName':
                setLastName(value)
                break;
            case 'MobileNo':
                const phoneDetails = getPhoneNumberDetails(value)
                setMobileNo(phoneDetails?.format ?? value)
                if (phoneDetails) {
                    setCountryCode(phoneDetails.countryCode ?? null)
                    setIsMobileValid((phoneDetails?.isValid && value?.length >= 6) ? true : false)
                } else {
                    setCountryCode(null)
                }
                break;
            case 'Password':
                setPassword(value)
                break;
            case 'ConfirmPassword':
                setConfirmPassword(value)
                break;
            default:
                break;
        }
    }

    const onSubmitLogin = (e) => {
        e.preventDefault()
        if (isMobileValid && FirstName.length > 2 && LastName.length > 2 &&
            (Password.length > 7 && Password === ConfirmPassword)) {
            var obj = {
                MobileNo: removeWhiteSpaceFromString(MobileNo),
                FCM, IsProduction: addon_prefix === 'cp' ? false : true,
                FirstName, LastName, Password,
            }
            setLoading(true)
            postPatientProfile(obj).then(res => {
                if (IS_MOUNTED) {
                    const data = res.data
                    if (data.Status === 'Success') {
                        props.history.push('/login')
                    } else {
                        setShowError(true)
                    }
                    setLoading(false)
                }
            }).catch((e) => setLoading(false))
        }
    }

    function getFCMToken() {
        return firebase.messaging().getToken()
    }

    useEffect(() => {
        IS_MOUNTED = true
        if (Auth.isAuthenticated()) {
            props.history.push('/home')
        }
        if (firebase.messaging.isSupported()) {
            getFCMToken().then(res => {
                setFCM(res)
            })
        }
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    return (
        <ViewContainer>
            <div className="login-bg d-flex align-items-center justify-content-center">
                <div className="login-container">
                    <h1>Log in</h1>
                    <div className="login-ph-profile-container">
                        <img loading="lazy" src="/assets/img/patient-profile-ph.svg" alt="" />
                    </div>
                    <h4>{I18nManager.isRTL() ? "مرحبا بعودتك!" : "Welcome Back!"}</h4>
                    <h4>{I18nManager.isRTL() ? "تسجيل الدخول إلى حسابك" : "Login to your Account"}</h4>
                    <form onSubmit={onSubmitLogin}>
                        {showError && <div className="alert alert-danger" role="alert">{I18nManager.isRTL() ? "خطأ أثناء التسجيل" : "Error while signing up"}</div>}
                        <div className="d-flex align-items-center login-input-container mt-25">
                            <img loading="lazy" src="/assets/img/full-name.svg" alt="" />
                            <input type="text" name="FirstName" value={FirstName} onChange={onInputChange} placeholder={I18nManager.isRTL() ? 'الاسم الأول' : 'First Name'} />
                        </div>
                        <div className="d-flex align-items-center login-input-container mt-25">
                            <img loading="lazy" src="/assets/img/full-name.svg" alt="" />
                            <input type="text" name="LastName" value={LastName} onChange={onInputChange} placeholder={I18nManager.isRTL() ? 'اسم العائلة' : 'Last Name'} />
                        </div>
                        <div className="d-flex align-items-center login-input-container mt-25"
                            style={{ borderColor: isMobileValid ? '#00ff00' : undefined }}>
                            <img loading="lazy" src="/assets/img/noun-call.svg" alt="" />
                            <input type="tel" name="MobileNo" value={MobileNo} onChange={onInputChange} placeholder="+966 55 555 5555" />
                        </div>
                        <div className="d-flex align-items-center login-input-container mt-25">
                            <img loading="lazy" src="/assets/img/password.svg" alt="" />
                            <input type="password" name="Password" value={Password} onChange={onInputChange} placeholder={I18nManager.isRTL() ? "كلمه السر" : "Password"} />
                        </div>
                        <div className="d-flex align-items-center login-input-container mt-25">
                            <img loading="lazy" src="/assets/img/password.svg" alt="" />
                            <input type="password" name="ConfirmPassword" value={ConfirmPassword} onChange={onInputChange} placeholder={I18nManager.isRTL() ? "تأكيد كلمة المرور" : "Confirm Password"} />
                        </div>
                        <div className="d-flex align-items-center justify-content-between w-100 remember-password-container">
                            <div className="d-flex align-items-center">
                                <div className={"remember-checkbox " + (I18nManager.isRTL() ? "ml-5" : "mr-5")}>
                                    <i className="fa fa-check"></i>
                                </div>
                                <span className="agree-to-terms-text">{I18nManager.isRTL() ? "بالتسجيل فإنك توافق على" : "By signing up you agree to our "}</span>
                                &nbsp;
                                <span className="no-account-text">{I18nManager.isRTL() ? "تعليمات الاستخدام" : "Terms Of Use"}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <button disabled={loading} type='submit'>{I18nManager.isRTL() ? "انضم الآن" : "Join Now"}</button>
                            <div className="d-flex align-items-center no-account-container">
                                <span className="no-account-text">{I18nManager.isRTL() ? "لا حساب؟" : "No account?"}</span>
                                &nbsp;
                                <a onClick={goToPage('login')}>
                                    <span className="signup-text">{I18nManager.isRTL() ? "تسجيل الدخول" : "Login"}</span>
                                </a>
                            </div>
                        </div>
                        <div className="login-info">
                            <span className="header">{constants.loginInfo?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</span>
                            {constants.loginInfo.data.map((item, index) => (
                                <div key={index} className="d-flex align-items-center mt-5">
                                    <img loading="lazy" src={item.uri} alt="" />
                                    <span>{item?.[I18nManager.isRTL() ? "titleAr" : "titleEn"]}</span>
                                </div>
                            ))}
                        </div>
                    </form>
                </div>
            </div>
        </ViewContainer>
    )
}