import React, { useState, useEffect } from 'react'
import Modal from 'react-modal';
import { postGetCampaignCoupons } from '../../../../core/api/Api';
import I18nManager from '../../../../core/I18nManager/I18nManager';
import { modalStyle } from '../../../../core/styles/custom-styles';
import './ModalCouponList.css';

var IS_MOUNTED = true

export default function ModalCouponList(props) {

    const [data, setData] = useState([])

    useEffect(() => {
        IS_MOUNTED = true
        Modal.setAppElement('body')
        getCouponList()
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    function getCouponList() {
        postGetCampaignCoupons(0).then(res => {
            if (IS_MOUNTED) {
                const data = res.data.data
                setData(data)
            }
        }).catch(e => this.getCouponList())
    }

    function onCouponPress(index) {
        const tmp_data = [...data]
        tmp_data.forEach((element, i) => {
            element.active = parseInt(index) === i
        });
        setData(tmp_data)
    }
    const { loading } = props
    return (
        <Modal {...props} style={modalStyle} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
            <div className="p-20">
                {data.length === 0 && <h3 className="text-center">{I18nManager.isRTL() ? "رموز هدايا جديدة ستتوفر قريباً" : "New Gift Codes Will be available Soon"}</h3>}
                {data.length > 0 && <div className="d-flex row flex-row flex-wrap">
                    {data.map((item, index) => (
                        <span key={index} onClick={() => onCouponPress(index)} className={item.active ? "active-coupon-item" : "inactive-coupon-item"}>
                            {item?.CouponCode} ({item?.IsPercent ? (item?.Percentage + '%') : (item?.Amount + " " + (I18nManager.isRTL() ? 'ريال' : 'SAR'))})
                        </span>
                    ))}
                </div>}
            </div>
            <button disabled={loading} className="coupon-apply-button" onClick={props.onApplyPress.bind(this, data?.find(el => el?.active === true))}>{I18nManager.isRTL() ? 'تطبيق' : 'Apply'}</button>
        </Modal>
    )
}
