import React, { Component } from 'react'
import I18nManager from '../I18nManager/I18nManager';

const FilterContext = React.createContext();

export class FilterProvider extends Component {

    state = {
        filters: {
            sortBy: [
                { titleEn: "Best Match", titleAr: "أفضل مطابقة", active: true },
                { titleEn: "Top Rated", titleAr: "أعلى التقييمات", active: false },
                { titleEn: "Price Low To High", titleAr: "السعر من الارخص للاعلى", active: false },
                { titleEn: "Price High To Low", titleAr: "السعر الاعلى الى الادنى", active: false },
            ],
            genders: [
                { titleEn: "Any", titleAr: "كل", active: true },
                { id: 1, titleEn: "Male", titleAr: "ذكر", value: "Male", active: false },
                { id: 2, titleEn: "Female", titleAr: "أنثى", value: "Female", active: false },
            ],
            examinationFee: [
                { titleEn: "Any", titleAr: "كل", active: true },
                { titleEn: "Less Than 50", titleAr: "أقل من 50", from: 0, to: 49, active: false },
                { titleEn: "From 50 to 100", titleAr: "من 50 إلى 100", from: 50, to: 100, active: false },
                { titleEn: "From 100 to 200", titleAr: "من 100 إلى 200", from: 100, to: 200, active: false },
                { titleEn: "From 100 to 300", titleAr: "من 100 إلى 300", from: 200, to: 300, active: false },
            ],
            entity: [
                { titleEn: "Hospital", titleAr: "مستشفى", active: false },
                { titleEn: "Clinic", titleAr: "عيادة", active: false },
            ],
            ratings: [
                { titleEn: "Any", titleAr: "كل", active: true },
                { value: 5, active: false },
                { value: 4, active: false },
                { value: 3, active: false },
            ],
            yearsOfExperience: [
                { titleEn: "Any", titleAr: "كل", active: true },
                { titleEn: "1-2 years", titleAr: "1-2 سنة", from: 1, to: 2, active: false },
                { titleEn: "2-3 years", titleAr: "2-3 سنوات", from: 2, to: 3, active: false },
                { titleEn: "3-5 years", titleAr: "3-5 سنوات", from: 4, to: 5, active: false },
            ],
            availability: [
                { titleEn: "Any Day", titleAr: "اي يوم", active: true },
                { titleEn: "Today", titleAr: "اليوم", active: false },
                { titleEn: "Tomorrow", titleAr: "غدا", active: false },
            ],
            title: [
                { titleEn: "Any", titleAr: "كل", active: true },
                { OccupationID: 1, titleEn: "General", titleAr: "عام", active: false },
                { OccupationID: 2, titleEn: "Sepcialist", titleAr: "متخصص", active: false },
                { OccupationID: 4, titleEn: "Consultant", titleAr: "مستشار", active: false },
            ],
            serviceType: [
                { titleEn: "Any", titleAr: "كل", active: true },
                { titleEn: "Online", titleAr: "اونلاين", active: false },
                { titleEn: "Onclinic", titleAr: "في العيادة", active: false },
            ],
            nationality: [],
            boardCertificates: [],
        },
        ourSpecialities: [
            {
                GeneralServiceCategoryID: 4,
                active: true,
                titleEn: 'General Medicine',
                titleAr: 'الطب العام',
                uri: '/assets/img/general-medicine.svg',
                subTitleEn: 'Also known as family medicine, concerned with public health, general practice, and geriatric care.',
                subTitleAr: 'يُعرف أيضًا باسم طب الأسرة، ويهتم بالصحة العامة والممارسة العامة ورعاية المسنين.',
                descriptionEn: [
                    `Medical specialty which provides continuing comprehensive health care for individuals and family. It is a specialty in breadth that integrates the biological, clinical, and behavioral sciences.`,
                    `The scope of family medicine encompasses all ages, all genders, each organ system, and every disease entity.`
                ],
                descriptionAr: [
                    `يمتلك الطبيب العام المعرفة الكاملة بالأمراض التي تصيب جسم الإنسان والأعراض المصاحبة لها، كما أنه قادر على تشخيص الحالة العضوية والنفسية للمريض تشخيصا أوليا.`,
                    `تتعدد مهام الطبيب العام منها التشخيص، والمعالجة بالأدوية والعقاقير الطبية، بعض الاجراءات الجراحية البسيطة كخياطة الجروح، 
                        كما يعنى الطبيب العام بتقديم النصائح، والإجراءات التي يجب اتخاذها؛ للوقاية من الأمراض، والمشكلات الصحّية، ودراسة الحالة النفسية للمريض ما يساعده على فهم حالته بشكل أوضح.`
                ]
            },
            {
                GeneralServiceCategoryID: 13,
                titleEn: 'Dermatologist',
                titleAr: 'طبيب الجلدية',
                uri: '/assets/img/dermatologist.svg',
                subTitleEn: 'Branch of medicine related to nails, hair, and skin.',
                subTitleAr: 'ثلاث كلمات أساسية ترتبط بطبيب الجلدية: بشرتك، شعرك، أظافرك',
                descriptionEn: [
                    `A dermatologist is a doctor who specializes in conditions involving the skin, hair, and nails. A dermatologist can identify and treat more than 3,000 conditions. These conditions include eczema, psoriasis, and skin cancer, among many others.`
                ],
                descriptionAr: [
                    `طبيب الأمراض الجلدية هو طبيب متخصص في الحالات التي تشمل الجلد والشعر والأظافر، يمكن لطبيب الأمراض الجلدية تشخيص ما يزيد عن 3000 حالة مرضية مختلفة تشمل هذه الحالات الإكزيما والصدفية وسرطان الجلد وغيرها الكثير.`
                ],
                list: {
                    titleEn: 'Dermatology procedure:',
                    titleAr: 'أهم الإجراءات في طب الجلدية:',
                    infoEn: [
                        `laser hair and tattoo removal`,
                        `IPL facials`,
                        `sun and age spot removal`,
                        `unwanted blood vessels`,
                        `injectable fillers`,
                        `PRP injections`,
                        `sclerotherapy`,
                        `chemical peels`,
                        `skin biopsy topical chemotherapy`,
                        `photodynamic therapy`,
                        `MOHS micrographic surgery`
                    ],
                    infoAr: [
                        `إزالة الشعر والوشم بالليزر`,
                        `علاجات الوجه بتقنية    IPL`,
                        `إزالة البقع الناتجة عن الشمس والتقدم بالعمر`,
                        `إزالة الدوالي`,
                        `حقن الفيلر`,
                        `حقن البلازما`,
                        `التقشير الكيميائي`,
                        `خزعة الجلد `,
                        `العلاج الكيميائي الموضعي`,
                        `العلاج الضوئي`,
                        `جراحة التصوير المجهري`,
                    ],
                }
            },
            {
                GeneralServiceCategoryID: 31,
                titleEn: 'Dentist',
                titleAr: 'طبيب أسنان',
                uri: '/assets/img/dentist.svg',
                subTitleEn: 'branch of the healing arts and sciences devoted to maintaining oral health.',
                subTitleAr: 'كل ما يعنى بصحة أسنانك وما يرتبط بهم من اجراءات طبية وتجميلية لنتعرف عليها سويا',
                descriptionEn: [
                    `The realization that oral health can have a serious impact on systemic health drives the expansion of new professional opportunities each year. The fact that dentistry is so dynamic, makes the profession interesting. Thanks to the developments in science and technology, dentistry has become easier, both for the patient and the dentist. The procedures are quicker, less painful with better quality results and increased longevity.`,
                    `Since the mouth is the gateway to health, any progress in dentistry means progress in the overall health of a person.`
                ],
                descriptionAr: [
                    `يعنى طبيب الأسنان بشكل أساسي بصحة الأسنان وتدريب المريض على حماية أسنانه والمحافظة عليها، ثم تتدرج مهامه حسب اختصاصه كسحب العصب والحشوات والتراكيب وتقويم الأسنان والقشور "فينيرز" وغيرها من الاجراءات الطبية منها والتجميلية.`,
                    `خاصة بعد التطور الكبير الذي حدث في عالم طب الأسنان في العقد الأخير وظهور الكثير من الاجراءات السنية التجميلية لعل أشهرها ما بات يسمى باتسامة المشاهير أو "هوليوود سمايل"`
                ],
                list: {
                    titleEn: 'Fields of dentistry:',
                    titleAr: 'فروع طب الأسنان:',
                    infoEn: [
                        `General Dentist.`,
                        `Pediatric Dentist.`,
                        `Orthodontist.`,
                        `Periodontist.`,
                        `Endodontist.`,
                        `Oral Pathologist or Oral Surgeon.`,
                        `Prosthodontist.`
                    ],
                    infoAr: [
                        `طبيب أسنان عام.`,
                        `طبيب أسنان الأطفال.`,
                        `أخصائي تقويم الأسنان.`,
                        `أخصائي اللثة.`,
                        `أخصائي عصب الاسنان.`,
                        `أخصائي أمراض اوجراحة الوجه والفكين`,
                        `أخصائي التركيبات السنية.`
                    ]
                }
            },
            {
                GeneralServiceCategoryID: 26,
                titleEn: 'Psychiatry',
                titleAr: 'الطب النفسي',
                uri: '/assets/img/psychiatry.svg',
                subTitleEn: 'branch of medicine focused on the diagnosis, treatment, and prevention of mental, emotional, and behavioral disorders',
                subTitleAr: 'لا بأس بطلب المساعدة. لا تجعل الشعور بالخجل والخوف من النظرة الإجتماعية يقف عائقا بينك وبين صحتك النفسية.',
                descriptionEn: [
                    `Mental health includes our emotional, psychological, and social well-being. It affects how we think, feel, and act. It also helps determine how we handle stress, relate to others, and make choices. Mental health is important at every stage of life, from childhood and adolescence through adulthood.`,
                    `Throughout your life, if you experience mental health problems, thinking, mood, and behavior could be affected.`
                ],
                descriptionAr: [
                    `قد يشعر أي منا بالحاجة للذهاب إلى طبيب نفسي وبرغم الإعتقاد السابق لدى الناس أن الطب النفسي خدعة إلا أن الوعي العام قد زاد بنسبة كبيرة في السنوات الأخيرة في الوطن العربي عامة وفي المملكة العربية السعودية خاصة.`,
                    `ومن الحالات التي يتعامل معها الأطباء النفسيين وأكثرها شيوعا: الاكتئاب، القلق المرضي، الأرق واضطرابات النوم، فقدان الشهية المرضي، اضطراب الهوية الجندرية، البرود الجنسي لدى النساء والاضطرابات الجنسية وسرعة القذف أو تأخر القذف عند الرجال وغيرها.`,

                ],
                list: {
                    titleEn: 'factors contributed to mental health:',
                    titleAr: 'العوامل المرتبطة بالأمراض النفسية:',
                    infoEn: [
                        `Biological factors, such as genes or brain chemistry`,
                        `Life experiences, such as trauma or abuse`,
                        `Family history of mental health problems`
                    ],
                    infoAr: [
                        `العوامل البيولوجية مثل الجينات أو كيمياء الدماغ.`,
                        `التجارب الشخصية، مثل الصدمة أو سوء المعاملة في الطفولة.`,
                        `وجود تاريخ عائلي للإصابة بمشاكل نفسية.`
                    ]
                }
            },
            {
                GeneralServiceCategoryID: 7,
                titleEn: 'Orthopedics',
                titleAr: 'طب العظام',
                uri: '/assets/img/orthopedics.svg',
                subTitleEn: 'A branch of medicine involved with examination and treatment of the musculoskeletal system, especially the spine, joints, and muscles.',
                subTitleAr: 'تشكل مشاكل العظام والمفاصل والغضاريف ألما كبيرا لدى المريض خاصة كبار السن، إن المسارعة في البحث عن العلاج يعمل على تخفيف معاناة المريض.',
                descriptionEn: [
                    `Pediatrics focuses on the diagnosis, correction, prevention, and treatment of patients with skeletal deformities - disorders of the bones, joints, muscles, ligaments, tendons, nerves, and skin.`,
                    `These elements make up the musculoskeletal system.`
                ],
                descriptionAr: [
                    `يتداخل طب العظام والمفاصل مع العديد من التخصصات الطبية الأخرى كالطب العصبي وطب التأهيل والعلاج الطبيعي،`,
                    `كما يساهم في تخفيف آلام العظام والمفاصل سواء بوصف الأدوية المناسبة أو الجراحات المختلفة أشهرها جراحة الركبة وجراحة الكتف والعمود الفقري وغيرها `
                ],
                list: {
                    titleEn: 'Some conditions related to pediatrics:',
                    titleAr: 'ويهتم طيب العظام بعلاج عشرات الأمراض المرتبطة بالعظام والعضلات منها:',
                    infoEn: [
                        `Back pain and cervical discs`,
                        `arthritis`,
                        `Lupus`,
                        `Reiter's syndrome`,
                        `Autoimmune diseases`,
                        `Spinal cord atrophy`,
                        `Aplastic anemia`,
                        `Gout disease`
                    ],
                    infoAr: [
                        `ألم الظهر ودسك الرقبة`,
                        `التهاب المفاصل`,
                        `مرض الذئبة lupus`,
                        `متلازمة رايتر`,
                        `أمراض المناعة الذاتية`,
                        `ضمور النخاع الشوكي`,
                        `نزيف النخاع العظمي`,
                        `النقرس والنقرس الكاذب وغيرها.`,
                    ]
                }
            },
            {
                GeneralServiceCategoryID: 10,
                titleEn: 'Neurology',
                titleAr: 'علم الأعصاب',
                uri: '/assets/img/neurology.svg',
                subTitleEn: ` Nervous system disorders are among the most dangerous injuries that threaten the patient's life, but with early diagnosis and proper and prompt treatment, many risks can be avoided.`,
                subTitleAr: `اضطرابات الجهاز العصبي من أخطر الإصابات التي تهدد حياة المريض ولكن مع التشخيص المبكر والعلاج السليم والسريع يمكن تجنب العديد من المخاطر`,
                descriptionEn: [
                    `neurologists and neurosurgeons diagnose and treat diseases of the nervous system, neurologists do not do any surgery. Neurologists focus on identifying specific diagnostic neurological conditions that can be corrected with other drugs or therapies or that require strict care.`,
                    `while neurosurgery is needed to remove or correct the condition to improve your outcome.`
                ],
                descriptionAr: [
                    `يهتم طبيب الدماغ  بفحص وتشخيص وعلاج الاضطرابات العصبية والأعراض المصاحبة لها كوهن العضلات والتهابات الجهاز العصبي بما في ذلك الدمامل والتهاب الدماغ والسحايا، الزهايمر ومرض لو جيهريج، والتهابات الحبل الشوكي، والاضطرابات الالتهابية وأمراض المناعة الذاتية، والصداع بأنواعه.`,
                    `وقد تتداخل مهام طبيب الأعصاب مع الطبيب النفسي في العديد من الحالات النفسية ذات المنشأ العصبي.`
                ],
                list: {
                    titleEn: 'Some conditions that need surgery:',
                    titleAr: 'بعض الجراحات المرتبطة بطب الأعصاب:',
                    infoEn: [
                        `Pituitary adenoma.`,
                        `Craniofacial trauma (brain hematomas, cranial fractures, etc.).`,
                        `Spinal trauma with vertebral fractures.`,
                        `Brain and cerebellum tumors.`,
                        `cerebral arteriovenous malformations`,
                        `Parkinson's disease.`,
                        `Pain surgery.`,
                    ],
                    infoAr: [
                        `الورم الغددي النخامي.`,
                        `مَوه الرأس (وضع صمام التحويل).`,
                        `رض القحف (أورام دموية بالدماغ، وكسور القحف، الخ.).`,
                        `رض العمود الفقري مع الكسور الفقرية.`,
                        `أورام المخ والمخيخ.`,
                        `الدم داخل القحف ومجموع التشوهات الشريانية-الوريدية الدماغية.`,
                        `النزف والورم الدموي الدماغي.`,
                        `مرض باركينسون.`,
                        `جراحة الألم.`,
                    ]
                }
            },
            {
                GeneralServiceCategoryID: 27,
                titleEn: 'Gynecology',
                titleAr: 'أمراض النساء',
                uri: '/assets/img/gynecology.svg',
                subTitleEn: 'A branch of medicine that deals with the diseases and routine physical care of the reproductive system of women.',
                subTitleAr: 'يوصي الأطباء النساء  بإجراء الفحص السنوي وفي أي وقت تشعر المرأة بالقلق بشأن أي تغييرات لتجنب تطور الأعراض إلى مضاعفات خطيرة، كما أن زيارتك لطبيب النسائية طوال فترة الحمل يحافظ على صحتك وصحة جنينك.',
                descriptionEn: [
                    `A gynecologist is a doctor who specializes in female reproductive health. They diagnose and treat issues related to the female reproductive tract. This includes the uterus, fallopian tubes, and ovaries, and breasts.`,
                    `unlike almost any other specialty, gynecologists get to practice both surgery and non-surgical medicine. Ob/Gyns perform 2 of the most common surgical procedures (hysterectomies and cesarean deliveries) and many other interesting and sometimes challenging procedures like laparoscopy (“belly button surgery”) and vaginal and vulvar surgery.`,
                    `they also provide a lot of primary care. During annual exams, gynecologists talk about exercise, weight management, vaccinations, screening for cancer, safe sex practices, and contraception.`
                ],
                descriptionAr: [
                    `تشعر بعض النساء بالقلق والتوتر من زيارة الطبيب النسائي كما تعزف الكثير من السيدات عن اجراء الفحوصات المبكرة لسرطان عنق الرحم، في كثير من الأحيان يكون التشخيص المبكر للعديد من الحالات سببا في التخلص من الأعراض بسهولة وسرعة قبل تحولها إلى مشكلات قد لا تزول أبدا.`,
                    `كما أن على الأم الحامل التزام مواعيد المتابعة مع الطبيب لضمان مرور فترة الحمل دون أخطار سواء على صحة الأم وجنينها `,
                    `وتشتمل خدمات طبيب النسائية على إجراء الفحوصات الطبية المرتبطة بصحة المرأة، ومتابعة الحمل وفحص الجنين، وإجراء عمليات الولادة والفحوصات اللازمة لضمان خلو الجسم من الأمراض، ومعالجة الإلتهابات النسائية ومشاكل الحيض والهرمونات.`,
                    `كما يعنى طب النسائية بإجراء الجراحات المرتبطة بأورام الرحم والمبيضين، وبعض الإجراءات التجميلية كحقن المهبل بالبوتوكس وعمليات التضييق وتفتيح المناطق الحساسة.`
                ],
            },
            {
                GeneralServiceCategoryID: 69,
                titleEn: 'Pediatrics',
                titleAr: 'طب الأطفال',
                uri: '/assets/img/pediatrics.svg',
                subTitleEn: 'branch of medicine that manages the health of your child, including physical, behavioral, and mental health issues. They’re trained to diagnose and treat childhood illnesses, from minor health problems to serious diseases.',
                subTitleAr: 'أطفالنا مستقبلنا، فمن أجدر من طبيب الأطفال برعاية أطفالنا وصحتهم ووقايتهم لنتعرف سوية على مهام طبيب الأطفال.',
                descriptionEn: [
                    `infants can go through minor or major illnesses which are handled by a specific doctor specialized in this particular field.`,
                    `As compared to general medicine, pediatrics is unique in different aspects such as:`,
                    `neurology development, congenital malformations, genetic and chromosomal disorders, recurrent infectious diseases, malnutrition manifestation during childhood, Etc…`
                ],
                descriptionAr: [
                    `يمكن أن يمر الأطفال بأمراض طفيفة أو خطيرة يعالجها طبيب معين متخصص في هذا المجال بالذات ويسمى طبيب الأطفال.`,
                    `بالمقارنة مع الطب العام، فإن طب الأطفال يعني بجوانب علاجية إضافية مرتبطة بصحة الطفل مثل:`,
                    `التطور العصبي، التشوهات الخلقية، الاضطرابات الجينية والكروموسومية، الأمراض المعدية، مظاهر سوء التغذية أثناء الطفولة، إلخ ...`,
                ],
                list: {
                    titleEn: 'Some services offered by pediatricians are:',
                    titleAr: 'بعض الخدمات التي يقدمها طبيب الأطفال:',
                    infoEn: [
                        `Infant and Child nutrition`,
                        `Vaccination and Immunization`,
                        `Congenital Disorders Evaluation or Treatment`,
                        `Infectious Disease Treatment`,
                        `Health Checkup (Pediatric)`,
                        `Viral Fever Treatment`,
                        `Tonsillitis Treatment`,
                        `New Born Care`,
                        `Growth and Development Evaluation or Management`,
                    ],
                    infoAr: [
                        `تغذية الرضع والأطفال`,
                        `التطعيم واللقاحات`,
                        `تقييم أو علاج الاضطرابات الخلقية`,
                        `علاج الأمراض المعدية`,
                        `الفحص الطبي الدوري`,
                        `علاج الحمى الفيروسية`,
                        `علاج التهاب اللوزتين`,
                        `رعاية حديثي الولادة`,
                        `تقييم النمو والحالة النفسية الأولية عند الطفل`,
                    ]
                }
            },
            {
                GeneralServiceCategoryID: 24,
                titleEn: 'Cardiology',
                titleAr: 'طب القلب',
                uri: '/assets/img/cardiology.svg',
                subTitleEn: 'Is the study and treatment of disorders of the heart and blood vessels. A person with heart disease or cardiovascular disease may be referred to a cardiologist. Cardiology is a branch of internal medicine. A cardiologist is not the same as a cardiac surgeon',
                subTitleAr: 'تعتبر أمراض القلب والشرايين والأوعية الدموية المسبب الأول للوفاة في المملكة العربية السعودية وترتبط العديد من حالات الوفاة بتجاهل الأعراض البدائية للإصابة بأمراض ضغط الدم والقلب، كالصداعات البسيطة والتعب الدائم والإغماء المتكرر وضيق التنفس والآلام في منطقة الصدر واضطراب نبضات القلب دون سبب واضح.',
                descriptionEn: [
                    `Cardiovascular disease is the leading cause of death in the Kingdom of Saudi Arabia, and many deaths are associated with ignoring the primitive symptoms of blood pressure and heart diseases, such as minor headaches, permanent fatigue, recurrent fainting, shortness of breath, pains in the chest area, and an irregular heartbeat without a clear reason. Rush to search for a suitable cardiologist as soon as you feel one or more of these symptoms.`,
                    `Cardiologists treat patients with diseases of the heart, arteries, and veins (cardiovascular disease). Cardiologists and surgeons work with specialists in several different fields to determine the most appropriate treatment for different cases, whether it is drug therapy, diagnostic and therapeutic catheters, or different surgeries.`
                ],
                descriptionAr: [
                    `يعمل أطباء القلب والأوعية الدموية على معالجة المرضى المصابين بأمراضٍ في القلب والشرايين والأوردة (أمراض القلب والأوعية الدموية). ويعمل أطباء وجرَّاحو القلب مع اختصاصيين في عدة مجالات مختلفة من أجل تحديد العلاج الأنسب للحالات المختلفة سواء كان بالعلاج الدوائي أو القسطرة العلاجية والتشخيصية أو الجراحات المختلفة `
                ],
                list: {
                    titleEn: 'Some conditions cardiologists and surgeons deal with:',
                    titleAr: 'أشهر الحالات التي يتعامل معها أطباء وجراحو القلب والأوعية الدموية:',
                    infoEn: [
                        `Coronary and vascular diseases`,
                        `Congenital heart anomalies`,
                        `Cardiomyopathy`,
                        `Heart attacks`,
                        `Angina pectoris`,
                        `Heart failure`,
                        `Abnormal heart rhythms`,
                        `Bacterial and viral infections of the heart`,
                        `Strokes`
                    ],
                    infoAr: [
                        `أمراض الشريان التاجي والأوعية الدموية`,
                        `تشوهات القلب الخلقية`,
                        `اعتلال عضلة القلب`,
                        `النوبات القلبية`,
                        `الذبحات الصدرية`,
                        `فشل عضلة القلب`,
                        `اضطراب نبضات القلب`,
                        `التهابات القلب البكتيرية والفيروسية`,
                        `انسداد صمامات القلب والتهابها`,
                        `انسداد الشرايين وتصلب الشرايين`
                    ]
                }
            },
            {
                GeneralServiceCategoryID: 77,
                titleEn: 'Otolaryngology',
                titleAr: 'طب الأنف',
                uri: '/assets/img/otolaryngology.svg',
                subTitleEn: 'branch of medicine involved in treatment and examination of conditions related to ear, nose, and throat.',
                subTitleAr: 'يعد طبيب ال ENT من أكثر الفروع الطبية الجراحية تداخلا مع التخصصات الأخرى ',
                descriptionEn: [
                    `tasks of an otolaryngologist vary between diagnostic, therapeutic, and cosmetic tasks, such as:`
                ],
                descriptionAr: [
                    `هل تعاني من بحة في الصوت؟ كسور في الأنف؟`,
                    `هل تحتاج على جراحة تجميلية في الوجه والرأس؟`,
                    `إذا ستمر على طبيب أنف وأذن وحنجرة قبل أي طبيب آخر.`
                ],
                list: {
                    titleEn: `tasks of an otolaryngologist vary between diagnostic, therapeutic, and cosmetic tasks, such as:`,
                    titleAr: 'تتعدد مهام طبيب الأنف والأذن والحنجرة بين مهام تشخيصية علاجية وأخرى تجميلية لنتعرف على بعض منها:',
                    infoEn: [
                        `Head tumors and cancer: such as cancers of the mouth, skin, thyroid gland, skull base surgeries, and pituitary tumors.`,
                        `Ear and nerve problems associated with hearing: such as middle and inner ear infections, dizziness, labyrinthine fistula, deafness, perforation of the eardrum, and all surgeries related to the ear.`,
                        `Nose problems: such as blocked and inflamed sinuses, severe and frequent nosebleeds, allergies, nose surgery, and nasal bone fractures.`,
                        `Throat problems: such as hoarseness, laryngitis, tracheal perforation, and laryngeal cancer.`,
                        `Plastic and reconstructive surgery: such as cosmetic injections, lower jaw fractures, and some skin cancers.`,
                    ],
                    infoAr: [
                        `أورام الرأس والسرطان: كسرطان الفم والجلد والغدة الدرقية وجراحات قاعدة الجمجمة وأورام الغدة النخامية.`,
                        `مشاكل الأذن والأعصاب المرتبطة بالسمع: كالتهابات الأذن الوسطى والداخلية والدوخة والناسور التيهي والصمم وثقب طبلة الأذن وكافة الجراحات المرتبطة بالأذن.`,
                        `مشاكل الأنف: كانسداد الجيوب والتهابها، الرعاف الشديد والمتكرر، الحساسية وعمليات جميل الأنف وكسور العظم الأنفي.`,
                        `مشاكل الحنجرة: مثل بحة الصوت والتهاب الحنجرة وثقب القصبة الهوائية وسرطان الحنجرة.`,
                        `الجراحة التجميلية والترميم: مثل الحقن التجميلي وكسور الفك السفلي وبعض سرطانات الجلد.`,
                    ]
                }
            },
        ]
    }

    setFilterContextValue = (key, val) => {
        this.setState({ [key]: val })
    }

    render() {
        const { setFilterContextValue } = this
        return (
            <FilterContext.Provider value={{
                ...this.state,
                setFilterContextValue
            }}>
                {this.props.children}
            </FilterContext.Provider>
        )
    }
}

export default FilterContext