import React, { useEffect } from 'react'
import './ModalBookingSuccess.css';
import Modal from 'react-modal';
import I18nManager from '../../../core/I18nManager/I18nManager';

export default function ModalBookingSuccess(props) {

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    return (
        <Modal {...props} className="ReactModalPortal" shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
            <div className="modal-booking-success-container">
                <i className="fa fa-check fa-4x"></i>
                <h1>{I18nManager.isRTL() ? "تهانينا!" : "Congratulations!"}</h1>
                <h2>{I18nManager.isRTL() ? "لقد قمت بتحديد موعد بنجاح ، وسوف نتواصل معك في أقرب وقت ممكن." : "You have succesfully made an appointment, We will contact with you as soon as possible."}</h2>
                <button onClick={props.onCloseClick}>
                    <span>{I18nManager.isRTL() ? "أغلق" : "Close"}</span>
                </button>
            </div>
        </Modal>
    )
}
