import React, { Component, } from 'react'
import StoreContext from '../../core/store/StoreContext'
import { postBookingOrder, postGetMasterClinic } from '../../core/api/Api'
import { getCookie } from '../../core/common-service/CommonService'
import { reception_db_data } from '../../core/firebase/fire/Fire'
import I18nManager from '../../core/I18nManager/I18nManager'
import ViewContainer from '../../core/routes/view-container/ViewContainer'
import CancelButton from '../_common/cancel-button/CancelButton'
import ModalSubmit from '../_common/modal-submit/ModalSubmit'
import './NewAppointment.css'

var IS_MOUNTED = true
var SELECTED_SERVICE = {}

export default class NewAppointment extends Component {
    static contextType = StoreContext

    state = {
        bookingTypes: [
            {
                title: I18nManager.isRTL() ? 'إستشارة طبية اون لاين' : 'Online Medical Consultation', uri: '/assets/img/recepionist.png',
                IsRemoteSession: true, IsOnSite: false, IsOnClinic: false, IsSecondOpinion: false,
            },
            {
                title: I18nManager.isRTL() ? 'زيارة طبية في موقعك' : 'Onsite Medical Visit', uri: '/assets/img/location-home.png',
                IsRemoteSession: false, IsOnSite: true, IsOnClinic: false, IsSecondOpinion: false,
            },
            {
                title: I18nManager.isRTL() ? 'إستشارة طبية في العيادة' : 'Clinic Medical Visit', uri: '/assets/img/clinic.png',
                IsRemoteSession: false, IsOnSite: false, IsOnClinic: true, IsSecondOpinion: false,
            },
        ],
        emergencyShowModal: false,
        helpShowModal: false,
        IsEmergency: false,
        IsAssistante: false,
        MasterClinicID: null,
        loading: false,
    }

    setSelectedService(IsRemoteSession, IsOnSite, IsOnClinic, IsSecondOpinion) {
        SELECTED_SERVICE = { IsRemoteSession, IsOnSite, IsOnClinic, IsSecondOpinion }
    }

    changeState(key, value) {
        this.setState({ [key]: value })
    }

    handlePermission() {
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
            if (result.state === 'granted') {
            } else if (result.state === 'prompt') {
            } else if (result.state === 'denied') {
                alert('You must enable location permission.')
            }
            result.onchange = function () { }
        });
    }

    onServicePress(item) {
        this.setSelectedService(item?.IsRemoteSession, item?.IsOnSite, item?.IsOnClinic, item?.IsSecondOpinion)
        this.setState({ emergencyShowModal: true })
    }

    onEmergencyModalResponse(value) {
        this.setState({ IsEmergency: value }, () => {
            if (value) {
                this.onBookNowPress()
            } else {
                this.setState({ emergencyShowModal: false, helpShowModal: true })
            }
        })

    }

    onHelpModalResponse(value) {
        const { IsEmergency } = this.state
        this.setState({ IsAssistante: value }, () => {
            if (value) {
                this.onBookNowPress()
            } else {
                this.setState({ helpShowModal: false })
                const obj = { ...SELECTED_SERVICE, IsEmergency, IsAssistante: value }
                this.props.history.push('/doctors-list', obj)
            }
        })

    }

    getMasterClinic() {
        postGetMasterClinic().then(res => {
            if (IS_MOUNTED) {
                this.setState({ MasterClinicID: res.data.data })
            }
        }).catch(e => this.getMasterClinic())
    }

    onBookNowPress() {
        const { UserProfileBaseInfo } = this.context
        if (UserProfileBaseInfo.FirstName && UserProfileBaseInfo.LastName) {
            const { MasterClinicID, IsEmergency, IsAssistante } = this.state
            navigator.geolocation.getCurrentPosition(position => {
                let CouponDetails = {}
                try {
                    CouponDetails = JSON?.parse(getCookie('selectedCouponCode'))
                } catch (error) { }

                const obj = {
                    DoctorID: null, ClinicID: MasterClinicID,
                    Patientlatitude: position?.coords?.latitude, Patientlongitude: position?.coords?.longitude,
                    ...SELECTED_SERVICE, IsEmergency, TriggerNotificationID: null,
                    IsPaid: false, OrderWithRecepionist: false,
                    CouponID: CouponDetails?.CouponID, CouponCode: CouponDetails?.CouponCode,
                    CouponPercentage: CouponDetails?.Percentage, CouponAmount: CouponDetails?.Amount,
                    CouponIsPercent: CouponDetails?.IsPercent, CouponIsCampaign: CouponDetails?.IsCampaign,
                    CouponPaymentTypeID: CouponDetails?.PaymentTypeID, CouponServiceProviderID: CouponDetails?.ServiceProviderID,
                    CouponEndDate: CouponDetails?.EndDate
                }
                this.setState({ loading: true })
                postBookingOrder(obj).then(async res => {
                    var data = res.data.data
                    if (IS_MOUNTED) {
                        if (res?.data?.StatusCode === 100) {
                            data.Questions = res?.data?.questions
                            const tmp_item = { ...data, ...obj, IsSpecialitySelected: false, IsEmergency, IsAssistante, ConsumerDetails: UserProfileBaseInfo }
                            await reception_db_data(data.BookingID).update(tmp_item).then(() => {
                                this.setState({ loading: false })
                                this.props.history.push('/assistant-chat/' + data?.BookingID, tmp_item)
                            })
                        } else if (res?.data?.StatusCode === 111) {
                            const message = I18nManager.isRTL() ? 'الطبيب غير متوفر' : 'Doctor isn\'t available'
                            alert(message)
                            this.setState({ loading: false })
                        } else {
                            const message = I18nManager.isRTL() ? 'نعتذر، لا يمكنك تنفيذ اكثر من ٣ طلبات في نفس الوقت. يمكنك الغاء اي من الطلبات المتعلقة او الجارية.' :
                                'Sorry, you can\'t place more than 3 order at the same time. you can cancel from the pending orders.'
                            alert(message)
                            this.setState({ loading: false })
                        }
                    }
                }).catch(e => { this.setState({ loading: false }) })
            }, (error) => { this.handlePermission() });
        }
    }

    componentWillUnmount() {
        IS_MOUNTED = false
    }

    componentDidMount() {
        IS_MOUNTED = true
        this.getMasterClinic()
    }

    render() {
        const { emergencyShowModal, helpShowModal, loading, bookingTypes } = this.state
        return (
            <ViewContainer>
                <div className="container patient-plus-bg d-flex flex-column align-items-center justify-content-center">
                    <ModalSubmit isOpen={emergencyShowModal} text={I18nManager.isRTL() ? 'هل هذه حالة طارئة؟' : 'Is this an Emergency case?'}
                        uri="/assets/img/question.png" leftButtonText={I18nManager.isRTL() ? 'نعم' : 'Yes'} rightButtonText={I18nManager.isRTL() ? 'كلا' : 'No'}
                        loading={loading} onRequestClose={() => this.changeState('emergencyShowModal', false)}
                        onLeftButtonClick={() => this.onEmergencyModalResponse(true)} onRightButtonClick={() => this.onEmergencyModalResponse(false)}
                    />
                    <ModalSubmit isOpen={helpShowModal} text={I18nManager.isRTL() ? 'هل تريد مساعدة من دك ناو؟' : 'Would you like DocNow to help you?'} uri="/assets/img/question.png"
                        leftButtonText={I18nManager.isRTL() ? 'نعم' : 'Yes'} rightButtonText={I18nManager.isRTL() ? 'كلا' : 'No'} onRequestClose={() => this.changeState('helpShowModal', false)} loading={loading}
                        onLeftButtonClick={() => this.onHelpModalResponse(true)} onRightButtonClick={() => this.onHelpModalResponse(false)}
                    />
                    <div className="align-self-center main-container p-20">
                        <CancelButton />
                        <div className="d-flex justify-content-center">
                            <img src="/assets/img/calendar.png" className="img-icon" alt="" />
                        </div>
                        <h5 className="pt-20 pb-20 text-center">{I18nManager.isRTL() ? "موعد جديد" : "New Appointment"}</h5>
                        <div className="d-flex flex-row flex-wrap justify-content-center">
                            {bookingTypes.map((item, index) => (
                                <div key={index} onClick={() => this.onServicePress(item)} className="item-container d-flex flex-column align-items-center justify-content-center p-5 mb-10 mr-10">
                                    <img src={item.uri} className="item-container-icon" alt="" />
                                    <span className="text-center pt-20">{item.title}</span>
                                </div>
                            ))}
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="horizontal-line" />
                            <h6 className="text-center ml-10 mr-10 mt-20 mb-20">{I18nManager.isRTL() ? "أو الحصول على رأي الطبيب الثاني" : "Or have a doctor second opinion"}</h6>
                            <div className="horizontal-line" />
                        </div>
                        <div className="d-flex justify-content-center">
                            <img src="/assets/img/second-opinion.png" className="item-container-icon-second-opinion d-flex" alt="" />
                        </div>
                    </div>
                </div>
            </ViewContainer>
        )
    }
}