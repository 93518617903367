import React from 'react'
import { BASE_URL } from '../../../core/api/Api'
import I18nManager from '../../../core/I18nManager/I18nManager'
import ReadMoreText from '../../_common/react-more-text/ReadMoreText'
import './OfferItem.css'

const OfferItem = ({ item }) => {

    function getCouponDiscount(item) {
        return (item?.IsPercent ? (item?.Percentage + ' %') : (item?.Amount + ' ' + (I18nManager.isRTL() ? 'ريال' : 'SAR'))) + ' ' + (I18nManager.isRTL() ? 'خصم' : 'disc.')
    }

    function getPriceAfterDiscount(item) {
        return (item?.IsPercent ? (item?.OfferAmount - (item?.OfferAmount * (item?.Percentage / 100))) : (item?.OfferAmount - item?.Amount)) + ' ' + (I18nManager.isRTL() ? 'ريال' : 'SAR')
    }

    function getDiscountAmount(item) {
        return (I18nManager.isRTL() ? 'ستوفر' : 'Savings') + ' ' + (item?.IsPercent ? (item?.OfferAmount * (item?.Percentage / 100)) : item?.Amount) + ' ' + (I18nManager.isRTL() ? 'ريال' : 'SAR')
    }

    return (
        <div className="offers-img-main-container">
            <div className="position-relative">
                <img loading="lazy" src={BASE_URL + item?.OfferImage} className="custom-shadow img-fluid" alt="" />
                <div className="offer-overlay-container">
                    <div className="offer-overlay-subcontainer">
                        <small className="text-white font-bold">{getCouponDiscount(item)}</small>
                    </div>
                </div>
                {item?.[I18nManager.isRTL() ? "ClinicNameAr" : "ClinicName"] &&
                    <div className="offer-overlay-clinic-container">
                        <span>{item?.[I18nManager.isRTL() ? "ClinicNameAr" : "ClinicName"]}</span>
                    </div>
                }
            </div>
            <div className="mt-10">
                <span className="offers-item-title">{item?.[I18nManager.isRTL() ? "OfferTitleAr" : "OfferTitle"]}</span>
            </div>
            <span className="offers-item-subtitle">{item?.[I18nManager.isRTL() ? "OfferSubTextAr" : "OfferSubText"]}</span>
            <ReadMoreText className="offers-item-description" content={
                <span>{item?.[I18nManager.isRTL() ? 'OfferTextAr' : 'OfferText']}</span>
            } />
            <div className="d-flex justify-content-between">
                <div className="d-flex flex-column">
                    <div className="d-flex align-items-center">
                        <span className="offers-item-before-price">{item?.OfferAmount + " " + (I18nManager.isRTL() ? 'ريال' : 'SAR')}</span>
                        <span className="offers-item-saving">{getDiscountAmount(item)}</span>
                    </div>
                    <span className="offers-item-after-price">{getPriceAfterDiscount(item)}</span>
                </div>
                <button className="offers-item-button">{I18nManager.isRTL() ? 'احجز الحين' : 'Book now'}</button>
            </div>
        </div>
    )
}

export default OfferItem
