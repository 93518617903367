import React, { useEffect } from 'react'
import Modal from 'react-modal';
import { invoiceModalStyle } from '../../../../core/styles/custom-styles';
import InvoiceView from './invoice-view/InvoiceView';
import './ModalInvoice.css';

export default function ModalInvoice(props) {

    useEffect(() => {
        Modal.setAppElement('body')
    }, [])

    const { Data } = props
    return (
        <Modal {...props} style={invoiceModalStyle} shouldCloseOnEsc={true} shouldCloseOnOverlayClick={true}>
            <InvoiceView Data={Data} />
        </Modal>
    )
}
