import React, { useEffect, useState } from 'react'
import I18nManager from '../../../core/I18nManager/I18nManager'
import './TimeslotAppointments.css'
import moment from 'moment';
import Auth from '../../../core/routes/auth/Auth';
import { getPhoneNumberDetails } from '../../../core/common-service/CommonService';
import FlipCloseButton from '../flip-close-button/FlipCloseButton';
import FlipCard from '../flip-card/FlipCard';

const DoctorTimeslotAppointments = ({
    style,
    data, loading, onBookNowPress, onPreviousPress, onNextPress, onIsMoreClick, onTimeSlotClick,
}) => {
    const [MobileNo, setMobileNo] = useState('')
    const [FullName, setFullName] = useState('')
    const [Password, setPassword] = useState('')
    // const [Email, setEmail] = useState('')
    const [formErrors, setFormErrors] = useState({
        FullName: '',
        MobileNo: '',
        // Email: '',
        // Password: ''
    })
    // const [countryCode, setCountryCode] = useState(null)
    const [isAppointmentBookingFlipped, setIsAppointmentBookingFlipped] = useState(false)
    const flipCardAppointmentBooking = (e) => {
        e.preventDefault()
        setIsAppointmentBookingFlipped(prevState => !prevState)
    }

    const getDate = (date) => {
        if (moment().format('L') === moment(date).format('L')) {
            return (I18nManager.isRTL() ? "اليوم" : "Today")
        } else if (moment(date).format('L') === moment().add(1, 'day').format('L')) {
            return (I18nManager.isRTL() ? "غدا" : "Tomorrow")
        } else {
            return moment(date).format("MMM Do YY")
        }
    }

    const setTimeSlot = (item) => {
        const timeSlots = item?.timeSlots
        if (timeSlots?.length > 4) {
            return item?.isMorePressed ? timeSlots : timeSlots.slice(0, timeSlots?.length === 5 ? 5 : 4)
        } else {
            return timeSlots.concat(Array(5 - timeSlots?.length).fill(0))
        }
    }

    const renderMoreArrow = (item) => (
        item?.isMorePressed ? <span>{I18nManager.isRTL() ? "أقل" : "Less"} <i className="fa fa-chevron-up"></i></span> :
            <span>{I18nManager.isRTL() ? "أكثر" : "More"} <i className="fa fa-chevron-down"></i></span>
    )

    const renderBody = (item, index) => (
        item?.timeSlots?.length === 0 ? (
            <>
                {Array.from(Array(5).keys()).map((arrItem, i) => (
                    <div key={arrItem} className="timeslot-sub-title" style={{ backgroundColor: '#f0f1f3' }}>
                        {i === 2 ? <span>{I18nManager.isRTL() ? "غير متاح" : "Not Available"}</span> : <span>&nbsp;</span>}
                    </div>
                ))}
            </>
        ) :
            (setTimeSlot(item)?.map((el, el_index) => (
                <div key={el_index} className={(el?.IsBooked ? "timeslot-sub-title-booked" : "") + " timeslot-sub-title " + (el?.active ? "timeslot-sub-title-active" : "")}
                    onClick={(el?.From && !el?.IsBooked) ? onTimeSlotClick(index, el_index) : undefined}>
                    <span>{el?.From ? moment(el?.From, "hh:mm").format('LT') : "-"}</span>
                </div>
            )))
    )

    const onInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        switch (name) {
            case 'FullName':
                const fullNameValid = value.match(/^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/)
                setFormErrors({ ...formErrors, FullName: fullNameValid ? true : false })
                setFullName(value)
                break;
            case 'MobileNo':
                const phoneDetails = getPhoneNumberDetails(value)
                setMobileNo(phoneDetails?.format ?? value)
                if (phoneDetails) {
                    // setCountryCode(phoneDetails.countryCode ?? null)
                    setFormErrors({ ...formErrors, MobileNo: (phoneDetails?.isValid && value?.length >= 6) ? true : false })
                } else {
                    // setCountryCode(null)
                }
                break;
            case 'Email':
                // const emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                // setFormErrors({ ...formErrors, Email: emailValid ? true : false })
                // setEmail(value)
                break;
            // case 'Password':
            //     setFormErrors({ ...formErrors, Password: value.length > 7 ? true : false })
            //     setPassword(value)
            //     break;
            default:
                break;
        }
    }

    const checkFormErrors = () => {
        return Object.values(formErrors).every(item => item === true)
    }

    const _onBookNowPress = (e) => {
        if (!Auth.isAuthenticated() && !isAppointmentBookingFlipped) {
            flipCardAppointmentBooking(e)
        } else {
            onBookNowPress(FullName, MobileNo, Password, '')
            if (!Auth.isAuthenticated()) {
                flipCardAppointmentBooking(e)
            }
        }
    }

    const isSlotIsSelected = () => {
        return data?.find(el => el.timeSlots.find(el => el.active === true))
    }

    useEffect(() => {
        if (Auth.isAuthenticated()) {
            Auth.login(() => { })
        } else {
            Auth.logout(() => { })
        }
    }, [Auth.isAuthenticated()])

    return (
        <div className="home-services-container" style={style}>
            {isAppointmentBookingFlipped && !loading && <FlipCloseButton onClick={flipCardAppointmentBooking} />}
            <h3 className="appointment-booking-title">{I18nManager.isRTL() ? "حجز موعد" : "Appointment Booking"}</h3>
            <FlipCard isFlipped={isAppointmentBookingFlipped}
                frontChildren={
                    <div className="col-12 d-flex flex-column align-items-center">
                        <div>
                            <div className="d-flex justify-content-start w-100">
                                <h6 className="appointment-booking-sub-title">{I18nManager.isRTL() ? "حجز موعد" : "Appointment reservation"}</h6>
                            </div>
                            <div className="d-flex align-items-center">
                                <button disabled={loading} className={"timeslot-next-prev " + (I18nManager.isRTL() ? "ml-10" : "mr-10")} onClick={onPreviousPress}>
                                    <i className={"fa fa-chevron-" + (I18nManager.isRTL() ? "right" : "left")}></i>
                                </button>
                                <div className="d-flex flex-wrap justify-content-center">
                                    {data?.map((item, index) => (
                                        <div key={index} className={"timeslot-item-container mb-10 " + (index === 1 ? "ml-10 mr-10" : "")} >
                                            <div className="timeslot-title">
                                                <span className="text-white">{getDate(item?.Date)}</span>
                                            </div>
                                            {renderBody(item, index)}
                                            {item?.timeSlots?.length > 5 &&
                                                <div className="timeslot-more-button" onClick={onIsMoreClick(index)}>
                                                    {renderMoreArrow(item)}
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                                <button disabled={loading} className={"timeslot-next-prev " + (I18nManager.isRTL() ? "mr-10" : "ml-10")} onClick={onNextPress}>
                                    <i className={"fa fa-chevron-" + (I18nManager.isRTL() ? "left" : "right")}></i>
                                </button>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button className="timeslot-book-button" disabled={!isSlotIsSelected() || loading} onClick={_onBookNowPress}>
                                <span>{Auth.isAuthenticated() ? (I18nManager.isRTL() ? 'احجز الحين' : 'Book now') : (I18nManager.isRTL() ? 'استمر' : 'Continue')} </span>
                            </button>
                        </div>
                    </div>
                }
                backChildrem={
                    <div className="col-12">
                        <div className="d-flex align-items-center login-input-container mt-25"
                            style={{ borderColor: formErrors.FullName ? '#00ff00' : undefined }}>
                            <img loading="lazy" src="/assets/img/full-name.svg" alt="" />
                            <input type="text" name="FullName" value={FullName} onChange={onInputChange} placeholder={I18nManager.isRTL() ? 'الاسم بالكامل' : 'Full Name'} />
                        </div>
                        <div className="d-flex align-items-center login-input-container mb-25 mt-25"
                            style={{ borderColor: formErrors.MobileNo ? '#00ff00' : undefined }}>
                            <img loading="lazy" src="/assets/img/noun-call.svg" alt="" />
                            <input type="tel" name="MobileNo" value={MobileNo} onChange={onInputChange} placeholder="+966 55 555 5555" />
                        </div>
                        {/* <div className="d-flex align-items-center login-input-container mb-25 mt-25"
                            style={{ borderColor: formErrors.Password ? '#00ff00' : undefined }}>
                            <img loading="lazy" src="/assets/img/password.svg" alt="" />
                            <input type="password" name="Password" value={Password} onChange={onInputChange} placeholder={I18nManager.isRTL() ? "كلمه السر" : "Password"} />
                        </div> */}
                        <div className="d-flex justify-content-center">
                            <button className="timeslot-book-button" disabled={!checkFormErrors() || loading} onClick={_onBookNowPress}>
                                <span>{I18nManager.isRTL() ? 'احجز الحين' : 'Book now'}</span>
                            </button>
                        </div>
                        {/* <div className="d-flex align-items-center login-input-container mb-25 mt-25"
                        style={{ borderColor: formErrors.Email ? '#00ff00' : undefined }}>
                        <img loading="lazy" src="/assets/img/email.svg" alt="" />
                        <input type="email" name="Email" value={Email} onChange={onInputChange} placeholder={I18nManager.isRTL() ? "أدخل الايميل" : "Enter your email"} />
                    </div> */}
                    </div>
                }
            />
        </div>
    )
}

export default DoctorTimeslotAppointments
