import axios from 'axios';

// export const addon_prefix = 'admin'
export const addon_prefix = 'cp'

export const BASE_URL = (addon_prefix === 'admin' ? 'https://' : 'http://') + addon_prefix + '.docnow247.com/'

axios.defaults.baseURL = BASE_URL + 'api/';
axios.defaults.timeout = 30000;

const HYPERPAY_BASEURL = 'https://' + (addon_prefix === 'admin' ? '' : 'test.') + 'oppwa.com/v1/'
const HYPERPAY_TOKEN = addon_prefix === 'admin' ? "Bearer OGFjZGE0Yzk3MjkyZjQ3MTAxNzJiMTY1NDA2ZjEwMWJ8emZyWVduNThwcw==" : "Bearer OGFjN2E0Y2E3MWNiYzU5ZjAxNzFkOWY2OGQ4MTEzMTJ8NWptYUJSUGRxMw=="
const GOOGLE_API = 'AIzaSyBQ8SHQ3-80PspE8HsPbZn53opJ8Qb8ecs'

export function setDefaultHeader(value) {
    axios.defaults.headers.common['Authorization'] = value
}

export function getCoordsFromIp() {
    return axios.post('https://www.googleapis.com/geolocation/v1/geolocate?key=' + GOOGLE_API, { considerIp: true })
}

// HyperPay
export function postInitialHyperPayRequest(params) {
    const instance = axios.create({
        baseURL: HYPERPAY_BASEURL,
        headers: { Authorization: HYPERPAY_TOKEN }
    })
    return instance.post('checkouts', null, { params })
}
export function getHyperPayPaymentStatus(checkoutID, entityId) {
    const instance = axios.create({
        baseURL: HYPERPAY_BASEURL,
        headers: { Authorization: HYPERPAY_TOKEN }
    })
    return instance.get('checkouts/' + checkoutID + '/payment?entityId=' + entityId)
}
export function getHyperPayTransactionReport(checkoutID, entityId) {
    const instance = axios.create({
        baseURL: HYPERPAY_BASEURL,
        headers: { Authorization: HYPERPAY_TOKEN }
    })
    return instance.get('query/' + checkoutID + '?entityId=' + entityId)
}


// Registration
export function postPatientProfile(data) {
    return axios.post('PatientProfile', data)
}
export function postVerifyPatientProfile(data) {
    return axios.post('VerifyPatientProfile', data)
}

// Patient Profile
export function postGetPatientProfile() {
    return axios.post('GetPatientProfile')
}
export function postSavePatientProfileData(data) {
    return axios.post('SavePatientProfileData', data)
}
export function postSaveProfilePicture(data) {
    return axios.post('SaveProfilePicture', data)
}

// Booking
export function postGetPatientOrders(data) {
    return axios.post('GetPatientOrders', data)
}
export function postPatientOrderRequestsList() {
    return axios.post('PatientOrderRequestsList')
}
export function postCancelBookingRequest(data) {
    return axios.post('CancelBookingRequest', data)
}
export function postGetPatientOrderByID(id) {
    return axios.post('GetPatientOrderByID/' + id)
}
export function postAcceptAddonRequest(data) {
    return axios.post('AcceptAddonRequest', data)
}

// My People
export function postGetRelationshipLookup() {
    return axios.post('GetRelationshipLookup')
}
export function postGetPeopleForPatient(id) {
    return axios.post('GetPeopleForPatient/' + id)
}
export function postAddPeopleForPatient(data) {
    return axios.post('AddPeopleForPatient', data)
}
export function postUpdatePeopleForPatient(data) {
    return axios.post('UpdatePeopleForPatient', data)
}
export function postDeletePeopleForPatient(data) {
    return axios.post('DeletePeopleForPatient', data)
}

// Medical Report
export function postGetReportTypes() {
    return axios.post('GetReportTypes')
}
export function postSavePatientMedicalReport(data) {
    return axios.post('SavePatientMedicalReport', data)
}
export function postGetPatientMedicalReports(id) {
    return axios.post('GetPatientMedicalReports/' + id)
}
export function postDeletePatientMedicalReport(data) {
    return axios.post('DeletePatientMedicalReport', data)
}

const apiKey = '1bvMjQL2B6OYxDQw2VN0lk8CxrU7kYQx_URmsTOdz_A'
export function getMapStaticImage(lat, long, w, h) {
    return 'https://image.maps.ls.hereapi.com/mia/1.6/mapview?apiKey=' + apiKey + '&c=' + lat + ',' + long + '&t=0&z=16&w=' + w + '&h=' + h
}

// Payments
export function postGetPatientPayments(data) {
    return axios.post('GetPatientPayments', data)
}
export function postGetPaymentMethods(data) {
    return axios.post('GetPaymentMethods', data)
}
export function postGetCampaignCoupons(id) {
    return axios.post('GetCampaignCoupons/' + id)
}

// Saved Location
export function postGetPatientSavedLocation(id) {
    return axios.post('GetPatientSavedLocation/' + id)
}
export function postAddPatientSavedLocation(data) {
    return axios.post('AddPatientSavedLocation', data)
}
export function postUpdatePatientSavedLocation(data) {
    return axios.post('UpdatePatientSavedLocation', data)
}
export function postDeletePatientSavedLocation(data) {
    return axios.post('DeletePatientSavedLocation', data)
}

// Active Specialities Services
export function postGetServiceCategories(data) {
    return axios.post('GetServiceCategories/1', data)
}

export function postLogout(data) {
    return axios.post('Logout', data)
}

// Google Places API
var key = 'AIzaSyBQ8SHQ3-80PspE8HsPbZn53opJ8Qb8ecs'
export function getAutoCompleteResult(input) {
    return axios.get('https://maps.googleapis.com/maps/api/place/autocomplete/json', { params: { key, input, fields: 'All' } })
}
export function getAutoCompleteResultItemDetails(place_id) {
    return axios.get('https://maps.googleapis.com/maps/api/place/details/json', { params: { key, place_id } })
}

// Doctors Info
export function postGetDoctorLocationWithProfile(data) {
    return axios.post('GetDoctorLocationWithProfile', data)
}
export function postGetDoctorAvailabilityForPatient(data) {
    return axios.post('GetDoctorAvailabilityForPatient', data)
}
export function postGetDistanceAndDuration(data) {
    return axios.post('GetDistanceAndDuration', data)
}
export function postGetOccupation() {
    return axios.post('GetOccupation')
}
export function postGetDoctorTimeSlots(data) {
    return axios.post('GetDoctorTimeSlots', data)
}
export function postGetDoctorByID(id) {
    return axios.post('GetDoctorByID/' + id)
}

// Chat
export function postPatientUploadFileAndGetUrl(data) {
    return axios.post('PatientUploadFileAndGetUrl', data)
}
export function postAddBookingOrderforChatBox(data) {
    return axios.post('AddBookingOrderforChatBox', data)
}
export function postAddBookingOrder(data) {
    return axios.post('AddBookingOrder', data)
}
export function postNotificationForChat(data) {
    return axios.post('NotificationForChat', data)
}
export function postGetVoiceNoteSetting() {
    return axios.post('GetVoiceNoteSetting')
}
export function postReOpenBooking(data) {
    return axios.post('ReOpenBooking', data)
}
export function postCompleteBookingRequest(data) {
    return axios.post('CompleteBookingRequest', data)
}
export function postPayAmount(data) {
    return axios.post('PayAmount', data)
}
export function postGetTotalAmount(data) {
    return axios.post('GetTotalAmount', data)
}

// Recepionist
export function postBookingOrder(data) {
    return axios.post('BookingOrder', data)
}

// Clinic Info
export function postGetClinics(data) {
    return axios.post('GetClinics', data)
}
export function postGetDoctorsByClinics(data) {
    return axios.post('GetDoctorByClinic', data)
}
export function postGetMasterClinic() {
    return axios.post('GetMasterClinic')
}

// Create PDF
export function postCreateInvoice(data) {
    return axios.post('CreateInvoice', data)
}
export function postCreatePrescription(data) {
    return axios.post('CreatePrescription', data)
}

// Notification
export function postGetPatientNotification() {
    return axios.post('GetPatientNotification')
}

// Business Info
export function postGetBusinessInfo() {
    return axios.post('GetBusinessInfo')
}
export function postGetBusinessInformations(id) {
    return axios.post('GetBusinessInformations/' + id)
}

// Home Service
export function postGetHomeSerives(data) {
    return axios.post('GetHomeSerives', data)
}


// Coupon Code
export function postActivateCoupons(data) {
    return axios.post('ActivateCoupons', data)
}


// Chat History
export function postSaveChatHistory(data) {
    return axios.post('SaveChatHistory', data)
}


export function postGetEducationLevel() {
    return axios.post('GetEducationLevel')
}

export function postGetNationality() {
    return axios.post('GetNationality')
}