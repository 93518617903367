import React, { useEffect, useState } from 'react'
import { BASE_URL, getCoordsFromIp, postGetClinics } from '../../../core/api/Api'
import './ClinicsList.css';
import I18nManager from '../../../core/I18nManager/I18nManager';
import ViewContainer from '../../../core/routes/view-container/ViewContainer';
import FAQ from '../../../core/routes/faq/FAQ';
import TopFilter from '../../_common/filter/top-filter/TopFilter';
import TopViewBg from '../../_common/top-view-bg/TopViewBg';

var IS_MOUNTED = true
export default function ClinicsList(props) {
    const [data, setData] = useState([])

    function getClinics() {
        getCoordsFromIp().then(coords => {
            const obj = {
                Latitude: coords?.data?.location?.lat, Longitude: coords?.data?.location?.lng,
                // 0 is to get all doctors and then filter when choosing speciality
                Radius: null, IsRemoteSession: false, IsOnClinic: true
            }
            postGetClinics(obj).then(res => {
                var data = res.data.data
                if (IS_MOUNTED) {
                    setData(data?.ProfileData)
                }
            }).catch(e => { })
        }).catch(e => { getClinics() })
    }


    const goToClinicDetails = (item) => () => {
        props.history.push('/clinic-details/' + item?.Profile?.ClinicID, item)
    }

    useEffect(() => {
        IS_MOUNTED = true
        getClinics()
        return () => {
            IS_MOUNTED = false
        }
    }, [])

    return (
        <ViewContainer>

            <TopViewBg title={I18nManager.isRTL() ? "أفضل العيادات في السعودية" : "Best Clinics in Saudi"} />

            <TopFilter />

            <div className="container">
                <div className="row">
                    {data?.map((item, index) => (
                        <div key={index} className="col-md-6 col-12" onClick={goToClinicDetails(item)}>
                            <div className="clinic-item-container">
                                <div className="profile-container">
                                    <img loading="lazy" className="rounded-circle" src={item?.Profile?.Image ? (BASE_URL + item?.Profile?.Image) : '/assets/img/clinic.png'} alt="" />
                                </div>
                                <div className={I18nManager.isRTL() ? "mr-10" : "ml-10"}>
                                    <h1>{item?.Profile?.[I18nManager.isRTL() ? "ClinicNameAr" : "ClinicName"]}</h1>
                                    <div className="d-flex align-items-center mb-10 mt-10">
                                        <img wid='16' height='16' loading="lazy" src="/assets/img/choose-speciality.svg" alt="" />
                                        <span className={I18nManager.isRTL() ? "mr-10" : "ml-10"}>{item?.ClinicSpecialities?.length || 0} {I18nManager.isRTL() ? "الإختصاص" : "Speciality"}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img wid='16' height='16' loading="lazy" src="/assets/img/speak-with-psychologist.svg" alt="" />
                                        <span className={I18nManager.isRTL() ? "mr-10" : "ml-10"}>{item?.DoctorCount || 0} {I18nManager.isRTL() ? "أطباء" : "Doctors"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <FAQ />

        </ViewContainer>
    )
}